import React from "react"
import moment from 'moment-timezone';
import tzIds from "tz-ids";
import Select, { createFilter } from 'react-select';
import cityList from "../components/citylist";
import { FixedSizeList as List } from "react-window";

class ToMeet extends React.Component {

    constructor(props){
        super(props);
        var thisZone = moment.tz.guess();
        var lab = thisZone.split("/")
        this.state = {
            startTimezone: {
                value: thisZone,
                label: lab[lab.length-1].replace("_", " ")},
            oneTimezone: {value:'Australia/Melbourne', label: 'Melbourne - Australia'},
            twoTimeZone: {value:'Europe/London', label: 'London - United Kingdom'},
        }
    }    
    handleTimezoneChange = (val) => {
        if(val !== null){
            this.setState( {startTimezone: val})
        }
    }
    handleOneChange = (val) => {
        if(val !== null) {
            this.setState( {oneTimezone: val})
        }
    }

    render() {
        var i = 0;
        var out_table = [];

        //var lab;
        //var tzIdsObject = []
        /*
        for (const id of tzIds){
            lab = id.split("/")
            tzIdsObject.push({
                value:id,
                label:lab[lab.length-1].replace("_", " "),
            })
        }

        tzIds.sort(
            function(a,b) {
                if (a.label < b.label) return -1;
                if (a.label > b.label) return 1;
                return 0;
            }
        )
        */
        var timeCurrent = moment().tz(this.state.startTimezone.value);
        var addHour = 0;
        var timeOther; //= moment(timeCurrent).tz(this.state.oneTimezone.value);
        var otherCell;
        //var youCanCallText = "You can call " + this.state.oneTimezone.label + " ";
        // one solution is to add text to instant scroll to the next time

        for (addHour = 0; addHour < 24; addHour++){
            timeOther = moment(timeCurrent).tz(this.state.oneTimezone.value);
            if(timeOther.hour() > 8 && timeOther.hour() < 20){
                otherCell = <div className="col-lg-6">
                <p>
                        {this.state.oneTimezone.label}{": "}
                        <mark class="tertiary inline-block">
                        <strong>{timeOther.format('HH:00 (a)')}</strong>
                        {" ✔"}
                    </mark>
                </p>
                </div>

            } else {
                otherCell = <div className="col-lg-6">
                <p>
                        {this.state.oneTimezone.label}{": "}
                        <mark class="secondary inline-block">
                        {timeOther.format('HH:00 (a)')}
                        {" ✘"}
                    </mark>
                </p>
                </div>
            }
            
            out_table.push(
                <div className="row time-row">
                    <div className="col-lg-6">
                        <p>
                            {this.state.startTimezone.label}{": "}
                            <mark class="primary inline-block">
                            {timeCurrent.format('HH:00 (a)')}
                        </mark>
                        </p>
                    </div>
                    {otherCell}
                </div>
            )
            timeCurrent.add(1, 'hour');
        }
        /*
        for (addHour = 0; addHour < 24; addHour++){
            timeOther = moment(timeCurrent).tz(this.state.oneTimezone.value);
            if(timeOther.hour() > 8 && timeOther.hour() < 20){
                otherCell = <td>
                        {this.state.oneTimezone.label}{": "}
                        <mark class="tertiary inline-block">
                        <strong>{timeOther.format('HH:00 (a)')}</strong>
                        {" ✔"}
                    </mark>
                </td>

            } else {
                otherCell = <td>
                        {this.state.oneTimezone.label}{": "}
                        <mark class="secondary inline-block">
                        {timeOther.format('HH:00 (a)')}
                        {" ✘"}
                    </mark>
                </td>
            }
            
            
            out_table.push(
                <tr className="tomeet-row">
                    <td>
                        
                            {this.state.startTimezone.label}{": "}
                            <mark class="primary inline-block">
                            {timeCurrent.format('HH:00 (a)')}
                        </mark>
                    </td>
                    {otherCell}
                </tr>
            )
            timeCurrent.add(1, 'hour');
        }
        */
        // Algorithm:
        // 1. for each hour in currenttime+24 hours
        // 2. table row with col1: this hour col2: if other hour is accepetable, other hour and its time else empty

        const stringify = option => option.label;
        const filterOption = createFilter({ignoreCase: true, stringify});
        return (
            <div>
                <fieldset>
                    <div className="row cols-lg-6 cols-sm-12">
                    <div>
                    <Select
                        options={cityList}
                        value={this.state.startTimezone}
                        onChange={this.handleTimezoneChange}
                        filterOption={filterOption}
                        components={{MenuList}}
                    />
                    </div>
                    <div>
                    <Select
                        options={cityList}
                        value={this.state.oneTimezone}
                        onChange={this.handleOneChange}
                        filterOption={filterOption}
                        components={{MenuList}}
                    />
                    </div>
                    </div>
                </fieldset>    
                
                <div>
                    {out_table}
                </div>
            </div>
        )
    }
}
/*
<table 
                    style={{
                        maxHeight: "640px",
                    }}
                    id="tomeet"
                >
                    <thead></thead>
                    <tbody>
                        {out_table}
                    </tbody>
                </table>
*/
/*
<thead style={{
                        zIndex: 0
                    }}>
                        <tr>
                            
                            <th>
                                Your time ({this.state.startTimezone.label})
                            </th>
                            <th>
                                Their time ({this.state.oneTimezone.label})
                            </th>
                        </tr>
                    </thead>
*/
const height = 35;
class MenuList extends React.Component {
    render() {
        const { options, children, maxHeight, getValue } = this.props;
        const [value] = getValue();
        const initialOffset = options.indexOf(value) * height;
    
        return (
          <List
            height={maxHeight}
            itemCount={children.length}
            itemSize={height}
            initialScrollOffset={initialOffset}
          >
            {({ index, style }) => <div style={style}>{children[index]}</div>}
          </List>
        );
      }
}
export default ToMeet