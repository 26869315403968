import React from "react"

import tzIds from "tz-ids";
import Select from "react-select";
import moment from 'moment-timezone';

class ToCall extends React.Component {

    constructor(props){
        super(props);

        this.state = {
            proposedTimezone: {value:'Australia/Sydney', label: 'Sydney'},
        }
    }

    handleProposedZoneChange = (val) => {
        if(val !== null){
            this.setState( {proposedTimezone: val})
        }
    }

    render() {

        var currentTime = moment.tz.guess();
        var lab;
        var tzIdsObject = []
        for (const id of tzIds){
            lab = id.split("/")
            tzIdsObject.push({
                value:id,
                label:lab[lab.length-1].replace("_", " "),
            })
        }

        tzIds.sort(
            function(a,b) {
                if (a.label < b.label) return -1;
                if (a.label > b.label) return 1;
                return 0;
            }
        )

        var theirTime = moment().tz(this.state.proposedTimezone.value);

        var startSaneTime = moment().tz(this.state.proposedTimezone.value);
        startSaneTime.hour(8);
        startSaneTime.minute(0);

        var endSaneTime = moment().tz(this.state.proposedTimezone.value);
        endSaneTime.hour(20);
        endSaneTime.minute(0);

        var shouldYouCall = <mark class="secondary">Not a great time</mark>;

        if (theirTime.isAfter(startSaneTime) && theirTime.isBefore(endSaneTime)){
            shouldYouCall = <mark class="tertiary">Can be called!</mark>;
        }

        var londonTime = moment().tz('Europe/London');
        const londonEnd = moment().tz('Europe/London');
        londonEnd.hour(20);
        londonEnd.minute(0);
        const londonStart = moment().tz('Europe/London');
        londonStart.hour(8);
        londonStart.minute(0);

        var beijingTime = moment().tz('Asia/Shanghai');
        const beijingEnd = moment().tz('Asia/Shanghai');
        beijingEnd.hour(20);
        beijingEnd.minute(0);
        const beijingStart = moment().tz('Asia/Shanghai');
        beijingStart.hour(8);
        beijingStart.minute(0);

        var newYorkTime = moment().tz('America/New_York');
        const newYorkEnd = moment().tz('America/New_York');
        newYorkEnd.hour(20);
        newYorkEnd.minute(0);
        const newYorkStart = moment().tz('America/New_York');
        newYorkStart.hour(8);
        newYorkStart.minute(0);

        var london = <div>
            <p>London<br/>{londonTime.format("h:mm a")}
                <hr/>
            <mark class="secondary">Not a great time</mark>
            </p>
        </div>;

        var beijing = <div>
        <p>Beijing<br/>{beijingTime.format("h:mm a")}<hr/>
        <mark class="secondary">Not a great time</mark>
        </p>
        </div>;

        var newYork = <div>
        <p>New York<br/>{newYorkTime.format("h:mm a")}<hr/>
        <mark class="secondary">Not a great time</mark>
        </p>
        </div>;

        if(londonTime.isAfter(londonStart) && londonTime.isBefore(londonEnd)){
            london = <div>
                <p>
                    London<br/>{londonTime.format("h:mm a")}
                <hr/>
                <mark class="tertiary">Can be called!</mark>
                </p>
            </div>
        }

        if(beijingTime.isAfter(beijingStart) && beijingTime.isBefore(beijingEnd)){
            beijing = <div>
                <p>
                    Beijing<br/>{beijingTime.format("h:mm a")}
                <hr/>
                <mark class="tertiary">Can be called!</mark>
                </p>
            </div>
        }

        if(newYorkTime.isAfter(newYorkStart) && newYorkTime.isBefore(newYorkEnd)){
            newYork = <div>
                <p>
                    New York<br/>{newYorkTime.format("h:mm a")}
                <hr/>
                <mark class="tertiary">Can be called!</mark>
                </p>
            </div>
        }

        return (
            <div>
                <div className="row">
                        <div className="col-sm-12 col-lg-3">
                            <div className="card fluid card-center">{london}</div>
                        </div>
                        <div className="col-sm-12 col-lg-3">
                            <div className="card fluid card-center">{beijing}</div>
                        </div>
                        <div className="col-sm-12 col-lg-3">
                            <div className="card fluid card-center">{newYork}</div>
                        </div>
                </div>
                
                <div className="row"><div className="col-sm-12 col-lg-9"><form>
                <Select
                    options={tzIdsObject}
                    value={this.state.proposedTimezone}
                    onChange={this.handleProposedZoneChange}
                />
                    <hr/>
                    <p><strong>{theirTime.format("h:mm a")}</strong></p>
                    <p>{shouldYouCall}</p>
                </form></div></div>
                
            </div>
        )
    }
}

export default ToCall