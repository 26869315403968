const cityList = [
    {
      "value": "Europe/Berlin",
      "label": "Aachen - Germany"
    },
    {
      "value": "Europe/Copenhagen",
      "label": "Aalborg - Denmark"
    },
    {
      "value": "Europe/Copenhagen",
      "label": "Aarhus - Denmark"
    },
    {
      "value": "America/Godthab",
      "label": "Aasiaat - Greenland"
    },
    {
      "value": "Asia/Tehran",
      "label": "Abadan - Iran"
    },
    {
      "value": "Pacific/Tarawa",
      "label": "Abaiang Atoll - Kiribati"
    },
    {
      "value": "Asia/Krasnoyarsk",
      "label": "Abakan - Russia"
    },
    {
      "value": "Europe/Paris",
      "label": "Abbeville - France"
    },
    {
      "value": "America/Vancouver",
      "label": "Abbotsford - Canada"
    },
    {
      "value": "Africa/Ndjamena",
      "label": "Abeche - Chad"
    },
    {
      "value": "Pacific/Tarawa",
      "label": "Abemama - Kiribati"
    },
    {
      "value": "Europe/London",
      "label": "Aberdeen - United Kingdom"
    },
    {
      "value": "Asia/Riyadh",
      "label": "Abha - Saudi Arabia"
    },
    {
      "value": "Africa/Abidjan",
      "label": "Abidjan - Cote d'Ivoire"
    },
    {
      "value": "America/Chicago",
      "label": "Abilene - United States"
    },
    {
      "value": "Pacific/Efate",
      "label": "Ablow - Vanuatu"
    },
    {
      "value": "Asia/Dubai",
      "label": "Abu Dhabi - United Arab Emirates"
    },
    {
      "value": "Africa/Cairo",
      "label": "Abu Simbel - Egypt"
    },
    {
      "value": "Africa/Lagos",
      "label": "Abuja - Nigeria"
    },
    {
      "value": "Asia/Tehran",
      "label": "Abumusa I. - Iran"
    },
    {
      "value": "America/Bogota",
      "label": "Acandi - Colombia"
    },
    {
      "value": "America/Mexico_City",
      "label": "Acapulco - Mexico"
    },
    {
      "value": "America/Caracas",
      "label": "Acarigua - Venezuela"
    },
    {
      "value": "Africa/Accra",
      "label": "Accra - Ghana"
    },
    {
      "value": "America/Chicago",
      "label": "Ada - United States"
    },
    {
      "value": "America/Adak",
      "label": "Adak Island - United States"
    },
    {
      "value": "Europe/Istanbul",
      "label": "Adana - Turkey"
    },
    {
      "value": "Africa/Addis_Ababa",
      "label": "Addis Ababa - Ethiopia"
    },
    {
      "value": "America/Chicago",
      "label": "Addison - United States"
    },
    {
      "value": "Australia/Adelaide",
      "label": "Adelaide - Australia"
    },
    {
      "value": "Asia/Aden",
      "label": "Aden - Yemen"
    },
    {
      "value": "Europe/Istanbul",
      "label": "Adiyaman - Turkey"
    },
    {
      "value": "Africa/Algiers",
      "label": "Adrar - Algeria"
    },
    {
      "value": "Pacific/Guadalcanal",
      "label": "Afutara - Solomon Islands"
    },
    {
      "value": "Europe/Istanbul",
      "label": "Afyon - Turkey"
    },
    {
      "value": "Africa/Niamey",
      "label": "Agadez - Niger"
    },
    {
      "value": "Africa/Casablanca",
      "label": "Agadir - Morocco"
    },
    {
      "value": "Pacific/Guam",
      "label": "Agana - Guam"
    },
    {
      "value": "Asia/Calcutta",
      "label": "Agartala - India"
    },
    {
      "value": "Asia/Calcutta",
      "label": "Agatti Island - India"
    },
    {
      "value": "Europe/Paris",
      "label": "Agen - France"
    },
    {
      "value": "Africa/Johannesburg",
      "label": "Aggeneys - South Africa"
    },
    {
      "value": "Asia/Tehran",
      "label": "Aghajari - Iran"
    },
    {
      "value": "Asia/Calcutta",
      "label": "Agra - India"
    },
    {
      "value": "Europe/Istanbul",
      "label": "Agri - Turkey"
    },
    {
      "value": "Europe/Athens",
      "label": "Agrinion - Greece"
    },
    {
      "value": "America/Puerto_Rico",
      "label": "Aguadilla - Puerto Rico"
    },
    {
      "value": "America/Mexico_City",
      "label": "Aguascalientes - Mexico"
    },
    {
      "value": "Asia/Tokyo",
      "label": "Aguni - Japan"
    },
    {
      "value": "Pacific/Tahiti",
      "label": "Ahe - French Polynesia"
    },
    {
      "value": "Asia/Calcutta",
      "label": "Ahmedabad - India"
    },
    {
      "value": "America/Tegucigalpa",
      "label": "Ahuas - Honduras"
    },
    {
      "value": "Asia/Tehran",
      "label": "Ahwaz - Iran"
    },
    {
      "value": "America/New_York",
      "label": "Aiken - United States"
    },
    {
      "value": "Africa/Nouakchott",
      "label": "Aioun El Atrouss - Mauritania"
    },
    {
      "value": "Australia/Brisbane",
      "label": "Airlie Beach - Australia"
    },
    {
      "value": "Pacific/Rarotonga",
      "label": "Aitutaki - Cook Islands"
    },
    {
      "value": "Asia/Calcutta",
      "label": "Aizwal - India"
    },
    {
      "value": "Europe/Paris",
      "label": "Ajaccio - France"
    },
    {
      "value": "America/Anchorage",
      "label": "Akhiok - United States"
    },
    {
      "value": "America/Anchorage",
      "label": "Akiak - United States"
    },
    {
      "value": "Asia/Tokyo",
      "label": "Akita - Japan"
    },
    {
      "value": "America/Edmonton",
      "label": "Aklavik - Canada"
    },
    {
      "value": "Asia/Calcutta",
      "label": "Akola - India"
    },
    {
      "value": "America/New_York",
      "label": "Akron - United States"
    },
    {
      "value": "Europe/London",
      "label": "Akrotiri - Cyprus"
    },
    {
      "value": "Asia/Shanghai",
      "label": "Aksu - China"
    },
    {
      "value": "Asia/Oral",
      "label": "Aktau - Kazakhstan"
    },
    {
      "value": "Asia/Oral",
      "label": "Aktyubinsk - Kazakhstan"
    },
    {
      "value": "America/Toronto",
      "label": "Akulivik - Canada"
    },
    {
      "value": "Africa/Lagos",
      "label": "Akure - Nigeria"
    },
    {
      "value": "Atlantic/Reykjavik",
      "label": "Akureyri - Iceland"
    },
    {
      "value": "America/Anchorage",
      "label": "Akutan - United States"
    },
    {
      "value": "Asia/Dubai",
      "label": "Al Ain - United Arab Emirates"
    },
    {
      "value": "Asia/Baghdad",
      "label": "Al Asad - Iraq"
    },
    {
      "value": "Asia/Baghdad",
      "label": "Al Bakr - Iraq"
    },
    {
      "value": "Africa/Tripoli",
      "label": "Al Bayda' - Libya"
    },
    {
      "value": "Asia/Aden",
      "label": "Al Ghaidah Intl - Yemen"
    },
    {
      "value": "Africa/Casablanca",
      "label": "Al Hociema - Morocco"
    },
    {
      "value": "Asia/Riyadh",
      "label": "Al Kharj - Saudi Arabia"
    },
    {
      "value": "Asia/Baghdad",
      "label": "Al Taqaddum - Iraq"
    },
    {
      "value": "Asia/Riyadh",
      "label": "Al-ahsa - Saudi Arabia"
    },
    {
      "value": "America/Anchorage",
      "label": "Alakanuk - United States"
    },
    {
      "value": "America/Los_Angeles",
      "label": "Alameda - United States"
    },
    {
      "value": "America/Denver",
      "label": "Alamogordo - United States"
    },
    {
      "value": "America/Denver",
      "label": "Alamosa - United States"
    },
    {
      "value": "Europe/Istanbul",
      "label": "Alanya - Turkey"
    },
    {
      "value": "Europe/Madrid",
      "label": "Albacete - Spain"
    },
    {
      "value": "Australia/Perth",
      "label": "Albany - Australia"
    },
    {
      "value": "America/New_York",
      "label": "Albany - United States"
    },
    {
      "value": "Europe/Rome",
      "label": "Albenga - Italy"
    },
    {
      "value": "Europe/Paris",
      "label": "Albert - France"
    },
    {
      "value": "Europe/Paris",
      "label": "Albi - France"
    },
    {
      "value": "America/Paramaribo",
      "label": "Albina - Suriname"
    },
    {
      "value": "America/Denver",
      "label": "Albuquerque - United States"
    },
    {
      "value": "Australia/Sydney",
      "label": "Albury - Australia"
    },
    {
      "value": "Asia/Yakutsk",
      "label": "Aldan - Russia"
    },
    {
      "value": "Europe/Guernsey",
      "label": "Alderney - Guernsey"
    },
    {
      "value": "America/Anchorage",
      "label": "Aleknagik - United States"
    },
    {
      "value": "Asia/Damascus",
      "label": "Aleppo - Syria"
    },
    {
      "value": "America/Toronto",
      "label": "Alert - Canada"
    },
    {
      "value": "Europe/Oslo",
      "label": "Alesund - Norway"
    },
    {
      "value": "Africa/Johannesburg",
      "label": "Alexander Bay - South Africa"
    },
    {
      "value": "Pacific/Auckland",
      "label": "Alexandra - New Zealand"
    },
    {
      "value": "Africa/Cairo",
      "label": "Alexandria - Egypt"
    },
    {
      "value": "America/Chicago",
      "label": "Alexandria - United States"
    },
    {
      "value": "Europe/Athens",
      "label": "Alexandroupolis - Greece"
    },
    {
      "value": "Europe/Rome",
      "label": "Alghero - Italy"
    },
    {
      "value": "Africa/Algiers",
      "label": "Algier - Algeria"
    },
    {
      "value": "Europe/Madrid",
      "label": "Alicante - Spain"
    },
    {
      "value": "America/Chicago",
      "label": "Alice - United States"
    },
    {
      "value": "Australia/Darwin",
      "label": "Alice Springs - Australia"
    },
    {
      "value": "America/Nassau",
      "label": "Alice Town - Bahamas"
    },
    {
      "value": "Asia/Riyadh",
      "label": "Al-Jawf - Saudi Arabia"
    },
    {
      "value": "Asia/Calcutta",
      "label": "Allahabad - India"
    },
    {
      "value": "America/Anchorage",
      "label": "Allakaket - United States"
    },
    {
      "value": "Africa/Johannesburg",
      "label": "Alldays - South Africa"
    },
    {
      "value": "America/New_York",
      "label": "Allentown - United States"
    },
    {
      "value": "America/Denver",
      "label": "Alliance - United States"
    },
    {
      "value": "America/Godthab",
      "label": "Alluitsup Paa - Greenland"
    },
    {
      "value": "America/Toronto",
      "label": "Alma - Canada"
    },
    {
      "value": "Asia/Qyzylorda",
      "label": "Alma-ata - Kazakhstan"
    },
    {
      "value": "America/Belem",
      "label": "Almeirim - Brazil"
    },
    {
      "value": "Europe/Madrid",
      "label": "Almeria - Spain"
    },
    {
      "value": "Pacific/Niue",
      "label": "Alofi - Niue"
    },
    {
      "value": "Asia/Calcutta",
      "label": "Along - India"
    },
    {
      "value": "Asia/Makassar",
      "label": "Alor Island - Indonesia"
    },
    {
      "value": "Asia/Kuala_Lumpur",
      "label": "Alor Setar - Malaysia"
    },
    {
      "value": "America/New_York",
      "label": "Alpena - United States"
    },
    {
      "value": "Europe/Oslo",
      "label": "Alta - Norway"
    },
    {
      "value": "America/Campo_Grande",
      "label": "Alta Floresta - Brazil"
    },
    {
      "value": "Asia/Ulaanbaatar",
      "label": "Altai - Mongolia"
    },
    {
      "value": "America/Belem",
      "label": "Altamira - Brazil"
    },
    {
      "value": "Europe/Berlin",
      "label": "Altenburg - Germany"
    },
    {
      "value": "Europe/Zurich",
      "label": "Altenrhein - Switzerland"
    },
    {
      "value": "America/Catamarca",
      "label": "Alto Rio Senguer - Argentina"
    },
    {
      "value": "America/New_York",
      "label": "Altoona - United States"
    },
    {
      "value": "America/Chicago",
      "label": "Altus - United States"
    },
    {
      "value": "Africa/Mogadishu",
      "label": "Alula - Somalia"
    },
    {
      "value": "Europe/Lisbon",
      "label": "Alverca - Portugal"
    },
    {
      "value": "Africa/Ndjamena",
      "label": "Am Timan - Chad"
    },
    {
      "value": "Asia/Tokyo",
      "label": "Amakusa - Japan"
    },
    {
      "value": "Asia/Tokyo",
      "label": "Amami - Japan"
    },
    {
      "value": "America/Chicago",
      "label": "Amarillo - United States"
    },
    {
      "value": "America/Guayaquil",
      "label": "Ambato - Ecuador"
    },
    {
      "value": "Indian/Antananarivo",
      "label": "Ambatondrazaka - Madagascar"
    },
    {
      "value": "Australia/Brisbane",
      "label": "Amberley - Australia"
    },
    {
      "value": "Indian/Antananarivo",
      "label": "Ambilobe - Madagascar"
    },
    {
      "value": "America/Anchorage",
      "label": "Ambler - United States"
    },
    {
      "value": "Asia/Jayapura",
      "label": "Ambon - Indonesia"
    },
    {
      "value": "Africa/Nairobi",
      "label": "Amboseli National Park - Kenya"
    },
    {
      "value": "Pacific/Efate",
      "label": "Ambryn Island - Vanuatu"
    },
    {
      "value": "Europe/Moscow",
      "label": "Amderma - Russia"
    },
    {
      "value": "Atlantic/Cape_Verde",
      "label": "Amilcar Cabral - Cape Verde"
    },
    {
      "value": "Asia/Amman",
      "label": "Amman - Jordan"
    },
    {
      "value": "Indian/Antananarivo",
      "label": "Ampampamena - Madagascar"
    },
    {
      "value": "Asia/Calcutta",
      "label": "Amritsar - India"
    },
    {
      "value": "Europe/Amsterdam",
      "label": "Amsterdam - Netherlands"
    },
    {
      "value": "Pacific/Tahiti",
      "label": "Anaa - French Polynesia"
    },
    {
      "value": "America/Caracas",
      "label": "Anaco - Venezuela"
    },
    {
      "value": "Asia/Anadyr",
      "label": "Anadyr - Russia"
    },
    {
      "value": "America/Vancouver",
      "label": "Anahim Lake - Canada"
    },
    {
      "value": "America/Anchorage",
      "label": "Anaktuvuk Pass - United States"
    },
    {
      "value": "Indian/Antananarivo",
      "label": "Analalava - Madagascar"
    },
    {
      "value": "Asia/Jakarta",
      "label": "Anambas Islands - Indonesia"
    },
    {
      "value": "Europe/Moscow",
      "label": "Anapa - Russia"
    },
    {
      "value": "America/Anchorage",
      "label": "Anchorage - United States"
    },
    {
      "value": "Europe/Rome",
      "label": "Ancona - Italy"
    },
    {
      "value": "America/Lima",
      "label": "Andahuaylas - Peru"
    },
    {
      "value": "Indian/Antananarivo",
      "label": "Andapa - Madagascar"
    },
    {
      "value": "Pacific/Guam",
      "label": "Andersen - Guam"
    },
    {
      "value": "America/New_York",
      "label": "Andersen - United States"
    },
    {
      "value": "Asia/Samarkand",
      "label": "Andizhan - Uzbekistan"
    },
    {
      "value": "America/Lima",
      "label": "Andoas - Peru"
    },
    {
      "value": "Europe/Oslo",
      "label": "Andoya - Norway"
    },
    {
      "value": "Europe/Athens",
      "label": "Andravida - Greece"
    },
    {
      "value": "America/Nassau",
      "label": "Andros - Bahamas"
    },
    {
      "value": "America/Nassau",
      "label": "Andros Town - Bahamas"
    },
    {
      "value": "Pacific/Efate",
      "label": "Anelghowhat - Vanuatu"
    },
    {
      "value": "Pacific/Tongatapu",
      "label": "Angaha, Niuafo'ou Island - Tonga"
    },
    {
      "value": "Asia/Manila",
      "label": "Angeles City - Philippines"
    },
    {
      "value": "Europe/London",
      "label": "Angelsey - United Kingdom"
    },
    {
      "value": "Europe/Paris",
      "label": "Angers/Marcé - France"
    },
    {
      "value": "America/Chicago",
      "label": "Angleton - United States"
    },
    {
      "value": "America/Winnipeg",
      "label": "Angling Lake - Canada"
    },
    {
      "value": "America/New_York",
      "label": "Angola - United States"
    },
    {
      "value": "America/Anchorage",
      "label": "Angoon - United States"
    },
    {
      "value": "Europe/Paris",
      "label": "Angouleme - France"
    },
    {
      "value": "America/Anchorage",
      "label": "Aniak - United States"
    },
    {
      "value": "Pacific/Efate",
      "label": "Aniwa - Vanuatu"
    },
    {
      "value": "Indian/Comoro",
      "label": "Anjouan - Comoros"
    },
    {
      "value": "Asia/Shanghai",
      "label": "Ankang - China"
    },
    {
      "value": "Indian/Antananarivo",
      "label": "Ankavandra - Madagascar"
    },
    {
      "value": "Asia/Rangoon",
      "label": "Ann - Burma"
    },
    {
      "value": "America/New_York",
      "label": "Ann Arbor - United States"
    },
    {
      "value": "Africa/Algiers",
      "label": "Annaba - Algeria"
    },
    {
      "value": "America/Guyana",
      "label": "Annai - Guyana"
    },
    {
      "value": "America/New_York",
      "label": "Annapolis - United States"
    },
    {
      "value": "Europe/Paris",
      "label": "Annecy - France"
    },
    {
      "value": "Europe/Paris",
      "label": "Annemasse - France"
    },
    {
      "value": "America/Anchorage",
      "label": "Annette Island - United States"
    },
    {
      "value": "America/Chicago",
      "label": "Anniston - United States"
    },
    {
      "value": "Asia/Shanghai",
      "label": "Anqing - China"
    },
    {
      "value": "Asia/Shanghai",
      "label": "Anshan - China"
    },
    {
      "value": "Asia/Shanghai",
      "label": "Anshun - China"
    },
    {
      "value": "America/Lima",
      "label": "Anta - Peru"
    },
    {
      "value": "Indian/Antananarivo",
      "label": "Antalaha - Madagascar"
    },
    {
      "value": "Europe/Istanbul",
      "label": "Antalya - Turkey"
    },
    {
      "value": "Indian/Antananarivo",
      "label": "Antananarivo - Madagascar"
    },
    {
      "value": "America/Antigua",
      "label": "Antigua - Antigua and Barbuda"
    },
    {
      "value": "America/Santiago",
      "label": "Antofagasta - Chile"
    },
    {
      "value": "Indian/Antananarivo",
      "label": "Antsalova - Madagascar"
    },
    {
      "value": "Indian/Antananarivo",
      "label": "Antsirabe - Madagascar"
    },
    {
      "value": "Indian/Antananarivo",
      "label": "Antsiranana - Madagascar"
    },
    {
      "value": "Indian/Antananarivo",
      "label": "Antsohihy - Madagascar"
    },
    {
      "value": "Europe/Brussels",
      "label": "Antwerp - Belgium"
    },
    {
      "value": "Asia/Colombo",
      "label": "Anuradhapura - Sri Lanka"
    },
    {
      "value": "America/Anchorage",
      "label": "Anvik - United States"
    },
    {
      "value": "Asia/Tokyo",
      "label": "Aomori - Japan"
    },
    {
      "value": "Europe/Rome",
      "label": "Aosta - Italy"
    },
    {
      "value": "America/New_York",
      "label": "Apalachicola - United States"
    },
    {
      "value": "Pacific/Tahiti",
      "label": "Apataki - French Polynesia"
    },
    {
      "value": "Europe/Moscow",
      "label": "Apatity - Russia"
    },
    {
      "value": "Pacific/Apia",
      "label": "Apia - Samoa"
    },
    {
      "value": "America/Chicago",
      "label": "Appleton - United States"
    },
    {
      "value": "Asia/Amman",
      "label": "Aqaba - Jordan"
    },
    {
      "value": "America/Fortaleza",
      "label": "Aracaju - Brazil"
    },
    {
      "value": "America/Sao_Paulo",
      "label": "Aracatuba - Brazil"
    },
    {
      "value": "Europe/Bucharest",
      "label": "Arad - Romania"
    },
    {
      "value": "America/Fortaleza",
      "label": "Araguaina - Brazil"
    },
    {
      "value": "Asia/Tehran",
      "label": "Arak - Iran"
    },
    {
      "value": "Asia/Riyadh",
      "label": "Arar - Saudi Arabia"
    },
    {
      "value": "America/Sao_Paulo",
      "label": "Araracuara - Brazil"
    },
    {
      "value": "America/Bogota",
      "label": "Araracuara - Colombia"
    },
    {
      "value": "America/Bogota",
      "label": "Arauca - Colombia"
    },
    {
      "value": "America/Sao_Paulo",
      "label": "Araxa - Brazil"
    },
    {
      "value": "Africa/Addis_Ababa",
      "label": "Arba Minch - Ethiopia"
    },
    {
      "value": "America/Los_Angeles",
      "label": "Arcata CA - United States"
    },
    {
      "value": "America/Winnipeg",
      "label": "Arctic Bay - Canada"
    },
    {
      "value": "America/Anchorage",
      "label": "Arctic Village - United States"
    },
    {
      "value": "Asia/Tehran",
      "label": "Ardabil - Iran"
    },
    {
      "value": "Pacific/Auckland",
      "label": "Ardmore - New Zealand"
    },
    {
      "value": "America/Chicago",
      "label": "Ardmore - United States"
    },
    {
      "value": "America/Puerto_Rico",
      "label": "Arecibo - Puerto Rico"
    },
    {
      "value": "America/Lima",
      "label": "Arequipa - Peru"
    },
    {
      "value": "Australia/Perth",
      "label": "Argyle - Australia"
    },
    {
      "value": "America/Santiago",
      "label": "Arica - Chile"
    },
    {
      "value": "Asia/Qyzylorda",
      "label": "Arkalyk - Kazakhstan"
    },
    {
      "value": "Europe/Moscow",
      "label": "Arkhangelsk - Russia"
    },
    {
      "value": "America/Bogota",
      "label": "Armenia - Colombia"
    },
    {
      "value": "Australia/Sydney",
      "label": "Armidale - Australia"
    },
    {
      "value": "America/Toronto",
      "label": "Armstrong - Canada"
    },
    {
      "value": "Pacific/Tarawa",
      "label": "Arorae - Kiribati"
    },
    {
      "value": "Atlantic/Canary",
      "label": "Arrecife - Spain"
    },
    {
      "value": "America/Nassau",
      "label": "Arthur's Town - Bahamas"
    },
    {
      "value": "Africa/Kampala",
      "label": "Arua - Uganda"
    },
    {
      "value": "Africa/Dar_es_Salaam",
      "label": "Arusha - Tanzania"
    },
    {
      "value": "Pacific/Tahiti",
      "label": "Arutua - French Polynesia"
    },
    {
      "value": "Asia/Ulaanbaatar",
      "label": "Arvaikheer - Mongolia"
    },
    {
      "value": "Europe/Stockholm",
      "label": "Arvidsjaur - Sweden"
    },
    {
      "value": "Africa/Lagos",
      "label": "Asaba - Nigeria"
    },
    {
      "value": "Asia/Tokyo",
      "label": "Asahikawa - Japan"
    },
    {
      "value": "America/New_York",
      "label": "Asheville - United States"
    },
    {
      "value": "Asia/Ashgabat",
      "label": "Ashkhabad - Turkmenistan"
    },
    {
      "value": "Africa/Asmera",
      "label": "Asmara - Eritrea"
    },
    {
      "value": "Africa/Addis_Ababa",
      "label": "Asosa - Ethiopia"
    },
    {
      "value": "America/Denver",
      "label": "Aspen - United States"
    },
    {
      "value": "Africa/Asmera",
      "label": "Assab - Eritrea"
    },
    {
      "value": "Europe/Stockholm",
      "label": "Östersund - Sweden"
    },
    {
      "value": "America/Los_Angeles",
      "label": "Astoria - United States"
    },
    {
      "value": "Europe/Samara",
      "label": "Astrakhan - Russia"
    },
    {
      "value": "Europe/Athens",
      "label": "Astypalaia - Greece"
    },
    {
      "value": "America/Asuncion",
      "label": "Asuncion - Paraguay"
    },
    {
      "value": "Africa/Cairo",
      "label": "Aswan - Egypt"
    },
    {
      "value": "Africa/Cairo",
      "label": "Asyut - Egypt"
    },
    {
      "value": "Asia/Aden",
      "label": "Ataq - Yemen"
    },
    {
      "value": "Africa/Nouakchott",
      "label": "Atar - Mauritania"
    },
    {
      "value": "Africa/Khartoum",
      "label": "Atbara - Sudan"
    },
    {
      "value": "Europe/Athens",
      "label": "Athens - Greece"
    },
    {
      "value": "America/New_York",
      "label": "Athens - United States"
    },
    {
      "value": "America/Coral_Harbour",
      "label": "Atikokan - Canada"
    },
    {
      "value": "Pacific/Rarotonga",
      "label": "Atiu Island - Cook Islands"
    },
    {
      "value": "America/Adak",
      "label": "Atka - United States"
    },
    {
      "value": "America/New_York",
      "label": "Atlanta - United States"
    },
    {
      "value": "America/New_York",
      "label": "Atlantic City - United States"
    },
    {
      "value": "Pacific/Guadalcanal",
      "label": "Atoifi - Solomon Islands"
    },
    {
      "value": "America/Anchorage",
      "label": "Atqasuk - United States"
    },
    {
      "value": "Asia/Tokyo",
      "label": "Atsugi - Japan"
    },
    {
      "value": "America/Toronto",
      "label": "Attawapiskat - Canada"
    },
    {
      "value": "Asia/Oral",
      "label": "Atyrau - Kazakhstan"
    },
    {
      "value": "Europe/Paris",
      "label": "Aubenas-vals-lanas - France"
    },
    {
      "value": "America/Chicago",
      "label": "Auburn - United States"
    },
    {
      "value": "Pacific/Auckland",
      "label": "Auckland - New Zealand"
    },
    {
      "value": "Europe/Berlin",
      "label": "Augsburg - Germany"
    },
    {
      "value": "America/New_York",
      "label": "Augusta - United States"
    },
    {
      "value": "Pacific/Guadalcanal",
      "label": "Auki - Solomon Islands"
    },
    {
      "value": "America/Toronto",
      "label": "Aupaluk - Canada"
    },
    {
      "value": "Asia/Calcutta",
      "label": "Aurangabad - India"
    },
    {
      "value": "Europe/Paris",
      "label": "Aurillac - France"
    },
    {
      "value": "Australia/Brisbane",
      "label": "Aurukun - Australia"
    },
    {
      "value": "America/Chicago",
      "label": "Austin - United States"
    },
    {
      "value": "Europe/Paris",
      "label": "Auxerre - France"
    },
    {
      "value": "Australia/Hobart",
      "label": "Avalon - Australia"
    },
    {
      "value": "Pacific/Rarotonga",
      "label": "Avarua - Cook Islands"
    },
    {
      "value": "Europe/Rome",
      "label": "Aviano - Italy"
    },
    {
      "value": "Europe/Paris",
      "label": "Avignon - France"
    },
    {
      "value": "Europe/Madrid",
      "label": "Aviles - Spain"
    },
    {
      "value": "America/New_York",
      "label": "Avon Park - United States"
    },
    {
      "value": "Africa/Addis_Ababa",
      "label": "Awasa - Ethiopia"
    },
    {
      "value": "Africa/Addis_Ababa",
      "label": "Axum - Ethiopia"
    },
    {
      "value": "America/Lima",
      "label": "Ayacucho - Peru"
    },
    {
      "value": "America/Asuncion",
      "label": "Ayolas - Paraguay"
    },
    {
      "value": "Pacific/Noumea",
      "label": "Île des Pins - New Caledonia"
    },
    {
      "value": "Europe/Paris",
      "label": "Île d'Yeu - France"
    },
    {
      "value": "Africa/Algiers",
      "label": "Béchar - Algeria"
    },
    {
      "value": "Europe/Berlin",
      "label": "Büsum - Germany"
    },
    {
      "value": "Pacific/Palau",
      "label": "Babelthuap - Palau"
    },
    {
      "value": "Asia/Jayapura",
      "label": "Babo - Indonesia"
    },
    {
      "value": "Europe/Bucharest",
      "label": "Bacau - Romania"
    },
    {
      "value": "Africa/Addis_Ababa",
      "label": "Baco - Ethiopia"
    },
    {
      "value": "Asia/Manila",
      "label": "Bacolod - Philippines"
    },
    {
      "value": "Europe/Madrid",
      "label": "Badajoz - Spain"
    },
    {
      "value": "Australia/Brisbane",
      "label": "Badu Island - Australia"
    },
    {
      "value": "Africa/Douala",
      "label": "Bafoussam - Cameroon"
    },
    {
      "value": "Asia/Rangoon",
      "label": "Bagan - Burma"
    },
    {
      "value": "America/Sao_Paulo",
      "label": "Bage - Brazil"
    },
    {
      "value": "Asia/Baghdad",
      "label": "Baghdad - Iraq"
    },
    {
      "value": "Asia/Calcutta",
      "label": "Baghdogra - India"
    },
    {
      "value": "America/Toronto",
      "label": "Bagotville - Canada"
    },
    {
      "value": "Asia/Manila",
      "label": "Baguio - Philippines"
    },
    {
      "value": "Africa/Addis_Ababa",
      "label": "Bahar Dar - Ethiopia"
    },
    {
      "value": "Asia/Karachi",
      "label": "Bahawalpur - Pakistan"
    },
    {
      "value": "America/Buenos_Aires",
      "label": "Bahia Blanca - Argentina"
    },
    {
      "value": "America/Bogota",
      "label": "Bahia Solano - Colombia"
    },
    {
      "value": "Asia/Bahrain",
      "label": "Bahrain - Bahrain"
    },
    {
      "value": "Europe/Bucharest",
      "label": "Baia Mare - Romania"
    },
    {
      "value": "America/Toronto",
      "label": "Baie Comeau - Canada"
    },
    {
      "value": "America/New_York",
      "label": "Bainbridge - United States"
    },
    {
      "value": "Australia/Hobart",
      "label": "Bairnsdale - Australia"
    },
    {
      "value": "Asia/Katmandu",
      "label": "Bajhang - Nepal"
    },
    {
      "value": "Asia/Katmandu",
      "label": "Bajura - Nepal"
    },
    {
      "value": "Asia/Kuala_Lumpur",
      "label": "Bakalalan - Malaysia"
    },
    {
      "value": "Africa/Dakar",
      "label": "Bakel - Senegal"
    },
    {
      "value": "America/Winnipeg",
      "label": "Baker Lake - Canada"
    },
    {
      "value": "America/Los_Angeles",
      "label": "Bakersfield - United States"
    },
    {
      "value": "Asia/Tehran",
      "label": "Bakhtaran - Iran"
    },
    {
      "value": "Asia/Baku",
      "label": "Baku - Azerbaijan"
    },
    {
      "value": "Europe/Moscow",
      "label": "Balakovo - Russia"
    },
    {
      "value": "Asia/Manila",
      "label": "Baler - Philippines"
    },
    {
      "value": "Europe/Istanbul",
      "label": "Balikesir - Turkey"
    },
    {
      "value": "Europe/Istanbul",
      "label": "Balikesir Korfez - Turkey"
    },
    {
      "value": "Asia/Makassar",
      "label": "Balikpapan - Indonesia"
    },
    {
      "value": "Asia/Qyzylorda",
      "label": "Balkhash - Kazakhstan"
    },
    {
      "value": "Pacific/Guadalcanal",
      "label": "Ballalae - Solomon Islands"
    },
    {
      "value": "Australia/Brisbane",
      "label": "Ballera - Australia"
    },
    {
      "value": "Australia/Sydney",
      "label": "Ballina Byron Bay - Australia"
    },
    {
      "value": "America/Santiago",
      "label": "Balmaceda - Chile"
    },
    {
      "value": "America/New_York",
      "label": "Baltimore - United States"
    },
    {
      "value": "Asia/Tehran",
      "label": "Bam - Iran"
    },
    {
      "value": "Africa/Bamako",
      "label": "Bamako - Mali"
    },
    {
      "value": "Africa/Douala",
      "label": "Bamenda - Cameroon"
    },
    {
      "value": "Asia/Kabul",
      "label": "Bamyan - Afghanistan"
    },
    {
      "value": "Asia/Jakarta",
      "label": "Banda Aceh - Indonesia"
    },
    {
      "value": "Asia/Tehran",
      "label": "Bandar Abbas - Iran"
    },
    {
      "value": "Asia/Jakarta",
      "label": "Bandar Lampung-Sumatra Island - Indonesia"
    },
    {
      "value": "Asia/Tehran",
      "label": "Bandar Lengeh - Iran"
    },
    {
      "value": "Asia/Tehran",
      "label": "Bandar Mahshahr - Iran"
    },
    {
      "value": "Asia/Brunei",
      "label": "Bandar Seri Begawan - Brunei"
    },
    {
      "value": "Europe/Istanbul",
      "label": "Bandirma - Turkey"
    },
    {
      "value": "Africa/Kinshasa",
      "label": "Bandoundu - Congo (Kinshasa)"
    },
    {
      "value": "Asia/Jakarta",
      "label": "Bandung - Indonesia"
    },
    {
      "value": "Asia/Calcutta",
      "label": "Bangalore - India"
    },
    {
      "value": "Asia/Shanghai",
      "label": "Bangda - China"
    },
    {
      "value": "Asia/Bangkok",
      "label": "Bangkok - Thailand"
    },
    {
      "value": "America/New_York",
      "label": "Bangor - United States"
    },
    {
      "value": "Africa/Bangui",
      "label": "Bangui - Central African Republic"
    },
    {
      "value": "Europe/Sarajevo",
      "label": "Banja Luka - Bosnia and Herzegovina"
    },
    {
      "value": "Asia/Makassar",
      "label": "Banjarmasin - Indonesia"
    },
    {
      "value": "Africa/Banjul",
      "label": "Banjul - Gambia"
    },
    {
      "value": "Asia/Rangoon",
      "label": "Banmaw - Burma"
    },
    {
      "value": "Asia/Karachi",
      "label": "Bannu - Pakistan"
    },
    {
      "value": "Europe/Dublin",
      "label": "Bantry - Ireland"
    },
    {
      "value": "Asia/Shanghai",
      "label": "Baotou - China"
    },
    {
      "value": "America/New_York",
      "label": "Bar Harbor - United States"
    },
    {
      "value": "America/Havana",
      "label": "Baracoa Playa - Cuba"
    },
    {
      "value": "America/Santo_Domingo",
      "label": "Barahona - Dominican Republic"
    },
    {
      "value": "Australia/Brisbane",
      "label": "Barcaldine - Australia"
    },
    {
      "value": "Europe/Madrid",
      "label": "Barcelona - Spain"
    },
    {
      "value": "America/Caracas",
      "label": "Barcelona - Venezuela"
    },
    {
      "value": "America/Boa_Vista",
      "label": "Barcelos - Brazil"
    },
    {
      "value": "Europe/Oslo",
      "label": "Bardufoss - Norway"
    },
    {
      "value": "Asia/Calcutta",
      "label": "Bareilly - India"
    },
    {
      "value": "Europe/Rome",
      "label": "Bari - Italy"
    },
    {
      "value": "America/Caracas",
      "label": "Barinas - Venezuela"
    },
    {
      "value": "Asia/Kuala_Lumpur",
      "label": "Bario - Malaysia"
    },
    {
      "value": "Asia/Dhaka",
      "label": "Barisal - Bangladesh"
    },
    {
      "value": "Pacific/Honolulu",
      "label": "Barking Sands - United States"
    },
    {
      "value": "Asia/Krasnoyarsk",
      "label": "Barnaul - Russia"
    },
    {
      "value": "America/New_York",
      "label": "Barnstable - United States"
    },
    {
      "value": "Asia/Calcutta",
      "label": "Baroda - India"
    },
    {
      "value": "America/Caracas",
      "label": "Barquisimeto - Venezuela"
    },
    {
      "value": "Europe/London",
      "label": "Barra - United Kingdom"
    },
    {
      "value": "America/Campo_Grande",
      "label": "Barra Do Garcas - Brazil"
    },
    {
      "value": "America/Bogota",
      "label": "Barrancabermeja - Colombia"
    },
    {
      "value": "America/Bogota",
      "label": "Barranquilla - Colombia"
    },
    {
      "value": "America/Fortaleza",
      "label": "Barreiras - Brazil"
    },
    {
      "value": "America/Toronto",
      "label": "Barrie-Orillia - Canada"
    },
    {
      "value": "America/Anchorage",
      "label": "Barrow - United States"
    },
    {
      "value": "Australia/Perth",
      "label": "Barrow Island - Australia"
    },
    {
      "value": "Europe/London",
      "label": "Barrow Island - United Kingdom"
    },
    {
      "value": "America/Anchorage",
      "label": "Barter Island - United States"
    },
    {
      "value": "Europe/Berlin",
      "label": "Barth - Germany"
    },
    {
      "value": "America/Guyana",
      "label": "Bartica - Guyana"
    },
    {
      "value": "America/New_York",
      "label": "Bartow - United States"
    },
    {
      "value": "Europe/Vilnius",
      "label": "Barysiai - Lithuania"
    },
    {
      "value": "Africa/Kinshasa",
      "label": "Basankusu - Congo (Kinshasa)"
    },
    {
      "value": "Asia/Baghdad",
      "label": "Basrah - Iraq"
    },
    {
      "value": "America/Guadeloupe",
      "label": "Basse Terre - Guadeloupe"
    },
    {
      "value": "America/St_Kitts",
      "label": "Basse Terre - Saint Kitts and Nevis"
    },
    {
      "value": "Europe/Paris",
      "label": "Bastia - France"
    },
    {
      "value": "Africa/Malabo",
      "label": "Bata - Equatorial Guinea"
    },
    {
      "value": "Asia/Vladivostok",
      "label": "Batagay - Russia"
    },
    {
      "value": "Asia/Jakarta",
      "label": "Batam - Indonesia"
    },
    {
      "value": "Australia/Sydney",
      "label": "Bathurst - Australia"
    },
    {
      "value": "America/Halifax",
      "label": "Bathurst - Canada"
    },
    {
      "value": "Australia/Darwin",
      "label": "Bathurst Island - Australia"
    },
    {
      "value": "Europe/Istanbul",
      "label": "Batman - Turkey"
    },
    {
      "value": "Africa/Algiers",
      "label": "Batna - Algeria"
    },
    {
      "value": "America/Chicago",
      "label": "Baton Rouge - United States"
    },
    {
      "value": "Europe/Oslo",
      "label": "Batsfjord - Norway"
    },
    {
      "value": "Asia/Phnom_Penh",
      "label": "Battambang - Cambodia"
    },
    {
      "value": "Asia/Colombo",
      "label": "Batticaloa - Sri Lanka"
    },
    {
      "value": "Asia/Makassar",
      "label": "Batu Licin - Indonesia"
    },
    {
      "value": "Asia/Tbilisi",
      "label": "Batumi - Georgia"
    },
    {
      "value": "Asia/Dili",
      "label": "Baucau - East Timor"
    },
    {
      "value": "America/Chicago",
      "label": "Baudette - United States"
    },
    {
      "value": "America/Sao_Paulo",
      "label": "Bauru - Brazil"
    },
    {
      "value": "America/Havana",
      "label": "Bayamo - Cuba"
    },
    {
      "value": "Asia/Ulaanbaatar",
      "label": "Bayankhongor - Mongolia"
    },
    {
      "value": "Asia/Shanghai",
      "label": "Bayannur - China"
    },
    {
      "value": "Europe/Berlin",
      "label": "Bayreuth - Germany"
    },
    {
      "value": "America/Winnipeg",
      "label": "Bearskin Lake - Canada"
    },
    {
      "value": "America/New_York",
      "label": "Beaufort - United States"
    },
    {
      "value": "America/Chicago",
      "label": "Beaumont - United States"
    },
    {
      "value": "Europe/Paris",
      "label": "Beauvais - France"
    },
    {
      "value": "America/Anchorage",
      "label": "Beaver - United States"
    },
    {
      "value": "America/New_York",
      "label": "Beaver Falls - United States"
    },
    {
      "value": "America/New_York",
      "label": "Beckley - United States"
    },
    {
      "value": "America/New_York",
      "label": "Bedford - United States"
    },
    {
      "value": "Australia/Brisbane",
      "label": "Bedourie - Australia"
    },
    {
      "value": "America/Vancouver",
      "label": "Bedwell Harbour - Canada"
    },
    {
      "value": "Asia/Jerusalem",
      "label": "Beer-sheba - Israel"
    },
    {
      "value": "Africa/Addis_Ababa",
      "label": "Beica - Ethiopia"
    },
    {
      "value": "Asia/Shanghai",
      "label": "Beihai - China"
    },
    {
      "value": "Asia/Aden",
      "label": "Beihan - Yemen"
    },
    {
      "value": "Asia/Shanghai",
      "label": "Beijing - China"
    },
    {
      "value": "Africa/Maputo",
      "label": "Beira - Mozambique"
    },
    {
      "value": "Asia/Beirut",
      "label": "Beirut - Lebanon"
    },
    {
      "value": "Europe/Lisbon",
      "label": "Beja (madeira) - Portugal"
    },
    {
      "value": "Africa/Algiers",
      "label": "Bejaja - Algeria"
    },
    {
      "value": "Indian/Antananarivo",
      "label": "Bekily - Madagascar"
    },
    {
      "value": "Asia/Kuala_Lumpur",
      "label": "Belaga - Malaysia"
    },
    {
      "value": "America/Belem",
      "label": "Belem - Brazil"
    },
    {
      "value": "Europe/London",
      "label": "Belfast - United Kingdom"
    },
    {
      "value": "Europe/Paris",
      "label": "Belfort - France"
    },
    {
      "value": "Asia/Calcutta",
      "label": "Belgaum - India"
    },
    {
      "value": "Europe/Moscow",
      "label": "Belgorod - Russia"
    },
    {
      "value": "Europe/Belgrade",
      "label": "Belgrade - Serbia"
    },
    {
      "value": "America/Belize",
      "label": "Belize City - Belize"
    },
    {
      "value": "America/Vancouver",
      "label": "Bella Coola - Canada"
    },
    {
      "value": "Asia/Calcutta",
      "label": "Bellary - India"
    },
    {
      "value": "America/Chicago",
      "label": "Belleville - United States"
    },
    {
      "value": "America/Los_Angeles",
      "label": "Bellingham - United States"
    },
    {
      "value": "America/Sao_Paulo",
      "label": "Belo Horizonte - Brazil"
    },
    {
      "value": "Indian/Antananarivo",
      "label": "Belo sur Tsiribihina - Madagascar"
    },
    {
      "value": "Asia/Yekaterinburg",
      "label": "Beloyarsky - Russia"
    },
    {
      "value": "Europe/London",
      "label": "Bembridge - United Kingdom"
    },
    {
      "value": "America/Chicago",
      "label": "Bemidji - United States"
    },
    {
      "value": "Africa/Casablanca",
      "label": "Ben Slimane - Morocco"
    },
    {
      "value": "Europe/London",
      "label": "Benbecula - United Kingdom"
    },
    {
      "value": "Australia/Hobart",
      "label": "Bendigo - Australia"
    },
    {
      "value": "Africa/Tripoli",
      "label": "Benghazi - Libya"
    },
    {
      "value": "Asia/Jakarta",
      "label": "Bengkulu - Indonesia"
    },
    {
      "value": "Africa/Luanda",
      "label": "Benguela - Angola"
    },
    {
      "value": "Africa/Casablanca",
      "label": "Beni Mellal - Morocco"
    },
    {
      "value": "Africa/Lagos",
      "label": "Benin - Nigeria"
    },
    {
      "value": "Europe/London",
      "label": "Benson - United Kingdom"
    },
    {
      "value": "America/Chicago",
      "label": "Bentonville - United States"
    },
    {
      "value": "America/St_Vincent",
      "label": "Bequia - Saint Vincent and the Grenadines"
    },
    {
      "value": "Africa/Mogadishu",
      "label": "Berbera - Somalia"
    },
    {
      "value": "Africa/Bangui",
      "label": "Berberati - Central African Republic"
    },
    {
      "value": "America/Winnipeg",
      "label": "Berens River - Canada"
    },
    {
      "value": "Asia/Yekaterinburg",
      "label": "Berezovo - Russia"
    },
    {
      "value": "Europe/Rome",
      "label": "Bergamo - Italy"
    },
    {
      "value": "Europe/Oslo",
      "label": "Bergen - Norway"
    },
    {
      "value": "Europe/Paris",
      "label": "Bergerac - France"
    },
    {
      "value": "Europe/Oslo",
      "label": "Berlevag - Norway"
    },
    {
      "value": "Europe/Berlin",
      "label": "Berlin - Germany"
    },
    {
      "value": "America/La_Paz",
      "label": "Bermejo - Bolivia"
    },
    {
      "value": "Atlantic/Bermuda",
      "label": "Bermuda - Bermuda"
    },
    {
      "value": "Europe/Zurich",
      "label": "Bern - Switzerland"
    },
    {
      "value": "Pacific/Tarawa",
      "label": "Beru Island - Kiribati"
    },
    {
      "value": "Indian/Antananarivo",
      "label": "Besalampy - Madagascar"
    },
    {
      "value": "Europe/Moscow",
      "label": "Beslan - Russia"
    },
    {
      "value": "America/Anchorage",
      "label": "Bethel - United States"
    },
    {
      "value": "America/Anchorage",
      "label": "Bettles - United States"
    },
    {
      "value": "America/New_York",
      "label": "Beverly - United States"
    },
    {
      "value": "Europe/Paris",
      "label": "Beziers - France"
    },
    {
      "value": "Asia/Karachi",
      "label": "Bhagtanwala - Pakistan"
    },
    {
      "value": "Asia/Katmandu",
      "label": "Bhairawa - Nepal"
    },
    {
      "value": "Asia/Katmandu",
      "label": "Bharatpur - Nepal"
    },
    {
      "value": "Asia/Calcutta",
      "label": "Bhatinda - India"
    },
    {
      "value": "Asia/Calcutta",
      "label": "Bhaunagar - India"
    },
    {
      "value": "Asia/Katmandu",
      "label": "Bhojpur - Nepal"
    },
    {
      "value": "Asia/Calcutta",
      "label": "Bhopal - India"
    },
    {
      "value": "Asia/Calcutta",
      "label": "Bhubaneswar - India"
    },
    {
      "value": "Asia/Calcutta",
      "label": "Bhuj - India"
    },
    {
      "value": "Asia/Jayapura",
      "label": "Biak - Indonesia"
    },
    {
      "value": "Europe/Paris",
      "label": "Biarritz-bayonne - France"
    },
    {
      "value": "Europe/Berlin",
      "label": "Bielefeld - Germany"
    },
    {
      "value": "America/Anchorage",
      "label": "Big Mountain - United States"
    },
    {
      "value": "America/Winnipeg",
      "label": "Big Trout Lake - Canada"
    },
    {
      "value": "Europe/London",
      "label": "Biggin Hill - United Kingdom"
    },
    {
      "value": "Asia/Shanghai",
      "label": "Bijie - China"
    },
    {
      "value": "Asia/Calcutta",
      "label": "Bikaner - India"
    },
    {
      "value": "Asia/Calcutta",
      "label": "Bilaspur - India"
    },
    {
      "value": "Europe/Madrid",
      "label": "Bilbao - Spain"
    },
    {
      "value": "Atlantic/Reykjavik",
      "label": "Bildudalur - Iceland"
    },
    {
      "value": "America/Denver",
      "label": "Billings - United States"
    },
    {
      "value": "Europe/Copenhagen",
      "label": "Billund - Denmark"
    },
    {
      "value": "Australia/Brisbane",
      "label": "Biloela - Australia"
    },
    {
      "value": "America/Chicago",
      "label": "Biloxi - United States"
    },
    {
      "value": "Asia/Makassar",
      "label": "Bima - Indonesia"
    },
    {
      "value": "America/New_York",
      "label": "Binghamton - United States"
    },
    {
      "value": "Asia/Kuala_Lumpur",
      "label": "Bintulu - Malaysia"
    },
    {
      "value": "Asia/Jayapura",
      "label": "Bintuni - Indonesia"
    },
    {
      "value": "Asia/Katmandu",
      "label": "Biratnagar - Nepal"
    },
    {
      "value": "Europe/Berlin",
      "label": "Birburg - Germany"
    },
    {
      "value": "Australia/Brisbane",
      "label": "Birdsville - Australia"
    },
    {
      "value": "Asia/Tehran",
      "label": "Birjand - Iran"
    },
    {
      "value": "Europe/London",
      "label": "Birmingham - United Kingdom"
    },
    {
      "value": "America/Chicago",
      "label": "Birmingham - United States"
    },
    {
      "value": "Asia/Riyadh",
      "label": "Bisha - Saudi Arabia"
    },
    {
      "value": "Asia/Bishkek",
      "label": "Bishkek - Kyrgyzstan"
    },
    {
      "value": "Africa/Johannesburg",
      "label": "Bisho - South Africa"
    },
    {
      "value": "Africa/Algiers",
      "label": "Biskra - Algeria"
    },
    {
      "value": "America/Chicago",
      "label": "Bismarck - United States"
    },
    {
      "value": "Africa/Bissau",
      "label": "Bissau - Guinea-Bissau"
    },
    {
      "value": "Africa/Libreville",
      "label": "Bitam - Gabon"
    },
    {
      "value": "America/Halifax",
      "label": "Black Tickle - Canada"
    },
    {
      "value": "Australia/Brisbane",
      "label": "Blackall - Australia"
    },
    {
      "value": "Europe/London",
      "label": "Blackbushe - United Kingdom"
    },
    {
      "value": "Europe/London",
      "label": "Blackpool - United Kingdom"
    },
    {
      "value": "Australia/Brisbane",
      "label": "Blackwater - Australia"
    },
    {
      "value": "Asia/Yakutsk",
      "label": "Blagoveschensk - Russia"
    },
    {
      "value": "Africa/Blantyre",
      "label": "Blantyre - Malawi"
    },
    {
      "value": "Africa/Algiers",
      "label": "Blida - Algeria"
    },
    {
      "value": "America/New_York",
      "label": "Block Island - United States"
    },
    {
      "value": "Africa/Johannesburg",
      "label": "Bloemfontein - South Africa"
    },
    {
      "value": "America/Chicago",
      "label": "Bloomington - United States"
    },
    {
      "value": "America/New_York",
      "label": "Bluefield - United States"
    },
    {
      "value": "America/Managua",
      "label": "Bluefields - Nicaragua"
    },
    {
      "value": "America/Sao_Paulo",
      "label": "BLUMENAU - Brazil"
    },
    {
      "value": "America/Los_Angeles",
      "label": "Blythe - United States"
    },
    {
      "value": "America/Chicago",
      "label": "Blytheville - United States"
    },
    {
      "value": "Africa/Freetown",
      "label": "Bo - Sierra Leone"
    },
    {
      "value": "America/Boa_Vista",
      "label": "Boa Vista - Brazil"
    },
    {
      "value": "Atlantic/Cape_Verde",
      "label": "Boa Vista - Cape Verde"
    },
    {
      "value": "America/Vancouver",
      "label": "Bob Quinn Lake - Canada"
    },
    {
      "value": "Africa/Ouagadougou",
      "label": "Bobo-dioulasso - Burkina Faso"
    },
    {
      "value": "America/New_York",
      "label": "Boca Raton - United States"
    },
    {
      "value": "America/Panama",
      "label": "Bocas Del Toro - Panama"
    },
    {
      "value": "Asia/Irkutsk",
      "label": "Bodaibo - Russia"
    },
    {
      "value": "Europe/Oslo",
      "label": "Bodo - Norway"
    },
    {
      "value": "Europe/Istanbul",
      "label": "Bodrum - Turkey"
    },
    {
      "value": "America/Bogota",
      "label": "Bogota - Colombia"
    },
    {
      "value": "Australia/Brisbane",
      "label": "Boigu - Australia"
    },
    {
      "value": "America/Campo_Grande",
      "label": "Bointo - Brazil"
    },
    {
      "value": "America/Denver",
      "label": "Boise - United States"
    },
    {
      "value": "Asia/Tehran",
      "label": "Bojnourd - Iran"
    },
    {
      "value": "Asia/Rangoon",
      "label": "Bokepyin - Burma"
    },
    {
      "value": "Asia/Jayapura",
      "label": "Bokondini-Papua Island - Indonesia"
    },
    {
      "value": "Europe/Rome",
      "label": "Bologna - Italy"
    },
    {
      "value": "Europe/Rome",
      "label": "Bolzano - Italy"
    },
    {
      "value": "America/Fortaleza",
      "label": "Bom Jesus Da Lapa - Brazil"
    },
    {
      "value": "Africa/Kinshasa",
      "label": "Boma - Congo (Kinshasa)"
    },
    {
      "value": "America/Managua",
      "label": "Bonanza - Nicaragua"
    },
    {
      "value": "America/Toronto",
      "label": "Bonaventure - Canada"
    },
    {
      "value": "America/Edmonton",
      "label": "Bonnyville - Canada"
    },
    {
      "value": "Africa/Freetown",
      "label": "Bonthe - Sierra Leone"
    },
    {
      "value": "Asia/Krasnoyarsk",
      "label": "Bor - Russia"
    },
    {
      "value": "Pacific/Tahiti",
      "label": "Bora Bora - French Polynesia"
    },
    {
      "value": "America/Boa_Vista",
      "label": "Borba - Brazil"
    },
    {
      "value": "Europe/Paris",
      "label": "Bordeaux - France"
    },
    {
      "value": "Africa/Algiers",
      "label": "Bordj Badji Mokhtar - Algeria"
    },
    {
      "value": "Europe/Berlin",
      "label": "Borkum - Germany"
    },
    {
      "value": "Europe/Stockholm",
      "label": "Borlange - Sweden"
    },
    {
      "value": "Africa/Mogadishu",
      "label": "Bosaso - Somalia"
    },
    {
      "value": "America/New_York",
      "label": "Boston - United States"
    },
    {
      "value": "America/Sao_Paulo",
      "label": "Botucatu - Brazil"
    },
    {
      "value": "Africa/Algiers",
      "label": "Bou Saada - Algeria"
    },
    {
      "value": "Africa/Abidjan",
      "label": "Bouake - Cote d'Ivoire"
    },
    {
      "value": "Africa/Casablanca",
      "label": "Bouarfa - Morocco"
    },
    {
      "value": "America/Denver",
      "label": "Boulder - United States"
    },
    {
      "value": "Australia/Brisbane",
      "label": "Boulia - Australia"
    },
    {
      "value": "America/Vancouver",
      "label": "Boundary Bay - Canada"
    },
    {
      "value": "Europe/Sofia",
      "label": "Bourgas - Bulgaria"
    },
    {
      "value": "Europe/Paris",
      "label": "Bourges - France"
    },
    {
      "value": "Australia/Sydney",
      "label": "Bourke - Australia"
    },
    {
      "value": "Europe/London",
      "label": "Bournemouth - United Kingdom"
    },
    {
      "value": "America/Chicago",
      "label": "Bowling Green - United States"
    },
    {
      "value": "America/Denver",
      "label": "Bozeman - United States"
    },
    {
      "value": "Europe/Zagreb",
      "label": "Brac - Croatia"
    },
    {
      "value": "America/New_York",
      "label": "Bradford - United States"
    },
    {
      "value": "Europe/Lisbon",
      "label": "Braga - Portugal"
    },
    {
      "value": "Europe/Lisbon",
      "label": "Braganca - Portugal"
    },
    {
      "value": "America/Sao_Paulo",
      "label": "Braganca Paulista - Brazil"
    },
    {
      "value": "America/Chicago",
      "label": "Brainerd - United States"
    },
    {
      "value": "Australia/Brisbane",
      "label": "Brampton Island - Australia"
    },
    {
      "value": "America/Winnipeg",
      "label": "Brandon - Canada"
    },
    {
      "value": "America/Sao_Paulo",
      "label": "Brasilia - Brazil"
    },
    {
      "value": "Europe/Bratislava",
      "label": "Bratislava - Slovakia"
    },
    {
      "value": "Asia/Irkutsk",
      "label": "Bratsk - Russia"
    },
    {
      "value": "Europe/Berlin",
      "label": "Braunschweig - Germany"
    },
    {
      "value": "Africa/Brazzaville",
      "label": "Brazzaville - Congo (Brazzaville)"
    },
    {
      "value": "America/Chicago",
      "label": "Breckenridge - United States"
    },
    {
      "value": "Europe/Berlin",
      "label": "Bremen - Germany"
    },
    {
      "value": "Europe/Berlin",
      "label": "Bremerhaven - Germany"
    },
    {
      "value": "America/Los_Angeles",
      "label": "Bremerton - United States"
    },
    {
      "value": "Europe/Rome",
      "label": "Brescia - Italy"
    },
    {
      "value": "Europe/Minsk",
      "label": "Brest - Belarus"
    },
    {
      "value": "Europe/Paris",
      "label": "Brest - France"
    },
    {
      "value": "America/Belem",
      "label": "Breves - Brazil"
    },
    {
      "value": "America/Anchorage",
      "label": "Brevig Mission - United States"
    },
    {
      "value": "America/Barbados",
      "label": "Bridgetown - Barbados"
    },
    {
      "value": "America/Denver",
      "label": "Brigham City - United States"
    },
    {
      "value": "Europe/Rome",
      "label": "Brindisi - Italy"
    },
    {
      "value": "Australia/Brisbane",
      "label": "Brisbane - Australia"
    },
    {
      "value": "Europe/London",
      "label": "Bristol - United Kingdom"
    },
    {
      "value": "America/New_York",
      "label": "BRISTOL - United States"
    },
    {
      "value": "Europe/Paris",
      "label": "Brive - France"
    },
    {
      "value": "Europe/London",
      "label": "Brize Norton - United Kingdom"
    },
    {
      "value": "Europe/Prague",
      "label": "Brno - Czech Republic"
    },
    {
      "value": "America/Winnipeg",
      "label": "Brochet - Canada"
    },
    {
      "value": "Australia/Adelaide",
      "label": "Broken Hill - Australia"
    },
    {
      "value": "America/Toronto",
      "label": "Bromont - Canada"
    },
    {
      "value": "Europe/Oslo",
      "label": "Bronnoysund - Norway"
    },
    {
      "value": "Australia/Perth",
      "label": "Broome - Australia"
    },
    {
      "value": "America/Denver",
      "label": "Broomfield-CO - United States"
    },
    {
      "value": "America/Toronto",
      "label": "Broughton Island - Canada"
    },
    {
      "value": "America/Chicago",
      "label": "Brownsville - United States"
    },
    {
      "value": "America/New_York",
      "label": "Brunswick - United States"
    },
    {
      "value": "America/Tegucigalpa",
      "label": "Brus Laguna - Honduras"
    },
    {
      "value": "Europe/Brussels",
      "label": "Brussels - Belgium"
    },
    {
      "value": "Australia/Perth",
      "label": "Brusselton - Australia"
    },
    {
      "value": "America/Chicago",
      "label": "Bryan - United States"
    },
    {
      "value": "Europe/Moscow",
      "label": "Bryansk - Russia"
    },
    {
      "value": "America/Denver",
      "label": "Bryce Canyon - United States"
    },
    {
      "value": "America/Bogota",
      "label": "Bucaramanga - Colombia"
    },
    {
      "value": "Europe/Bucharest",
      "label": "Bucharest - Romania"
    },
    {
      "value": "America/Phoenix",
      "label": "Buckeye - United States"
    },
    {
      "value": "America/Anchorage",
      "label": "Buckland - United States"
    },
    {
      "value": "America/Denver",
      "label": "Buckley - United States"
    },
    {
      "value": "Europe/Budapest",
      "label": "Budapest - Hungary"
    },
    {
      "value": "America/Bogota",
      "label": "Buenaventura - Colombia"
    },
    {
      "value": "America/Buenos_Aires",
      "label": "Buenos Aires - Argentina"
    },
    {
      "value": "America/Costa_Rica",
      "label": "Buenos Aires - Costa Rica"
    },
    {
      "value": "America/New_York",
      "label": "Buffalo - United States"
    },
    {
      "value": "America/Regina",
      "label": "Buffalo Narrows - Canada"
    },
    {
      "value": "Europe/Moscow",
      "label": "Bugulma - Russia"
    },
    {
      "value": "Africa/Bujumbura",
      "label": "Bujumbura - Burundi"
    },
    {
      "value": "Pacific/Port_Moresby",
      "label": "Buka Island - Papua New Guinea"
    },
    {
      "value": "Africa/Lubumbashi",
      "label": "Bukavu/kavumu - Congo (Kinshasa)"
    },
    {
      "value": "Asia/Samarkand",
      "label": "Bukhara - Uzbekistan"
    },
    {
      "value": "Africa/Dar_es_Salaam",
      "label": "Bukoba - Tanzania"
    },
    {
      "value": "Africa/Harare",
      "label": "Bulawayo - Zimbabwe"
    },
    {
      "value": "Asia/Ulaanbaatar",
      "label": "Bulgan - Mongolia"
    },
    {
      "value": "America/Nassau",
      "label": "Bullocks Harbour - Bahamas"
    },
    {
      "value": "Africa/Harare",
      "label": "Bumi Hills - Zimbabwe"
    },
    {
      "value": "Australia/Brisbane",
      "label": "Bundaberg - Australia"
    },
    {
      "value": "Africa/Lubumbashi",
      "label": "Bunia - Congo (Kinshasa)"
    },
    {
      "value": "Europe/Zurich",
      "label": "Buochs - Switzerland"
    },
    {
      "value": "Asia/Saigon",
      "label": "Buonmethuot - Vietnam"
    },
    {
      "value": "Africa/Mogadishu",
      "label": "Burao - Somalia"
    },
    {
      "value": "America/Los_Angeles",
      "label": "Burbank - United States"
    },
    {
      "value": "Europe/Berlin",
      "label": "Burg Feuerstein - Germany"
    },
    {
      "value": "Europe/Madrid",
      "label": "Burgos - Spain"
    },
    {
      "value": "Asia/Bangkok",
      "label": "Buri Ram - Thailand"
    },
    {
      "value": "Australia/Brisbane",
      "label": "Burketown - Australia"
    },
    {
      "value": "America/New_York",
      "label": "Burlington - United States"
    },
    {
      "value": "Australia/Melbourne",
      "label": "Burnie - Australia"
    },
    {
      "value": "America/Los_Angeles",
      "label": "Burns - United States"
    },
    {
      "value": "Asia/Shanghai",
      "label": "Burqin - China"
    },
    {
      "value": "Europe/Istanbul",
      "label": "Bursa - Turkey"
    },
    {
      "value": "America/Vancouver",
      "label": "Burwash - Canada"
    },
    {
      "value": "Asia/Seoul",
      "label": "Busan - South Korea"
    },
    {
      "value": "America/New_York",
      "label": "Bush Field - United States"
    },
    {
      "value": "Asia/Tehran",
      "label": "Bushehr - Iran"
    },
    {
      "value": "Asia/Manila",
      "label": "Busuanga - Philippines"
    },
    {
      "value": "Africa/Lubumbashi",
      "label": "Buta Zega - Congo (Kinshasa)"
    },
    {
      "value": "Pacific/Tarawa",
      "label": "Butaritari - Kiribati"
    },
    {
      "value": "America/Denver",
      "label": "Butte - United States"
    },
    {
      "value": "Asia/Kuala_Lumpur",
      "label": "Butterworth - Malaysia"
    },
    {
      "value": "Asia/Manila",
      "label": "Butuan - Philippines"
    },
    {
      "value": "Europe/Warsaw",
      "label": "Bydgoszcz - Poland"
    },
    {
      "value": "Asia/Saigon",
      "label": "Ca Mau - Vietnam"
    },
    {
      "value": "Africa/Luanda",
      "label": "Cabinda - Angola"
    },
    {
      "value": "America/Sao_Paulo",
      "label": "Cabo Frio - Brazil"
    },
    {
      "value": "America/Santo_Domingo",
      "label": "Cabo Rojo - Dominican Republic"
    },
    {
      "value": "America/Sao_Paulo",
      "label": "Cacador - Brazil"
    },
    {
      "value": "America/Boa_Vista",
      "label": "Cacoal - Brazil"
    },
    {
      "value": "Europe/Paris",
      "label": "Caen - France"
    },
    {
      "value": "Europe/Rome",
      "label": "Cagliari - Italy"
    },
    {
      "value": "Europe/Paris",
      "label": "Cahors - France"
    },
    {
      "value": "America/Caracas",
      "label": "Caicara De Orinoco - Venezuela"
    },
    {
      "value": "Australia/Brisbane",
      "label": "Cairns - Australia"
    },
    {
      "value": "Africa/Cairo",
      "label": "Cairo - Egypt"
    },
    {
      "value": "America/Lima",
      "label": "Cajamarca - Peru"
    },
    {
      "value": "Africa/Lagos",
      "label": "Calabar - Nigeria"
    },
    {
      "value": "America/Caracas",
      "label": "Calabozo - Venezuela"
    },
    {
      "value": "Europe/Paris",
      "label": "Calais - France"
    },
    {
      "value": "America/Santiago",
      "label": "Calama - Chile"
    },
    {
      "value": "Asia/Manila",
      "label": "Calbayog City - Philippines"
    },
    {
      "value": "America/Sao_Paulo",
      "label": "Caldas Novas - Brazil"
    },
    {
      "value": "America/New_York",
      "label": "Caldwell - United States"
    },
    {
      "value": "America/Los_Angeles",
      "label": "Calexico - United States"
    },
    {
      "value": "America/Edmonton",
      "label": "Calgary - Canada"
    },
    {
      "value": "America/Bogota",
      "label": "Cali - Colombia"
    },
    {
      "value": "Asia/Calcutta",
      "label": "Calicut - India"
    },
    {
      "value": "Europe/Paris",
      "label": "Calvi - France"
    },
    {
      "value": "America/Havana",
      "label": "Camaguey - Cuba"
    },
    {
      "value": "Europe/London",
      "label": "Cambridge - United Kingdom"
    },
    {
      "value": "America/Edmonton",
      "label": "Cambridge Bay - Canada"
    },
    {
      "value": "Australia/Sydney",
      "label": "Camden - Australia"
    },
    {
      "value": "America/New_York",
      "label": "Camden - United States"
    },
    {
      "value": "Asia/Manila",
      "label": "Camiguin - Philippines"
    },
    {
      "value": "Asia/Kabul",
      "label": "Camp Bastion - Afghanistan"
    },
    {
      "value": "America/Chicago",
      "label": "Camp Douglas - United States"
    },
    {
      "value": "America/New_York",
      "label": "Camp Springs - United States"
    },
    {
      "value": "America/Vancouver",
      "label": "Campbell River - Canada"
    },
    {
      "value": "Europe/London",
      "label": "Campbeltown - United Kingdom"
    },
    {
      "value": "America/Mexico_City",
      "label": "Campeche - Mexico"
    },
    {
      "value": "America/Fortaleza",
      "label": "Campina Grande - Brazil"
    },
    {
      "value": "America/Sao_Paulo",
      "label": "Campinas - Brazil"
    },
    {
      "value": "America/Campo_Grande",
      "label": "Campo Grande - Brazil"
    },
    {
      "value": "America/Sao_Paulo",
      "label": "Campos - Brazil"
    },
    {
      "value": "Asia/Saigon",
      "label": "Can Tho - Vietnam"
    },
    {
      "value": "America/Caracas",
      "label": "Canaima - Venezuela"
    },
    {
      "value": "Europe/Istanbul",
      "label": "Canakkale - Turkey"
    },
    {
      "value": "Australia/Sydney",
      "label": "Canberra - Australia"
    },
    {
      "value": "America/Cancun",
      "label": "Cancun - Mexico"
    },
    {
      "value": "America/Dominica",
      "label": "Canefield - Dominica"
    },
    {
      "value": "Europe/Paris",
      "label": "Cannes - France"
    },
    {
      "value": "America/St_Vincent",
      "label": "Canouan Island - Saint Vincent and the Grenadines"
    },
    {
      "value": "Pacific/Enderbury",
      "label": "Canton Island - Kiribati"
    },
    {
      "value": "America/Port-au-Prince",
      "label": "Cap Haitien - Haiti"
    },
    {
      "value": "Africa/Dakar",
      "label": "Cap Skiring - Senegal"
    },
    {
      "value": "America/Toronto",
      "label": "Cape Dorset - Canada"
    },
    {
      "value": "America/Chicago",
      "label": "Cape Girardeau - United States"
    },
    {
      "value": "America/Anchorage",
      "label": "Cape Lisburne - United States"
    },
    {
      "value": "America/Anchorage",
      "label": "Cape Newenham - United States"
    },
    {
      "value": "America/Anchorage",
      "label": "Cape Romanzof - United States"
    },
    {
      "value": "Africa/Johannesburg",
      "label": "Cape Town - South Africa"
    },
    {
      "value": "America/Bogota",
      "label": "Capurgana - Colombia"
    },
    {
      "value": "Europe/Bucharest",
      "label": "Caransebes - Romania"
    },
    {
      "value": "America/Boa_Vista",
      "label": "Carauari - Brazil"
    },
    {
      "value": "America/Fortaleza",
      "label": "Caravelas - Brazil"
    },
    {
      "value": "Europe/Paris",
      "label": "Carcassonne - France"
    },
    {
      "value": "Europe/London",
      "label": "Cardiff - United Kingdom"
    },
    {
      "value": "America/Bogota",
      "label": "Carepa - Colombia"
    },
    {
      "value": "America/New_York",
      "label": "Caribou - United States"
    },
    {
      "value": "Europe/London",
      "label": "Carlisle - United Kingdom"
    },
    {
      "value": "America/Denver",
      "label": "Carlsbad - United States"
    },
    {
      "value": "Australia/Perth",
      "label": "Carnarvon - Australia"
    },
    {
      "value": "Asia/Calcutta",
      "label": "Carnicobar - India"
    },
    {
      "value": "America/Fortaleza",
      "label": "Carolina - Brazil"
    },
    {
      "value": "America/Caracas",
      "label": "Carora - Venezuela"
    },
    {
      "value": "America/Costa_Rica",
      "label": "Carrillo - Costa Rica"
    },
    {
      "value": "America/Bogota",
      "label": "Cartagena - Colombia"
    },
    {
      "value": "America/Bogota",
      "label": "Cartago - Colombia"
    },
    {
      "value": "America/Halifax",
      "label": "Cartwright - Canada"
    },
    {
      "value": "America/Fortaleza",
      "label": "Caruaru - Brazil"
    },
    {
      "value": "America/Caracas",
      "label": "Carupano - Venezuela"
    },
    {
      "value": "America/Phoenix",
      "label": "Casa Grande - United States"
    },
    {
      "value": "Africa/Casablanca",
      "label": "Casablanca - Morocco"
    },
    {
      "value": "Europe/Lisbon",
      "label": "Cascais - Portugal"
    },
    {
      "value": "America/Sao_Paulo",
      "label": "Cascavel - Brazil"
    },
    {
      "value": "America/Denver",
      "label": "Casper - United States"
    },
    {
      "value": "America/Vancouver",
      "label": "Castlegar - Canada"
    },
    {
      "value": "Europe/Paris",
      "label": "Castres - France"
    },
    {
      "value": "America/St_Lucia",
      "label": "Castries - Saint Lucia"
    },
    {
      "value": "America/Santiago",
      "label": "Castro - Chile"
    },
    {
      "value": "America/Nassau",
      "label": "Cat Island - Bahamas"
    },
    {
      "value": "America/Winnipeg",
      "label": "Cat Lake - Canada"
    },
    {
      "value": "America/Los_Angeles",
      "label": "Catalina Island - United States"
    },
    {
      "value": "America/Catamarca",
      "label": "Catamarca - Argentina"
    },
    {
      "value": "Europe/Rome",
      "label": "Catania - Italy"
    },
    {
      "value": "Asia/Manila",
      "label": "Catarman - Philippines"
    },
    {
      "value": "Asia/Manila",
      "label": "Caticlan - Philippines"
    },
    {
      "value": "Africa/Luanda",
      "label": "Catumbela - Angola"
    },
    {
      "value": "Asia/Manila",
      "label": "Cauayan - Philippines"
    },
    {
      "value": "America/Bogota",
      "label": "Caucasia - Colombia"
    },
    {
      "value": "America/Sao_Paulo",
      "label": "Caxias Do Sul - Brazil"
    },
    {
      "value": "America/Cayenne",
      "label": "Cayenne - French Guiana"
    },
    {
      "value": "America/Port-au-Prince",
      "label": "Cayes - Haiti"
    },
    {
      "value": "America/Cayman",
      "label": "Cayman Brac - Cayman Islands"
    },
    {
      "value": "America/Havana",
      "label": "Cayo Largo del Sur - Cuba"
    },
    {
      "value": "Asia/Manila",
      "label": "Cebu - Philippines"
    },
    {
      "value": "America/Denver",
      "label": "Cedar City - United States"
    },
    {
      "value": "America/Chicago",
      "label": "Cedar Rapids - United States"
    },
    {
      "value": "Australia/Adelaide",
      "label": "Ceduna - Australia"
    },
    {
      "value": "America/Puerto_Rico",
      "label": "Ceiba - Puerto Rico"
    },
    {
      "value": "America/Mexico_City",
      "label": "Celaya - Mexico"
    },
    {
      "value": "Europe/Berlin",
      "label": "Celle - Germany"
    },
    {
      "value": "America/Anchorage",
      "label": "Central - United States"
    },
    {
      "value": "Australia/Sydney",
      "label": "Cessnock - Australia"
    },
    {
      "value": "America/Costa_Rica",
      "label": "Chacarita - Costa Rica"
    },
    {
      "value": "America/Lima",
      "label": "Chachapoyas - Peru"
    },
    {
      "value": "America/Denver",
      "label": "Chadron - United States"
    },
    {
      "value": "Asia/Kabul",
      "label": "Chaghcharan - Afghanistan"
    },
    {
      "value": "Asia/Tehran",
      "label": "Chah Bahar - Iran"
    },
    {
      "value": "America/Santiago",
      "label": "Chaiten - Chile"
    },
    {
      "value": "America/Anchorage",
      "label": "Chalkyitsik - United States"
    },
    {
      "value": "Europe/Paris",
      "label": "Chalons - France"
    },
    {
      "value": "Europe/Paris",
      "label": "Chambery - France"
    },
    {
      "value": "America/Chicago",
      "label": "Champaign - United States"
    },
    {
      "value": "Asia/Calcutta",
      "label": "Chandigarh - India"
    },
    {
      "value": "Asia/Katmandu",
      "label": "Chandragarhi - Nepal"
    },
    {
      "value": "Asia/Shanghai",
      "label": "Changcha - China"
    },
    {
      "value": "Asia/Shanghai",
      "label": "Changchun - China"
    },
    {
      "value": "Asia/Shanghai",
      "label": "Changde - China"
    },
    {
      "value": "Asia/Shanghai",
      "label": "Changhai - China"
    },
    {
      "value": "America/Panama",
      "label": "Changuinola - Panama"
    },
    {
      "value": "Asia/Shanghai",
      "label": "Changzhi - China"
    },
    {
      "value": "Asia/Shanghai",
      "label": "Changzhou - China"
    },
    {
      "value": "Europe/Athens",
      "label": "Chania - Greece"
    },
    {
      "value": "Asia/Shanghai",
      "label": "Chaoyang - China"
    },
    {
      "value": "America/La_Paz",
      "label": "Chapacura - Bolivia"
    },
    {
      "value": "America/Sao_Paulo",
      "label": "Chapeco - Brazil"
    },
    {
      "value": "America/Toronto",
      "label": "Chapleau - Canada"
    },
    {
      "value": "Asia/Ashgabat",
      "label": "Chardzhou - Turkmenistan"
    },
    {
      "value": "Europe/Brussels",
      "label": "Charleroi - Belgium"
    },
    {
      "value": "America/New_York",
      "label": "Charleston - United States"
    },
    {
      "value": "America/St_Kitts",
      "label": "Charlestown - Saint Kitts and Nevis"
    },
    {
      "value": "Europe/Paris",
      "label": "Charleville - France"
    },
    {
      "value": "Australia/Brisbane",
      "label": "Charlieville - Australia"
    },
    {
      "value": "America/Halifax",
      "label": "Charlo - Canada"
    },
    {
      "value": "America/New_York",
      "label": "Charlotte - United States"
    },
    {
      "value": "America/St_Thomas",
      "label": "Charlotte Amalie - Virgin Islands"
    },
    {
      "value": "America/New_York",
      "label": "Charlottesville VA - United States"
    },
    {
      "value": "America/Halifax",
      "label": "Charlottetown - Canada"
    },
    {
      "value": "Europe/Paris",
      "label": "Chateauroux - France"
    },
    {
      "value": "America/Halifax",
      "label": "Chatham - Canada"
    },
    {
      "value": "Pacific/Chatham",
      "label": "Chatham Island - New Zealand"
    },
    {
      "value": "Australia/Sydney",
      "label": "Chatsworth - United States"
    },
    {
      "value": "America/New_York",
      "label": "Chattanooga - United States"
    },
    {
      "value": "Europe/Moscow",
      "label": "Cheboksary - Russia"
    },
    {
      "value": "America/Anchorage",
      "label": "Chefornak - United States"
    },
    {
      "value": "America/Los_Angeles",
      "label": "Chehalis - United States"
    },
    {
      "value": "Asia/Seoul",
      "label": "Cheju - South Korea"
    },
    {
      "value": "Asia/Yekaterinburg",
      "label": "Chelyabinsk - Russia"
    },
    {
      "value": "America/Anchorage",
      "label": "Chenega - United States"
    },
    {
      "value": "Asia/Shanghai",
      "label": "Chengdu - China"
    },
    {
      "value": "America/New_York",
      "label": "Cheraw - United States"
    },
    {
      "value": "Europe/Paris",
      "label": "Cherbourg - France"
    },
    {
      "value": "Europe/Moscow",
      "label": "Cherepovets - Russia"
    },
    {
      "value": "Europe/Kiev",
      "label": "Cherkassy - Ukraine"
    },
    {
      "value": "Europe/Kiev",
      "label": "Chernigov - Ukraine"
    },
    {
      "value": "Europe/Kiev",
      "label": "Chernovtsk - Ukraine"
    },
    {
      "value": "Asia/Srednekolymsk",
      "label": "Cherskiy - Russia"
    },
    {
      "value": "America/Winnipeg",
      "label": "Chesterfield Inlet - Canada"
    },
    {
      "value": "America/Cancun",
      "label": "Chetumal - Mexico"
    },
    {
      "value": "America/Anchorage",
      "label": "Chevak - United States"
    },
    {
      "value": "America/Blanc-Sablon",
      "label": "Chevery - Canada"
    },
    {
      "value": "America/Denver",
      "label": "Cheyenne - United States"
    },
    {
      "value": "Asia/Bangkok",
      "label": "Chiang Mai - Thailand"
    },
    {
      "value": "Asia/Bangkok",
      "label": "Chiang Rai - Thailand"
    },
    {
      "value": "Asia/Taipei",
      "label": "Chiayi - Taiwan"
    },
    {
      "value": "America/Toronto",
      "label": "Chibougamau - Canada"
    },
    {
      "value": "America/Chicago",
      "label": "Chicago - United States"
    },
    {
      "value": "America/Chicago",
      "label": "Chicago-Wheeling - United States"
    },
    {
      "value": "America/Mexico_City",
      "label": "Chichen Itza - Mexico"
    },
    {
      "value": "America/Lima",
      "label": "Chiclayo - Peru"
    },
    {
      "value": "America/Los_Angeles",
      "label": "Chico - United States"
    },
    {
      "value": "America/New_York",
      "label": "Chicopee Falls - United States"
    },
    {
      "value": "Asia/Shanghai",
      "label": "Chifeng - China"
    },
    {
      "value": "America/Mazatlan",
      "label": "Chihuahua - Mexico"
    },
    {
      "value": "America/Chicago",
      "label": "Childress - United States"
    },
    {
      "value": "America/Santiago",
      "label": "Chile Chico - Chile"
    },
    {
      "value": "America/Santiago",
      "label": "Chillan - Chile"
    },
    {
      "value": "America/Vancouver",
      "label": "Chilliwack - Canada"
    },
    {
      "value": "America/Lima",
      "label": "Chimbote - Peru"
    },
    {
      "value": "Asia/Qyzylorda",
      "label": "Chimkent - Kazakhstan"
    },
    {
      "value": "Africa/Maputo",
      "label": "Chimoio - Mozambique"
    },
    {
      "value": "Australia/Brisbane",
      "label": "Chinchilla - Australia"
    },
    {
      "value": "Asia/Seoul",
      "label": "Chinhae - South Korea"
    },
    {
      "value": "Europe/Athens",
      "label": "Chios - Greece"
    },
    {
      "value": "Africa/Lusaka",
      "label": "Chipata - Zambia"
    },
    {
      "value": "Africa/Harare",
      "label": "Chiredzi - Zimbabwe"
    },
    {
      "value": "America/Toronto",
      "label": "Chisasibi - Canada"
    },
    {
      "value": "Europe/Chisinau",
      "label": "Chisinau - Moldova"
    },
    {
      "value": "Asia/Yakutsk",
      "label": "Chita - Russia"
    },
    {
      "value": "America/Panama",
      "label": "Chitré - Panama"
    },
    {
      "value": "Asia/Karachi",
      "label": "Chitral - Pakistan"
    },
    {
      "value": "Asia/Dhaka",
      "label": "Chittagong - Bangladesh"
    },
    {
      "value": "Asia/Ulaanbaatar",
      "label": "Choibalsan - Mongolia"
    },
    {
      "value": "Pacific/Guadalcanal",
      "label": "Choiseul Bay - Solomon Islands"
    },
    {
      "value": "Asia/Srednekolymsk",
      "label": "Chokurdah - Russia"
    },
    {
      "value": "Europe/Paris",
      "label": "Cholet - France"
    },
    {
      "value": "Asia/Seoul",
      "label": "Chongju - South Korea"
    },
    {
      "value": "Asia/Shanghai",
      "label": "Chongqing - China"
    },
    {
      "value": "America/Argentina/Salta",
      "label": "Chosmadal - Argentina"
    },
    {
      "value": "Pacific/Auckland",
      "label": "Christchurch - New Zealand"
    },
    {
      "value": "Indian/Christmas",
      "label": "Christmas Island - Christmas Island"
    },
    {
      "value": "Asia/Saigon",
      "label": "Chu Lai - Vietnam"
    },
    {
      "value": "America/Nassau",
      "label": "Chub Cay - Bahamas"
    },
    {
      "value": "Asia/Bangkok",
      "label": "Chumphon - Thailand"
    },
    {
      "value": "America/Winnipeg",
      "label": "Churchill - Canada"
    },
    {
      "value": "America/Halifax",
      "label": "Churchill Falls - Canada"
    },
    {
      "value": "Pacific/Truk",
      "label": "Chuuk - Micronesia"
    },
    {
      "value": "Pacific/Fiji",
      "label": "Cicia - Fiji"
    },
    {
      "value": "America/Havana",
      "label": "Ciego De Avila - Cuba"
    },
    {
      "value": "America/Havana",
      "label": "Cienfuegos - Cuba"
    },
    {
      "value": "Asia/Jakarta",
      "label": "Cilacap - Indonesia"
    },
    {
      "value": "America/New_York",
      "label": "Cincinnati - United States"
    },
    {
      "value": "America/Anchorage",
      "label": "Circle - United States"
    },
    {
      "value": "Asia/Jakarta",
      "label": "Cirebon - Indonesia"
    },
    {
      "value": "America/Mexico_City",
      "label": "Ciudad Acuna - Mexico"
    },
    {
      "value": "America/Caracas",
      "label": "Ciudad Bolivar - Venezuela"
    },
    {
      "value": "America/Mazatlan",
      "label": "Ciudad Constitución - Mexico"
    },
    {
      "value": "America/Mexico_City",
      "label": "Ciudad Del Carmen - Mexico"
    },
    {
      "value": "America/Asuncion",
      "label": "Ciudad del Este - Paraguay"
    },
    {
      "value": "America/Mazatlan",
      "label": "Ciudad Juarez - Mexico"
    },
    {
      "value": "America/Hermosillo",
      "label": "Ciudad Obregon - Mexico"
    },
    {
      "value": "Europe/Madrid",
      "label": "Ciudad Real - Spain"
    },
    {
      "value": "America/Mexico_City",
      "label": "Ciudad Victoria - Mexico"
    },
    {
      "value": "Europe/Istanbul",
      "label": "Cizre - Turkey"
    },
    {
      "value": "America/Nassau",
      "label": "Clarence Bain - Bahamas"
    },
    {
      "value": "Pacific/Auckland",
      "label": "Claris - New Zealand"
    },
    {
      "value": "America/New_York",
      "label": "Clarksburg - United States"
    },
    {
      "value": "America/Chicago",
      "label": "Clarksville - United States"
    },
    {
      "value": "America/New_York",
      "label": "Clearwater - United States"
    },
    {
      "value": "America/New_York",
      "label": "Clemson - United States"
    },
    {
      "value": "Europe/Paris",
      "label": "Clermont-Ferrand - France"
    },
    {
      "value": "America/New_York",
      "label": "Cleveland - United States"
    },
    {
      "value": "America/Chicago",
      "label": "Clinton - United States"
    },
    {
      "value": "Australia/Brisbane",
      "label": "Cloncurry - Australia"
    },
    {
      "value": "Australia/Perth",
      "label": "Cloudbreak - Australia"
    },
    {
      "value": "America/Denver",
      "label": "Clovis - United States"
    },
    {
      "value": "Africa/Blantyre",
      "label": "Club Makokola - Malawi"
    },
    {
      "value": "Europe/Bucharest",
      "label": "Cluj-napoca - Romania"
    },
    {
      "value": "America/Toronto",
      "label": "Clyde River - Canada"
    },
    {
      "value": "America/Boa_Vista",
      "label": "Coari - Brazil"
    },
    {
      "value": "America/New_York",
      "label": "Coatesville - United States"
    },
    {
      "value": "America/Guatemala",
      "label": "Coban - Guatemala"
    },
    {
      "value": "Australia/Sydney",
      "label": "Cobar - Australia"
    },
    {
      "value": "America/La_Paz",
      "label": "Cobija - Bolivia"
    },
    {
      "value": "America/Guayaquil",
      "label": "Coca - Ecuador"
    },
    {
      "value": "America/La_Paz",
      "label": "Cochabamba - Bolivia"
    },
    {
      "value": "America/Toronto",
      "label": "Cochrane - Canada"
    },
    {
      "value": "Europe/Berlin",
      "label": "Cochstedt - Germany"
    },
    {
      "value": "America/Nassau",
      "label": "Cockburn Town - Bahamas"
    },
    {
      "value": "America/Grand_Turk",
      "label": "Cockburn Town - Turks and Caicos Islands"
    },
    {
      "value": "America/New_York",
      "label": "Coco Beach - United States"
    },
    {
      "value": "Australia/Brisbane",
      "label": "Coconut Island - Australia"
    },
    {
      "value": "Indian/Cocos",
      "label": "Cocos Keeling Island - Cocos (Keeling) Islands"
    },
    {
      "value": "America/Antigua",
      "label": "Codrington - Antigua and Barbuda"
    },
    {
      "value": "America/Denver",
      "label": "Cody - United States"
    },
    {
      "value": "Australia/Brisbane",
      "label": "Coen - Australia"
    },
    {
      "value": "America/Los_Angeles",
      "label": "Coeur d'Alene - United States"
    },
    {
      "value": "Australia/Sydney",
      "label": "Coff's Harbour - Australia"
    },
    {
      "value": "Europe/Paris",
      "label": "Cognac - France"
    },
    {
      "value": "Asia/Calcutta",
      "label": "Coimbatore - India"
    },
    {
      "value": "America/Panama",
      "label": "Colón - Panama"
    },
    {
      "value": "America/Anchorage",
      "label": "Cold Bay - United States"
    },
    {
      "value": "America/Edmonton",
      "label": "Cold Lake - Canada"
    },
    {
      "value": "America/Mexico_City",
      "label": "Colima - Mexico"
    },
    {
      "value": "America/Chicago",
      "label": "College Station - United States"
    },
    {
      "value": "Europe/Paris",
      "label": "Colmar - France"
    },
    {
      "value": "Europe/Berlin",
      "label": "Cologne - Germany"
    },
    {
      "value": "Asia/Colombo",
      "label": "Colombo - Sri Lanka"
    },
    {
      "value": "America/Chicago",
      "label": "Colombus - United States"
    },
    {
      "value": "America/Nassau",
      "label": "Colonel Hill - Bahamas"
    },
    {
      "value": "America/Buenos_Aires",
      "label": "Colonel Suarez - Argentina"
    },
    {
      "value": "America/Montevideo",
      "label": "Colonia - Uruguay"
    },
    {
      "value": "Europe/London",
      "label": "Colonsay - United Kingdom"
    },
    {
      "value": "America/Denver",
      "label": "Colorado Springs - United States"
    },
    {
      "value": "America/New_York",
      "label": "Columbia - United States"
    },
    {
      "value": "America/New_York",
      "label": "Columbus - United States"
    },
    {
      "value": "America/Chicago",
      "label": "Columbus Mississippi - United States"
    },
    {
      "value": "America/Edmonton",
      "label": "Colville Lake - Canada"
    },
    {
      "value": "Europe/Rome",
      "label": "Comiso - Italy"
    },
    {
      "value": "America/Catamarca",
      "label": "Comodoro Rivadavia - Argentina"
    },
    {
      "value": "America/Vancouver",
      "label": "Comox - Canada"
    },
    {
      "value": "Africa/Conakry",
      "label": "Conakry - Guinea"
    },
    {
      "value": "America/Belem",
      "label": "Conceicao Do Araguaia - Brazil"
    },
    {
      "value": "America/Santiago",
      "label": "Concepcion - Chile"
    },
    {
      "value": "America/Asuncion",
      "label": "Conception - Paraguay"
    },
    {
      "value": "America/New_York",
      "label": "Concord - United States"
    },
    {
      "value": "America/New_York",
      "label": "Concord NH - United States"
    },
    {
      "value": "America/Cordoba",
      "label": "Concordia - Argentina"
    },
    {
      "value": "America/Sao_Paulo",
      "label": "Concordia - Brazil"
    },
    {
      "value": "America/Bogota",
      "label": "Condoto - Colombia"
    },
    {
      "value": "America/Campo_Grande",
      "label": "Confresa - Brazil"
    },
    {
      "value": "Europe/London",
      "label": "Coningsby - United Kingdom"
    },
    {
      "value": "Europe/Dublin",
      "label": "Connaught - Ireland"
    },
    {
      "value": "America/Chicago",
      "label": "Conroe - United States"
    },
    {
      "value": "Asia/Saigon",
      "label": "Conson - Vietnam"
    },
    {
      "value": "Europe/Bucharest",
      "label": "Constanta - Romania"
    },
    {
      "value": "Africa/Algiers",
      "label": "Constantine - Algeria"
    },
    {
      "value": "America/Santo_Domingo",
      "label": "Constanza - Dominican Republic"
    },
    {
      "value": "Australia/Adelaide",
      "label": "Coober Pedy - Australia"
    },
    {
      "value": "Asia/Calcutta",
      "label": "Cooch-behar - India"
    },
    {
      "value": "Australia/Darwin",
      "label": "Cooinda - Australia"
    },
    {
      "value": "Australia/Brisbane",
      "label": "Cooktown - Australia"
    },
    {
      "value": "Australia/Brisbane",
      "label": "Coolangatta - Australia"
    },
    {
      "value": "Australia/Sydney",
      "label": "Cooma - Australia"
    },
    {
      "value": "Australia/Sydney",
      "label": "Coonabarabran - Australia"
    },
    {
      "value": "Australia/Sydney",
      "label": "Coonamble - Australia"
    },
    {
      "value": "Europe/Copenhagen",
      "label": "Copenhagen - Denmark"
    },
    {
      "value": "America/Edmonton",
      "label": "Coppermine - Canada"
    },
    {
      "value": "America/Coral_Harbour",
      "label": "Coral Harbour - Canada"
    },
    {
      "value": "America/Cordoba",
      "label": "Cordoba - Argentina"
    },
    {
      "value": "Europe/Madrid",
      "label": "Cordoba - Spain"
    },
    {
      "value": "America/Anchorage",
      "label": "Cordova - United States"
    },
    {
      "value": "Europe/Dublin",
      "label": "Cork - Ireland"
    },
    {
      "value": "Europe/Istanbul",
      "label": "Corlu - Turkey"
    },
    {
      "value": "America/Managua",
      "label": "Corn Island - Nicaragua"
    },
    {
      "value": "America/Toronto",
      "label": "Cornwall - Canada"
    },
    {
      "value": "America/Caracas",
      "label": "Coro - Venezuela"
    },
    {
      "value": "America/Edmonton",
      "label": "Coronation - Canada"
    },
    {
      "value": "America/Bogota",
      "label": "Corozal - Colombia"
    },
    {
      "value": "America/Chicago",
      "label": "Corpus Christi - United States"
    },
    {
      "value": "America/Cordoba",
      "label": "Corrientes - Argentina"
    },
    {
      "value": "America/Denver",
      "label": "Cortez - United States"
    },
    {
      "value": "America/Campo_Grande",
      "label": "Corumba - Brazil"
    },
    {
      "value": "America/Los_Angeles",
      "label": "Corvallis - United States"
    },
    {
      "value": "Atlantic/Azores",
      "label": "Corvo - Portugal"
    },
    {
      "value": "Asia/Manila",
      "label": "Cotabato - Philippines"
    },
    {
      "value": "America/Costa_Rica",
      "label": "Coto 47 - Costa Rica"
    },
    {
      "value": "Africa/Porto-Novo",
      "label": "Cotonou - Benin"
    },
    {
      "value": "Europe/Berlin",
      "label": "Cottbus - Germany"
    },
    {
      "value": "America/Chicago",
      "label": "Cotulla - United States"
    },
    {
      "value": "Europe/Paris",
      "label": "Courcheval - France"
    },
    {
      "value": "Europe/London",
      "label": "Coventry - United Kingdom"
    },
    {
      "value": "Asia/Dhaka",
      "label": "Cox's Bazar - Bangladesh"
    },
    {
      "value": "America/Santiago",
      "label": "Coyhaique - Chile"
    },
    {
      "value": "America/Cancun",
      "label": "Cozumel - Mexico"
    },
    {
      "value": "Pacific/Efate",
      "label": "Craig Cove - Vanuatu"
    },
    {
      "value": "Europe/Bucharest",
      "label": "Craiova - Romania"
    },
    {
      "value": "America/Edmonton",
      "label": "Cranbrook - Canada"
    },
    {
      "value": "Europe/Paris",
      "label": "Creil - France"
    },
    {
      "value": "America/Los_Angeles",
      "label": "Crescent City - United States"
    },
    {
      "value": "America/Chicago",
      "label": "Crestview - United States"
    },
    {
      "value": "America/Sao_Paulo",
      "label": "Criciuma - Brazil"
    },
    {
      "value": "America/New_York",
      "label": "Cross City - United States"
    },
    {
      "value": "America/Winnipeg",
      "label": "Cross Lake - Canada"
    },
    {
      "value": "Europe/Rome",
      "label": "Crotone - Italy"
    },
    {
      "value": "America/Rio_Branco",
      "label": "Cruzeiro do Sul - Brazil"
    },
    {
      "value": "Africa/Maputo",
      "label": "Cuamba - Mozambique"
    },
    {
      "value": "America/Bogota",
      "label": "Cucuta - Colombia"
    },
    {
      "value": "Asia/Calcutta",
      "label": "Cuddapah - India"
    },
    {
      "value": "America/Guayaquil",
      "label": "Cuenca - Ecuador"
    },
    {
      "value": "America/Mexico_City",
      "label": "Cuernavaca - Mexico"
    },
    {
      "value": "America/Campo_Grande",
      "label": "Cuiaba - Brazil"
    },
    {
      "value": "America/Puerto_Rico",
      "label": "Culebra Island - Puerto Rico"
    },
    {
      "value": "America/Mazatlan",
      "label": "Culiacan - Mexico"
    },
    {
      "value": "America/Caracas",
      "label": "Cumana - Venezuela"
    },
    {
      "value": "America/New_York",
      "label": "Cumberland - United States"
    },
    {
      "value": "America/Havana",
      "label": "Cunagua - Cuba"
    },
    {
      "value": "Europe/Rome",
      "label": "Cuneo - Italy"
    },
    {
      "value": "Australia/Brisbane",
      "label": "Cunnamulla - Australia"
    },
    {
      "value": "America/Sao_Paulo",
      "label": "Curitiba - Brazil"
    },
    {
      "value": "America/Cordoba",
      "label": "Curuzu Cuatia - Argentina"
    },
    {
      "value": "America/Chicago",
      "label": "Cushing - United States"
    },
    {
      "value": "America/Denver",
      "label": "Cutbank - United States"
    },
    {
      "value": "America/Argentina/Salta",
      "label": "Cutralco - Argentina"
    },
    {
      "value": "Asia/Manila",
      "label": "Cuyo - Philippines"
    },
    {
      "value": "America/Lima",
      "label": "Cuzco - Peru"
    },
    {
      "value": "Europe/Athens",
      "label": "Cyclades Islands - Greece"
    },
    {
      "value": "Africa/Cairo",
      "label": "Dabaa City - Egypt"
    },
    {
      "value": "Asia/Manila",
      "label": "Daet - Philippines"
    },
    {
      "value": "Africa/Dakar",
      "label": "Dakar - Senegal"
    },
    {
      "value": "Africa/El_Aaiun",
      "label": "Dakhla - Western Sahara"
    },
    {
      "value": "Europe/Istanbul",
      "label": "Dalaman - Turkey"
    },
    {
      "value": "Asia/Ulaanbaatar",
      "label": "Dalanzadgad - Mongolia"
    },
    {
      "value": "Asia/Saigon",
      "label": "Dalat - Vietnam"
    },
    {
      "value": "Asia/Karachi",
      "label": "Dalbandin - Pakistan"
    },
    {
      "value": "America/Chicago",
      "label": "Dalhart - United States"
    },
    {
      "value": "Asia/Shanghai",
      "label": "Dali - China"
    },
    {
      "value": "Asia/Shanghai",
      "label": "Dalian - China"
    },
    {
      "value": "America/Chicago",
      "label": "Dallas - United States"
    },
    {
      "value": "America/Chicago",
      "label": "Dallas-Fort Worth - United States"
    },
    {
      "value": "Africa/Abidjan",
      "label": "Daloa - Cote d'Ivoire"
    },
    {
      "value": "America/New_York",
      "label": "Dalton - United States"
    },
    {
      "value": "Asia/Calcutta",
      "label": "Daman - India"
    },
    {
      "value": "Asia/Damascus",
      "label": "Damascus - Syria"
    },
    {
      "value": "Africa/Khartoum",
      "label": "Damazin - Sudan"
    },
    {
      "value": "Asia/Riyadh",
      "label": "Dammam - Saudi Arabia"
    },
    {
      "value": "Asia/Saigon",
      "label": "Danang - Vietnam"
    },
    {
      "value": "America/New_York",
      "label": "Danbury - United States"
    },
    {
      "value": "Asia/Katmandu",
      "label": "Dang - Nepal"
    },
    {
      "value": "America/Chicago",
      "label": "Danville - United States"
    },
    {
      "value": "Asia/Shanghai",
      "label": "Daocheng - China"
    },
    {
      "value": "Africa/Dar_es_Salaam",
      "label": "Dar Es Salaam - Tanzania"
    },
    {
      "value": "Asia/Tehran",
      "label": "Daran - Iran"
    },
    {
      "value": "Australia/Brisbane",
      "label": "Darnley Island - Australia"
    },
    {
      "value": "Pacific/Port_Moresby",
      "label": "Daru - Papua New Guinea"
    },
    {
      "value": "Australia/Darwin",
      "label": "Darwin - Australia"
    },
    {
      "value": "Asia/Tehran",
      "label": "Dasht-e-naz - Iran"
    },
    {
      "value": "Asia/Ashgabat",
      "label": "Dasoguz - Turkmenistan"
    },
    {
      "value": "Asia/Makassar",
      "label": "Datadawai-Borneo Island - Indonesia"
    },
    {
      "value": "Asia/Shanghai",
      "label": "Datong - China"
    },
    {
      "value": "America/Winnipeg",
      "label": "Dauphin - Canada"
    },
    {
      "value": "Asia/Manila",
      "label": "Davao - Philippines"
    },
    {
      "value": "America/Panama",
      "label": "David - Panama"
    },
    {
      "value": "Asia/Rangoon",
      "label": "Dawei - Burma"
    },
    {
      "value": "America/Vancouver",
      "label": "Dawson - Canada"
    },
    {
      "value": "America/Dawson_Creek",
      "label": "Dawson Creek - Canada"
    },
    {
      "value": "Asia/Shanghai",
      "label": "Dayong - China"
    },
    {
      "value": "America/New_York",
      "label": "Dayton - United States"
    },
    {
      "value": "America/New_York",
      "label": "Daytona Beach - United States"
    },
    {
      "value": "Asia/Shanghai",
      "label": "Dazhou - China"
    },
    {
      "value": "Europe/Amsterdam",
      "label": "De Kooy - Netherlands"
    },
    {
      "value": "America/Nassau",
      "label": "Dead Man's Cay - Bahamas"
    },
    {
      "value": "America/Anchorage",
      "label": "Deadhorse - United States"
    },
    {
      "value": "America/Vancouver",
      "label": "Dease Lake - Canada"
    },
    {
      "value": "Europe/Paris",
      "label": "Deauville - France"
    },
    {
      "value": "Africa/Addis_Ababa",
      "label": "Debre Marqos - Ethiopia"
    },
    {
      "value": "Africa/Addis_Ababa",
      "label": "Debre Tabor - Ethiopia"
    },
    {
      "value": "Africa/Addis_Ababa",
      "label": "Debre Zeyit - Ethiopia"
    },
    {
      "value": "Europe/Budapest",
      "label": "Debrecen - Hungary"
    },
    {
      "value": "America/Chicago",
      "label": "Decatur - United States"
    },
    {
      "value": "Europe/Rome",
      "label": "Decimomannu - Italy"
    },
    {
      "value": "America/St_Johns",
      "label": "Deer Lake - Canada"
    },
    {
      "value": "America/Anchorage",
      "label": "Deering - United States"
    },
    {
      "value": "Asia/Calcutta",
      "label": "Dehra Dun - India"
    },
    {
      "value": "Asia/Damascus",
      "label": "Deire Zor - Syria"
    },
    {
      "value": "America/Mexico_City",
      "label": "Del Bajio - Mexico"
    },
    {
      "value": "America/Chicago",
      "label": "Del Rio - United States"
    },
    {
      "value": "America/Edmonton",
      "label": "Deline - Canada"
    },
    {
      "value": "America/Denver",
      "label": "Delta - United States"
    },
    {
      "value": "Africa/Addis_Ababa",
      "label": "Dembidollo - Ethiopia"
    },
    {
      "value": "Europe/Istanbul",
      "label": "Denizli - Turkey"
    },
    {
      "value": "Asia/Makassar",
      "label": "Denpasar - Indonesia"
    },
    {
      "value": "America/Denver",
      "label": "Denver - United States"
    },
    {
      "value": "Asia/Karachi",
      "label": "Dera Ghazi Khan - Pakistan"
    },
    {
      "value": "Asia/Karachi",
      "label": "Dera Ismael Khan - Pakistan"
    },
    {
      "value": "Australia/Perth",
      "label": "Derby - Australia"
    },
    {
      "value": "America/Chicago",
      "label": "Deridder - United States"
    },
    {
      "value": "America/Chicago",
      "label": "Des Moines - United States"
    },
    {
      "value": "Indian/Mahe",
      "label": "Desroches - Seychelles"
    },
    {
      "value": "Africa/Addis_Ababa",
      "label": "Dessie - Ethiopia"
    },
    {
      "value": "America/Chicago",
      "label": "Destin - United States"
    },
    {
      "value": "America/New_York",
      "label": "Detroit - United States"
    },
    {
      "value": "America/Chicago",
      "label": "Devils Lake - United States"
    },
    {
      "value": "Australia/Melbourne",
      "label": "Devonport - Australia"
    },
    {
      "value": "Asia/Tehran",
      "label": "Dezful - Iran"
    },
    {
      "value": "Asia/Riyadh",
      "label": "Dhahran - Saudi Arabia"
    },
    {
      "value": "Asia/Calcutta",
      "label": "Dhanbad - India"
    },
    {
      "value": "Asia/Katmandu",
      "label": "Dhangarhi - Nepal"
    },
    {
      "value": "America/Sao_Paulo",
      "label": "Diamantina - Brazil"
    },
    {
      "value": "America/Campo_Grande",
      "label": "Diamantino - Brazil"
    },
    {
      "value": "America/Denver",
      "label": "Dickinson - United States"
    },
    {
      "value": "Indian/Chagos",
      "label": "Diego Garcia Island - British Indian Ocean Territory"
    },
    {
      "value": "Asia/Saigon",
      "label": "Dienbienphu - Vietnam"
    },
    {
      "value": "Europe/Paris",
      "label": "Dijon - France"
    },
    {
      "value": "Asia/Krasnoyarsk",
      "label": "Dikson - Russia"
    },
    {
      "value": "Asia/Dili",
      "label": "Dili - East Timor"
    },
    {
      "value": "America/Anchorage",
      "label": "Dillingham - United States"
    },
    {
      "value": "Pacific/Efate",
      "label": "Dillon's Bay - Vanuatu"
    },
    {
      "value": "Asia/Calcutta",
      "label": "Dimapur - India"
    },
    {
      "value": "Europe/Paris",
      "label": "Dinard - France"
    },
    {
      "value": "Asia/Manila",
      "label": "Dipolog - Philippines"
    },
    {
      "value": "Africa/Addis_Ababa",
      "label": "Dire Dawa - Ethiopia"
    },
    {
      "value": "Asia/Calcutta",
      "label": "Diu - India"
    },
    {
      "value": "Europe/Istanbul",
      "label": "Diyabakir - Turkey"
    },
    {
      "value": "Africa/Algiers",
      "label": "Djanet - Algeria"
    },
    {
      "value": "Africa/Algiers",
      "label": "Djelfa - Algeria"
    },
    {
      "value": "Africa/Tunis",
      "label": "Djerba - Tunisia"
    },
    {
      "value": "Africa/Djibouti",
      "label": "Djibouti - Djibouti"
    },
    {
      "value": "Europe/Kiev",
      "label": "Dnepropetrovsk - Ukraine"
    },
    {
      "value": "America/Chicago",
      "label": "Dodge City - United States"
    },
    {
      "value": "Africa/Dar_es_Salaam",
      "label": "Dodoma - Tanzania"
    },
    {
      "value": "Europe/Paris",
      "label": "Dole - France"
    },
    {
      "value": "Asia/Katmandu",
      "label": "Dolpa - Nepal"
    },
    {
      "value": "America/Dominica",
      "label": "Dominica - Dominica"
    },
    {
      "value": "Europe/Berlin",
      "label": "Donaueschingen - Germany"
    },
    {
      "value": "Europe/London",
      "label": "Doncaster, Sheffield - United Kingdom"
    },
    {
      "value": "Europe/Kiev",
      "label": "Donetsk - Ukraine"
    },
    {
      "value": "Europe/Dublin",
      "label": "Dongloe - Ireland"
    },
    {
      "value": "Africa/Khartoum",
      "label": "Dongola - Sudan"
    },
    {
      "value": "Asia/Shanghai",
      "label": "Dongsheng - China"
    },
    {
      "value": "Australia/Brisbane",
      "label": "Doomadgee - Australia"
    },
    {
      "value": "Europe/Berlin",
      "label": "Dortmund - Germany"
    },
    {
      "value": "America/Chicago",
      "label": "Dothan - United States"
    },
    {
      "value": "Africa/Douala",
      "label": "Douala - Cameroon"
    },
    {
      "value": "America/Phoenix",
      "label": "Douglas - United States"
    },
    {
      "value": "America/Campo_Grande",
      "label": "Dourados - Brazil"
    },
    {
      "value": "America/New_York",
      "label": "Dover - United States"
    },
    {
      "value": "America/New_York",
      "label": "Doylestown - United States"
    },
    {
      "value": "Europe/Berlin",
      "label": "Dresden - Germany"
    },
    {
      "value": "America/Paramaribo",
      "label": "Drietabbetje - Suriname"
    },
    {
      "value": "America/New_York",
      "label": "Drummond Island - United States"
    },
    {
      "value": "America/Winnipeg",
      "label": "Dryden - Canada"
    },
    {
      "value": "America/New_York",
      "label": "Du Bois - United States"
    },
    {
      "value": "Asia/Dubai",
      "label": "Dubai - United Arab Emirates"
    },
    {
      "value": "Australia/Sydney",
      "label": "Dubbo - Australia"
    },
    {
      "value": "Europe/Dublin",
      "label": "Dublin - Ireland"
    },
    {
      "value": "America/New_York",
      "label": "Dublin - United States"
    },
    {
      "value": "Europe/Zagreb",
      "label": "Dubrovnik - Croatia"
    },
    {
      "value": "America/Chicago",
      "label": "Dubuque IA - United States"
    },
    {
      "value": "Europe/Berlin",
      "label": "Duesseldorf - Germany"
    },
    {
      "value": "America/Chicago",
      "label": "Duluth - United States"
    },
    {
      "value": "Asia/Manila",
      "label": "Dumaguete - Philippines"
    },
    {
      "value": "Asia/Jakarta",
      "label": "Dumai - Indonesia"
    },
    {
      "value": "America/Chicago",
      "label": "Duncan - United States"
    },
    {
      "value": "America/Nassau",
      "label": "Duncan Town - Bahamas"
    },
    {
      "value": "Europe/London",
      "label": "Dundee - United Kingdom"
    },
    {
      "value": "Africa/Luanda",
      "label": "Dundo - Angola"
    },
    {
      "value": "Pacific/Auckland",
      "label": "Dunedin - New Zealand"
    },
    {
      "value": "Asia/Shanghai",
      "label": "Dunhuang - China"
    },
    {
      "value": "Australia/Brisbane",
      "label": "Dunk Island - Australia"
    },
    {
      "value": "America/New_York",
      "label": "Dunkirk - United States"
    },
    {
      "value": "America/Mexico_City",
      "label": "Durango - Mexico"
    },
    {
      "value": "America/Denver",
      "label": "Durango - United States"
    },
    {
      "value": "America/Montevideo",
      "label": "Durazno - Uruguay"
    },
    {
      "value": "Africa/Johannesburg",
      "label": "Durban - South Africa"
    },
    {
      "value": "Asia/Dushanbe",
      "label": "Dushanbe - Tajikistan"
    },
    {
      "value": "Europe/London",
      "label": "Duxford - United Kingdom"
    },
    {
      "value": "Indian/Mayotte",
      "label": "Dzaoudzi - Mayotte"
    },
    {
      "value": "Asia/Qyzylorda",
      "label": "Dzhambul - Kazakhstan"
    },
    {
      "value": "America/Anchorage",
      "label": "Eagle - United States"
    },
    {
      "value": "America/Chicago",
      "label": "Eagle River - United States"
    },
    {
      "value": "America/Toronto",
      "label": "Earlton - Canada"
    },
    {
      "value": "Africa/Johannesburg",
      "label": "East London - South Africa"
    },
    {
      "value": "Europe/London",
      "label": "East Midlands - United Kingdom"
    },
    {
      "value": "America/New_York",
      "label": "East Tawas - United States"
    },
    {
      "value": "Pacific/Easter",
      "label": "Easter Island - Chile"
    },
    {
      "value": "America/Toronto",
      "label": "Eastmain River - Canada"
    },
    {
      "value": "America/New_York",
      "label": "Easton - United States"
    },
    {
      "value": "America/Los_Angeles",
      "label": "Eastsound - United States"
    },
    {
      "value": "America/Chicago",
      "label": "Eau Claire - United States"
    },
    {
      "value": "Africa/Algiers",
      "label": "Ech-cheliff - Algeria"
    },
    {
      "value": "Europe/London",
      "label": "Eday - United Kingdom"
    },
    {
      "value": "America/Chicago",
      "label": "Eden Prairie - United States"
    },
    {
      "value": "Europe/London",
      "label": "Edinburgh - United Kingdom"
    },
    {
      "value": "America/Edmonton",
      "label": "Edmonton - Canada"
    },
    {
      "value": "America/Edmonton",
      "label": "Edson - Canada"
    },
    {
      "value": "America/Los_Angeles",
      "label": "Edwards Afb - United States"
    },
    {
      "value": "America/Anchorage",
      "label": "Eek - United States"
    },
    {
      "value": "America/Anchorage",
      "label": "Egegik - United States"
    },
    {
      "value": "Europe/Berlin",
      "label": "Egelsbach - Germany"
    },
    {
      "value": "Atlantic/Reykjavik",
      "label": "Egilsstadir - Iceland"
    },
    {
      "value": "Europe/Amsterdam",
      "label": "Eindhoven - Netherlands"
    },
    {
      "value": "America/Boa_Vista",
      "label": "Eirunepe - Brazil"
    },
    {
      "value": "Europe/Berlin",
      "label": "Eisenach - Germany"
    },
    {
      "value": "America/Edmonton",
      "label": "Ekati - Canada"
    },
    {
      "value": "Asia/Qyzylorda",
      "label": "Ekibastuz - Kazakhstan"
    },
    {
      "value": "Africa/El_Aaiun",
      "label": "El Aaiún - Western Sahara"
    },
    {
      "value": "Africa/Cairo",
      "label": "El Arish - Egypt"
    },
    {
      "value": "Africa/Casablanca",
      "label": "El Aroui - Morocco"
    },
    {
      "value": "America/Bogota",
      "label": "El Bagre - Colombia"
    },
    {
      "value": "America/Bogota",
      "label": "El Banco - Colombia"
    },
    {
      "value": "America/Argentina/Salta",
      "label": "El Bolson - Argentina"
    },
    {
      "value": "Africa/Tunis",
      "label": "El Borma - Tunisia"
    },
    {
      "value": "America/Los_Angeles",
      "label": "El Cajon - United States"
    },
    {
      "value": "America/Argentina/Rio_Gallegos",
      "label": "El Calafate - Argentina"
    },
    {
      "value": "America/Los_Angeles",
      "label": "El Centro - United States"
    },
    {
      "value": "America/Cordoba",
      "label": "El Dorado - Argentina"
    },
    {
      "value": "America/Chicago",
      "label": "El Dorado - United States"
    },
    {
      "value": "America/Caracas",
      "label": "El Dorado - Venezuela"
    },
    {
      "value": "Africa/Khartoum",
      "label": "El Fasher - Sudan"
    },
    {
      "value": "Africa/Algiers",
      "label": "El Golea - Algeria"
    },
    {
      "value": "Africa/Cairo",
      "label": "El Gorah - Egypt"
    },
    {
      "value": "Africa/Khartoum",
      "label": "El Obeid - Sudan"
    },
    {
      "value": "America/Buenos_Aires",
      "label": "El Palomar - Argentina"
    },
    {
      "value": "America/Denver",
      "label": "El Paso - United States"
    },
    {
      "value": "America/Santiago",
      "label": "El Salvador - Chile"
    },
    {
      "value": "America/Caracas",
      "label": "El Vigía - Venezuela"
    },
    {
      "value": "Asia/Jerusalem",
      "label": "Elat - Israel"
    },
    {
      "value": "Europe/Istanbul",
      "label": "Elazig - Turkey"
    },
    {
      "value": "Asia/Riyadh",
      "label": "El-baha - Saudi Arabia"
    },
    {
      "value": "Australia/Darwin",
      "label": "Elcho Island - Australia"
    },
    {
      "value": "Africa/Nairobi",
      "label": "Eldoret - Kenya"
    },
    {
      "value": "America/Anchorage",
      "label": "Elfin Cove - United States"
    },
    {
      "value": "America/Anchorage",
      "label": "Elim - United States"
    },
    {
      "value": "Europe/Moscow",
      "label": "Elista - Russia"
    },
    {
      "value": "America/New_York",
      "label": "Elizabeth City - United States"
    },
    {
      "value": "America/New_York",
      "label": "Elkins - United States"
    },
    {
      "value": "America/Los_Angeles",
      "label": "Elko - United States"
    },
    {
      "value": "America/Toronto",
      "label": "ELLIOT LAKE - Canada"
    },
    {
      "value": "America/New_York",
      "label": "Elmira - United States"
    },
    {
      "value": "America/Caracas",
      "label": "Elorza - Venezuela"
    },
    {
      "value": "Africa/Cairo",
      "label": "El-tor - Egypt"
    },
    {
      "value": "America/Chicago",
      "label": "Ely - United States"
    },
    {
      "value": "Asia/Tehran",
      "label": "Emam Shahr - Iran"
    },
    {
      "value": "Europe/Berlin",
      "label": "Emden - Germany"
    },
    {
      "value": "Australia/Brisbane",
      "label": "Emerald - Australia"
    },
    {
      "value": "Europe/Zurich",
      "label": "Emmen - Switzerland"
    },
    {
      "value": "America/Anchorage",
      "label": "Emmonak - United States"
    },
    {
      "value": "Asia/Makassar",
      "label": "Ende - Indonesia"
    },
    {
      "value": "America/Chicago",
      "label": "Enid - United States"
    },
    {
      "value": "Pacific/Majuro",
      "label": "Eniwetok Atoll - Marshall Islands"
    },
    {
      "value": "Europe/London",
      "label": "Enniskillen - United Kingdom"
    },
    {
      "value": "Europe/Helsinki",
      "label": "Enontekio - Finland"
    },
    {
      "value": "Europe/Amsterdam",
      "label": "Enschede - Netherlands"
    },
    {
      "value": "America/Tijuana",
      "label": "Ensenada - Mexico"
    },
    {
      "value": "Asia/Shanghai",
      "label": "Enshi - China"
    },
    {
      "value": "Africa/Kampala",
      "label": "Entebbe - Uganda"
    },
    {
      "value": "Africa/Lagos",
      "label": "Enugu - Nigeria"
    },
    {
      "value": "Europe/Paris",
      "label": "Epinal - France"
    },
    {
      "value": "Asia/Baghdad",
      "label": "Erbil - Iraq"
    },
    {
      "value": "America/Sao_Paulo",
      "label": "Erechim - Brazil"
    },
    {
      "value": "Europe/Berlin",
      "label": "Erfurt - Germany"
    },
    {
      "value": "America/New_York",
      "label": "Erie - United States"
    },
    {
      "value": "Africa/Casablanca",
      "label": "Er-rachidia - Morocco"
    },
    {
      "value": "Europe/Istanbul",
      "label": "Erzincan - Turkey"
    },
    {
      "value": "Europe/Istanbul",
      "label": "Erzurum - Turkey"
    },
    {
      "value": "Europe/Copenhagen",
      "label": "Esbjerg - Denmark"
    },
    {
      "value": "America/New_York",
      "label": "Escanaba - United States"
    },
    {
      "value": "Europe/Stockholm",
      "label": "Eskilstuna - Sweden"
    },
    {
      "value": "America/Winnipeg",
      "label": "Eskimo Point - Canada"
    },
    {
      "value": "Europe/Istanbul",
      "label": "Eskisehir - Turkey"
    },
    {
      "value": "Europe/Istanbul",
      "label": "Eskissehir - Turkey"
    },
    {
      "value": "America/Guayaquil",
      "label": "Esmeraldas - Ecuador"
    },
    {
      "value": "Australia/Perth",
      "label": "Esperance - Australia"
    },
    {
      "value": "America/Catamarca",
      "label": "Esquel - Argentina"
    },
    {
      "value": "Africa/Casablanca",
      "label": "Essadouira - Morocco"
    },
    {
      "value": "Europe/Berlin",
      "label": "Essen - Germany"
    },
    {
      "value": "America/Regina",
      "label": "Estevan - Canada"
    },
    {
      "value": "Pacific/Tongatapu",
      "label": "Eua Island - Tonga"
    },
    {
      "value": "America/Chicago",
      "label": "Eufala - United States"
    },
    {
      "value": "America/Los_Angeles",
      "label": "Eugene - United States"
    },
    {
      "value": "America/Winnipeg",
      "label": "Eureka - Canada"
    },
    {
      "value": "America/Denver",
      "label": "Evanston - United States"
    },
    {
      "value": "America/Chicago",
      "label": "Evansville - United States"
    },
    {
      "value": "America/Los_Angeles",
      "label": "Everett - United States"
    },
    {
      "value": "Europe/Paris",
      "label": "Evreux - France"
    },
    {
      "value": "Europe/London",
      "label": "Exeter - United Kingdom"
    },
    {
      "value": "Asia/Jerusalem",
      "label": "Eyn-yahav - Israel"
    },
    {
      "value": "Europe/Moscow",
      "label": "Eysk - Russia"
    },
    {
      "value": "Europe/Oslo",
      "label": "Fagernes - Norway"
    },
    {
      "value": "Europe/London",
      "label": "Fair Isle - United Kingdom"
    },
    {
      "value": "America/Anchorage",
      "label": "Fairbanks - United States"
    },
    {
      "value": "America/Los_Angeles",
      "label": "Fairfield - United States"
    },
    {
      "value": "Europe/London",
      "label": "Fairford - United Kingdom"
    },
    {
      "value": "Asia/Karachi",
      "label": "Faisalabad - Pakistan"
    },
    {
      "value": "Asia/Kabul",
      "label": "Faizabad - Afghanistan"
    },
    {
      "value": "America/Puerto_Rico",
      "label": "Fajardo - Puerto Rico"
    },
    {
      "value": "Asia/Jayapura",
      "label": "Fak Fak - Indonesia"
    },
    {
      "value": "Pacific/Tahiti",
      "label": "Fakarava - French Polynesia"
    },
    {
      "value": "Pacific/Apia",
      "label": "Faleolo - Samoa"
    },
    {
      "value": "America/Los_Angeles",
      "label": "Fallon - United States"
    },
    {
      "value": "America/New_York",
      "label": "Falmouth - United States"
    },
    {
      "value": "America/Anchorage",
      "label": "False Pass - United States"
    },
    {
      "value": "Pacific/Tahiti",
      "label": "Fangatau - French Polynesia"
    },
    {
      "value": "Indian/Antananarivo",
      "label": "Farafangana - Madagascar"
    },
    {
      "value": "Africa/Conakry",
      "label": "Faranah - Guinea"
    },
    {
      "value": "America/Chicago",
      "label": "Fargo - United States"
    },
    {
      "value": "America/New_York",
      "label": "Farmingdale - United States"
    },
    {
      "value": "America/Denver",
      "label": "Farmington - United States"
    },
    {
      "value": "Europe/London",
      "label": "Farnborough - United Kingdom"
    },
    {
      "value": "America/Vancouver",
      "label": "Faro - Canada"
    },
    {
      "value": "Europe/Lisbon",
      "label": "Faro - Portugal"
    },
    {
      "value": "Europe/Oslo",
      "label": "Farsund - Norway"
    },
    {
      "value": "Asia/Tehran",
      "label": "Fasa - Iran"
    },
    {
      "value": "Africa/Ndjamena",
      "label": "Faya-largeau - Chad"
    },
    {
      "value": "America/Chicago",
      "label": "Fayetteville - United States"
    },
    {
      "value": "Asia/Taipei",
      "label": "Fengnin - Taiwan"
    },
    {
      "value": "Pacific/Guadalcanal",
      "label": "Fera Island - Solomon Islands"
    },
    {
      "value": "Asia/Samarkand",
      "label": "Fergana - Uzbekistan"
    },
    {
      "value": "America/Fortaleza",
      "label": "Fernando Do Noronha - Brazil"
    },
    {
      "value": "Africa/Casablanca",
      "label": "Fes - Morocco"
    },
    {
      "value": "Indian/Antananarivo",
      "label": "Fianarantsoa - Madagascar"
    },
    {
      "value": "Africa/Johannesburg",
      "label": "Ficksburg - South Africa"
    },
    {
      "value": "Europe/Paris",
      "label": "Figari - France"
    },
    {
      "value": "America/New_York",
      "label": "Findley - United States"
    },
    {
      "value": "Africa/Conakry",
      "label": "Fira - Guinea"
    },
    {
      "value": "Pacific/Pago_Pago",
      "label": "Fiti\\'uta - American Samoa"
    },
    {
      "value": "Australia/Perth",
      "label": "Fitzroy Crossing - Australia"
    },
    {
      "value": "America/Phoenix",
      "label": "Flagstaff - United States"
    },
    {
      "value": "Europe/Berlin",
      "label": "Flensburg - Germany"
    },
    {
      "value": "America/Winnipeg",
      "label": "Flin Flon - Canada"
    },
    {
      "value": "Australia/Melbourne",
      "label": "Flinders Island - Australia"
    },
    {
      "value": "America/New_York",
      "label": "Flint - United States"
    },
    {
      "value": "Europe/Rome",
      "label": "Florence - Italy"
    },
    {
      "value": "America/New_York",
      "label": "Florence - United States"
    },
    {
      "value": "America/Bogota",
      "label": "Florencia - Colombia"
    },
    {
      "value": "America/Guatemala",
      "label": "Flores - Guatemala"
    },
    {
      "value": "Atlantic/Azores",
      "label": "Flores - Portugal"
    },
    {
      "value": "America/Sao_Paulo",
      "label": "Florianopolis - Brazil"
    },
    {
      "value": "Europe/Oslo",
      "label": "Floro - Norway"
    },
    {
      "value": "Europe/Rome",
      "label": "Foggia - Italy"
    },
    {
      "value": "America/Chicago",
      "label": "Fond du Lac - United States"
    },
    {
      "value": "America/Regina",
      "label": "Fond-Du-Lac - Canada"
    },
    {
      "value": "America/Boa_Vista",
      "label": "Fonte Boa - Brazil"
    },
    {
      "value": "America/Toronto",
      "label": "Forestville - Canada"
    },
    {
      "value": "Europe/Rome",
      "label": "Forli - Italy"
    },
    {
      "value": "America/Cordoba",
      "label": "Formosa - Argentina"
    },
    {
      "value": "Australia/Perth",
      "label": "Forrest - Australia"
    },
    {
      "value": "America/Toronto",
      "label": "Fort Albany - Canada"
    },
    {
      "value": "America/New_York",
      "label": "Fort Bragg - United States"
    },
    {
      "value": "America/Denver",
      "label": "Fort Bridger - United States"
    },
    {
      "value": "America/Denver",
      "label": "Fort Carson - United States"
    },
    {
      "value": "America/Edmonton",
      "label": "Fort Chipewyan - Canada"
    },
    {
      "value": "America/Denver",
      "label": "Fort Collins - United States"
    },
    {
      "value": "America/Chicago",
      "label": "Fort Dodge - United States"
    },
    {
      "value": "America/Winnipeg",
      "label": "Fort Frances - Canada"
    },
    {
      "value": "America/Edmonton",
      "label": "Fort Good Hope - Canada"
    },
    {
      "value": "America/Toronto",
      "label": "Fort Hope - Canada"
    },
    {
      "value": "America/New_York",
      "label": "Fort Lauderdale - United States"
    },
    {
      "value": "America/Chicago",
      "label": "Fort Leonardwood - United States"
    },
    {
      "value": "America/Edmonton",
      "label": "Fort Mcmurray - Canada"
    },
    {
      "value": "America/Edmonton",
      "label": "Fort Mcpherson - Canada"
    },
    {
      "value": "America/New_York",
      "label": "Fort Meade - United States"
    },
    {
      "value": "America/New_York",
      "label": "Fort Myers - United States"
    },
    {
      "value": "America/Vancouver",
      "label": "Fort Nelson - Canada"
    },
    {
      "value": "America/Los_Angeles",
      "label": "Fort Ord - United States"
    },
    {
      "value": "America/New_York",
      "label": "Fort Pierce - United States"
    },
    {
      "value": "America/Edmonton",
      "label": "Fort Resolution - Canada"
    },
    {
      "value": "America/Dawson_Creek",
      "label": "Fort Saint John - Canada"
    },
    {
      "value": "America/Toronto",
      "label": "Fort Severn - Canada"
    },
    {
      "value": "America/Edmonton",
      "label": "Fort Simpson - Canada"
    },
    {
      "value": "America/Edmonton",
      "label": "Fort Smith - Canada"
    },
    {
      "value": "America/Chicago",
      "label": "Fort Smith - United States"
    },
    {
      "value": "America/Chicago",
      "label": "Fort Stockton - United States"
    },
    {
      "value": "America/New_York",
      "label": "Fort Wayne - United States"
    },
    {
      "value": "America/New_York",
      "label": "Fort Wayne IN - United States"
    },
    {
      "value": "America/Chicago",
      "label": "Fort Worth - United States"
    },
    {
      "value": "America/Anchorage",
      "label": "Fort Yukon - United States"
    },
    {
      "value": "America/Fortaleza",
      "label": "Fortaleza - Brazil"
    },
    {
      "value": "America/Martinique",
      "label": "Fort-de-france - Martinique"
    },
    {
      "value": "Africa/Douala",
      "label": "Foumban - Cameroon"
    },
    {
      "value": "America/Sao_Paulo",
      "label": "Foz Do Iguacu - Brazil"
    },
    {
      "value": "America/Sao_Paulo",
      "label": "Franca - Brazil"
    },
    {
      "value": "Africa/Libreville",
      "label": "Franceville - Gabon"
    },
    {
      "value": "America/Sao_Paulo",
      "label": "Francisco Beltrao - Brazil"
    },
    {
      "value": "Africa/Gaborone",
      "label": "Francistown - Botswana"
    },
    {
      "value": "America/New_York",
      "label": "Frankfort - United States"
    },
    {
      "value": "Europe/Berlin",
      "label": "Frankfurt - Germany"
    },
    {
      "value": "America/New_York",
      "label": "Franklin - United States"
    },
    {
      "value": "America/Los_Angeles",
      "label": "Fraser Island - Australia"
    },
    {
      "value": "America/Halifax",
      "label": "Fredericton - Canada"
    },
    {
      "value": "America/Nassau",
      "label": "Freeport - Bahamas"
    },
    {
      "value": "Africa/Freetown",
      "label": "Freetown - Sierra Leone"
    },
    {
      "value": "Europe/Paris",
      "label": "Frejus - France"
    },
    {
      "value": "America/New_York",
      "label": "Frenchville - United States"
    },
    {
      "value": "America/Los_Angeles",
      "label": "Fresno - United States"
    },
    {
      "value": "America/Los_Angeles",
      "label": "Friday Harbor - United States"
    },
    {
      "value": "Europe/Berlin",
      "label": "Friedrichshafen - Germany"
    },
    {
      "value": "Europe/Berlin",
      "label": "Fritzlar - Germany"
    },
    {
      "value": "America/New_York",
      "label": "Fryeburg - United States"
    },
    {
      "value": "Europe/Berlin",
      "label": "Fuerstenfeldbruck - Germany"
    },
    {
      "value": "America/Argentina/Salta",
      "label": "Fuerte Gral Roca - Argentina"
    },
    {
      "value": "Atlantic/Canary",
      "label": "Fuerteventura - Spain"
    },
    {
      "value": "Asia/Dubai",
      "label": "Fujeirah - United Arab Emirates"
    },
    {
      "value": "Asia/Tokyo",
      "label": "Fukue - Japan"
    },
    {
      "value": "Asia/Tokyo",
      "label": "Fukui - Japan"
    },
    {
      "value": "Asia/Tokyo",
      "label": "Fukuoka - Japan"
    },
    {
      "value": "Asia/Tokyo",
      "label": "Fukushima - Japan"
    },
    {
      "value": "America/Los_Angeles",
      "label": "Fullerton - United States"
    },
    {
      "value": "Pacific/Funafuti",
      "label": "Funafuti - Tuvalu"
    },
    {
      "value": "Europe/Lisbon",
      "label": "Funchal - Portugal"
    },
    {
      "value": "America/Anchorage",
      "label": "Funter Bay - United States"
    },
    {
      "value": "America/Santiago",
      "label": "Futaleufu - Chile"
    },
    {
      "value": "Pacific/Efate",
      "label": "Futuna Island - Vanuatu"
    },
    {
      "value": "Pacific/Wallis",
      "label": "Futuna Island - Wallis and Futuna"
    },
    {
      "value": "Asia/Shanghai",
      "label": "Fuyang - China"
    },
    {
      "value": "Asia/Shanghai",
      "label": "Fuzhou - China"
    },
    {
      "value": "Africa/Gaborone",
      "label": "Gaberone - Botswana"
    },
    {
      "value": "Africa/Tunis",
      "label": "Gabes - Tunisia"
    },
    {
      "value": "Asia/Tehran",
      "label": "Gachsaran - Iran"
    },
    {
      "value": "America/Chicago",
      "label": "Gadsden - United States"
    },
    {
      "value": "Africa/Tunis",
      "label": "Gafsa - Tunisia"
    },
    {
      "value": "America/New_York",
      "label": "Gainesville - United States"
    },
    {
      "value": "America/New_York",
      "label": "Gaithersburg - United States"
    },
    {
      "value": "Pacific/Galapagos",
      "label": "Galapagos - Ecuador"
    },
    {
      "value": "Africa/Mogadishu",
      "label": "Galcaio - Somalia"
    },
    {
      "value": "America/Anchorage",
      "label": "Galena - United States"
    },
    {
      "value": "America/New_York",
      "label": "Galion - United States"
    },
    {
      "value": "Europe/Stockholm",
      "label": "Gallivare - Sweden"
    },
    {
      "value": "America/Denver",
      "label": "Gallup - United States"
    },
    {
      "value": "Asia/Colombo",
      "label": "Galoya - Sri Lanka"
    },
    {
      "value": "America/Chicago",
      "label": "Galveston - United States"
    },
    {
      "value": "Europe/Dublin",
      "label": "Galway - Ireland"
    },
    {
      "value": "America/Edmonton",
      "label": "Gamètì - Canada"
    },
    {
      "value": "America/Anchorage",
      "label": "Gambell - United States"
    },
    {
      "value": "Africa/Addis_Ababa",
      "label": "Gambella - Ethiopia"
    },
    {
      "value": "Indian/Maldives",
      "label": "Gan Island - Maldives"
    },
    {
      "value": "America/St_Johns",
      "label": "Gander - Canada"
    },
    {
      "value": "America/Vancouver",
      "label": "Ganges - Canada"
    },
    {
      "value": "Asia/Baku",
      "label": "Ganja - Azerbaijan"
    },
    {
      "value": "Asia/Shanghai",
      "label": "Ganzhou - China"
    },
    {
      "value": "Africa/Bamako",
      "label": "Gao - Mali"
    },
    {
      "value": "America/Chicago",
      "label": "Garden City - United States"
    },
    {
      "value": "Africa/Nairobi",
      "label": "Garissa - Kenya"
    },
    {
      "value": "Africa/Douala",
      "label": "Garoua - Cameroon"
    },
    {
      "value": "America/Chicago",
      "label": "Gary - United States"
    },
    {
      "value": "Asia/Manila",
      "label": "Gasan - Philippines"
    },
    {
      "value": "America/Toronto",
      "label": "Gaspe - Canada"
    },
    {
      "value": "Asia/Riyadh",
      "label": "Gassim - Saudi Arabia"
    },
    {
      "value": "America/Toronto",
      "label": "Gatineau - Canada"
    },
    {
      "value": "Pacific/Efate",
      "label": "Gaua Island - Vanuatu"
    },
    {
      "value": "Europe/Stockholm",
      "label": "Gavle - Sweden"
    },
    {
      "value": "Asia/Calcutta",
      "label": "Gaya - India"
    },
    {
      "value": "Australia/Brisbane",
      "label": "Gayndah - Australia"
    },
    {
      "value": "Asia/Gaza",
      "label": "Gaza - Palestine"
    },
    {
      "value": "Europe/Istanbul",
      "label": "Gaziantep - Turkey"
    },
    {
      "value": "Africa/Kinshasa",
      "label": "Gbadolite - Congo (Kinshasa)"
    },
    {
      "value": "Africa/Freetown",
      "label": "Gbangbatok - Sierra Leone"
    },
    {
      "value": "Europe/Warsaw",
      "label": "Gdansk - Poland"
    },
    {
      "value": "Europe/Warsaw",
      "label": "Gdynia - Poland"
    },
    {
      "value": "Australia/Hobart",
      "label": "Geelong - Australia"
    },
    {
      "value": "Europe/Berlin",
      "label": "Geilenkirchen - Germany"
    },
    {
      "value": "Europe/Oslo",
      "label": "Geilo - Norway"
    },
    {
      "value": "Europe/Moscow",
      "label": "Gelendzhik - Russia"
    },
    {
      "value": "Africa/Kinshasa",
      "label": "Gemena - Congo (Kinshasa)"
    },
    {
      "value": "Africa/Khartoum",
      "label": "Geneina - Sudan"
    },
    {
      "value": "America/Argentina/Salta",
      "label": "General Pico - Argentina"
    },
    {
      "value": "Europe/Paris",
      "label": "Geneva - Switzerland"
    },
    {
      "value": "Europe/Rome",
      "label": "Genoa - Italy"
    },
    {
      "value": "Africa/Johannesburg",
      "label": "George - South Africa"
    },
    {
      "value": "America/Cayman",
      "label": "Georgetown - Cayman Islands"
    },
    {
      "value": "America/Guyana",
      "label": "Georgetown - Guyana"
    },
    {
      "value": "America/Montserrat",
      "label": "Geralds - Montserrat"
    },
    {
      "value": "Australia/Perth",
      "label": "Geraldton - Australia"
    },
    {
      "value": "America/Toronto",
      "label": "Geraldton - Canada"
    },
    {
      "value": "Europe/Madrid",
      "label": "Gerona - Spain"
    },
    {
      "value": "Africa/Tripoli",
      "label": "Ghadames - Libya"
    },
    {
      "value": "Africa/Gaborone",
      "label": "Ghanzi - Botswana"
    },
    {
      "value": "Africa/Algiers",
      "label": "Ghardaia - Algeria"
    },
    {
      "value": "Africa/Tripoli",
      "label": "Ghat - Libya"
    },
    {
      "value": "Asia/Tehran",
      "label": "Ghazvin - Iran"
    },
    {
      "value": "Africa/Algiers",
      "label": "Ghriss - Algeria"
    },
    {
      "value": "Europe/Gibraltar",
      "label": "Gibraltar - Gibraltar"
    },
    {
      "value": "Asia/Tokyo",
      "label": "Gifu - Japan"
    },
    {
      "value": "Asia/Karachi",
      "label": "Gilgit - Pakistan"
    },
    {
      "value": "America/Winnipeg",
      "label": "Gillam - Canada"
    },
    {
      "value": "America/Denver",
      "label": "Gillette - United States"
    },
    {
      "value": "Europe/Amsterdam",
      "label": "Gilze-rijen - Netherlands"
    },
    {
      "value": "America/Winnipeg",
      "label": "Gimli - Canada"
    },
    {
      "value": "America/Bogota",
      "label": "Girardot - Colombia"
    },
    {
      "value": "Pacific/Port_Moresby",
      "label": "Girua - Papua New Guinea"
    },
    {
      "value": "Pacific/Auckland",
      "label": "Gisborne - New Zealand"
    },
    {
      "value": "Africa/Kigali",
      "label": "Gisenyi - Rwanda"
    },
    {
      "value": "Asia/Riyadh",
      "label": "Gizan - Saudi Arabia"
    },
    {
      "value": "Pacific/Guadalcanal",
      "label": "Gizo - Solomon Islands"
    },
    {
      "value": "America/Edmonton",
      "label": "Gjoa Haven - Canada"
    },
    {
      "value": "Atlantic/Reykjavik",
      "label": "Gjogur - Iceland"
    },
    {
      "value": "Australia/Brisbane",
      "label": "Gladstone - Australia"
    },
    {
      "value": "America/New_York",
      "label": "Gladwin - United States"
    },
    {
      "value": "Europe/London",
      "label": "Glasgow - United Kingdom"
    },
    {
      "value": "America/Denver",
      "label": "Glasgow - United States"
    },
    {
      "value": "Australia/Sydney",
      "label": "Glen Innes - Australia"
    },
    {
      "value": "America/Denver",
      "label": "Glendive - United States"
    },
    {
      "value": "Pacific/Auckland",
      "label": "Glentanner - New Zealand"
    },
    {
      "value": "Asia/Calcutta",
      "label": "Goa - India"
    },
    {
      "value": "Africa/Addis_Ababa",
      "label": "Goba - Ethiopia"
    },
    {
      "value": "America/Argentina/Rio_Gallegos",
      "label": "Gobernador Gregores - Argentina"
    },
    {
      "value": "Africa/Addis_Ababa",
      "label": "Gode - Ethiopia"
    },
    {
      "value": "America/Winnipeg",
      "label": "Gods Lake Narrows - Canada"
    },
    {
      "value": "America/Winnipeg",
      "label": "Gods River - Canada"
    },
    {
      "value": "America/Godthab",
      "label": "Godthaab - Greenland"
    },
    {
      "value": "America/Sao_Paulo",
      "label": "Goiania - Brazil"
    },
    {
      "value": "America/New_York",
      "label": "Goldsboro - United States"
    },
    {
      "value": "America/Costa_Rica",
      "label": "Golfito - Costa Rica"
    },
    {
      "value": "Asia/Shanghai",
      "label": "Golmud - China"
    },
    {
      "value": "Europe/London",
      "label": "Golouchestershire - United Kingdom"
    },
    {
      "value": "America/Anchorage",
      "label": "Golovin - United States"
    },
    {
      "value": "Africa/Kigali",
      "label": "Goma - Congo (Kinshasa)"
    },
    {
      "value": "Europe/Minsk",
      "label": "Gomel - Belarus"
    },
    {
      "value": "Africa/Addis_Ababa",
      "label": "Gondar - Ethiopia"
    },
    {
      "value": "America/Denver",
      "label": "Goodland - United States"
    },
    {
      "value": "America/Phoenix",
      "label": "Goodyear - United States"
    },
    {
      "value": "America/Halifax",
      "label": "Goose Bay - Canada"
    },
    {
      "value": "Asia/Calcutta",
      "label": "Gorakhpur - India"
    },
    {
      "value": "Africa/Addis_Ababa",
      "label": "Gore - Ethiopia"
    },
    {
      "value": "America/Toronto",
      "label": "Gore Bay - Canada"
    },
    {
      "value": "Asia/Tehran",
      "label": "Gorgan - Iran"
    },
    {
      "value": "Europe/Sofia",
      "label": "Gorna Orechovica - Bulgaria"
    },
    {
      "value": "Asia/Krasnoyarsk",
      "label": "Gorno-Altaysk - Russia"
    },
    {
      "value": "Pacific/Port_Moresby",
      "label": "Goroka - Papua New Guinea"
    },
    {
      "value": "Asia/Makassar",
      "label": "Gorontalo - Indonesia"
    },
    {
      "value": "Europe/Stockholm",
      "label": "Gothenborg - Sweden"
    },
    {
      "value": "Australia/Sydney",
      "label": "Goulburn - Australia"
    },
    {
      "value": "Australia/Darwin",
      "label": "Gove - Australia"
    },
    {
      "value": "America/Sao_Paulo",
      "label": "Governador Valadares - Brazil"
    },
    {
      "value": "America/Nassau",
      "label": "Governor's Harbor - Bahamas"
    },
    {
      "value": "America/Cordoba",
      "label": "Goya - Argentina"
    },
    {
      "value": "Europe/Malta",
      "label": "Gozo - Malta"
    },
    {
      "value": "Atlantic/Azores",
      "label": "Graciosa Island - Portugal"
    },
    {
      "value": "Australia/Sydney",
      "label": "Grafton - Australia"
    },
    {
      "value": "Atlantic/Reykjavik",
      "label": "Grímsey - Iceland"
    },
    {
      "value": "Atlantic/Canary",
      "label": "Gran Canaria - Spain"
    },
    {
      "value": "America/Guadeloupe",
      "label": "Grand Bourg - Guadeloupe"
    },
    {
      "value": "America/Phoenix",
      "label": "Grand Canyon - United States"
    },
    {
      "value": "America/Chicago",
      "label": "Grand Forks - United States"
    },
    {
      "value": "America/Chicago",
      "label": "Grand Island - United States"
    },
    {
      "value": "America/Denver",
      "label": "Grand Junction - United States"
    },
    {
      "value": "America/Chicago",
      "label": "Grand Marais - United States"
    },
    {
      "value": "America/New_York",
      "label": "Grand Rapids - United States"
    },
    {
      "value": "America/Chicago",
      "label": "Grand Rapids MN - United States"
    },
    {
      "value": "America/Guadeloupe",
      "label": "Grande Anse - Guadeloupe"
    },
    {
      "value": "America/Edmonton",
      "label": "Grande Prairie - Canada"
    },
    {
      "value": "America/Cayenne",
      "label": "Grand-Santi - French Guiana"
    },
    {
      "value": "America/Los_Angeles",
      "label": "Grant County Airport - United States"
    },
    {
      "value": "America/Denver",
      "label": "Grants - United States"
    },
    {
      "value": "Europe/Paris",
      "label": "Granville - France"
    },
    {
      "value": "Europe/Vienna",
      "label": "Graz - Austria"
    },
    {
      "value": "America/Chicago",
      "label": "Great Bend - United States"
    },
    {
      "value": "America/Nassau",
      "label": "Great Exuma - Bahamas"
    },
    {
      "value": "America/Denver",
      "label": "Great Falls - United States"
    },
    {
      "value": "Australia/Brisbane",
      "label": "Great Keppel Island - Australia"
    },
    {
      "value": "America/Chicago",
      "label": "Green Bay - United States"
    },
    {
      "value": "Asia/Taipei",
      "label": "Green Island - Taiwan"
    },
    {
      "value": "America/New_York",
      "label": "Greensboro - United States"
    },
    {
      "value": "America/Chicago",
      "label": "Greenvile - United States"
    },
    {
      "value": "Africa/Monrovia",
      "label": "Greenville - Liberia"
    },
    {
      "value": "America/New_York",
      "label": "Greenville - United States"
    },
    {
      "value": "America/Halifax",
      "label": "Greenwood - Canada"
    },
    {
      "value": "America/Chicago",
      "label": "Greenwood - United States"
    },
    {
      "value": "Europe/Zurich",
      "label": "Grenchen - Switzerland"
    },
    {
      "value": "Europe/Paris",
      "label": "Grenoble - France"
    },
    {
      "value": "Australia/Sydney",
      "label": "Griffith - Australia"
    },
    {
      "value": "America/Toronto",
      "label": "Grise Fiord - Canada"
    },
    {
      "value": "Europe/Amsterdam",
      "label": "Groningen - Netherlands"
    },
    {
      "value": "Australia/Darwin",
      "label": "Groote Eylandt - Australia"
    },
    {
      "value": "Africa/Windhoek",
      "label": "Grootfontein - Namibia"
    },
    {
      "value": "Europe/Rome",
      "label": "Grosseto - Italy"
    },
    {
      "value": "America/New_York",
      "label": "Groton CT - United States"
    },
    {
      "value": "Europe/Rome",
      "label": "Grottaglie - Italy"
    },
    {
      "value": "America/Mexico_City",
      "label": "Guadalajara - Mexico"
    },
    {
      "value": "America/Boa_Vista",
      "label": "Guajara-mirim - Brazil"
    },
    {
      "value": "America/Cordoba",
      "label": "Gualeguaychu - Argentina"
    },
    {
      "value": "America/Tegucigalpa",
      "label": "Guanaja - Honduras"
    },
    {
      "value": "America/Fortaleza",
      "label": "Guanambi - Brazil"
    },
    {
      "value": "America/Caracas",
      "label": "Guanare - Venezuela"
    },
    {
      "value": "Asia/Shanghai",
      "label": "Guangyuan - China"
    },
    {
      "value": "Asia/Shanghai",
      "label": "Guangzhou - China"
    },
    {
      "value": "America/Bogota",
      "label": "Guapi - Colombia"
    },
    {
      "value": "America/Costa_Rica",
      "label": "Guapiles - Costa Rica"
    },
    {
      "value": "America/Sao_Paulo",
      "label": "Guarapuava - Brazil"
    },
    {
      "value": "America/Sao_Paulo",
      "label": "Guaratingueta - Brazil"
    },
    {
      "value": "America/Caracas",
      "label": "Guasdualito - Venezuela"
    },
    {
      "value": "America/Guatemala",
      "label": "Guatemala City - Guatemala"
    },
    {
      "value": "America/Caracas",
      "label": "Guayana - Venezuela"
    },
    {
      "value": "America/Guayaquil",
      "label": "Guayaquil - Ecuador"
    },
    {
      "value": "America/La_Paz",
      "label": "Guayaramerín - Bolivia"
    },
    {
      "value": "America/Hermosillo",
      "label": "Guaymas - Mexico"
    },
    {
      "value": "Africa/Algiers",
      "label": "Guemar - Algeria"
    },
    {
      "value": "Europe/Guernsey",
      "label": "Guernsey - Guernsey"
    },
    {
      "value": "America/Tijuana",
      "label": "Guerrero Negro - Mexico"
    },
    {
      "value": "Europe/Berlin",
      "label": "Guetersloh - Germany"
    },
    {
      "value": "Asia/Shanghai",
      "label": "Guilin - China"
    },
    {
      "value": "America/Caracas",
      "label": "Guiria - Venezuela"
    },
    {
      "value": "Asia/Shanghai",
      "label": "Guiyang - China"
    },
    {
      "value": "America/Chicago",
      "label": "Gulf Shores - United States"
    },
    {
      "value": "America/Chicago",
      "label": "Gulfport - United States"
    },
    {
      "value": "America/Anchorage",
      "label": "Gulkana - United States"
    },
    {
      "value": "Africa/Kampala",
      "label": "Gulu - Uganda"
    },
    {
      "value": "Asia/Calcutta",
      "label": "Guna - India"
    },
    {
      "value": "America/Denver",
      "label": "Gunnison - United States"
    },
    {
      "value": "Asia/Jakarta",
      "label": "Gunung Sitoli - Indonesia"
    },
    {
      "value": "Asia/Riyadh",
      "label": "Guriat - Saudi Arabia"
    },
    {
      "value": "Pacific/Port_Moresby",
      "label": "Gurney - Papua New Guinea"
    },
    {
      "value": "America/Fortaleza",
      "label": "Gurupi - Brazil"
    },
    {
      "value": "America/Anchorage",
      "label": "Gustavus - United States"
    },
    {
      "value": "Asia/Calcutta",
      "label": "Guwahati - India"
    },
    {
      "value": "Asia/Shanghai",
      "label": "Guyuan - China"
    },
    {
      "value": "Asia/Karachi",
      "label": "Gwadar - Pakistan"
    },
    {
      "value": "Asia/Calcutta",
      "label": "Gwalior - India"
    },
    {
      "value": "Africa/Harare",
      "label": "Gwert - Zimbabwe"
    },
    {
      "value": "America/New_York",
      "label": "Gwinn - United States"
    },
    {
      "value": "Asia/Yerevan",
      "label": "Gyumri - Armenia"
    },
    {
      "value": "Indian/Maldives",
      "label": "Haa Dhaalu Atoll - Maldives"
    },
    {
      "value": "Asia/Tokyo",
      "label": "Hachijojima - Japan"
    },
    {
      "value": "Asia/Tokyo",
      "label": "Hachinoe - Japan"
    },
    {
      "value": "Asia/Riyadh",
      "label": "Hafr Al-batin - Saudi Arabia"
    },
    {
      "value": "America/New_York",
      "label": "Hagerstown - United States"
    },
    {
      "value": "Europe/Stockholm",
      "label": "Hagfors - Sweden"
    },
    {
      "value": "Europe/Berlin",
      "label": "Hahn - Germany"
    },
    {
      "value": "Asia/Jerusalem",
      "label": "Haifa - Israel"
    },
    {
      "value": "Asia/Shanghai",
      "label": "Haikou - China"
    },
    {
      "value": "Asia/Riyadh",
      "label": "Hail - Saudi Arabia"
    },
    {
      "value": "Asia/Shanghai",
      "label": "Hailar - China"
    },
    {
      "value": "America/Denver",
      "label": "Hailey - United States"
    },
    {
      "value": "America/Anchorage",
      "label": "Haines - United States"
    },
    {
      "value": "Asia/Saigon",
      "label": "Haiphong - Vietnam"
    },
    {
      "value": "Asia/Tokyo",
      "label": "Hakodate - Japan"
    },
    {
      "value": "America/Toronto",
      "label": "Hall Beach - Canada"
    },
    {
      "value": "Europe/Helsinki",
      "label": "Halli - Finland"
    },
    {
      "value": "Australia/Perth",
      "label": "Halls Creek - Australia"
    },
    {
      "value": "Europe/Stockholm",
      "label": "Halmstad - Sweden"
    },
    {
      "value": "Asia/Tehran",
      "label": "Hamadan - Iran"
    },
    {
      "value": "Europe/Oslo",
      "label": "Hamar - Norway"
    },
    {
      "value": "Europe/Berlin",
      "label": "Hamburg - Germany"
    },
    {
      "value": "Asia/Pyongyang",
      "label": "Hamhung - North Korea"
    },
    {
      "value": "Asia/Shanghai",
      "label": "Hami - China"
    },
    {
      "value": "Australia/Hobart",
      "label": "Hamilton - Australia"
    },
    {
      "value": "America/Toronto",
      "label": "Hamilton - Canada"
    },
    {
      "value": "Pacific/Auckland",
      "label": "Hamilton - New Zealand"
    },
    {
      "value": "America/New_York",
      "label": "Hamilton - United States"
    },
    {
      "value": "Australia/Brisbane",
      "label": "Hamilton Island - Australia"
    },
    {
      "value": "Europe/Oslo",
      "label": "Hammerfest - Norway"
    },
    {
      "value": "America/New_York",
      "label": "Hampton - United States"
    },
    {
      "value": "Pacific/Honolulu",
      "label": "Hana - United States"
    },
    {
      "value": "Asia/Tokyo",
      "label": "Hanamaki - Japan"
    },
    {
      "value": "America/New_York",
      "label": "Hancock - United States"
    },
    {
      "value": "Asia/Shanghai",
      "label": "Handan - China"
    },
    {
      "value": "Asia/Shanghai",
      "label": "Hangzhou - China"
    },
    {
      "value": "Europe/Berlin",
      "label": "Hannover - Germany"
    },
    {
      "value": "Asia/Shanghai",
      "label": "Hanzhong - China"
    },
    {
      "value": "Asia/Shanghai",
      "label": "Harbin - China"
    },
    {
      "value": "Africa/Mogadishu",
      "label": "Hargeisa - Somalia"
    },
    {
      "value": "America/Chicago",
      "label": "Harlingen - United States"
    },
    {
      "value": "America/New_York",
      "label": "Harrisburg - United States"
    },
    {
      "value": "Africa/Johannesburg",
      "label": "Harrismith - South Africa"
    },
    {
      "value": "America/Chicago",
      "label": "Harrison - United States"
    },
    {
      "value": "Europe/Oslo",
      "label": "Harstad/Narvik - Norway"
    },
    {
      "value": "America/New_York",
      "label": "Hartford - United States"
    },
    {
      "value": "Africa/Algiers",
      "label": "Hassi Messaoud - Algeria"
    },
    {
      "value": "Europe/Oslo",
      "label": "Hasvik - Norway"
    },
    {
      "value": "Asia/Bangkok",
      "label": "Hat Yai - Thailand"
    },
    {
      "value": "Europe/Istanbul",
      "label": "Hatay - Turkey"
    },
    {
      "value": "America/Chicago",
      "label": "Hattiesburg - United States"
    },
    {
      "value": "America/Chicago",
      "label": "Hattiesburg/Laurel - United States"
    },
    {
      "value": "Europe/Oslo",
      "label": "Haugesund - Norway"
    },
    {
      "value": "America/Havana",
      "label": "Havana - Cuba"
    },
    {
      "value": "Europe/London",
      "label": "Haverfordwest - United Kingdom"
    },
    {
      "value": "America/Denver",
      "label": "Havre - United States"
    },
    {
      "value": "America/Toronto",
      "label": "Havre-Saint-Pierre - Canada"
    },
    {
      "value": "Europe/London",
      "label": "Hawarden - United Kingdom"
    },
    {
      "value": "America/Los_Angeles",
      "label": "Hawthorne - United States"
    },
    {
      "value": "America/Edmonton",
      "label": "Hay River - Canada"
    },
    {
      "value": "America/Denver",
      "label": "Hayden - United States"
    },
    {
      "value": "America/Chicago",
      "label": "Hays - United States"
    },
    {
      "value": "America/Los_Angeles",
      "label": "Hayward - United States"
    },
    {
      "value": "America/New_York",
      "label": "Hazleton - United States"
    },
    {
      "value": "America/Toronto",
      "label": "Hearst - Canada"
    },
    {
      "value": "Asia/Shanghai",
      "label": "Hefei - China"
    },
    {
      "value": "Asia/Rangoon",
      "label": "Heho - Burma"
    },
    {
      "value": "Asia/Shanghai",
      "label": "Heihe - China"
    },
    {
      "value": "America/Denver",
      "label": "Helena - United States"
    },
    {
      "value": "Europe/Berlin",
      "label": "Helgoland - Germany"
    },
    {
      "value": "Europe/Helsinki",
      "label": "Helsinki - Finland"
    },
    {
      "value": "Europe/Stockholm",
      "label": "Hemavan - Sweden"
    },
    {
      "value": "America/Los_Angeles",
      "label": "Henderson - United States"
    },
    {
      "value": "Asia/Taipei",
      "label": "Hengchun - Taiwan"
    },
    {
      "value": "Europe/Athens",
      "label": "Heraklion - Greece"
    },
    {
      "value": "Asia/Kabul",
      "label": "Herat - Afghanistan"
    },
    {
      "value": "Europe/Berlin",
      "label": "Heringsdorf - Germany"
    },
    {
      "value": "America/Hermosillo",
      "label": "Hermosillo - Mexico"
    },
    {
      "value": "Australia/Brisbane",
      "label": "Hervey Bay - Australia"
    },
    {
      "value": "America/St_Lucia",
      "label": "Hewandorra - Saint Lucia"
    },
    {
      "value": "America/Chicago",
      "label": "Hibbing - United States"
    },
    {
      "value": "America/New_York",
      "label": "Hickory - United States"
    },
    {
      "value": "Atlantic/Canary",
      "label": "Hierro - Spain"
    },
    {
      "value": "America/Edmonton",
      "label": "High Level - Canada"
    },
    {
      "value": "America/Caracas",
      "label": "Higuerote - Venezuela"
    },
    {
      "value": "Pacific/Honolulu",
      "label": "Hilo - United States"
    },
    {
      "value": "America/New_York",
      "label": "Hilton Head Island - United States"
    },
    {
      "value": "Asia/Tokyo",
      "label": "Hiroshima - Japan"
    },
    {
      "value": "Asia/Calcutta",
      "label": "Hissar - India"
    },
    {
      "value": "Pacific/Marquesas",
      "label": "Hiva-oa - French Polynesia"
    },
    {
      "value": "Asia/Saigon",
      "label": "Ho Chi Minh City - Vietnam"
    },
    {
      "value": "Australia/Melbourne",
      "label": "Hobart - Australia"
    },
    {
      "value": "America/Chicago",
      "label": "Hobart - United States"
    },
    {
      "value": "America/Denver",
      "label": "Hobbs - United States"
    },
    {
      "value": "Asia/Aden",
      "label": "Hodeidah - Yemen"
    },
    {
      "value": "Africa/Johannesburg",
      "label": "Hoedspruit - South Africa"
    },
    {
      "value": "Europe/Berlin",
      "label": "Hof - Germany"
    },
    {
      "value": "Atlantic/Reykjavik",
      "label": "Hofn - Iceland"
    },
    {
      "value": "Europe/Vienna",
      "label": "Hohenems - Austria"
    },
    {
      "value": "Asia/Shanghai",
      "label": "Hohhot - China"
    },
    {
      "value": "Pacific/Auckland",
      "label": "Hokitika - New Zealand"
    },
    {
      "value": "Africa/Nairobi",
      "label": "Hola - Kenya"
    },
    {
      "value": "America/Havana",
      "label": "Holguin - Cuba"
    },
    {
      "value": "America/New_York",
      "label": "Hollywood - United States"
    },
    {
      "value": "America/Edmonton",
      "label": "Holman Island - Canada"
    },
    {
      "value": "America/Anchorage",
      "label": "Holy Cross - United States"
    },
    {
      "value": "America/Anchorage",
      "label": "Homer - United States"
    },
    {
      "value": "America/New_York",
      "label": "Homestead - United States"
    },
    {
      "value": "Asia/Rangoon",
      "label": "Hommalin - Burma"
    },
    {
      "value": "Africa/Tripoli",
      "label": "Hon - Libya"
    },
    {
      "value": "Asia/Hong_Kong",
      "label": "Hong Kong - Hong Kong"
    },
    {
      "value": "Pacific/Guadalcanal",
      "label": "Honiara - Solomon Islands"
    },
    {
      "value": "Europe/London",
      "label": "Honington - United Kingdom"
    },
    {
      "value": "Europe/Oslo",
      "label": "Honningsvag - Norway"
    },
    {
      "value": "Pacific/Honolulu",
      "label": "Honolulu - United States"
    },
    {
      "value": "Asia/Srednekolymsk",
      "label": "Honuu - Russia"
    },
    {
      "value": "Australia/Darwin",
      "label": "Hooker Creek - Australia"
    },
    {
      "value": "America/Anchorage",
      "label": "Hoonah - United States"
    },
    {
      "value": "America/Anchorage",
      "label": "Hooper Bay - United States"
    },
    {
      "value": "America/Halifax",
      "label": "Hopedale - Canada"
    },
    {
      "value": "America/Chicago",
      "label": "Hopkinsville - United States"
    },
    {
      "value": "America/Los_Angeles",
      "label": "Hoquiam - United States"
    },
    {
      "value": "Australia/Brisbane",
      "label": "Horn Island - Australia"
    },
    {
      "value": "America/Toronto",
      "label": "Hornepayne - Canada"
    },
    {
      "value": "Atlantic/Azores",
      "label": "Horta - Portugal"
    },
    {
      "value": "Pacific/Port_Moresby",
      "label": "Hoskins - Papua New Guinea"
    },
    {
      "value": "America/Chicago",
      "label": "Hot Springs - United States"
    },
    {
      "value": "Asia/Shanghai",
      "label": "Hotan - China"
    },
    {
      "value": "America/New_York",
      "label": "Houghton Lake - United States"
    },
    {
      "value": "America/New_York",
      "label": "Houlton - United States"
    },
    {
      "value": "America/Chicago",
      "label": "Houston - United States"
    },
    {
      "value": "America/Panama",
      "label": "Howard - Panama"
    },
    {
      "value": "Asia/Rangoon",
      "label": "Hpa-an - Burma"
    },
    {
      "value": "Europe/Minsk",
      "label": "Hrodna - Belarus"
    },
    {
      "value": "Asia/Taipei",
      "label": "Hsinchu - Taiwan"
    },
    {
      "value": "America/Lima",
      "label": "Huánuco - Peru"
    },
    {
      "value": "Pacific/Tahiti",
      "label": "Huahine Island - French Polynesia"
    },
    {
      "value": "Asia/Taipei",
      "label": "Hualien - Taiwan"
    },
    {
      "value": "Africa/Luanda",
      "label": "Huambo - Angola"
    },
    {
      "value": "Asia/Shanghai",
      "label": "Huangshan - China"
    },
    {
      "value": "Asia/Shanghai",
      "label": "Huangyan - China"
    },
    {
      "value": "America/Mexico_City",
      "label": "Huatulco - Mexico"
    },
    {
      "value": "Asia/Vientiane",
      "label": "Huay Xai - Laos"
    },
    {
      "value": "Asia/Calcutta",
      "label": "Hubli - India"
    },
    {
      "value": "Europe/Stockholm",
      "label": "Hudiksvall - Sweden"
    },
    {
      "value": "America/Regina",
      "label": "Hudson Bay - Canada"
    },
    {
      "value": "Asia/Saigon",
      "label": "Hue - Vietnam"
    },
    {
      "value": "Europe/Madrid",
      "label": "Huesca - Spain"
    },
    {
      "value": "Australia/Brisbane",
      "label": "Hughenden - Australia"
    },
    {
      "value": "America/Anchorage",
      "label": "Hughes - United States"
    },
    {
      "value": "Europe/Stockholm",
      "label": "Hultsfred - Sweden"
    },
    {
      "value": "America/Boa_Vista",
      "label": "Humaita - Brazil"
    },
    {
      "value": "Europe/London",
      "label": "Humberside - United Kingdom"
    },
    {
      "value": "Africa/Addis_Ababa",
      "label": "Humera - Ethiopia"
    },
    {
      "value": "America/New_York",
      "label": "Huntington - United States"
    },
    {
      "value": "America/Chicago",
      "label": "Huntsville - United States"
    },
    {
      "value": "Africa/Cairo",
      "label": "Hurghada - Egypt"
    },
    {
      "value": "America/Chicago",
      "label": "Huron - United States"
    },
    {
      "value": "Atlantic/Reykjavik",
      "label": "Husavik - Iceland"
    },
    {
      "value": "America/Anchorage",
      "label": "Huslia - United States"
    },
    {
      "value": "America/Chicago",
      "label": "Hutchinson - United States"
    },
    {
      "value": "Africa/Harare",
      "label": "Hwange National Park - Zimbabwe"
    },
    {
      "value": "America/Anchorage",
      "label": "Hydaburg - United States"
    },
    {
      "value": "Asia/Calcutta",
      "label": "Hyderabad - India"
    },
    {
      "value": "Asia/Karachi",
      "label": "Hyderabad - Pakistan"
    },
    {
      "value": "Europe/Paris",
      "label": "Hyeres - France"
    },
    {
      "value": "Europe/Helsinki",
      "label": "Hyvinkaa - Finland"
    },
    {
      "value": "Europe/Bucharest",
      "label": "Iasi - Romania"
    },
    {
      "value": "Africa/Lagos",
      "label": "Ibadan - Nigeria"
    },
    {
      "value": "America/Bogota",
      "label": "Ibague - Colombia"
    },
    {
      "value": "Asia/Tokyo",
      "label": "Ibaraki - Japan"
    },
    {
      "value": "America/Lima",
      "label": "Iberia - Peru"
    },
    {
      "value": "Europe/Madrid",
      "label": "Ibiza - Spain"
    },
    {
      "value": "America/Denver",
      "label": "Idaho Falls - United States"
    },
    {
      "value": "Asia/Krasnoyarsk",
      "label": "Igarka - Russia"
    },
    {
      "value": "America/Anchorage",
      "label": "Igiugig - United States"
    },
    {
      "value": "America/Toronto",
      "label": "Igloolik - Canada"
    },
    {
      "value": "America/Fortaleza",
      "label": "Iguatu - Brazil"
    },
    {
      "value": "America/Cordoba",
      "label": "Iguazu Falls - Argentina"
    },
    {
      "value": "Europe/Athens",
      "label": "Ikaria - Greece"
    },
    {
      "value": "Asia/Tokyo",
      "label": "Iki - Japan"
    },
    {
      "value": "Asia/Tehran",
      "label": "Ilam - Iran"
    },
    {
      "value": "Africa/Lubumbashi",
      "label": "Ilebo - Congo (Kinshasa)"
    },
    {
      "value": "America/Toronto",
      "label": "Iles De La Madeleine - Canada"
    },
    {
      "value": "America/Winnipeg",
      "label": "Ilford - Canada"
    },
    {
      "value": "America/Fortaleza",
      "label": "Ilheus - Brazil"
    },
    {
      "value": "America/Anchorage",
      "label": "Iliamna - United States"
    },
    {
      "value": "Africa/Algiers",
      "label": "Illizi - Algeria"
    },
    {
      "value": "America/Lima",
      "label": "Ilo - Peru"
    },
    {
      "value": "Asia/Manila",
      "label": "Iloilo - Philippines"
    },
    {
      "value": "Africa/Lagos",
      "label": "Ilorin - Nigeria"
    },
    {
      "value": "America/Godthab",
      "label": "Ilulissat - Greenland"
    },
    {
      "value": "America/Guyana",
      "label": "Imbaimadai - Guyana"
    },
    {
      "value": "America/New_York",
      "label": "Immokalee  - United States"
    },
    {
      "value": "Africa/Lagos",
      "label": "Imo - Nigeria"
    },
    {
      "value": "America/Fortaleza",
      "label": "Imperatriz - Brazil"
    },
    {
      "value": "America/Los_Angeles",
      "label": "Imperial - United States"
    },
    {
      "value": "Asia/Calcutta",
      "label": "Imphal - India"
    },
    {
      "value": "Africa/Algiers",
      "label": "In Salah - Algeria"
    },
    {
      "value": "America/Anchorage",
      "label": "Indian Mountains - United States"
    },
    {
      "value": "America/Los_Angeles",
      "label": "Indian Springs - United States"
    },
    {
      "value": "America/New_York",
      "label": "Indianapolis - United States"
    },
    {
      "value": "Asia/Calcutta",
      "label": "Indore - India"
    },
    {
      "value": "Europe/Dublin",
      "label": "Indreabhan - Ireland"
    },
    {
      "value": "America/Argentina/Salta",
      "label": "Ingeniero Jacobacci - Argentina"
    },
    {
      "value": "Europe/Berlin",
      "label": "Ingolstadt - Germany"
    },
    {
      "value": "Africa/Maputo",
      "label": "Inhaca - Mozambique"
    },
    {
      "value": "Africa/Maputo",
      "label": "Inhambane - Mozambique"
    },
    {
      "value": "Europe/Dublin",
      "label": "Inis Mor - Ireland"
    },
    {
      "value": "Europe/Dublin",
      "label": "Inisheer - Ireland"
    },
    {
      "value": "Europe/Dublin",
      "label": "Inishmaan - Ireland"
    },
    {
      "value": "Australia/Brisbane",
      "label": "Innisfail - Australia"
    },
    {
      "value": "Europe/Vienna",
      "label": "Innsbruck - Austria"
    },
    {
      "value": "Africa/Kinshasa",
      "label": "Inongo - Congo (Kinshasa)"
    },
    {
      "value": "Europe/Zurich",
      "label": "Interlaken - Switzerland"
    },
    {
      "value": "America/Chicago",
      "label": "International Falls - United States"
    },
    {
      "value": "America/Toronto",
      "label": "Inukjuak - Canada"
    },
    {
      "value": "America/Edmonton",
      "label": "Inuvik - Canada"
    },
    {
      "value": "Pacific/Auckland",
      "label": "Invercargill - New Zealand"
    },
    {
      "value": "Australia/Sydney",
      "label": "Inverell - Australia"
    },
    {
      "value": "Europe/London",
      "label": "Inverness - United Kingdom"
    },
    {
      "value": "America/Los_Angeles",
      "label": "Inyokern - United States"
    },
    {
      "value": "Europe/Athens",
      "label": "Ioannina - Greece"
    },
    {
      "value": "America/Chicago",
      "label": "Iowa City - United States"
    },
    {
      "value": "America/Sao_Paulo",
      "label": "Ipatinga - Brazil"
    },
    {
      "value": "America/Bogota",
      "label": "Ipiales - Colombia"
    },
    {
      "value": "Asia/Kuala_Lumpur",
      "label": "Ipoh - Malaysia"
    },
    {
      "value": "Pacific/Efate",
      "label": "Ipota - Vanuatu"
    },
    {
      "value": "America/Toronto",
      "label": "Iqaluit - Canada"
    },
    {
      "value": "America/Santiago",
      "label": "Iquique - Chile"
    },
    {
      "value": "America/Lima",
      "label": "Iquitos - Peru"
    },
    {
      "value": "America/Chicago",
      "label": "Iraan - United States"
    },
    {
      "value": "Asia/Tehran",
      "label": "Iran Shahr - Iran"
    },
    {
      "value": "Africa/Dar_es_Salaam",
      "label": "Iringa - Tanzania"
    },
    {
      "value": "Asia/Irkutsk",
      "label": "Irkutsk - Russia"
    },
    {
      "value": "America/Chicago",
      "label": "Iron Mountain - United States"
    },
    {
      "value": "Pacific/Galapagos",
      "label": "Isabela - Ecuador"
    },
    {
      "value": "Atlantic/Reykjavik",
      "label": "Isafjordur - Iceland"
    },
    {
      "value": "Asia/Tokyo",
      "label": "Ishigaki - Japan"
    },
    {
      "value": "Asia/Dhaka",
      "label": "Ishurdi - Bangladesh"
    },
    {
      "value": "Africa/Lubumbashi",
      "label": "Isiro - Congo (Kinshasa)"
    },
    {
      "value": "America/Caracas",
      "label": "Isla De Coche - Venezuela"
    },
    {
      "value": "America/Cancun",
      "label": "Isla Mujeres - Mexico"
    },
    {
      "value": "Antarctica/South_Pole",
      "label": "Isla Rey Jorge - Antarctica"
    },
    {
      "value": "America/Winnipeg",
      "label": "Island Lake - Canada"
    },
    {
      "value": "Europe/London",
      "label": "Islay - United Kingdom"
    },
    {
      "value": "Europe/Isle_of_Man",
      "label": "Isle Of Man - Isle of Man"
    },
    {
      "value": "America/New_York",
      "label": "Islip - United States"
    },
    {
      "value": "Europe/Istanbul",
      "label": "Istanbul - Turkey"
    },
    {
      "value": "America/New_York",
      "label": "Ithaca - United States"
    },
    {
      "value": "America/Scoresbysund",
      "label": "Ittoqqortoormiit - Greenland"
    },
    {
      "value": "America/Sao_Paulo",
      "label": "Itumbiara - Brazil"
    },
    {
      "value": "Europe/Helsinki",
      "label": "Ivalo - Finland"
    },
    {
      "value": "Europe/Kiev",
      "label": "Ivano-Frankivsk - Ukraine"
    },
    {
      "value": "Europe/Moscow",
      "label": "Ivanovo - Russia"
    },
    {
      "value": "America/Toronto",
      "label": "Ivujivik - Canada"
    },
    {
      "value": "Asia/Tokyo",
      "label": "Iwami - Japan"
    },
    {
      "value": "Asia/Tokyo",
      "label": "Iwojima - Japan"
    },
    {
      "value": "Europe/Samara",
      "label": "Izhevsk - Russia"
    },
    {
      "value": "Europe/Istanbul",
      "label": "Izmir - Turkey"
    },
    {
      "value": "America/Mexico_City",
      "label": "Iztepec - Mexico"
    },
    {
      "value": "Asia/Tokyo",
      "label": "Izumo - Japan"
    },
    {
      "value": "Asia/Calcutta",
      "label": "Jabalpur - India"
    },
    {
      "value": "Australia/Darwin",
      "label": "Jabiru - Australia"
    },
    {
      "value": "America/Belem",
      "label": "Jacare-acanga - Brazil"
    },
    {
      "value": "America/Denver",
      "label": "Jacksn Hole - United States"
    },
    {
      "value": "America/Chicago",
      "label": "Jackson - United States"
    },
    {
      "value": "America/Chicago",
      "label": "Jacksonville - United States"
    },
    {
      "value": "America/New_York",
      "label": "Jacksonville NC - United States"
    },
    {
      "value": "America/Port-au-Prince",
      "label": "Jacmel - Haiti"
    },
    {
      "value": "Asia/Karachi",
      "label": "Jacobsbad - Pakistan"
    },
    {
      "value": "Asia/Colombo",
      "label": "Jaffna - Sri Lanka"
    },
    {
      "value": "Asia/Tehran",
      "label": "Jahrom - Iran"
    },
    {
      "value": "Asia/Calcutta",
      "label": "Jaipur - India"
    },
    {
      "value": "Asia/Calcutta",
      "label": "Jaisalmer - India"
    },
    {
      "value": "Asia/Jakarta",
      "label": "Jakarta - Indonesia"
    },
    {
      "value": "Asia/Kabul",
      "label": "Jalalabad - Afghanistan"
    },
    {
      "value": "America/Mexico_City",
      "label": "Jalapa - Mexico"
    },
    {
      "value": "Asia/Jakarta",
      "label": "Jambi - Indonesia"
    },
    {
      "value": "America/Chicago",
      "label": "Jamestown - United States"
    },
    {
      "value": "Asia/Calcutta",
      "label": "Jammu - India"
    },
    {
      "value": "Asia/Calcutta",
      "label": "Jamnagar - India"
    },
    {
      "value": "Asia/Calcutta",
      "label": "Jamshedpur - India"
    },
    {
      "value": "Asia/Katmandu",
      "label": "Janakpur - Nepal"
    },
    {
      "value": "America/Chicago",
      "label": "Janesville - United States"
    },
    {
      "value": "America/Panama",
      "label": "Jaqué - Panama"
    },
    {
      "value": "America/Lima",
      "label": "Jauja - Peru"
    },
    {
      "value": "Asia/Jayapura",
      "label": "Jayapura - Indonesia"
    },
    {
      "value": "America/Chicago",
      "label": "Jefferson City - United States"
    },
    {
      "value": "America/Port-au-Prince",
      "label": "Jeremie - Haiti"
    },
    {
      "value": "Europe/Madrid",
      "label": "Jerez - Spain"
    },
    {
      "value": "Europe/Jersey",
      "label": "Jersey - Jersey"
    },
    {
      "value": "Asia/Dhaka",
      "label": "Jessore - Bangladesh"
    },
    {
      "value": "Asia/Seoul",
      "label": "Jhunju - South Korea"
    },
    {
      "value": "Asia/Shanghai",
      "label": "Jiamusi - China"
    },
    {
      "value": "Asia/Shanghai",
      "label": "Jiayuguan - China"
    },
    {
      "value": "Africa/Algiers",
      "label": "Jijel - Algeria"
    },
    {
      "value": "Africa/Addis_Ababa",
      "label": "Jijiga - Ethiopia"
    },
    {
      "value": "Africa/Addis_Ababa",
      "label": "Jimma - Ethiopia"
    },
    {
      "value": "Asia/Shanghai",
      "label": "Jinan - China"
    },
    {
      "value": "Asia/Shanghai",
      "label": "Jingdezhen - China"
    },
    {
      "value": "Asia/Shanghai",
      "label": "Jinghonggasa - China"
    },
    {
      "value": "Asia/Shanghai",
      "label": "Jining - China"
    },
    {
      "value": "Asia/Shanghai",
      "label": "Jinzhou - China"
    },
    {
      "value": "America/Boa_Vista",
      "label": "Ji-Paraná - Brazil"
    },
    {
      "value": "Asia/Tehran",
      "label": "Jiroft - Iran"
    },
    {
      "value": "Asia/Shanghai",
      "label": "Jiujiang - China"
    },
    {
      "value": "Asia/Shanghai",
      "label": "Jiuzhaigou - China"
    },
    {
      "value": "Asia/Karachi",
      "label": "Jiwani - Pakistan"
    },
    {
      "value": "Asia/Shanghai",
      "label": "Jixi - China"
    },
    {
      "value": "America/Sao_Paulo",
      "label": "Joacaba - Brazil"
    },
    {
      "value": "America/Fortaleza",
      "label": "Joao Pessoa - Brazil"
    },
    {
      "value": "Asia/Calcutta",
      "label": "Jodhpur - India"
    },
    {
      "value": "Europe/Stockholm",
      "label": "Joenkoeping - Sweden"
    },
    {
      "value": "Europe/Helsinki",
      "label": "Joensuu - Finland"
    },
    {
      "value": "Africa/Johannesburg",
      "label": "Johannesburg - South Africa"
    },
    {
      "value": "Pacific/Johnston",
      "label": "Johnston Island - Johnston Atoll"
    },
    {
      "value": "America/New_York",
      "label": "Johnstown - United States"
    },
    {
      "value": "Asia/Kuala_Lumpur",
      "label": "Johor Bahru - Malaysia"
    },
    {
      "value": "America/Sao_Paulo",
      "label": "Joinville - Brazil"
    },
    {
      "value": "America/Chicago",
      "label": "Joliet - United States"
    },
    {
      "value": "Asia/Manila",
      "label": "Jolo - Philippines"
    },
    {
      "value": "Asia/Katmandu",
      "label": "Jomsom - Nepal"
    },
    {
      "value": "America/Chicago",
      "label": "Jonesboro - United States"
    },
    {
      "value": "America/Chicago",
      "label": "Joplin - United States"
    },
    {
      "value": "Asia/Calcutta",
      "label": "Jorhat - India"
    },
    {
      "value": "Africa/Lagos",
      "label": "Jos - Nigeria"
    },
    {
      "value": "America/Catamarca",
      "label": "Jose de San Martin - Argentina"
    },
    {
      "value": "America/Lima",
      "label": "Juanjui - Peru"
    },
    {
      "value": "America/Fortaleza",
      "label": "Juazeiro Do Norte - Brazil"
    },
    {
      "value": "Africa/Juba",
      "label": "Juba - South Sudan"
    },
    {
      "value": "Asia/Riyadh",
      "label": "Jubail - Saudi Arabia"
    },
    {
      "value": "Europe/Berlin",
      "label": "Juist - Germany"
    },
    {
      "value": "America/Sao_Paulo",
      "label": "Juiz De Fora - Brazil"
    },
    {
      "value": "America/Jujuy",
      "label": "Jujuy - Argentina"
    },
    {
      "value": "Australia/Brisbane",
      "label": "Julia Creek - Australia"
    },
    {
      "value": "America/Lima",
      "label": "Juliaca - Peru"
    },
    {
      "value": "Asia/Katmandu",
      "label": "Jumla - Nepal"
    },
    {
      "value": "America/Anchorage",
      "label": "Juneau - United States"
    },
    {
      "value": "America/Buenos_Aires",
      "label": "Junin - Argentina"
    },
    {
      "value": "Africa/Gaborone",
      "label": "Jwaneng - Botswana"
    },
    {
      "value": "Europe/Helsinki",
      "label": "Jyvaskyla - Finland"
    },
    {
      "value": "Indian/Maldives",
      "label": "Kaadedhdhoo - Maldives"
    },
    {
      "value": "Africa/Addis_Ababa",
      "label": "Kabri Dehar - Ethiopia"
    },
    {
      "value": "Asia/Kabul",
      "label": "Kabul - Afghanistan"
    },
    {
      "value": "Asia/Tokyo",
      "label": "Kadena - Japan"
    },
    {
      "value": "Africa/Khartoum",
      "label": "Kadugli - Sudan"
    },
    {
      "value": "Africa/Lagos",
      "label": "Kaduna - Nigeria"
    },
    {
      "value": "Africa/Nouakchott",
      "label": "Kaedi - Mauritania"
    },
    {
      "value": "Pacific/Guadalcanal",
      "label": "Kagau Island - Solomon Islands"
    },
    {
      "value": "Asia/Tokyo",
      "label": "Kagoshima - Japan"
    },
    {
      "value": "Europe/Istanbul",
      "label": "Kahramanmaras - Turkey"
    },
    {
      "value": "Pacific/Honolulu",
      "label": "Kahului - United States"
    },
    {
      "value": "America/Guyana",
      "label": "Kaieteur - Guyana"
    },
    {
      "value": "Pacific/Auckland",
      "label": "Kaikoura - New Zealand"
    },
    {
      "value": "Asia/Calcutta",
      "label": "Kailashahar - India"
    },
    {
      "value": "Asia/Jayapura",
      "label": "Kaimana - Indonesia"
    },
    {
      "value": "America/Chicago",
      "label": "Kaiser Lake Ozark - United States"
    },
    {
      "value": "Pacific/Auckland",
      "label": "Kaitaia - New Zealand"
    },
    {
      "value": "Europe/Helsinki",
      "label": "Kajaani - Finland"
    },
    {
      "value": "Africa/Nairobi",
      "label": "Kakamega - Kenya"
    },
    {
      "value": "Asia/Tehran",
      "label": "Kalaleh - Iran"
    },
    {
      "value": "Europe/Athens",
      "label": "Kalamata - Greece"
    },
    {
      "value": "America/New_York",
      "label": "Kalamazoo - United States"
    },
    {
      "value": "Australia/Perth",
      "label": "Kalbarri - Australia"
    },
    {
      "value": "Africa/Lubumbashi",
      "label": "Kalemie - Congo (Kinshasa)"
    },
    {
      "value": "Asia/Rangoon",
      "label": "Kalemyo - Myanmar"
    },
    {
      "value": "Australia/Perth",
      "label": "Kalgoorlie - Australia"
    },
    {
      "value": "Asia/Manila",
      "label": "Kalibo - Philippines"
    },
    {
      "value": "Europe/Kaliningrad",
      "label": "Kaliningrad - Russia"
    },
    {
      "value": "America/Denver",
      "label": "Kalispell - United States"
    },
    {
      "value": "Australia/Darwin",
      "label": "Kalkgurung - Australia"
    },
    {
      "value": "Europe/Stockholm",
      "label": "Kalkmar - Sweden"
    },
    {
      "value": "America/Anchorage",
      "label": "Kalskag - United States"
    },
    {
      "value": "America/Anchorage",
      "label": "Kaltag - United States"
    },
    {
      "value": "Europe/Moscow",
      "label": "Kaluga - Russia"
    },
    {
      "value": "Europe/Athens",
      "label": "Kalymnos - Greece"
    },
    {
      "value": "America/Guyana",
      "label": "Kamarang - Guyana"
    },
    {
      "value": "Africa/Kigali",
      "label": "Kamembe - Rwanda"
    },
    {
      "value": "Africa/Lubumbashi",
      "label": "Kamina Base - Congo (Kinshasa)"
    },
    {
      "value": "Asia/Damascus",
      "label": "Kamishly - Syria"
    },
    {
      "value": "America/Vancouver",
      "label": "Kamloops - Canada"
    },
    {
      "value": "Pacific/Honolulu",
      "label": "Kamuela - United States"
    },
    {
      "value": "Africa/Lubumbashi",
      "label": "Kananga - Congo (Kinshasa)"
    },
    {
      "value": "Asia/Tokyo",
      "label": "Kanazawa - Japan"
    },
    {
      "value": "Asia/Kabul",
      "label": "Kandahar - Afghanistan"
    },
    {
      "value": "Asia/Calcutta",
      "label": "Kandla - India"
    },
    {
      "value": "Pacific/Honolulu",
      "label": "Kaneohe Bay - United States"
    },
    {
      "value": "Asia/Shanghai",
      "label": "Kangding - China"
    },
    {
      "value": "America/Toronto",
      "label": "Kangiqsualujjuaq - Canada"
    },
    {
      "value": "America/Toronto",
      "label": "Kangiqsujuaq - Canada"
    },
    {
      "value": "America/Toronto",
      "label": "Kangirsuk - Canada"
    },
    {
      "value": "Asia/Seoul",
      "label": "Kangnung - South Korea"
    },
    {
      "value": "Asia/Calcutta",
      "label": "Kangra - India"
    },
    {
      "value": "America/Chicago",
      "label": "Kankakee - United States"
    },
    {
      "value": "Africa/Lagos",
      "label": "Kano - Nigeria"
    },
    {
      "value": "Asia/Calcutta",
      "label": "Kanpur - India"
    },
    {
      "value": "America/Chicago",
      "label": "Kansas City - United States"
    },
    {
      "value": "Asia/Taipei",
      "label": "Kaohsiung - Taiwan"
    },
    {
      "value": "Africa/Dakar",
      "label": "Kaolack - Senegal"
    },
    {
      "value": "Pacific/Honolulu",
      "label": "Kapolei - United States"
    },
    {
      "value": "America/Toronto",
      "label": "Kapuskasing - Canada"
    },
    {
      "value": "Asia/Karachi",
      "label": "Karachi - Pakistan"
    },
    {
      "value": "Asia/Qyzylorda",
      "label": "Karaganda - Kazakhstan"
    },
    {
      "value": "Asia/Shanghai",
      "label": "Karamay - China"
    },
    {
      "value": "Europe/Tallinn",
      "label": "Kardla - Estonia"
    },
    {
      "value": "Africa/Harare",
      "label": "Kariba - Zimbabwe"
    },
    {
      "value": "Europe/Prague",
      "label": "Karlovy Vary - Czech Republic"
    },
    {
      "value": "Europe/Stockholm",
      "label": "Karlskoga - Sweden"
    },
    {
      "value": "Europe/Berlin",
      "label": "Karlsruhe/Baden-Baden - Germany"
    },
    {
      "value": "Europe/Stockholm",
      "label": "Karlstad - Sweden"
    },
    {
      "value": "America/Anchorage",
      "label": "Karluk - United States"
    },
    {
      "value": "Africa/Blantyre",
      "label": "Karonga - Malawi"
    },
    {
      "value": "Europe/Athens",
      "label": "Karpathos - Greece"
    },
    {
      "value": "Australia/Perth",
      "label": "Karratha - Australia"
    },
    {
      "value": "Europe/Istanbul",
      "label": "Kars - Turkey"
    },
    {
      "value": "Australia/Brisbane",
      "label": "Karumba - Australia"
    },
    {
      "value": "Europe/Copenhagen",
      "label": "Karup - Denmark"
    },
    {
      "value": "Africa/Lusaka",
      "label": "Kasaba Bay - Zambia"
    },
    {
      "value": "America/Toronto",
      "label": "Kasabonika - Canada"
    },
    {
      "value": "Africa/Lusaka",
      "label": "Kasama - Zambia"
    },
    {
      "value": "Africa/Gaborone",
      "label": "Kasane - Botswana"
    },
    {
      "value": "America/Toronto",
      "label": "Kashechewan - Canada"
    },
    {
      "value": "Asia/Shanghai",
      "label": "Kashi - China"
    },
    {
      "value": "America/Anchorage",
      "label": "Kasigluk - United States"
    },
    {
      "value": "Africa/Lusaka",
      "label": "Kasompe - Zambia"
    },
    {
      "value": "Europe/Athens",
      "label": "Kasos - Greece"
    },
    {
      "value": "Africa/Khartoum",
      "label": "Kassala - Sudan"
    },
    {
      "value": "Europe/Berlin",
      "label": "Kassel - Germany"
    },
    {
      "value": "Europe/Istanbul",
      "label": "Kastamonu - Turkey"
    },
    {
      "value": "Europe/Athens",
      "label": "Kastelorizo - Greece"
    },
    {
      "value": "Europe/Athens",
      "label": "Kastoria - Greece"
    },
    {
      "value": "Africa/Blantyre",
      "label": "Kasungu - Malawi"
    },
    {
      "value": "Australia/Darwin",
      "label": "Katherine - Australia"
    },
    {
      "value": "Asia/Katmandu",
      "label": "Kathmandu - Nepal"
    },
    {
      "value": "Europe/Helsinki",
      "label": "Kauhajoki - Finland"
    },
    {
      "value": "Europe/Helsinki",
      "label": "Kauhava - Finland"
    },
    {
      "value": "Pacific/Tahiti",
      "label": "Kaukura Atoll - French Polynesia"
    },
    {
      "value": "Europe/Vilnius",
      "label": "Kaunas - Lithuania"
    },
    {
      "value": "Asia/Vladivostok",
      "label": "Kavalerovo - Russia"
    },
    {
      "value": "Pacific/Port_Moresby",
      "label": "Kavieng - Papua New Guinea"
    },
    {
      "value": "America/Havana",
      "label": "Kawama - Cuba"
    },
    {
      "value": "Asia/Rangoon",
      "label": "Kawthoung - Burma"
    },
    {
      "value": "Africa/Bamako",
      "label": "Kayes - Mali"
    },
    {
      "value": "Europe/Istanbul",
      "label": "Kayseri - Turkey"
    },
    {
      "value": "Europe/Moscow",
      "label": "Kazan - Russia"
    },
    {
      "value": "Africa/Dakar",
      "label": "Kedougou - Senegal"
    },
    {
      "value": "America/New_York",
      "label": "Keene - United States"
    },
    {
      "value": "Africa/Windhoek",
      "label": "Keetmanshoop - Namibia"
    },
    {
      "value": "America/Winnipeg",
      "label": "Keewaywin - Canada"
    },
    {
      "value": "Europe/Athens",
      "label": "Keffallinia - Greece"
    },
    {
      "value": "Atlantic/Reykjavik",
      "label": "Keflavik - Iceland"
    },
    {
      "value": "America/Blanc-Sablon",
      "label": "Kegaska - Canada"
    },
    {
      "value": "America/Vancouver",
      "label": "Kelowna - Canada"
    },
    {
      "value": "America/Los_Angeles",
      "label": "Kelso - United States"
    },
    {
      "value": "Europe/Helsinki",
      "label": "Kemi - Finland"
    },
    {
      "value": "Asia/Krasnoyarsk",
      "label": "Kemorovo - Russia"
    },
    {
      "value": "America/Chicago",
      "label": "Kempten - Germany"
    },
    {
      "value": "America/Anchorage",
      "label": "Kenai - United States"
    },
    {
      "value": "America/New_York",
      "label": "Kendall-tamiami - United States"
    },
    {
      "value": "Asia/Makassar",
      "label": "Kendari - Indonesia"
    },
    {
      "value": "Africa/Freetown",
      "label": "Kenema - Sierra Leone"
    },
    {
      "value": "Asia/Rangoon",
      "label": "Kengtung - Burma"
    },
    {
      "value": "America/Winnipeg",
      "label": "Kenora - Canada"
    },
    {
      "value": "America/Chicago",
      "label": "Kenosha - United States"
    },
    {
      "value": "Africa/Casablanca",
      "label": "Kentira - Morocco"
    },
    {
      "value": "America/Chicago",
      "label": "Keokuk - United States"
    },
    {
      "value": "Asia/Tokyo",
      "label": "Kerama - Japan"
    },
    {
      "value": "Europe/Simferopol",
      "label": "Kerch - Ukraine"
    },
    {
      "value": "Pacific/Port_Moresby",
      "label": "Kerema - Papua New Guinea"
    },
    {
      "value": "Africa/Nairobi",
      "label": "Kericho - Kenya"
    },
    {
      "value": "Pacific/Auckland",
      "label": "Kerikeri - New Zealand"
    },
    {
      "value": "Europe/Athens",
      "label": "Kerkyra/corfu - Greece"
    },
    {
      "value": "Asia/Tehran",
      "label": "Kerman - Iran"
    },
    {
      "value": "America/Chicago",
      "label": "Kerrville - United States"
    },
    {
      "value": "Europe/Dublin",
      "label": "Kerry - Ireland"
    },
    {
      "value": "Asia/Kuala_Lumpur",
      "label": "Kerteh - Malaysia"
    },
    {
      "value": "Asia/Calcutta",
      "label": "Keshod - India"
    },
    {
      "value": "Asia/Jakarta",
      "label": "Ketapang - Indonesia"
    },
    {
      "value": "America/Anchorage",
      "label": "Ketchikan - United States"
    },
    {
      "value": "America/New_York",
      "label": "Key West - United States"
    },
    {
      "value": "Asia/Vladivostok",
      "label": "Khabarovsk - Russia"
    },
    {
      "value": "Asia/Calcutta",
      "label": "Khajuraho - India"
    },
    {
      "value": "Asia/Tehran",
      "label": "Khalije Fars - Iran"
    },
    {
      "value": "Asia/Rangoon",
      "label": "Khamti - Burma"
    },
    {
      "value": "Asia/Samarkand",
      "label": "Khanabad - Uzbekistan"
    },
    {
      "value": "Asia/Yekaterinburg",
      "label": "Khanty-Mansiysk - Russia"
    },
    {
      "value": "Asia/Tehran",
      "label": "Khark Island - Iran"
    },
    {
      "value": "Europe/Kiev",
      "label": "Kharkov - Ukraine"
    },
    {
      "value": "Africa/Khartoum",
      "label": "Khartoum - Sudan"
    },
    {
      "value": "Asia/Muscat",
      "label": "Khasab - Oman"
    },
    {
      "value": "Asia/Krasnoyarsk",
      "label": "Khatanga - Russia"
    },
    {
      "value": "Europe/Kiev",
      "label": "Kherson - Ukraine"
    },
    {
      "value": "Europe/Kiev",
      "label": "Khmeinitskiy - Ukraine"
    },
    {
      "value": "Asia/Bangkok",
      "label": "Khon Kaen - Thailand"
    },
    {
      "value": "Asia/Tehran",
      "label": "Khorram Abad - Iran"
    },
    {
      "value": "Asia/Kabul",
      "label": "Khost - Afghanistan"
    },
    {
      "value": "Asia/Hovd",
      "label": "Khovd - Mongolia"
    },
    {
      "value": "Asia/Tehran",
      "label": "Khoy - Iran"
    },
    {
      "value": "Asia/Dushanbe",
      "label": "Khudzhand - Tajikistan"
    },
    {
      "value": "Asia/Karachi",
      "label": "Khuzdar - Pakistan"
    },
    {
      "value": "Africa/Gaborone",
      "label": "Khwai River - Botswana"
    },
    {
      "value": "America/Anchorage",
      "label": "Kiana - United States"
    },
    {
      "value": "Europe/Berlin",
      "label": "Kiel - Germany"
    },
    {
      "value": "Europe/Kiev",
      "label": "Kiev - Ukraine"
    },
    {
      "value": "Africa/Nouakchott",
      "label": "Kiffa - Mauritania"
    },
    {
      "value": "Africa/Kigali",
      "label": "Kigali - Rwanda"
    },
    {
      "value": "Africa/Dar_es_Salaam",
      "label": "Kigoma - Tanzania"
    },
    {
      "value": "Asia/Tokyo",
      "label": "Kikai - Japan"
    },
    {
      "value": "Pacific/Port_Moresby",
      "label": "Kikori - Papua New Guinea"
    },
    {
      "value": "Africa/Kinshasa",
      "label": "Kikwit - Congo (Kinshasa)"
    },
    {
      "value": "Africa/Nairobi",
      "label": "Kilaguni - Kenya"
    },
    {
      "value": "Pacific/Majuro",
      "label": "Kili Island - Marshall Islands"
    },
    {
      "value": "Africa/Dar_es_Salaam",
      "label": "Kilimanjaro - Tanzania"
    },
    {
      "value": "America/New_York",
      "label": "Kill Devil Hills - United States"
    },
    {
      "value": "Africa/Johannesburg",
      "label": "Kimberley - South Africa"
    },
    {
      "value": "America/Toronto",
      "label": "Kimmirut - Canada"
    },
    {
      "value": "America/Regina",
      "label": "Kindersley - Canada"
    },
    {
      "value": "Africa/Lubumbashi",
      "label": "Kindu - Congo (Kinshasa)"
    },
    {
      "value": "America/Anchorage",
      "label": "King Cove - United States"
    },
    {
      "value": "Australia/Melbourne",
      "label": "King Island - Australia"
    },
    {
      "value": "Asia/Riyadh",
      "label": "King Khalid Mil.city - Saudi Arabia"
    },
    {
      "value": "America/Anchorage",
      "label": "King Salmon - United States"
    },
    {
      "value": "America/Toronto",
      "label": "Kingfisher Lake - Canada"
    },
    {
      "value": "Australia/Adelaide",
      "label": "Kingscote - Australia"
    },
    {
      "value": "America/Toronto",
      "label": "Kingston - Canada"
    },
    {
      "value": "America/Jamaica",
      "label": "Kingston - Jamaica"
    },
    {
      "value": "America/St_Vincent",
      "label": "Kingstown - Saint Vincent and the Grenadines"
    },
    {
      "value": "America/Chicago",
      "label": "Kingsville - United States"
    },
    {
      "value": "Asia/Taipei",
      "label": "Kinmen - Taiwan"
    },
    {
      "value": "Africa/Kinshasa",
      "label": "Kinshasa - Congo (Kinshasa)"
    },
    {
      "value": "America/New_York",
      "label": "Kinston - United States"
    },
    {
      "value": "America/Anchorage",
      "label": "Kipnuk - United States"
    },
    {
      "value": "Pacific/Guadalcanal",
      "label": "Kirakira - Solomon Islands"
    },
    {
      "value": "Asia/Irkutsk",
      "label": "Kirensk - Russia"
    },
    {
      "value": "Africa/Kinshasa",
      "label": "Kiri - Congo (Kinshasa)"
    },
    {
      "value": "Europe/Oslo",
      "label": "Kirkenes - Norway"
    },
    {
      "value": "America/Toronto",
      "label": "Kirkland Lake - Canada"
    },
    {
      "value": "America/Chicago",
      "label": "Kirksville - United States"
    },
    {
      "value": "Asia/Baghdad",
      "label": "Kirkuk - Iraq"
    },
    {
      "value": "Europe/London",
      "label": "Kirkwall - United Kingdom"
    },
    {
      "value": "Europe/Moscow",
      "label": "Kirov - Russia"
    },
    {
      "value": "Europe/Kiev",
      "label": "Kirovograd - Ukraine"
    },
    {
      "value": "Europe/Stockholm",
      "label": "Kiruna - Sweden"
    },
    {
      "value": "Africa/Lubumbashi",
      "label": "Kisangani - Congo (Kinshasa)"
    },
    {
      "value": "Asia/Tehran",
      "label": "Kish Island - Iran"
    },
    {
      "value": "Africa/Mogadishu",
      "label": "Kismayu - Somalia"
    },
    {
      "value": "Africa/Conakry",
      "label": "Kissidougou - Guinea"
    },
    {
      "value": "America/New_York",
      "label": "Kissimmee - United States"
    },
    {
      "value": "Africa/Nairobi",
      "label": "Kisumu - Kenya"
    },
    {
      "value": "Asia/Tokyo",
      "label": "Kitadaito - Japan"
    },
    {
      "value": "Asia/Tokyo",
      "label": "Kitakyushu - Japan"
    },
    {
      "value": "Africa/Nairobi",
      "label": "Kitale - Kenya"
    },
    {
      "value": "Europe/Helsinki",
      "label": "Kitee - Finland"
    },
    {
      "value": "Europe/Athens",
      "label": "Kithira - Greece"
    },
    {
      "value": "Europe/Helsinki",
      "label": "Kittila - Finland"
    },
    {
      "value": "Europe/Berlin",
      "label": "Kitzingen - Germany"
    },
    {
      "value": "Pacific/Port_Moresby",
      "label": "Kiunga - Papua New Guinea"
    },
    {
      "value": "America/Anchorage",
      "label": "Kivalina - United States"
    },
    {
      "value": "Europe/Vienna",
      "label": "Klagenfurt - Austria"
    },
    {
      "value": "America/Los_Angeles",
      "label": "Klamath Falls - United States"
    },
    {
      "value": "America/Anchorage",
      "label": "Klawock - United States"
    },
    {
      "value": "Asia/Beirut",
      "label": "Kleiat - Lebanon"
    },
    {
      "value": "Africa/Johannesburg",
      "label": "Kleinsee - South Africa"
    },
    {
      "value": "Africa/Johannesburg",
      "label": "Klerksdorp - South Africa"
    },
    {
      "value": "America/Chicago",
      "label": "Knobnoster - United States"
    },
    {
      "value": "America/New_York",
      "label": "Knoxville - United States"
    },
    {
      "value": "Asia/Bangkok",
      "label": "Ko Samui - Thailand"
    },
    {
      "value": "Asia/Tokyo",
      "label": "Kobe - Japan"
    },
    {
      "value": "America/Anchorage",
      "label": "Kobuk - United States"
    },
    {
      "value": "Asia/Calcutta",
      "label": "Kochi - India"
    },
    {
      "value": "Asia/Tokyo",
      "label": "Kochi - Japan"
    },
    {
      "value": "America/Anchorage",
      "label": "Kodiak - United States"
    },
    {
      "value": "Europe/Berlin",
      "label": "Koethen - Germany"
    },
    {
      "value": "Asia/Yekaterinburg",
      "label": "Kogalym - Russia"
    },
    {
      "value": "Asia/Colombo",
      "label": "Koggala - Sri Lanka"
    },
    {
      "value": "Asia/Qyzylorda",
      "label": "Kokshetau - Kazakhstan"
    },
    {
      "value": "Asia/Calcutta",
      "label": "Kolhapur - India"
    },
    {
      "value": "America/Anchorage",
      "label": "Koliganek - United States"
    },
    {
      "value": "Asia/Calcutta",
      "label": "Kolkata - India"
    },
    {
      "value": "Africa/Lubumbashi",
      "label": "Kolwezi - Congo (Kinshasa)"
    },
    {
      "value": "Asia/Phnom_Penh",
      "label": "Kompong Chnang - Cambodia"
    },
    {
      "value": "Asia/Vladivostok",
      "label": "Komsomolsk-on-Amur - Russia"
    },
    {
      "value": "Pacific/Honolulu",
      "label": "Kona - United States"
    },
    {
      "value": "Pacific/Noumea",
      "label": "Kone - New Caledonia"
    },
    {
      "value": "America/Anchorage",
      "label": "Kongiganak - United States"
    },
    {
      "value": "Europe/Istanbul",
      "label": "Konya - Turkey"
    },
    {
      "value": "Indian/Maldives",
      "label": "Kooddoo - Maldives"
    },
    {
      "value": "Africa/Abidjan",
      "label": "Korhogo - Cote d'Ivoire"
    },
    {
      "value": "Asia/Shanghai",
      "label": "Korla - China"
    },
    {
      "value": "Pacific/Fiji",
      "label": "Koro Island - Fiji"
    },
    {
      "value": "Europe/Brussels",
      "label": "Kortrijk-vevelgem - Belgium"
    },
    {
      "value": "Europe/Athens",
      "label": "Kos - Greece"
    },
    {
      "value": "Europe/Bratislava",
      "label": "Kosice - Slovakia"
    },
    {
      "value": "Pacific/Kosrae",
      "label": "Kosrae - Micronesia"
    },
    {
      "value": "Asia/Qyzylorda",
      "label": "Kostanay - Kazakhstan"
    },
    {
      "value": "Europe/Moscow",
      "label": "Kostroma - Russia"
    },
    {
      "value": "Europe/Warsaw",
      "label": "Koszalin - Poland"
    },
    {
      "value": "Asia/Calcutta",
      "label": "Kota - India"
    },
    {
      "value": "Asia/Kuala_Lumpur",
      "label": "Kota Bahru - Malaysia"
    },
    {
      "value": "Asia/Kuala_Lumpur",
      "label": "Kota Kinabalu - Malaysia"
    },
    {
      "value": "Africa/Kinshasa",
      "label": "Kotakoli - Congo (Kinshasa)"
    },
    {
      "value": "Europe/Moscow",
      "label": "Kotlas - Russia"
    },
    {
      "value": "America/Anchorage",
      "label": "Kotlik - United States"
    },
    {
      "value": "America/Anchorage",
      "label": "Kotzebue - United States"
    },
    {
      "value": "Africa/Libreville",
      "label": "Koulamoutou - Gabon"
    },
    {
      "value": "Pacific/Noumea",
      "label": "Koumac - New Caledonia"
    },
    {
      "value": "Australia/Brisbane",
      "label": "Kowanyama - Australia"
    },
    {
      "value": "America/Anchorage",
      "label": "Koyuk - United States"
    },
    {
      "value": "America/Anchorage",
      "label": "Koyukuk - United States"
    },
    {
      "value": "Europe/Athens",
      "label": "Kozani - Greece"
    },
    {
      "value": "Asia/Bangkok",
      "label": "Krabi - Thailand"
    },
    {
      "value": "Europe/Warsaw",
      "label": "Krakow - Poland"
    },
    {
      "value": "America/Curacao",
      "label": "Kralendijk - Netherlands Antilles"
    },
    {
      "value": "Europe/Stockholm",
      "label": "Kramfors - Sweden"
    },
    {
      "value": "Europe/Moscow",
      "label": "Krasnodar - Russia"
    },
    {
      "value": "Asia/Ashgabat",
      "label": "Krasnovodsk - Turkmenistan"
    },
    {
      "value": "Asia/Krasnoyarsk",
      "label": "Krasnoyarsk - Russia"
    },
    {
      "value": "Asia/Phnom_Penh",
      "label": "Kratie - Cambodia"
    },
    {
      "value": "Europe/Oslo",
      "label": "Kristiansand - Norway"
    },
    {
      "value": "Europe/Stockholm",
      "label": "Kristianstad - Sweden"
    },
    {
      "value": "Europe/Oslo",
      "label": "Kristiansund - Norway"
    },
    {
      "value": "Europe/Kiev",
      "label": "Krivoy Rog - Ukraine"
    },
    {
      "value": "Europe/Helsinki",
      "label": "Kruunupyy - Finland"
    },
    {
      "value": "Asia/Kuala_Lumpur",
      "label": "Kuala Lumpur - Malaysia"
    },
    {
      "value": "Asia/Kuala_Lumpur",
      "label": "Kuala Terengganu - Malaysia"
    },
    {
      "value": "Asia/Kuala_Lumpur",
      "label": "Kuantan - Malaysia"
    },
    {
      "value": "Australia/Brisbane",
      "label": "Kubin - Australia"
    },
    {
      "value": "Asia/Kuala_Lumpur",
      "label": "Kuching - Malaysia"
    },
    {
      "value": "Asia/Kuala_Lumpur",
      "label": "Kudat - Malaysia"
    },
    {
      "value": "Africa/Tripoli",
      "label": "Kufra - Libya"
    },
    {
      "value": "Africa/Luanda",
      "label": "Kuito - Angola"
    },
    {
      "value": "Asia/Calcutta",
      "label": "Kulu - India"
    },
    {
      "value": "Asia/Dushanbe",
      "label": "Kulyab - Tajikistan"
    },
    {
      "value": "Asia/Tokyo",
      "label": "Kumamoto - Japan"
    },
    {
      "value": "Africa/Accra",
      "label": "Kumasi - Ghana"
    },
    {
      "value": "Asia/Tokyo",
      "label": "Kumejima - Japan"
    },
    {
      "value": "Pacific/Port_Moresby",
      "label": "Kundiawa - Papua New Guinea"
    },
    {
      "value": "Asia/Kabul",
      "label": "Kunduz - Afghanistan"
    },
    {
      "value": "Asia/Shanghai",
      "label": "Kunming - China"
    },
    {
      "value": "Europe/Prague",
      "label": "Kunovice - Czech Republic"
    },
    {
      "value": "Asia/Seoul",
      "label": "Kunsan - South Korea"
    },
    {
      "value": "Australia/Perth",
      "label": "Kununurra - Australia"
    },
    {
      "value": "Europe/Helsinki",
      "label": "Kuopio - Finland"
    },
    {
      "value": "Asia/Makassar",
      "label": "Kupang - Indonesia"
    },
    {
      "value": "America/Anchorage",
      "label": "Kuparuk - United States"
    },
    {
      "value": "Asia/Shanghai",
      "label": "Kuqa - China"
    },
    {
      "value": "Europe/Tallinn",
      "label": "Kuressaare - Estonia"
    },
    {
      "value": "Asia/Yekaterinburg",
      "label": "Kurgan - Russia"
    },
    {
      "value": "Asia/Dushanbe",
      "label": "Kurgan Tyube - Tajikistan"
    },
    {
      "value": "Pacific/Tarawa",
      "label": "Kuria - Kiribati"
    },
    {
      "value": "Europe/Moscow",
      "label": "Kursk - Russia"
    },
    {
      "value": "Africa/Johannesburg",
      "label": "Kuruman - South Africa"
    },
    {
      "value": "Asia/Tokyo",
      "label": "Kushiro - Japan"
    },
    {
      "value": "Asia/Tbilisi",
      "label": "Kutaisi - Georgia"
    },
    {
      "value": "America/Toronto",
      "label": "Kuujjuarapik - Canada"
    },
    {
      "value": "Europe/Helsinki",
      "label": "Kuusamo - Finland"
    },
    {
      "value": "Asia/Kuwait",
      "label": "Kuwait - Kuwait"
    },
    {
      "value": "Asia/Seoul",
      "label": "Kwangju - South Korea"
    },
    {
      "value": "America/Anchorage",
      "label": "Kwethluk - United States"
    },
    {
      "value": "America/Anchorage",
      "label": "Kwigillingok - United States"
    },
    {
      "value": "Asia/Rangoon",
      "label": "Kyaukpyu - Burma"
    },
    {
      "value": "Asia/Krasnoyarsk",
      "label": "Kyzyl - Russia"
    },
    {
      "value": "Asia/Qyzylorda",
      "label": "Kzyl-Orda - Kazakhstan"
    },
    {
      "value": "Europe/Paris",
      "label": "La Baule - France"
    },
    {
      "value": "America/Tegucigalpa",
      "label": "La Ceiba - Honduras"
    },
    {
      "value": "America/Havana",
      "label": "La Coloma - Cuba"
    },
    {
      "value": "Europe/Madrid",
      "label": "La Coruna - Spain"
    },
    {
      "value": "America/Chicago",
      "label": "La Crosse - United States"
    },
    {
      "value": "America/Costa_Rica",
      "label": "La Fortuna/San Carlos - Costa Rica"
    },
    {
      "value": "America/Caracas",
      "label": "La Fria - Venezuela"
    },
    {
      "value": "Atlantic/Canary",
      "label": "La Gomera - Spain"
    },
    {
      "value": "America/Toronto",
      "label": "La Grande Riviere - Canada"
    },
    {
      "value": "America/Santo_Domingo",
      "label": "La Isabela - Dominican Republic"
    },
    {
      "value": "Europe/Paris",
      "label": "La Môle - France"
    },
    {
      "value": "America/Bogota",
      "label": "La Mina - Colombia"
    },
    {
      "value": "America/Panama",
      "label": "La Palma - Panama"
    },
    {
      "value": "America/La_Paz",
      "label": "La Paz - Bolivia"
    },
    {
      "value": "America/Mazatlan",
      "label": "La Paz - Mexico"
    },
    {
      "value": "America/Bogota",
      "label": "La Pedrera - Colombia"
    },
    {
      "value": "America/Buenos_Aires",
      "label": "La Plata - Argentina"
    },
    {
      "value": "America/Argentina/La_Rioja",
      "label": "La Rioja - Argentina"
    },
    {
      "value": "Europe/Paris",
      "label": "La Roche-sur-yon - France"
    },
    {
      "value": "America/Blanc-Sablon",
      "label": "La Romaine - Canada"
    },
    {
      "value": "America/Santo_Domingo",
      "label": "La Romana - Dominican Republic"
    },
    {
      "value": "America/Regina",
      "label": "La Ronge - Canada"
    },
    {
      "value": "America/Santiago",
      "label": "La Serena - Chile"
    },
    {
      "value": "America/Blanc-Sablon",
      "label": "La Tabatière - Canada"
    },
    {
      "value": "America/Guayaquil",
      "label": "La Toma (Catamayo) - Ecuador"
    },
    {
      "value": "America/Los_Angeles",
      "label": "La Verne - United States"
    },
    {
      "value": "Europe/Berlin",
      "label": "Laage - Germany"
    },
    {
      "value": "Indian/Maldives",
      "label": "Laamu Atoll - Maldives"
    },
    {
      "value": "Africa/Conakry",
      "label": "Labe - Guinea"
    },
    {
      "value": "America/Boa_Vista",
      "label": "Labrea - Brazil"
    },
    {
      "value": "Asia/Kuala_Lumpur",
      "label": "Labuan - Malaysia"
    },
    {
      "value": "Asia/Makassar",
      "label": "Labuhan Bajo - Indonesia"
    },
    {
      "value": "America/Winnipeg",
      "label": "Lac Brochet - Canada"
    },
    {
      "value": "Africa/Johannesburg",
      "label": "Ladysmith - South Africa"
    },
    {
      "value": "Europe/Copenhagen",
      "label": "Laeso - Denmark"
    },
    {
      "value": "America/Chicago",
      "label": "Lafayette - United States"
    },
    {
      "value": "Africa/Algiers",
      "label": "Laghouat - Algeria"
    },
    {
      "value": "Africa/Lagos",
      "label": "Lagos - Nigeria"
    },
    {
      "value": "America/New_York",
      "label": "LaGrange - United States"
    },
    {
      "value": "Asia/Kuala_Lumpur",
      "label": "Lahad Datu - Malaysia"
    },
    {
      "value": "Pacific/Honolulu",
      "label": "Lahania-kapalua - United States"
    },
    {
      "value": "Asia/Karachi",
      "label": "Lahore - Pakistan"
    },
    {
      "value": "Europe/Berlin",
      "label": "Lahr - Germany"
    },
    {
      "value": "America/Sao_Paulo",
      "label": "Lajes - Brazil"
    },
    {
      "value": "Atlantic/Azores",
      "label": "Lajes (terceira Island) - Portugal"
    },
    {
      "value": "America/Chicago",
      "label": "Lake Charles - United States"
    },
    {
      "value": "America/New_York",
      "label": "Lake City - United States"
    },
    {
      "value": "Australia/Darwin",
      "label": "Lake Evella - Australia"
    },
    {
      "value": "America/Phoenix",
      "label": "Lake Havasu City - United States"
    },
    {
      "value": "Australia/Sydney",
      "label": "Lake Macquarie - Australia"
    },
    {
      "value": "Africa/Dar_es_Salaam",
      "label": "Lake Manyara - Tanzania"
    },
    {
      "value": "America/Anchorage",
      "label": "Lake Minchumina - United States"
    },
    {
      "value": "America/New_York",
      "label": "Lake Placid - United States"
    },
    {
      "value": "Pacific/Fiji",
      "label": "Lakeba Island - Fiji"
    },
    {
      "value": "America/New_York",
      "label": "Lakehurst - United States"
    },
    {
      "value": "America/New_York",
      "label": "Lakeland - United States"
    },
    {
      "value": "Europe/London",
      "label": "Lakenheath - United Kingdom"
    },
    {
      "value": "America/Los_Angeles",
      "label": "Lakeview - United States"
    },
    {
      "value": "Europe/Oslo",
      "label": "Lakselv - Norway"
    },
    {
      "value": "Africa/Addis_Ababa",
      "label": "Lalibella - Ethiopia"
    },
    {
      "value": "Pacific/Efate",
      "label": "Lamap - Vanuatu"
    },
    {
      "value": "America/Denver",
      "label": "Lamar - United States"
    },
    {
      "value": "Africa/Libreville",
      "label": "Lambarene - Gabon"
    },
    {
      "value": "Pacific/Fiji",
      "label": "Lambasa - Fiji"
    },
    {
      "value": "Pacific/Efate",
      "label": "Lamen Bay - Vanuatu"
    },
    {
      "value": "Asia/Tehran",
      "label": "Lamerd - Iran"
    },
    {
      "value": "Europe/Rome",
      "label": "Lamezia - Italy"
    },
    {
      "value": "Asia/Katmandu",
      "label": "Lamidanda - Nepal"
    },
    {
      "value": "Asia/Bangkok",
      "label": "Lampang - Thailand"
    },
    {
      "value": "Europe/Rome",
      "label": "Lampedusa - Italy"
    },
    {
      "value": "Africa/Nairobi",
      "label": "Lamu - Kenya"
    },
    {
      "value": "Pacific/Honolulu",
      "label": "Lanai - United States"
    },
    {
      "value": "America/New_York",
      "label": "Lancaster - United States"
    },
    {
      "value": "Europe/Paris",
      "label": "Landivisiau - France"
    },
    {
      "value": "Europe/London",
      "label": "Land's End - United Kingdom"
    },
    {
      "value": "Africa/Johannesburg",
      "label": "Langebaanweg - South Africa"
    },
    {
      "value": "Europe/Berlin",
      "label": "Langeoog - Germany"
    },
    {
      "value": "Asia/Jayapura",
      "label": "Langgur-Kei Islands - Indonesia"
    },
    {
      "value": "Asia/Kuala_Lumpur",
      "label": "Langkawi - Malaysia"
    },
    {
      "value": "America/Vancouver",
      "label": "Langley Township - Canada"
    },
    {
      "value": "Asia/Baku",
      "label": "Lankaran - Azerbaijan"
    },
    {
      "value": "Europe/Paris",
      "label": "Lannion - France"
    },
    {
      "value": "America/Toronto",
      "label": "Lansdowne House - Canada"
    },
    {
      "value": "America/New_York",
      "label": "Lansing - United States"
    },
    {
      "value": "Asia/Taipei",
      "label": "Lanyu - Taiwan"
    },
    {
      "value": "Asia/Shanghai",
      "label": "Lanzhou - China"
    },
    {
      "value": "Asia/Manila",
      "label": "Laoag - Philippines"
    },
    {
      "value": "Europe/Helsinki",
      "label": "Lappeenranta - Finland"
    },
    {
      "value": "Asia/Tehran",
      "label": "Lar - Iran"
    },
    {
      "value": "America/Denver",
      "label": "Laramie - United States"
    },
    {
      "value": "America/Chicago",
      "label": "Laredo - United States"
    },
    {
      "value": "Europe/Athens",
      "label": "Larissa - Greece"
    },
    {
      "value": "Asia/Nicosia",
      "label": "Larnaca - Cyprus"
    },
    {
      "value": "America/Anchorage",
      "label": "Larsen Bay - United States"
    },
    {
      "value": "America/Denver",
      "label": "Las Cruces - United States"
    },
    {
      "value": "America/Argentina/Rio_Gallegos",
      "label": "Las Heras - Argentina"
    },
    {
      "value": "America/Havana",
      "label": "Las Tunas - Cuba"
    },
    {
      "value": "America/Los_Angeles",
      "label": "Las Vegas - United States"
    },
    {
      "value": "Europe/London",
      "label": "Lasham - United Kingdom"
    },
    {
      "value": "Asia/Rangoon",
      "label": "Lashio - Burma"
    },
    {
      "value": "Asia/Kabul",
      "label": "Lashkar Gah - Afghanistan"
    },
    {
      "value": "America/Guayaquil",
      "label": "Latacunga - Ecuador"
    },
    {
      "value": "Asia/Damascus",
      "label": "Latakia - Syria"
    },
    {
      "value": "Europe/Rome",
      "label": "Latina - Italy"
    },
    {
      "value": "America/New_York",
      "label": "Latrobe - United States"
    },
    {
      "value": "Australia/Melbourne",
      "label": "Launceston - Australia"
    },
    {
      "value": "Europe/Zurich",
      "label": "Lausanne - Switzerland"
    },
    {
      "value": "Europe/Paris",
      "label": "Laval - France"
    },
    {
      "value": "Asia/Tehran",
      "label": "Lavan Island - Iran"
    },
    {
      "value": "Australia/Perth",
      "label": "Laverton - Australia"
    },
    {
      "value": "Asia/Kuala_Lumpur",
      "label": "Lawas - Malaysia"
    },
    {
      "value": "America/New_York",
      "label": "Lawrence - United States"
    },
    {
      "value": "America/New_York",
      "label": "Lawrenceville - United States"
    },
    {
      "value": "America/Chicago",
      "label": "Lawton - United States"
    },
    {
      "value": "America/Mexico_City",
      "label": "Lazard Cardenas - Mexico"
    },
    {
      "value": "Europe/Paris",
      "label": "Le Castellet - France"
    },
    {
      "value": "Europe/Paris",
      "label": "Le Havre - France"
    },
    {
      "value": "Europe/Paris",
      "label": "Le Mans - France"
    },
    {
      "value": "Europe/Paris",
      "label": "Le Puy - France"
    },
    {
      "value": "Europe/Paris",
      "label": "Le Tourquet - France"
    },
    {
      "value": "Australia/Perth",
      "label": "Learmonth - Australia"
    },
    {
      "value": "America/New_York",
      "label": "Lebanon - United States"
    },
    {
      "value": "Europe/Rome",
      "label": "Lecce - Italy"
    },
    {
      "value": "Europe/London",
      "label": "Leeds - United Kingdom"
    },
    {
      "value": "Europe/Amsterdam",
      "label": "Leeuwarden - Netherlands"
    },
    {
      "value": "Asia/Manila",
      "label": "Legazpi - Philippines"
    },
    {
      "value": "Asia/Calcutta",
      "label": "Leh - India"
    },
    {
      "value": "Australia/Adelaide",
      "label": "Leigh Creek - Australia"
    },
    {
      "value": "Australia/Perth",
      "label": "Leinster - Australia"
    },
    {
      "value": "Europe/Berlin",
      "label": "Leipzig - Germany"
    },
    {
      "value": "Europe/Oslo",
      "label": "Leknes - Norway"
    },
    {
      "value": "Europe/Amsterdam",
      "label": "Lelystad - Netherlands"
    },
    {
      "value": "America/Los_Angeles",
      "label": "Lemoore - United States"
    },
    {
      "value": "America/Fortaleza",
      "label": "Lençóis - Brazil"
    },
    {
      "value": "Asia/Yakutsk",
      "label": "Lensk - Russia"
    },
    {
      "value": "Europe/Madrid",
      "label": "Leon - Spain"
    },
    {
      "value": "Australia/Perth",
      "label": "Leonora - Australia"
    },
    {
      "value": "Europe/Athens",
      "label": "Leros - Greece"
    },
    {
      "value": "Europe/London",
      "label": "Lerwick - United Kingdom"
    },
    {
      "value": "America/Guadeloupe",
      "label": "Les Saintes - Guadeloupe"
    },
    {
      "value": "America/Edmonton",
      "label": "Lethbridge - Canada"
    },
    {
      "value": "America/Guyana",
      "label": "Lethem - Guyana"
    },
    {
      "value": "America/Bogota",
      "label": "Leticia - Colombia"
    },
    {
      "value": "Europe/London",
      "label": "Leuchars - United Kingdom"
    },
    {
      "value": "Pacific/Fiji",
      "label": "Levuka - Fiji"
    },
    {
      "value": "America/New_York",
      "label": "Lewisburg - United States"
    },
    {
      "value": "America/Los_Angeles",
      "label": "Lewiston - United States"
    },
    {
      "value": "America/Denver",
      "label": "Lewistown - United States"
    },
    {
      "value": "America/New_York",
      "label": "Lexington KY - United States"
    },
    {
      "value": "Asia/Shanghai",
      "label": "Lhasa - China"
    },
    {
      "value": "Asia/Jakarta",
      "label": "Lhok Seumawe-Sumatra Island - Indonesia"
    },
    {
      "value": "Asia/Jakarta",
      "label": "Lhok Sukon - Indonesia"
    },
    {
      "value": "Asia/Shanghai",
      "label": "Lianyungang - China"
    },
    {
      "value": "America/Chicago",
      "label": "Liberal - United States"
    },
    {
      "value": "America/Costa_Rica",
      "label": "Liberia - Costa Rica"
    },
    {
      "value": "Africa/Libreville",
      "label": "Libreville - Gabon"
    },
    {
      "value": "Africa/Maputo",
      "label": "Lichinga - Mozambique"
    },
    {
      "value": "Europe/Stockholm",
      "label": "Lidkoping - Sweden"
    },
    {
      "value": "Europe/Brussels",
      "label": "Liege - Belgium"
    },
    {
      "value": "Europe/Riga",
      "label": "Liepaja - Latvia"
    },
    {
      "value": "Pacific/Noumea",
      "label": "Lifou - New Caledonia"
    },
    {
      "value": "Pacific/Tongatapu",
      "label": "Lifuka - Tonga"
    },
    {
      "value": "Australia/Sydney",
      "label": "Lightning Ridge - Australia"
    },
    {
      "value": "Pacific/Honolulu",
      "label": "Lihue - United States"
    },
    {
      "value": "Asia/Shanghai",
      "label": "Lijiang - China"
    },
    {
      "value": "Africa/Blantyre",
      "label": "Likoma Island - Malawi"
    },
    {
      "value": "Asia/Calcutta",
      "label": "Lilabari - India"
    },
    {
      "value": "Europe/Paris",
      "label": "Lille - France"
    },
    {
      "value": "Africa/Blantyre",
      "label": "Lilongwe - Malawi"
    },
    {
      "value": "America/Lima",
      "label": "Lima - Peru"
    },
    {
      "value": "America/New_York",
      "label": "Lima - United States"
    },
    {
      "value": "Asia/Kuala_Lumpur",
      "label": "Limbang - Malaysia"
    },
    {
      "value": "Europe/Athens",
      "label": "Limnos - Greece"
    },
    {
      "value": "Europe/Paris",
      "label": "Limoges - France"
    },
    {
      "value": "America/Costa_Rica",
      "label": "Limon - Costa Rica"
    },
    {
      "value": "America/Chicago",
      "label": "Lincoln - United States"
    },
    {
      "value": "America/Denver",
      "label": "Lindau - Germany"
    },
    {
      "value": "America/New_York",
      "label": "Linden - United States"
    },
    {
      "value": "Africa/Dar_es_Salaam",
      "label": "Lindi - Tanzania"
    },
    {
      "value": "America/Sao_Paulo",
      "label": "Lins - Brazil"
    },
    {
      "value": "Europe/London",
      "label": "Linton-on-ouse - United Kingdom"
    },
    {
      "value": "Asia/Shanghai",
      "label": "Linyi - China"
    },
    {
      "value": "Europe/Vienna",
      "label": "Linz - Austria"
    },
    {
      "value": "Europe/Moscow",
      "label": "Lipetsk - Russia"
    },
    {
      "value": "Asia/Shanghai",
      "label": "Liping - China"
    },
    {
      "value": "Africa/Kinshasa",
      "label": "Lisala - Congo (Kinshasa)"
    },
    {
      "value": "Europe/Lisbon",
      "label": "Lisbon - Portugal"
    },
    {
      "value": "Australia/Sydney",
      "label": "Lismore - Australia"
    },
    {
      "value": "America/Cayman",
      "label": "Little Cayman - Cayman Islands"
    },
    {
      "value": "America/Winnipeg",
      "label": "Little Grand Rapids - Canada"
    },
    {
      "value": "America/Chicago",
      "label": "Little Rock - United States"
    },
    {
      "value": "Asia/Shanghai",
      "label": "Liuzhou - China"
    },
    {
      "value": "America/Los_Angeles",
      "label": "Livermore - United States"
    },
    {
      "value": "Europe/London",
      "label": "Liverpool - United Kingdom"
    },
    {
      "value": "Africa/Lusaka",
      "label": "Livingstone - Zambia"
    },
    {
      "value": "America/Denver",
      "label": "Livingston-Montana - United States"
    },
    {
      "value": "Australia/Brisbane",
      "label": "Lizard Island - Australia"
    },
    {
      "value": "Europe/Ljubljana",
      "label": "Ljubljana - Slovenia"
    },
    {
      "value": "Europe/Madrid",
      "label": "Lleida - Spain"
    },
    {
      "value": "America/Edmonton",
      "label": "Lloydminster - Canada"
    },
    {
      "value": "Europe/Zurich",
      "label": "Locarno - Switzerland"
    },
    {
      "value": "America/New_York",
      "label": "Lock Haven - United States"
    },
    {
      "value": "Australia/Brisbane",
      "label": "Lockhart River - Australia"
    },
    {
      "value": "America/Chicago",
      "label": "Lockport - United States"
    },
    {
      "value": "Africa/Lubumbashi",
      "label": "Lodja - Congo (Kinshasa)"
    },
    {
      "value": "Africa/Nairobi",
      "label": "Lodwar - Kenya"
    },
    {
      "value": "Europe/Warsaw",
      "label": "Lodz - Poland"
    },
    {
      "value": "Asia/Bangkok",
      "label": "Loei - Thailand"
    },
    {
      "value": "America/Denver",
      "label": "Logan - United States"
    },
    {
      "value": "Europe/Madrid",
      "label": "Logroño-Agoncillo - Spain"
    },
    {
      "value": "Pacific/Efate",
      "label": "Loh/Linua - Vanuatu"
    },
    {
      "value": "Asia/Rangoon",
      "label": "Loikaw - Burma"
    },
    {
      "value": "Africa/Nairobi",
      "label": "Lokichoggio - Kenya"
    },
    {
      "value": "Africa/Lome",
      "label": "Lome - Togo"
    },
    {
      "value": "America/Los_Angeles",
      "label": "Lompoc - United States"
    },
    {
      "value": "America/Toronto",
      "label": "London - Canada"
    },
    {
      "value": "Europe/London",
      "label": "London - United Kingdom"
    },
    {
      "value": "America/New_York",
      "label": "London - United States"
    },
    {
      "value": "Europe/London",
      "label": "Londonderry - United Kingdom"
    },
    {
      "value": "America/Sao_Paulo",
      "label": "Londrina - Brazil"
    },
    {
      "value": "America/Chicago",
      "label": "Lone Rock - United States"
    },
    {
      "value": "Asia/Kuala_Lumpur",
      "label": "Long Akah - Malaysia"
    },
    {
      "value": "Asia/Makassar",
      "label": "Long Apung-Borneo Island - Indonesia"
    },
    {
      "value": "Asia/Makassar",
      "label": "Long Bawan-Borneo Island - Indonesia"
    },
    {
      "value": "America/Los_Angeles",
      "label": "Long Beach - United States"
    },
    {
      "value": "Asia/Kuala_Lumpur",
      "label": "Long Datih - Malaysia"
    },
    {
      "value": "Asia/Kuala_Lumpur",
      "label": "Long Seridan - Malaysia"
    },
    {
      "value": "Pacific/Efate",
      "label": "Longana - Vanuatu"
    },
    {
      "value": "Australia/Brisbane",
      "label": "Longreach - Australia"
    },
    {
      "value": "America/Chicago",
      "label": "Longview - United States"
    },
    {
      "value": "Asia/Shanghai",
      "label": "Longyan - China"
    },
    {
      "value": "Pacific/Efate",
      "label": "Lonorore - Vanuatu"
    },
    {
      "value": "America/Los_Angeles",
      "label": "Lopez - United States"
    },
    {
      "value": "Australia/Lord_Howe",
      "label": "Lord Howe Island - Australia"
    },
    {
      "value": "America/Mazatlan",
      "label": "Loreto - Mexico"
    },
    {
      "value": "Europe/Paris",
      "label": "Lorient - France"
    },
    {
      "value": "America/Denver",
      "label": "Los Alamos - United States"
    },
    {
      "value": "America/Santiago",
      "label": "Los Angeles - Chile"
    },
    {
      "value": "America/Los_Angeles",
      "label": "Los Angeles - United States"
    },
    {
      "value": "America/Costa_Rica",
      "label": "Los Chiles - Costa Rica"
    },
    {
      "value": "America/Mazatlan",
      "label": "Los Mochis - Mexico"
    },
    {
      "value": "America/Caracas",
      "label": "Los Roques - Venezuela"
    },
    {
      "value": "Europe/London",
      "label": "Lossiemouth - United Kingdom"
    },
    {
      "value": "Africa/Brazzaville",
      "label": "Loubomo - Congo (Brazzaville)"
    },
    {
      "value": "America/Chicago",
      "label": "Louisiana - United States"
    },
    {
      "value": "America/New_York",
      "label": "Louisville - United States"
    },
    {
      "value": "America/Blanc-Sablon",
      "label": "Lourdes-De-Blanc-Sablon - Canada"
    },
    {
      "value": "Africa/Luanda",
      "label": "Luanda - Angola"
    },
    {
      "value": "Asia/Vientiane",
      "label": "Luang Namtha - Laos"
    },
    {
      "value": "Asia/Vientiane",
      "label": "Luang Prabang - Laos"
    },
    {
      "value": "Asia/Manila",
      "label": "Lubang - Philippines"
    },
    {
      "value": "Africa/Luanda",
      "label": "Lubango - Angola"
    },
    {
      "value": "America/Chicago",
      "label": "Lubbock - United States"
    },
    {
      "value": "Africa/Lubumbashi",
      "label": "Lubumashi - Congo (Kinshasa)"
    },
    {
      "value": "Africa/Luanda",
      "label": "Lucapa - Angola"
    },
    {
      "value": "Asia/Calcutta",
      "label": "Lucknow - India"
    },
    {
      "value": "Africa/Windhoek",
      "label": "Luderitz - Namibia"
    },
    {
      "value": "Asia/Calcutta",
      "label": "Ludhiaha - India"
    },
    {
      "value": "Europe/Berlin",
      "label": "Luebeck - Germany"
    },
    {
      "value": "Africa/Luanda",
      "label": "Luena - Angola"
    },
    {
      "value": "America/Chicago",
      "label": "Lufkin - United States"
    },
    {
      "value": "Europe/Zurich",
      "label": "Lugano - Switzerland"
    },
    {
      "value": "Europe/Kiev",
      "label": "Lugansk - Ukraine"
    },
    {
      "value": "Asia/Katmandu",
      "label": "Lukla - Nepal"
    },
    {
      "value": "Europe/Stockholm",
      "label": "Lulea - Sweden"
    },
    {
      "value": "America/New_York",
      "label": "Lumberton - United States"
    },
    {
      "value": "Africa/Maputo",
      "label": "Lumbo - Mozambique"
    },
    {
      "value": "Asia/Shanghai",
      "label": "Luoyang - China"
    },
    {
      "value": "Africa/Lusaka",
      "label": "Lusaka - Zambia"
    },
    {
      "value": "Europe/Kiev",
      "label": "Lutsk - Ukraine"
    },
    {
      "value": "Asia/Makassar",
      "label": "Luwuk - Indonesia"
    },
    {
      "value": "Europe/Luxembourg",
      "label": "Luxemburg - Luxembourg"
    },
    {
      "value": "Asia/Shanghai",
      "label": "Luxi - China"
    },
    {
      "value": "Africa/Cairo",
      "label": "Luxor - Egypt"
    },
    {
      "value": "Asia/Shanghai",
      "label": "Luzhou - China"
    },
    {
      "value": "Asia/Shanghai",
      "label": "Lvliang - China"
    },
    {
      "value": "Europe/Kiev",
      "label": "Lvov - Ukraine"
    },
    {
      "value": "Europe/Stockholm",
      "label": "Lycksele - Sweden"
    },
    {
      "value": "Europe/London",
      "label": "Lydd - United Kingdom"
    },
    {
      "value": "Europe/London",
      "label": "Lympne - United Kingdom"
    },
    {
      "value": "America/New_York",
      "label": "Lynchburg - United States"
    },
    {
      "value": "Europe/London",
      "label": "Lyneham - United Kingdom"
    },
    {
      "value": "America/Winnipeg",
      "label": "Lynn Lake - Canada"
    },
    {
      "value": "Europe/Paris",
      "label": "Lyon - France"
    },
    {
      "value": "Indian/Maldives",
      "label": "Maamigili - Maldives"
    },
    {
      "value": "Europe/Amsterdam",
      "label": "Maastricht - Netherlands"
    },
    {
      "value": "America/Guyana",
      "label": "Mabaruma - Guyana"
    },
    {
      "value": "Australia/Brisbane",
      "label": "Mabuiag Island - Australia"
    },
    {
      "value": "America/Sao_Paulo",
      "label": "Macaé - Brazil"
    },
    {
      "value": "America/Fortaleza",
      "label": "Macapa - Brazil"
    },
    {
      "value": "America/Guayaquil",
      "label": "Macara - Ecuador"
    },
    {
      "value": "America/Guayaquil",
      "label": "Macas - Ecuador"
    },
    {
      "value": "Asia/Macau",
      "label": "Macau - Macau"
    },
    {
      "value": "America/Fortaleza",
      "label": "Maceio - Brazil"
    },
    {
      "value": "America/Guayaquil",
      "label": "Machala - Ecuador"
    },
    {
      "value": "Australia/Brisbane",
      "label": "Mackay - Australia"
    },
    {
      "value": "America/Chicago",
      "label": "Macon - United States"
    },
    {
      "value": "Pacific/Port_Moresby",
      "label": "Madang - Papua New Guinea"
    },
    {
      "value": "America/Los_Angeles",
      "label": "Madera - United States"
    },
    {
      "value": "Asia/Riyadh",
      "label": "Madinah - Saudi Arabia"
    },
    {
      "value": "America/Chicago",
      "label": "Madison - United States"
    },
    {
      "value": "Asia/Calcutta",
      "label": "Madras - India"
    },
    {
      "value": "Asia/Calcutta",
      "label": "Madurai - India"
    },
    {
      "value": "Asia/Bangkok",
      "label": "Mae Hong Son - Thailand"
    },
    {
      "value": "Pacific/Efate",
      "label": "Maewo Island - Vanuatu"
    },
    {
      "value": "Africa/Johannesburg",
      "label": "Mafeking - South Africa"
    },
    {
      "value": "Africa/Dar_es_Salaam",
      "label": "Mafia Island - Tanzania"
    },
    {
      "value": "Asia/Amman",
      "label": "Mafraq - Jordan"
    },
    {
      "value": "Asia/Srednekolymsk",
      "label": "Magadan - Russia"
    },
    {
      "value": "America/Bogota",
      "label": "Magangue - Colombia"
    },
    {
      "value": "Europe/Moscow",
      "label": "Magas - Russia"
    },
    {
      "value": "Europe/Berlin",
      "label": "Magdeburg - Germany"
    },
    {
      "value": "Asia/Yekaterinburg",
      "label": "Magnetiogorsk - Russia"
    },
    {
      "value": "Asia/Rangoon",
      "label": "Magwe - Burma"
    },
    {
      "value": "Indian/Antananarivo",
      "label": "Mahajanga - Madagascar"
    },
    {
      "value": "America/Guyana",
      "label": "Mahdia - Guyana"
    },
    {
      "value": "Indian/Mahe",
      "label": "Mahe - Seychelles"
    },
    {
      "value": "Pacific/Tarawa",
      "label": "Maiana - Kiribati"
    },
    {
      "value": "Africa/Lagos",
      "label": "Maiduguri - Nigeria"
    },
    {
      "value": "Asia/Kabul",
      "label": "Maimama - Afghanistan"
    },
    {
      "value": "Indian/Antananarivo",
      "label": "Maintirano - Madagascar"
    },
    {
      "value": "Atlantic/Cape_Verde",
      "label": "Maio - Cape Verde"
    },
    {
      "value": "Australia/Sydney",
      "label": "Maitland - Australia"
    },
    {
      "value": "Pacific/Majuro",
      "label": "Majuro - Marshall Islands"
    },
    {
      "value": "Africa/Addis_Ababa",
      "label": "Makale - Ethiopia"
    },
    {
      "value": "Asia/Makassar",
      "label": "Makale - Indonesia"
    },
    {
      "value": "Pacific/Tahiti",
      "label": "Makemo - French Polynesia"
    },
    {
      "value": "Europe/Moscow",
      "label": "Makhachkala - Russia"
    },
    {
      "value": "Pacific/Tarawa",
      "label": "Makin - Kiribati"
    },
    {
      "value": "America/Halifax",
      "label": "Makkovik - Canada"
    },
    {
      "value": "Africa/Libreville",
      "label": "Makokou - Gabon"
    },
    {
      "value": "Asia/Taipei",
      "label": "Makung - Taiwan"
    },
    {
      "value": "Africa/Lagos",
      "label": "Makurdi - Nigeria"
    },
    {
      "value": "Africa/Malabo",
      "label": "Malabo - Equatorial Guinea"
    },
    {
      "value": "Asia/Kuala_Lumpur",
      "label": "Malacca - Malaysia"
    },
    {
      "value": "Europe/Madrid",
      "label": "Malaga - Spain"
    },
    {
      "value": "Africa/Juba",
      "label": "Malakal - Sudan"
    },
    {
      "value": "Africa/Johannesburg",
      "label": "Malalane - South Africa"
    },
    {
      "value": "Africa/Johannesburg",
      "label": "Malamala - South Africa"
    },
    {
      "value": "Asia/Jakarta",
      "label": "Malang - Indonesia"
    },
    {
      "value": "Africa/Luanda",
      "label": "Malanje - Angola"
    },
    {
      "value": "America/Mendoza",
      "label": "Malargue - Argentina"
    },
    {
      "value": "Indian/Maldives",
      "label": "Male - Maldives"
    },
    {
      "value": "Pacific/Efate",
      "label": "Malekula Island - Vanuatu"
    },
    {
      "value": "Europe/Zagreb",
      "label": "Mali Losinj - Croatia"
    },
    {
      "value": "Africa/Nairobi",
      "label": "Malindi - Kenya"
    },
    {
      "value": "Australia/Hobart",
      "label": "Mallacoota - Australia"
    },
    {
      "value": "Europe/Stockholm",
      "label": "Malmoe - Sweden"
    },
    {
      "value": "Pacific/Fiji",
      "label": "Malolo Lailai Island - Fiji"
    },
    {
      "value": "Europe/Malta",
      "label": "Malta - Malta"
    },
    {
      "value": "Asia/Manila",
      "label": "Mamburao - Philippines"
    },
    {
      "value": "America/Los_Angeles",
      "label": "Mammoth Lakes - United States"
    },
    {
      "value": "Indian/Antananarivo",
      "label": "Mampikony - Madagascar"
    },
    {
      "value": "Africa/Abidjan",
      "label": "Man - Cote d'Ivoire"
    },
    {
      "value": "Pacific/Fiji",
      "label": "Mana Island - Fiji"
    },
    {
      "value": "Asia/Makassar",
      "label": "Manado - Indonesia"
    },
    {
      "value": "America/Managua",
      "label": "Managua - Nicaragua"
    },
    {
      "value": "Indian/Antananarivo",
      "label": "Manakara - Madagascar"
    },
    {
      "value": "Indian/Antananarivo",
      "label": "Mananara - Madagascar"
    },
    {
      "value": "Asia/Katmandu",
      "label": "Manang - Nepal"
    },
    {
      "value": "Indian/Antananarivo",
      "label": "Mananjary - Madagascar"
    },
    {
      "value": "Pacific/Auckland",
      "label": "Manapouri - New Zealand"
    },
    {
      "value": "America/New_York",
      "label": "Manassas - United States"
    },
    {
      "value": "America/Boa_Vista",
      "label": "Manaus - Brazil"
    },
    {
      "value": "Europe/London",
      "label": "Manchester - United Kingdom"
    },
    {
      "value": "America/New_York",
      "label": "Manchester NH - United States"
    },
    {
      "value": "Asia/Rangoon",
      "label": "Mandalay - Burma"
    },
    {
      "value": "Indian/Antananarivo",
      "label": "Mandritsara - Madagascar"
    },
    {
      "value": "Pacific/Rarotonga",
      "label": "Mangaia Island - Cook Islands"
    },
    {
      "value": "Asia/Calcutta",
      "label": "Mangalore - India"
    },
    {
      "value": "Asia/Karachi",
      "label": "Mangla - Pakistan"
    },
    {
      "value": "America/Chicago",
      "label": "Manhattan - United States"
    },
    {
      "value": "America/Boa_Vista",
      "label": "Manicore - Brazil"
    },
    {
      "value": "Pacific/Tahiti",
      "label": "Manihi - French Polynesia"
    },
    {
      "value": "Pacific/Rarotonga",
      "label": "Manihiki Island - Cook Islands"
    },
    {
      "value": "America/Godthab",
      "label": "Maniitsoq - Greenland"
    },
    {
      "value": "Asia/Manila",
      "label": "Manila - Philippines"
    },
    {
      "value": "Australia/Darwin",
      "label": "Maningrida - Australia"
    },
    {
      "value": "America/New_York",
      "label": "Manistee - United States"
    },
    {
      "value": "America/Toronto",
      "label": "Manitouwadge - Canada"
    },
    {
      "value": "America/Toronto",
      "label": "Manitowaning - Canada"
    },
    {
      "value": "America/Toronto",
      "label": "Maniwaki - Canada"
    },
    {
      "value": "America/Bogota",
      "label": "Manizales - Colombia"
    },
    {
      "value": "Indian/Antananarivo",
      "label": "Manja - Madagascar"
    },
    {
      "value": "America/Anchorage",
      "label": "Manley Hot Springs - United States"
    },
    {
      "value": "Europe/Berlin",
      "label": "Mannheim - Germany"
    },
    {
      "value": "America/Anchorage",
      "label": "Manokotak - United States"
    },
    {
      "value": "Asia/Jayapura",
      "label": "Manokwari - Indonesia"
    },
    {
      "value": "America/New_York",
      "label": "Mansfield - United States"
    },
    {
      "value": "Europe/London",
      "label": "Manston - United Kingdom"
    },
    {
      "value": "America/Guayaquil",
      "label": "Manta - Ecuador"
    },
    {
      "value": "America/New_York",
      "label": "Manteo - United States"
    },
    {
      "value": "America/Havana",
      "label": "Manzanillo - Cuba"
    },
    {
      "value": "America/Mexico_City",
      "label": "Manzanillo - Mexico"
    },
    {
      "value": "Asia/Shanghai",
      "label": "Manzhouli - China"
    },
    {
      "value": "Africa/Mbabane",
      "label": "Manzini - Swaziland"
    },
    {
      "value": "Africa/Maputo",
      "label": "Maputo - Mozambique"
    },
    {
      "value": "America/Buenos_Aires",
      "label": "Mar Del Plata - Argentina"
    },
    {
      "value": "America/Belem",
      "label": "Maraba - Brazil"
    },
    {
      "value": "America/Caracas",
      "label": "Maracay - Venezuela"
    },
    {
      "value": "Africa/Niamey",
      "label": "Maradi - Niger"
    },
    {
      "value": "Asia/Tehran",
      "label": "Maragheh - Iran"
    },
    {
      "value": "Pacific/Tarawa",
      "label": "Marakei - Kiribati"
    },
    {
      "value": "America/Sao_Paulo",
      "label": "Marília - Brazil"
    },
    {
      "value": "America/Phoenix",
      "label": "Marana - United States"
    },
    {
      "value": "America/Toronto",
      "label": "Marathon - Canada"
    },
    {
      "value": "America/New_York",
      "label": "Marathon - United States"
    },
    {
      "value": "Pacific/Guadalcanal",
      "label": "Marau - Solomon Islands"
    },
    {
      "value": "America/New_York",
      "label": "Marco Island Airport - United States"
    },
    {
      "value": "Europe/Istanbul",
      "label": "Mardin - Turkey"
    },
    {
      "value": "Pacific/Noumea",
      "label": "Mare - New Caledonia"
    },
    {
      "value": "Africa/Johannesburg",
      "label": "Margate - South Africa"
    },
    {
      "value": "Europe/London",
      "label": "Marham - United Kingdom"
    },
    {
      "value": "Europe/Copenhagen",
      "label": "Maribo - Denmark"
    },
    {
      "value": "Europe/Ljubljana",
      "label": "Maribor - Slovenia"
    },
    {
      "value": "Europe/Mariehamn",
      "label": "Mariehamn - Finland"
    },
    {
      "value": "America/New_York",
      "label": "Marietta - United States"
    },
    {
      "value": "Europe/Rome",
      "label": "Marina Di Campo - Italy"
    },
    {
      "value": "America/Sao_Paulo",
      "label": "Maringa - Brazil"
    },
    {
      "value": "America/Chicago",
      "label": "Marion - United States"
    },
    {
      "value": "America/Cayenne",
      "label": "Maripasoula - French Guiana"
    },
    {
      "value": "America/Los_Angeles",
      "label": "Mariposa - United States"
    },
    {
      "value": "America/Bogota",
      "label": "Mariquita - Colombia"
    },
    {
      "value": "America/Asuncion",
      "label": "Mariscal Estigarribia - Paraguay"
    },
    {
      "value": "Europe/Kiev",
      "label": "Mariupol International - Ukraine"
    },
    {
      "value": "Asia/Anadyr",
      "label": "Markovo - Russia"
    },
    {
      "value": "Indian/Antananarivo",
      "label": "Maroantsetra - Madagascar"
    },
    {
      "value": "Australia/Brisbane",
      "label": "Maroochydore - Australia"
    },
    {
      "value": "Africa/Douala",
      "label": "Maroua - Cameroon"
    },
    {
      "value": "Africa/Casablanca",
      "label": "Marrakech - Morocco"
    },
    {
      "value": "Africa/Cairo",
      "label": "Marsa Alam - Egypt"
    },
    {
      "value": "Africa/Tripoli",
      "label": "Marsa Brega - Libya"
    },
    {
      "value": "Europe/Paris",
      "label": "Marseille - France"
    },
    {
      "value": "America/Nassau",
      "label": "Marsh Harbor - Bahamas"
    },
    {
      "value": "America/Anchorage",
      "label": "Marshall - United States"
    },
    {
      "value": "America/New_York",
      "label": "Martinsburg - United States"
    },
    {
      "value": "Asia/Kuala_Lumpur",
      "label": "Marudi - Malaysia"
    },
    {
      "value": "Asia/Ashgabat",
      "label": "Mary - Turkmenistan"
    },
    {
      "value": "Australia/Brisbane",
      "label": "Maryborough - Australia"
    },
    {
      "value": "America/St_Johns",
      "label": "Mary's Harbour - Canada"
    },
    {
      "value": "America/Los_Angeles",
      "label": "Marysville - United States"
    },
    {
      "value": "Africa/Nairobi",
      "label": "Masai Mara - Kenya"
    },
    {
      "value": "Asia/Makassar",
      "label": "Masamba - Indonesia"
    },
    {
      "value": "Asia/Manila",
      "label": "Masbate - Philippines"
    },
    {
      "value": "Africa/Maseru",
      "label": "Maseru - Lesotho"
    },
    {
      "value": "Asia/Tehran",
      "label": "Mashhad - Iran"
    },
    {
      "value": "Asia/Muscat",
      "label": "Masirah - Oman"
    },
    {
      "value": "America/Chicago",
      "label": "Mason City - United States"
    },
    {
      "value": "Africa/Asmera",
      "label": "Massawa - Eritrea"
    },
    {
      "value": "America/New_York",
      "label": "Massena - United States"
    },
    {
      "value": "America/Vancouver",
      "label": "Masset - Canada"
    },
    {
      "value": "Pacific/Auckland",
      "label": "Masterton - New Zealand"
    },
    {
      "value": "Africa/Harare",
      "label": "Masvingo - Zimbabwe"
    },
    {
      "value": "Africa/Kinshasa",
      "label": "Matadi - Congo (Kinshasa)"
    },
    {
      "value": "America/Toronto",
      "label": "Matagami - Canada"
    },
    {
      "value": "Pacific/Tahiti",
      "label": "Mataiva - French Polynesia"
    },
    {
      "value": "Africa/Dakar",
      "label": "Matam - Senegal"
    },
    {
      "value": "America/Mexico_City",
      "label": "Matamoros - Mexico"
    },
    {
      "value": "Asia/Makassar",
      "label": "Mataram - Indonesia"
    },
    {
      "value": "Pacific/Fiji",
      "label": "Matei - Fiji"
    },
    {
      "value": "Asia/Taipei",
      "label": "Matsu Islands - Taiwan"
    },
    {
      "value": "Asia/Tokyo",
      "label": "Matsumoto - Japan"
    },
    {
      "value": "Asia/Tokyo",
      "label": "Matsuyama - Japan"
    },
    {
      "value": "Asia/Colombo",
      "label": "Mattala - Sri Lanka"
    },
    {
      "value": "America/Nassau",
      "label": "Matthew Town - Bahamas"
    },
    {
      "value": "America/Caracas",
      "label": "Maturin - Venezuela"
    },
    {
      "value": "Europe/Paris",
      "label": "Maubeuge - France"
    },
    {
      "value": "America/Boa_Vista",
      "label": "Maues - Brazil"
    },
    {
      "value": "Pacific/Rarotonga",
      "label": "Mauke Island - Cook Islands"
    },
    {
      "value": "Asia/Makassar",
      "label": "Maumere - Indonesia"
    },
    {
      "value": "Africa/Gaborone",
      "label": "Maun - Botswana"
    },
    {
      "value": "Pacific/Tahiti",
      "label": "Maupiti - French Polynesia"
    },
    {
      "value": "Asia/Rangoon",
      "label": "Mawlamyine - Burma"
    },
    {
      "value": "America/Nassau",
      "label": "Mayaguana - Bahamas"
    },
    {
      "value": "America/Puerto_Rico",
      "label": "Mayaguez - Puerto Rico"
    },
    {
      "value": "America/Vancouver",
      "label": "Mayo - Canada"
    },
    {
      "value": "Africa/Libreville",
      "label": "Mayumba - Gabon"
    },
    {
      "value": "Asia/Kabul",
      "label": "Mazar-i-sharif - Afghanistan"
    },
    {
      "value": "America/Mazatlan",
      "label": "Mazatlan - Mexico"
    },
    {
      "value": "Asia/Calcutta",
      "label": "Mazuffarpur - India"
    },
    {
      "value": "Pacific/Guadalcanal",
      "label": "Mbambanakira - Solomon Islands"
    },
    {
      "value": "Africa/Kinshasa",
      "label": "Mbandaka - Congo (Kinshasa)"
    },
    {
      "value": "Africa/Luanda",
      "label": "M'banza-congo - Angola"
    },
    {
      "value": "Africa/Lubumbashi",
      "label": "Mbuji-mayi - Congo (Kinshasa)"
    },
    {
      "value": "America/Chicago",
      "label": "Mcalester - United States"
    },
    {
      "value": "America/Chicago",
      "label": "Mcallen - United States"
    },
    {
      "value": "Australia/Darwin",
      "label": "McArthur River Mine - Australia"
    },
    {
      "value": "America/Denver",
      "label": "McCall - United States"
    },
    {
      "value": "America/Chicago",
      "label": "McCook - United States"
    },
    {
      "value": "America/Anchorage",
      "label": "Mcgrath - United States"
    },
    {
      "value": "America/Anchorage",
      "label": "McKinley Park - United States"
    },
    {
      "value": "America/Regina",
      "label": "Meadow Lake - Canada"
    },
    {
      "value": "Africa/Algiers",
      "label": "Mecheria - Algeria"
    },
    {
      "value": "Asia/Jakarta",
      "label": "Medan - Indonesia"
    },
    {
      "value": "America/Bogota",
      "label": "Medellin - Colombia"
    },
    {
      "value": "America/Los_Angeles",
      "label": "Medford - United States"
    },
    {
      "value": "America/Edmonton",
      "label": "Medicine Hat - Canada"
    },
    {
      "value": "Australia/Perth",
      "label": "Meekatharra - Australia"
    },
    {
      "value": "Asia/Katmandu",
      "label": "Meghauli - Nepal"
    },
    {
      "value": "Europe/Oslo",
      "label": "Mehamn - Norway"
    },
    {
      "value": "Asia/Shanghai",
      "label": "Meixian - China"
    },
    {
      "value": "Africa/Addis_Ababa",
      "label": "Mekane Selam - Ethiopia"
    },
    {
      "value": "Africa/Casablanca",
      "label": "Meknes - Morocco"
    },
    {
      "value": "America/Anchorage",
      "label": "Mekoryuk - United States"
    },
    {
      "value": "Australia/Hobart",
      "label": "Melbourne - Australia"
    },
    {
      "value": "America/New_York",
      "label": "Melbourne - United States"
    },
    {
      "value": "Europe/Madrid",
      "label": "Melilla - Spain"
    },
    {
      "value": "Asia/Tokyo",
      "label": "Memanbetsu - Japan"
    },
    {
      "value": "Europe/Berlin",
      "label": "Memmingen - Germany"
    },
    {
      "value": "America/Chicago",
      "label": "Memphis - United States"
    },
    {
      "value": "Europe/Paris",
      "label": "Mende - France"
    },
    {
      "value": "Pacific/Port_Moresby",
      "label": "Mendi - Papua New Guinea"
    },
    {
      "value": "America/Mendoza",
      "label": "Mendoza - Argentina"
    },
    {
      "value": "Africa/Luanda",
      "label": "Menongue - Angola"
    },
    {
      "value": "Europe/Madrid",
      "label": "Menorca - Spain"
    },
    {
      "value": "Asia/Jayapura",
      "label": "Merauke - Indonesia"
    },
    {
      "value": "America/Los_Angeles",
      "label": "Merced - United States"
    },
    {
      "value": "America/Mexico_City",
      "label": "Merida - Mexico"
    },
    {
      "value": "America/Caracas",
      "label": "Merida - Venezuela"
    },
    {
      "value": "Australia/Sydney",
      "label": "Merimbula - Australia"
    },
    {
      "value": "Africa/Cairo",
      "label": "Mersa-matruh - Egypt"
    },
    {
      "value": "Europe/Paris",
      "label": "Merville - France"
    },
    {
      "value": "Europe/Istanbul",
      "label": "Merzifon - Turkey"
    },
    {
      "value": "America/Phoenix",
      "label": "Mesa - United States"
    },
    {
      "value": "Europe/Paris",
      "label": "Metz - France"
    },
    {
      "value": "Asia/Jerusalem",
      "label": "Metzada - Israel"
    },
    {
      "value": "America/Tijuana",
      "label": "Mexicali - Mexico"
    },
    {
      "value": "America/Mexico_City",
      "label": "Mexico City - Mexico"
    },
    {
      "value": "Africa/Lusaka",
      "label": "Mfuwe - Zambia"
    },
    {
      "value": "America/New_York",
      "label": "Miami - United States"
    },
    {
      "value": "Indian/Antananarivo",
      "label": "Miandrivazo - Madagascar"
    },
    {
      "value": "Asia/Karachi",
      "label": "Mianwali - Pakistan"
    },
    {
      "value": "Asia/Shanghai",
      "label": "Mianyang - China"
    },
    {
      "value": "America/Chicago",
      "label": "Michigan City - United States"
    },
    {
      "value": "America/Grand_Turk",
      "label": "Middle Caicos - Turks and Caicos Islands"
    },
    {
      "value": "America/Chicago",
      "label": "Midland - United States"
    },
    {
      "value": "Pacific/Midway",
      "label": "Midway - Midway Islands"
    },
    {
      "value": "Asia/Tokyo",
      "label": "Miho - Japan"
    },
    {
      "value": "Europe/Helsinki",
      "label": "Mikkeli - Finland"
    },
    {
      "value": "Europe/Rome",
      "label": "Milan - Italy"
    },
    {
      "value": "Europe/London",
      "label": "Mildenhall - United Kingdom"
    },
    {
      "value": "Australia/Hobart",
      "label": "Mildura - Australia"
    },
    {
      "value": "America/Denver",
      "label": "Miles City - United States"
    },
    {
      "value": "Pacific/Auckland",
      "label": "Milford Sound - New Zealand"
    },
    {
      "value": "Pacific/Majuro",
      "label": "Mili Island - Marshall Islands"
    },
    {
      "value": "Australia/Darwin",
      "label": "Milingimbi - Australia"
    },
    {
      "value": "America/Chicago",
      "label": "Millington - United States"
    },
    {
      "value": "America/New_York",
      "label": "Millville - United States"
    },
    {
      "value": "Europe/Athens",
      "label": "Milos - Greece"
    },
    {
      "value": "America/Chicago",
      "label": "Milton - United States"
    },
    {
      "value": "America/Chicago",
      "label": "Milwaukee - United States"
    },
    {
      "value": "America/Sao_Paulo",
      "label": "Minacu - Brazil"
    },
    {
      "value": "Asia/Tokyo",
      "label": "Minami Daito - Japan"
    },
    {
      "value": "Asia/Tokyo",
      "label": "Minami Tori Shima - Japan"
    },
    {
      "value": "America/Mexico_City",
      "label": "Minatitlan - Mexico"
    },
    {
      "value": "America/Chicago",
      "label": "Mineral Wells - United States"
    },
    {
      "value": "Europe/Moscow",
      "label": "Mineralnye Vody - Russia"
    },
    {
      "value": "Asia/Dubai",
      "label": "Minhad AB - United Arab Emirates"
    },
    {
      "value": "Africa/Lagos",
      "label": "Minna - Nigeria"
    },
    {
      "value": "America/Chicago",
      "label": "Minneapolis - United States"
    },
    {
      "value": "America/Chicago",
      "label": "Minocqua - Woodruff - United States"
    },
    {
      "value": "America/Chicago",
      "label": "Minot - United States"
    },
    {
      "value": "Europe/Minsk",
      "label": "Minsk - Belarus"
    },
    {
      "value": "Europe/Minsk",
      "label": "Minsk 2 - Belarus"
    },
    {
      "value": "America/Miquelon",
      "label": "Miquelon - Saint Pierre and Miquelon"
    },
    {
      "value": "America/Los_Angeles",
      "label": "Miramar - United States"
    },
    {
      "value": "Asia/Kuala_Lumpur",
      "label": "Miri - Malaysia"
    },
    {
      "value": "Asia/Yakutsk",
      "label": "Mirnyj - Russia"
    },
    {
      "value": "Asia/Tokyo",
      "label": "Misawa - Japan"
    },
    {
      "value": "Pacific/Port_Moresby",
      "label": "Misima Island - Papua New Guinea"
    },
    {
      "value": "America/Denver",
      "label": "Missoula - United States"
    },
    {
      "value": "Pacific/Rarotonga",
      "label": "Mitiaro Island - Cook Islands"
    },
    {
      "value": "America/Bogota",
      "label": "Mitu - Colombia"
    },
    {
      "value": "Asia/Tokyo",
      "label": "Miyakejima - Japan"
    },
    {
      "value": "Asia/Tokyo",
      "label": "Miyako - Japan"
    },
    {
      "value": "Asia/Tokyo",
      "label": "Miyazaki - Japan"
    },
    {
      "value": "Africa/Addis_Ababa",
      "label": "Mizan Teferi - Ethiopia"
    },
    {
      "value": "Africa/Johannesburg",
      "label": "Mkuze - South Africa"
    },
    {
      "value": "Europe/Oslo",
      "label": "Mo i Rana - Norway"
    },
    {
      "value": "America/Havana",
      "label": "Moa - Cuba"
    },
    {
      "value": "America/Denver",
      "label": "Moab - United States"
    },
    {
      "value": "Pacific/Fiji",
      "label": "Moala - Fiji"
    },
    {
      "value": "America/Chicago",
      "label": "Mobile - United States"
    },
    {
      "value": "Africa/Luanda",
      "label": "Mocamedes - Angola"
    },
    {
      "value": "Africa/Maputo",
      "label": "Mocimboa Da Praia - Mozambique"
    },
    {
      "value": "America/Fortaleza",
      "label": "Mocord - Brazil"
    },
    {
      "value": "America/Los_Angeles",
      "label": "Modesto - United States"
    },
    {
      "value": "Europe/Berlin",
      "label": "Moenchengladbach - Germany"
    },
    {
      "value": "America/Paramaribo",
      "label": "Moengo - Suriname"
    },
    {
      "value": "Asia/Karachi",
      "label": "Moenjodaro - Pakistan"
    },
    {
      "value": "Africa/Mogadishu",
      "label": "Mogadishu - Somalia"
    },
    {
      "value": "Europe/Minsk",
      "label": "Mogilev - Belarus"
    },
    {
      "value": "Asia/Calcutta",
      "label": "Mohanbari - India"
    },
    {
      "value": "Asia/Shanghai",
      "label": "Mohe County - China"
    },
    {
      "value": "Europe/Stockholm",
      "label": "Mohed - Sweden"
    },
    {
      "value": "Indian/Comoro",
      "label": "Moheli - Comoros"
    },
    {
      "value": "America/Los_Angeles",
      "label": "Mojave - United States"
    },
    {
      "value": "Asia/Seoul",
      "label": "Mokpo - South Korea"
    },
    {
      "value": "Africa/Windhoek",
      "label": "Mokuti Lodge - Namibia"
    },
    {
      "value": "Europe/Oslo",
      "label": "Molde - Norway"
    },
    {
      "value": "America/Chicago",
      "label": "Moline - United States"
    },
    {
      "value": "Pacific/Honolulu",
      "label": "Molokai - United States"
    },
    {
      "value": "Africa/Nairobi",
      "label": "Mombasa - Kenya"
    },
    {
      "value": "Asia/Rangoon",
      "label": "Momeik - Burma"
    },
    {
      "value": "Pacific/Port_Moresby",
      "label": "Momote - Papua New Guinea"
    },
    {
      "value": "Africa/Tunis",
      "label": "Monastir - Tunisia"
    },
    {
      "value": "Asia/Tokyo",
      "label": "Monbetsu - Japan"
    },
    {
      "value": "America/Mexico_City",
      "label": "Monclova - Mexico"
    },
    {
      "value": "America/Halifax",
      "label": "Moncton - Canada"
    },
    {
      "value": "Asia/Rangoon",
      "label": "Mong Hsat - Burma"
    },
    {
      "value": "Africa/Lusaka",
      "label": "Mongu - Zambia"
    },
    {
      "value": "America/Chicago",
      "label": "Monroe - United States"
    },
    {
      "value": "Africa/Monrovia",
      "label": "Monrovia - Liberia"
    },
    {
      "value": "America/Toronto",
      "label": "Mont Joli - Canada"
    },
    {
      "value": "Europe/Paris",
      "label": "Montauban - France"
    },
    {
      "value": "America/Cordoba",
      "label": "Monte Caseros - Argentina"
    },
    {
      "value": "Europe/Lisbon",
      "label": "Monte Real - Portugal"
    },
    {
      "value": "America/Jamaica",
      "label": "Montego Bay - Jamaica"
    },
    {
      "value": "America/Los_Angeles",
      "label": "Monterey - United States"
    },
    {
      "value": "America/Bogota",
      "label": "Monteria - Colombia"
    },
    {
      "value": "America/Mexico_City",
      "label": "Monterrey - Mexico"
    },
    {
      "value": "America/Sao_Paulo",
      "label": "Montes Claros - Brazil"
    },
    {
      "value": "America/Chicago",
      "label": "Montgomery - United States"
    },
    {
      "value": "Europe/Paris",
      "label": "Montlucon-gueret - France"
    },
    {
      "value": "America/New_York",
      "label": "Montpelier - United States"
    },
    {
      "value": "Europe/Paris",
      "label": "Montpellier - France"
    },
    {
      "value": "America/Toronto",
      "label": "Montreal - Canada"
    },
    {
      "value": "America/Denver",
      "label": "Montrose CO - United States"
    },
    {
      "value": "America/Toronto",
      "label": "Mont-Tremblant - Canada"
    },
    {
      "value": "America/Denver",
      "label": "Monument Valley - United States"
    },
    {
      "value": "Asia/Rangoon",
      "label": "Monywa - Burma"
    },
    {
      "value": "Australia/Adelaide",
      "label": "Moomba - Australia"
    },
    {
      "value": "Pacific/Tahiti",
      "label": "Moorea - French Polynesia"
    },
    {
      "value": "America/Regina",
      "label": "Moose Jaw - Canada"
    },
    {
      "value": "America/Toronto",
      "label": "Moosonee - Canada"
    },
    {
      "value": "Africa/Bamako",
      "label": "Mopti - Mali"
    },
    {
      "value": "Europe/Stockholm",
      "label": "Mora - Sweden"
    },
    {
      "value": "Indian/Antananarivo",
      "label": "Morafenobe - Madagascar"
    },
    {
      "value": "Australia/Brisbane",
      "label": "Moranbah - Australia"
    },
    {
      "value": "Australia/Sydney",
      "label": "Moree - Australia"
    },
    {
      "value": "America/Mexico_City",
      "label": "Morelia - Mexico"
    },
    {
      "value": "America/New_York",
      "label": "Morgantown - United States"
    },
    {
      "value": "Europe/Paris",
      "label": "Morlaix - France"
    },
    {
      "value": "Australia/Brisbane",
      "label": "Mornington Island - Australia"
    },
    {
      "value": "Pacific/Port_Moresby",
      "label": "Moro - Papua New Guinea"
    },
    {
      "value": "Indian/Antananarivo",
      "label": "Morombe - Madagascar"
    },
    {
      "value": "Indian/Antananarivo",
      "label": "Morondava - Madagascar"
    },
    {
      "value": "Indian/Comoro",
      "label": "Moroni - Comoros"
    },
    {
      "value": "Asia/Jayapura",
      "label": "Morotai Island - Indonesia"
    },
    {
      "value": "America/New_York",
      "label": "Morristown - United States"
    },
    {
      "value": "America/New_York",
      "label": "Morrisville - United States"
    },
    {
      "value": "Australia/Sydney",
      "label": "Moruya - Australia"
    },
    {
      "value": "Europe/Moscow",
      "label": "Moscow - Russia"
    },
    {
      "value": "Europe/Oslo",
      "label": "Mosjoen - Norway"
    },
    {
      "value": "Europe/Sarajevo",
      "label": "Mostar - Bosnia and Herzegovina"
    },
    {
      "value": "Asia/Baghdad",
      "label": "Mosul - Iraq"
    },
    {
      "value": "Pacific/Auckland",
      "label": "Motueka - New Zealand"
    },
    {
      "value": "Africa/Libreville",
      "label": "Mouila - Gabon"
    },
    {
      "value": "Africa/Ndjamena",
      "label": "Moundou - Chad"
    },
    {
      "value": "America/New_York",
      "label": "Mount Clemens - United States"
    },
    {
      "value": "Pacific/Auckland",
      "label": "Mount Cook - New Zealand"
    },
    {
      "value": "Australia/Adelaide",
      "label": "Mount Gambier - Australia"
    },
    {
      "value": "Pacific/Port_Moresby",
      "label": "Mount Hagen - Papua New Guinea"
    },
    {
      "value": "America/New_York",
      "label": "Mount Holly - United States"
    },
    {
      "value": "Australia/Hobart",
      "label": "Mount Hotham - Australia"
    },
    {
      "value": "Australia/Brisbane",
      "label": "Mount Isa - Australia"
    },
    {
      "value": "Australia/Perth",
      "label": "Mount Keith - Australia"
    },
    {
      "value": "Australia/Perth",
      "label": "Mount Magnet - Australia"
    },
    {
      "value": "Atlantic/Stanley",
      "label": "Mount Pleasant - Falkland Islands"
    },
    {
      "value": "America/Denver",
      "label": "Mountain Home - United States"
    },
    {
      "value": "America/Los_Angeles",
      "label": "Mountain View - United States"
    },
    {
      "value": "America/Anchorage",
      "label": "Mountain Village - United States"
    },
    {
      "value": "Africa/Nairobi",
      "label": "Moyale - Kenya"
    },
    {
      "value": "Africa/Windhoek",
      "label": "Mpacha - Namibia"
    },
    {
      "value": "Africa/Johannesburg",
      "label": "Mpumalanga - South Africa"
    },
    {
      "value": "Africa/Dar_es_Salaam",
      "label": "Mtwara - Tanzania"
    },
    {
      "value": "Asia/Seoul",
      "label": "Muan - South Korea"
    },
    {
      "value": "Africa/Kinshasa",
      "label": "Muanda - Congo (Kinshasa)"
    },
    {
      "value": "Asia/Vientiane",
      "label": "Muang Xay - Laos"
    },
    {
      "value": "America/Fortaleza",
      "label": "Mucuri - Brazil"
    },
    {
      "value": "Asia/Shanghai",
      "label": "Mudanjiang - China"
    },
    {
      "value": "Australia/Sydney",
      "label": "Mudgee - Australia"
    },
    {
      "value": "Africa/Maputo",
      "label": "Mueda - Mozambique"
    },
    {
      "value": "Asia/Kuala_Lumpur",
      "label": "Mukah - Malaysia"
    },
    {
      "value": "Asia/Aden",
      "label": "Mukalla - Yemen"
    },
    {
      "value": "Europe/Paris",
      "label": "Mulhouse - France"
    },
    {
      "value": "Asia/Jayapura",
      "label": "Mulia - Indonesia"
    },
    {
      "value": "Asia/Karachi",
      "label": "Multan - Pakistan"
    },
    {
      "value": "Asia/Kuala_Lumpur",
      "label": "Mulu - Malaysia"
    },
    {
      "value": "Asia/Calcutta",
      "label": "Mumbai - India"
    },
    {
      "value": "America/New_York",
      "label": "Muncie - United States"
    },
    {
      "value": "Pacific/Guadalcanal",
      "label": "Munda - Solomon Islands"
    },
    {
      "value": "Europe/Berlin",
      "label": "Munich - Germany"
    },
    {
      "value": "Europe/Berlin",
      "label": "Munster - Germany"
    },
    {
      "value": "Europe/Madrid",
      "label": "Murcia - Spain"
    },
    {
      "value": "Asia/Ulaanbaatar",
      "label": "Muren - Mongolia"
    },
    {
      "value": "Europe/Moscow",
      "label": "Murmansk - Russia"
    },
    {
      "value": "Australia/Brisbane",
      "label": "Murray Island - Australia"
    },
    {
      "value": "America/Los_Angeles",
      "label": "Murrieta-Temecula - United States"
    },
    {
      "value": "Europe/Istanbul",
      "label": "Mus - Turkey"
    },
    {
      "value": "Asia/Muscat",
      "label": "Muscat - Oman"
    },
    {
      "value": "America/Chicago",
      "label": "Muscle Shoals - United States"
    },
    {
      "value": "America/New_York",
      "label": "Muskegon - United States"
    },
    {
      "value": "America/Toronto",
      "label": "Muskoka - Canada"
    },
    {
      "value": "America/Winnipeg",
      "label": "Muskrat Dam - Canada"
    },
    {
      "value": "Africa/Dar_es_Salaam",
      "label": "Musoma - Tanzania"
    },
    {
      "value": "America/St_Vincent",
      "label": "Mustique - Saint Vincent and the Grenadines"
    },
    {
      "value": "Africa/Harare",
      "label": "Mutare - Zimbabwe"
    },
    {
      "value": "Asia/Karachi",
      "label": "Muzaffarabad - Pakistan"
    },
    {
      "value": "Africa/Dar_es_Salaam",
      "label": "Mwanza - Tanzania"
    },
    {
      "value": "Asia/Rangoon",
      "label": "Myeik - Burma"
    },
    {
      "value": "Asia/Rangoon",
      "label": "Myitkyina - Burma"
    },
    {
      "value": "Europe/Athens",
      "label": "Mykonos - Greece"
    },
    {
      "value": "America/New_York",
      "label": "Myrtle Beach - United States"
    },
    {
      "value": "Asia/Calcutta",
      "label": "Mysore - India"
    },
    {
      "value": "Europe/Athens",
      "label": "Mytilini - Greece"
    },
    {
      "value": "Atlantic/Reykjavik",
      "label": "Myvatn - Iceland"
    },
    {
      "value": "Africa/Blantyre",
      "label": "Mzuzu - Malawi"
    },
    {
      "value": "Asia/Jayapura",
      "label": "Nabire - Indonesia"
    },
    {
      "value": "Africa/Maputo",
      "label": "Nacala - Mozambique"
    },
    {
      "value": "Asia/Yekaterinburg",
      "label": "Nadym - Russia"
    },
    {
      "value": "Pacific/Port_Moresby",
      "label": "Nadzab - Papua New Guinea"
    },
    {
      "value": "Asia/Manila",
      "label": "Naga - Philippines"
    },
    {
      "value": "Asia/Tokyo",
      "label": "Nagasaki - Japan"
    },
    {
      "value": "Asia/Tokyo",
      "label": "Nagoya - Japan"
    },
    {
      "value": "Asia/Calcutta",
      "label": "Nagpur - India"
    },
    {
      "value": "Asia/Makassar",
      "label": "Naha - Indonesia"
    },
    {
      "value": "America/Halifax",
      "label": "Nain - Canada"
    },
    {
      "value": "Asia/Calcutta",
      "label": "Nainital - India"
    },
    {
      "value": "Africa/Nairobi",
      "label": "Nairobi - Kenya"
    },
    {
      "value": "Asia/Baghdad",
      "label": "Najaf - Iraq"
    },
    {
      "value": "Asia/Tokyo",
      "label": "Nakashibetsu - Japan"
    },
    {
      "value": "Asia/Baku",
      "label": "Nakhchivan - Azerbaijan"
    },
    {
      "value": "Asia/Bangkok",
      "label": "Nakhon Pathom - Thailand"
    },
    {
      "value": "Asia/Bangkok",
      "label": "Nakhon Phanom - Thailand"
    },
    {
      "value": "Asia/Bangkok",
      "label": "Nakhon Sawan - Thailand"
    },
    {
      "value": "America/Toronto",
      "label": "Nakina - Canada"
    },
    {
      "value": "Europe/Moscow",
      "label": "Nalchik - Russia"
    },
    {
      "value": "Asia/Samarkand",
      "label": "Namangan - Uzbekistan"
    },
    {
      "value": "Africa/Maputo",
      "label": "Nampula - Mozambique"
    },
    {
      "value": "Asia/Rangoon",
      "label": "Namsang - Burma"
    },
    {
      "value": "Europe/Oslo",
      "label": "Namsos - Norway"
    },
    {
      "value": "Asia/Bangkok",
      "label": "Nan - Thailand"
    },
    {
      "value": "America/Vancouver",
      "label": "Nanaimo - Canada"
    },
    {
      "value": "Asia/Shanghai",
      "label": "Nanchang - China"
    },
    {
      "value": "Asia/Shanghai",
      "label": "Nanchong - China"
    },
    {
      "value": "Europe/Paris",
      "label": "Nancy - France"
    },
    {
      "value": "America/Costa_Rica",
      "label": "Nandayure - Costa Rica"
    },
    {
      "value": "Asia/Calcutta",
      "label": "Nanded - India"
    },
    {
      "value": "Pacific/Fiji",
      "label": "Nandi - Fiji"
    },
    {
      "value": "Asia/Jakarta",
      "label": "Nangapinoh - Indonesia"
    },
    {
      "value": "America/Toronto",
      "label": "Nanisivik - Canada"
    },
    {
      "value": "Asia/Shanghai",
      "label": "Nanjing - China"
    },
    {
      "value": "Asia/Tokyo",
      "label": "Nanki-shirahama - Japan"
    },
    {
      "value": "Asia/Shanghai",
      "label": "Nanning - China"
    },
    {
      "value": "America/Godthab",
      "label": "Nanortalik - Greenland"
    },
    {
      "value": "Europe/Paris",
      "label": "Nantes - France"
    },
    {
      "value": "America/New_York",
      "label": "Nantucket - United States"
    },
    {
      "value": "Asia/Shanghai",
      "label": "Nanyang - China"
    },
    {
      "value": "Africa/Nairobi",
      "label": "Nanyuki - Kenya"
    },
    {
      "value": "America/Los_Angeles",
      "label": "Napa - United States"
    },
    {
      "value": "America/Anchorage",
      "label": "Napakiak - United States"
    },
    {
      "value": "America/Anchorage",
      "label": "Napaskiak - United States"
    },
    {
      "value": "Pacific/Auckland",
      "label": "NAPIER - New Zealand"
    },
    {
      "value": "Europe/Rome",
      "label": "Naples - Italy"
    },
    {
      "value": "America/New_York",
      "label": "Naples - United States"
    },
    {
      "value": "Asia/Bangkok",
      "label": "Narathiwat - Thailand"
    },
    {
      "value": "Australia/Sydney",
      "label": "Narrabri - Australia"
    },
    {
      "value": "Australia/Sydney",
      "label": "Narrandera - Australia"
    },
    {
      "value": "America/Godthab",
      "label": "Narsaq - Greenland"
    },
    {
      "value": "America/Godthab",
      "label": "Narssarssuaq - Greenland"
    },
    {
      "value": "Europe/Oslo",
      "label": "Narvik - Norway"
    },
    {
      "value": "Europe/Moscow",
      "label": "Naryan-Mar - Russia"
    },
    {
      "value": "America/New_York",
      "label": "Nashua - United States"
    },
    {
      "value": "America/Chicago",
      "label": "Nashville - United States"
    },
    {
      "value": "Asia/Calcutta",
      "label": "Nasik Road - India"
    },
    {
      "value": "America/Nassau",
      "label": "Nassau - Bahamas"
    },
    {
      "value": "America/Fortaleza",
      "label": "Natal - Brazil"
    },
    {
      "value": "America/Toronto",
      "label": "Natashquan - Canada"
    },
    {
      "value": "America/Halifax",
      "label": "Natuashish - Canada"
    },
    {
      "value": "Pacific/Nauru",
      "label": "Nauru - Nauru"
    },
    {
      "value": "Pacific/Fiji",
      "label": "Nausori - Fiji"
    },
    {
      "value": "America/Sao_Paulo",
      "label": "Navegantes - Brazil"
    },
    {
      "value": "Asia/Samarkand",
      "label": "Navoi - Uzbekistan"
    },
    {
      "value": "Asia/Karachi",
      "label": "Nawabshah - Pakistan"
    },
    {
      "value": "Asia/Rangoon",
      "label": "Naypyidaw - Burma"
    },
    {
      "value": "America/Lima",
      "label": "Nazca - Peru"
    },
    {
      "value": "Africa/Ndjamena",
      "label": "N'djamena - Chad"
    },
    {
      "value": "Africa/Lusaka",
      "label": "Ndola - Zambia"
    },
    {
      "value": "Europe/Athens",
      "label": "Nea Anghialos - Greece"
    },
    {
      "value": "America/Buenos_Aires",
      "label": "Necochea - Argentina"
    },
    {
      "value": "America/Scoresbysund",
      "label": "Neerlerit Inaat - Greenland"
    },
    {
      "value": "Asia/Yekaterinburg",
      "label": "Nefteyugansk - Russia"
    },
    {
      "value": "Africa/Luanda",
      "label": "Negage - Angola"
    },
    {
      "value": "America/Jamaica",
      "label": "Negril - Jamaica"
    },
    {
      "value": "America/Bogota",
      "label": "Neiva - Colombia"
    },
    {
      "value": "Asia/Riyadh",
      "label": "Nejran - Saudi Arabia"
    },
    {
      "value": "Pacific/Auckland",
      "label": "Nelson - New Zealand"
    },
    {
      "value": "America/Anchorage",
      "label": "Nelson Lagoon - United States"
    },
    {
      "value": "Africa/Johannesburg",
      "label": "Nelspruit - South Africa"
    },
    {
      "value": "Africa/Nouakchott",
      "label": "Nema - Mauritania"
    },
    {
      "value": "America/Toronto",
      "label": "Nemiscau - Canada"
    },
    {
      "value": "Asia/Katmandu",
      "label": "Nepalgunj - Nepal"
    },
    {
      "value": "Europe/Zurich",
      "label": "Neuchatel - Switzerland"
    },
    {
      "value": "Europe/Berlin",
      "label": "Neumuenster - Germany"
    },
    {
      "value": "America/Argentina/Salta",
      "label": "Neuquen - Argentina"
    },
    {
      "value": "Asia/Jerusalem",
      "label": "Nevatim - Israel"
    },
    {
      "value": "Europe/Paris",
      "label": "Nevers - France"
    },
    {
      "value": "Europe/Istanbul",
      "label": "Nevsehir - Turkey"
    },
    {
      "value": "America/New_York",
      "label": "New Bedford - United States"
    },
    {
      "value": "America/New_York",
      "label": "New Bern - United States"
    },
    {
      "value": "America/New_York",
      "label": "New Haven - United States"
    },
    {
      "value": "America/Chicago",
      "label": "New Orleans - United States"
    },
    {
      "value": "America/New_York",
      "label": "New Philadelpha - United States"
    },
    {
      "value": "Pacific/Auckland",
      "label": "New Plymouth - New Zealand"
    },
    {
      "value": "America/Anchorage",
      "label": "New Stuyahok - United States"
    },
    {
      "value": "America/New_York",
      "label": "New York - United States"
    },
    {
      "value": "America/New_York",
      "label": "Newark - United States"
    },
    {
      "value": "America/New_York",
      "label": "Newburgh - United States"
    },
    {
      "value": "Australia/Sydney",
      "label": "Newcastle - Australia"
    },
    {
      "value": "Africa/Johannesburg",
      "label": "Newcastle - South Africa"
    },
    {
      "value": "Europe/London",
      "label": "Newcastle - United Kingdom"
    },
    {
      "value": "Australia/Perth",
      "label": "Newman - Australia"
    },
    {
      "value": "America/Los_Angeles",
      "label": "Newport - United States"
    },
    {
      "value": "America/New_York",
      "label": "Newport News - United States"
    },
    {
      "value": "Europe/London",
      "label": "Newquai - United Kingdom"
    },
    {
      "value": "America/Chicago",
      "label": "Newton - United States"
    },
    {
      "value": "Africa/Douala",
      "label": "N'gaoundere - Cameroon"
    },
    {
      "value": "Pacific/Fiji",
      "label": "Ngau - Fiji"
    },
    {
      "value": "Asia/Saigon",
      "label": "Nha Trang - Vietnam"
    },
    {
      "value": "Asia/Saigon",
      "label": "Nhatrang - Vietnam"
    },
    {
      "value": "America/New_York",
      "label": "Niagara Falls - United States"
    },
    {
      "value": "Africa/Niamey",
      "label": "Niamey - Niger"
    },
    {
      "value": "Africa/Lome",
      "label": "Niatougou - Togo"
    },
    {
      "value": "Pacific/Tahiti",
      "label": "Niau - French Polynesia"
    },
    {
      "value": "Europe/Paris",
      "label": "Nice - France"
    },
    {
      "value": "Asia/Nicosia",
      "label": "Nicosia - Cyprus"
    },
    {
      "value": "America/Paramaribo",
      "label": "Nieuw Nickerie - Suriname"
    },
    {
      "value": "America/Anchorage",
      "label": "Nightmute - United States"
    },
    {
      "value": "Asia/Tokyo",
      "label": "Niigata - Japan"
    },
    {
      "value": "America/Anchorage",
      "label": "Nikolai - United States"
    },
    {
      "value": "Europe/Kiev",
      "label": "Nikolayev - Ukraine"
    },
    {
      "value": "America/Anchorage",
      "label": "Nikolski - United States"
    },
    {
      "value": "Pacific/Tarawa",
      "label": "Nikunau - Kiribati"
    },
    {
      "value": "Europe/Paris",
      "label": "Nimes - France"
    },
    {
      "value": "Asia/Shanghai",
      "label": "Ninbo - China"
    },
    {
      "value": "Africa/Kinshasa",
      "label": "Nioki - Congo (Kinshasa)"
    },
    {
      "value": "Europe/Paris",
      "label": "Niort - France"
    },
    {
      "value": "Europe/Belgrade",
      "label": "Nis - Serbia"
    },
    {
      "value": "Pacific/Tongatapu",
      "label": "Niuatoputapu - Tonga"
    },
    {
      "value": "Europe/Moscow",
      "label": "Nizhnekamsk - Russia"
    },
    {
      "value": "Asia/Yekaterinburg",
      "label": "Nizhnevartovsk - Russia"
    },
    {
      "value": "Europe/Moscow",
      "label": "Nizhniy Novgorod - Russia"
    },
    {
      "value": "America/Anchorage",
      "label": "Noatak - United States"
    },
    {
      "value": "America/Hermosillo",
      "label": "Nogales - Mexico"
    },
    {
      "value": "America/Phoenix",
      "label": "Nogales - United States"
    },
    {
      "value": "America/Anchorage",
      "label": "Nome - United States"
    },
    {
      "value": "America/Anchorage",
      "label": "Nondalton - United States"
    },
    {
      "value": "Pacific/Tarawa",
      "label": "Nonouti - Kiribati"
    },
    {
      "value": "America/Anchorage",
      "label": "Noorvik - United States"
    },
    {
      "value": "Europe/Berlin",
      "label": "Norden - Germany"
    },
    {
      "value": "Europe/Berlin",
      "label": "Norderney - Germany"
    },
    {
      "value": "Atlantic/Reykjavik",
      "label": "Nordfjordur - Iceland"
    },
    {
      "value": "Europe/Berlin",
      "label": "Nordholz - Germany"
    },
    {
      "value": "America/New_York",
      "label": "Norfolk - United States"
    },
    {
      "value": "Pacific/Norfolk",
      "label": "Norfolk Island - Norfolk Island"
    },
    {
      "value": "Asia/Krasnoyarsk",
      "label": "Norilsk - Russia"
    },
    {
      "value": "America/Edmonton",
      "label": "Norman Wells - Canada"
    },
    {
      "value": "America/Nassau",
      "label": "Norman's Cay - Bahamas"
    },
    {
      "value": "Australia/Brisbane",
      "label": "Normanton - Australia"
    },
    {
      "value": "Pacific/Efate",
      "label": "Norsup - Vanuatu"
    },
    {
      "value": "America/Regina",
      "label": "North Battleford - Canada"
    },
    {
      "value": "America/Toronto",
      "label": "North Bay - Canada"
    },
    {
      "value": "America/Los_Angeles",
      "label": "North Bend - United States"
    },
    {
      "value": "America/Grand_Turk",
      "label": "North Caicos - Turks and Caicos Islands"
    },
    {
      "value": "Europe/London",
      "label": "North Connel - United Kingdom"
    },
    {
      "value": "America/Nassau",
      "label": "North Eleuthera - Bahamas"
    },
    {
      "value": "America/New_York",
      "label": "North Kingstown - United States"
    },
    {
      "value": "America/New_York",
      "label": "North Myrtle Beach - United States"
    },
    {
      "value": "America/Chicago",
      "label": "North Platte - United States"
    },
    {
      "value": "Europe/London",
      "label": "North Ronaldsay - United Kingdom"
    },
    {
      "value": "America/Winnipeg",
      "label": "North Spirit Lake - Canada"
    },
    {
      "value": "America/New_York",
      "label": "North Wilkesboro - United States"
    },
    {
      "value": "Europe/London",
      "label": "Northolt - United Kingdom"
    },
    {
      "value": "America/Anchorage",
      "label": "Northway - United States"
    },
    {
      "value": "America/Winnipeg",
      "label": "Norway House - Canada"
    },
    {
      "value": "Europe/London",
      "label": "Norwich - United Kingdom"
    },
    {
      "value": "America/New_York",
      "label": "Norwood - United States"
    },
    {
      "value": "America/Costa_Rica",
      "label": "Nosara Beach - Costa Rica"
    },
    {
      "value": "Asia/Tehran",
      "label": "Noshahr - Iran"
    },
    {
      "value": "Indian/Antananarivo",
      "label": "Nosy-be - Madagascar"
    },
    {
      "value": "Europe/Oslo",
      "label": "Notodden - Norway"
    },
    {
      "value": "Europe/London",
      "label": "Nottingham - United Kingdom"
    },
    {
      "value": "Africa/Nouakchott",
      "label": "Nouadhibou - Mauritania"
    },
    {
      "value": "Africa/Nouakchott",
      "label": "Nouakschott - Mauritania"
    },
    {
      "value": "Pacific/Noumea",
      "label": "Noumea - New Caledonia"
    },
    {
      "value": "America/Sao_Paulo",
      "label": "Nova Iguacu - Brazil"
    },
    {
      "value": "America/Los_Angeles",
      "label": "Novato - United States"
    },
    {
      "value": "America/Boa_Vista",
      "label": "Novo Aripuana - Brazil"
    },
    {
      "value": "Asia/Krasnoyarsk",
      "label": "Novokuznetsk - Russia"
    },
    {
      "value": "Asia/Krasnoyarsk",
      "label": "Novosibirsk - Russia"
    },
    {
      "value": "Asia/Yekaterinburg",
      "label": "Novy Urengoy - Russia"
    },
    {
      "value": "Australia/Sydney",
      "label": "Nowra - Australia"
    },
    {
      "value": "Asia/Yekaterinburg",
      "label": "Noyabrsk - Russia"
    },
    {
      "value": "Europe/Berlin",
      "label": "Nuernberg - Germany"
    },
    {
      "value": "America/Havana",
      "label": "Nueva Gerona - Cuba"
    },
    {
      "value": "America/Mazatlan",
      "label": "Nuevo Casas Grandes - Mexico"
    },
    {
      "value": "America/Mexico_City",
      "label": "Nuevo Laredo - Mexico"
    },
    {
      "value": "America/Anchorage",
      "label": "Nuiqsut - United States"
    },
    {
      "value": "Pacific/Marquesas",
      "label": "Nuku Hiva - French Polynesia"
    },
    {
      "value": "Asia/Samarkand",
      "label": "Nukus - Uzbekistan"
    },
    {
      "value": "America/Anchorage",
      "label": "Nulato - United States"
    },
    {
      "value": "Europe/Sarajevo",
      "label": "Null - Bosnia and Herzegovina"
    },
    {
      "value": "America/Chicago",
      "label": "Null - United States"
    },
    {
      "value": "Asia/Makassar",
      "label": "Nunukan-Nunukan Island - Indonesia"
    },
    {
      "value": "America/Bogota",
      "label": "Nuquí - Colombia"
    },
    {
      "value": "Asia/Yekaterinburg",
      "label": "Nyagan - Russia"
    },
    {
      "value": "Africa/Khartoum",
      "label": "Nyala - Sudan"
    },
    {
      "value": "Africa/Nairobi",
      "label": "NYERI - Kenya"
    },
    {
      "value": "Asia/Shanghai",
      "label": "Nyingchi - China"
    },
    {
      "value": "Africa/Luanda",
      "label": "Nzagi - Angola"
    },
    {
      "value": "Australia/Brisbane",
      "label": "Oakey - Australia"
    },
    {
      "value": "America/Los_Angeles",
      "label": "Oakland - United States"
    },
    {
      "value": "Pacific/Auckland",
      "label": "Oamaru - New Zealand"
    },
    {
      "value": "America/Mexico_City",
      "label": "Oaxaca - Mexico"
    },
    {
      "value": "Europe/Berlin",
      "label": "Oberpfaffenhofen - Germany"
    },
    {
      "value": "Asia/Tokyo",
      "label": "Obihiro - Japan"
    },
    {
      "value": "Africa/Djibouti",
      "label": "Obock - Djibouti"
    },
    {
      "value": "America/New_York",
      "label": "Ocala - United States"
    },
    {
      "value": "America/Bogota",
      "label": "Ocana - Colombia"
    },
    {
      "value": "America/New_York",
      "label": "Ocean Reef Club Airport - United States"
    },
    {
      "value": "America/New_York",
      "label": "Oceana - United States"
    },
    {
      "value": "America/Jamaica",
      "label": "Ocho Rios - Jamaica"
    },
    {
      "value": "Asia/Tokyo",
      "label": "Odate Noshiro - Japan"
    },
    {
      "value": "Europe/Copenhagen",
      "label": "Odense - Denmark"
    },
    {
      "value": "Europe/Kiev",
      "label": "Odessa - Ukraine"
    },
    {
      "value": "Europe/London",
      "label": "Odiham - United Kingdom"
    },
    {
      "value": "America/Denver",
      "label": "Ogden - United States"
    },
    {
      "value": "America/New_York",
      "label": "Ogdensburg - United States"
    },
    {
      "value": "America/Toronto",
      "label": "Ogoki Post - Canada"
    },
    {
      "value": "Pacific/Auckland",
      "label": "Ohakea - New Zealand"
    },
    {
      "value": "Europe/Skopje",
      "label": "Ohrid - Macedonia"
    },
    {
      "value": "America/Fortaleza",
      "label": "Oioiapoque - Brazil"
    },
    {
      "value": "Asia/Tokyo",
      "label": "Oita - Japan"
    },
    {
      "value": "Africa/Windhoek",
      "label": "Okaukuejo - Namibia"
    },
    {
      "value": "Asia/Tokyo",
      "label": "Okayama - Japan"
    },
    {
      "value": "America/New_York",
      "label": "Okeechobee - United States"
    },
    {
      "value": "Asia/Vladivostok",
      "label": "Okhotsk - Russia"
    },
    {
      "value": "Asia/Tokyo",
      "label": "Oki Island - Japan"
    },
    {
      "value": "Asia/Tokyo",
      "label": "Okierabu - Japan"
    },
    {
      "value": "Asia/Tokyo",
      "label": "Okinawa - Japan"
    },
    {
      "value": "America/Chicago",
      "label": "Oklahoma City - United States"
    },
    {
      "value": "America/Chicago",
      "label": "Okmulgee - United States"
    },
    {
      "value": "Africa/Libreville",
      "label": "Okondja - Gabon"
    },
    {
      "value": "Asia/Tokyo",
      "label": "Okushiri - Japan"
    },
    {
      "value": "America/Chicago",
      "label": "Olathe - United States"
    },
    {
      "value": "America/Buenos_Aires",
      "label": "Olavarria - Argentina"
    },
    {
      "value": "Europe/Rome",
      "label": "Olbia - Italy"
    },
    {
      "value": "America/Vancouver",
      "label": "Old Crow - Canada"
    },
    {
      "value": "Asia/Yakutsk",
      "label": "Olekminsk - Russia"
    },
    {
      "value": "Asia/Yakutsk",
      "label": "Olenyok - Russia"
    },
    {
      "value": "Asia/Hovd",
      "label": "Olgii - Mongolia"
    },
    {
      "value": "America/Chicago",
      "label": "Olive Branch - United States"
    },
    {
      "value": "Asia/Manila",
      "label": "Olongapo City - Philippines"
    },
    {
      "value": "America/Los_Angeles",
      "label": "Olympia - United States"
    },
    {
      "value": "Australia/Adelaide",
      "label": "Olympic Dam - Australia"
    },
    {
      "value": "America/Chicago",
      "label": "Omaha - United States"
    },
    {
      "value": "Africa/Libreville",
      "label": "Omboue Hospial - Gabon"
    },
    {
      "value": "Asia/Tehran",
      "label": "Omidyeh - Iran"
    },
    {
      "value": "Asia/Omsk",
      "label": "Omsk - Russia"
    },
    {
      "value": "Africa/Windhoek",
      "label": "Ondangwa - Namibia"
    },
    {
      "value": "Africa/Luanda",
      "label": "Ondjiva - Angola"
    },
    {
      "value": "Australia/Perth",
      "label": "Onslow - Australia"
    },
    {
      "value": "America/Los_Angeles",
      "label": "Ontario - United States"
    },
    {
      "value": "Pacific/Honolulu",
      "label": "Opolu - United States"
    },
    {
      "value": "Europe/Bucharest",
      "label": "Oradea - Romania"
    },
    {
      "value": "Africa/Algiers",
      "label": "Oran - Algeria"
    },
    {
      "value": "America/Argentina/Salta",
      "label": "Oran - Argentina"
    },
    {
      "value": "Australia/Sydney",
      "label": "Orange - Australia"
    },
    {
      "value": "Europe/Paris",
      "label": "Orange - France"
    },
    {
      "value": "Africa/Windhoek",
      "label": "Oranjemund - Namibia"
    },
    {
      "value": "America/Aruba",
      "label": "Oranjestad - Aruba"
    },
    {
      "value": "America/Curacao",
      "label": "Oranjestad - Netherlands Antilles"
    },
    {
      "value": "Africa/Gaborone",
      "label": "Orapa - Botswana"
    },
    {
      "value": "Europe/Stockholm",
      "label": "Orebro - Sweden"
    },
    {
      "value": "Asia/Yekaterinburg",
      "label": "Orenburg - Russia"
    },
    {
      "value": "America/Guyana",
      "label": "Orinduik - Guyana"
    },
    {
      "value": "Europe/Rome",
      "label": "Oristano - Italy"
    },
    {
      "value": "America/Belem",
      "label": "Oriximina - Brazil"
    },
    {
      "value": "Europe/Oslo",
      "label": "Orland - Norway"
    },
    {
      "value": "America/New_York",
      "label": "Orlando - United States"
    },
    {
      "value": "Europe/Paris",
      "label": "Orleans - France"
    },
    {
      "value": "Asia/Karachi",
      "label": "Ormara Raik - Pakistan"
    },
    {
      "value": "Asia/Manila",
      "label": "Ormoc City - Philippines"
    },
    {
      "value": "Europe/Stockholm",
      "label": "Ornskoldsvik - Sweden"
    },
    {
      "value": "Asia/Yekaterinburg",
      "label": "Orsk - Russia"
    },
    {
      "value": "Europe/Oslo",
      "label": "Orsta-Volda - Norway"
    },
    {
      "value": "America/La_Paz",
      "label": "Oruro - Bolivia"
    },
    {
      "value": "Asia/Tokyo",
      "label": "Osaka - Japan"
    },
    {
      "value": "Asia/Seoul",
      "label": "Osan - South Korea"
    },
    {
      "value": "America/New_York",
      "label": "Oscoda - United States"
    },
    {
      "value": "Asia/Bishkek",
      "label": "Osh - Kyrgyzstan"
    },
    {
      "value": "America/Toronto",
      "label": "Oshawa - Canada"
    },
    {
      "value": "Asia/Tokyo",
      "label": "Oshima - Japan"
    },
    {
      "value": "America/Chicago",
      "label": "Oshkosh - United States"
    },
    {
      "value": "Europe/Zagreb",
      "label": "Osijek - Croatia"
    },
    {
      "value": "Europe/Stockholm",
      "label": "Oskarshamn - Sweden"
    },
    {
      "value": "Europe/Oslo",
      "label": "Oslo - Norway"
    },
    {
      "value": "America/Santiago",
      "label": "Osorno - Chile"
    },
    {
      "value": "Europe/Brussels",
      "label": "Ostend - Belgium"
    },
    {
      "value": "Europe/Prague",
      "label": "Ostrava - Czech Republic"
    },
    {
      "value": "America/Toronto",
      "label": "Ottawa - Canada"
    },
    {
      "value": "America/Bogota",
      "label": "Otu - Colombia"
    },
    {
      "value": "Africa/Ouagadougou",
      "label": "Ouagadougou - Burkina Faso"
    },
    {
      "value": "Africa/Algiers",
      "label": "Ouargla - Algeria"
    },
    {
      "value": "Africa/Casablanca",
      "label": "Ouarzazate - Morocco"
    },
    {
      "value": "Africa/Johannesburg",
      "label": "Oudtshoorn - South Africa"
    },
    {
      "value": "Africa/Brazzaville",
      "label": "Ouesso - Congo (Kinshasa)"
    },
    {
      "value": "Africa/Casablanca",
      "label": "Oujda - Morocco"
    },
    {
      "value": "Europe/Helsinki",
      "label": "Oulu - Finland"
    },
    {
      "value": "America/Belem",
      "label": "Ourilandia do Norte - Brazil"
    },
    {
      "value": "Pacific/Noumea",
      "label": "Ouvea - New Caledonia"
    },
    {
      "value": "Asia/Jerusalem",
      "label": "Ovda - Israel"
    },
    {
      "value": "Africa/Johannesburg",
      "label": "Overberg - South Africa"
    },
    {
      "value": "Africa/Brazzaville",
      "label": "Owando - Congo (Kinshasa)"
    },
    {
      "value": "America/Chicago",
      "label": "Owensboro - United States"
    },
    {
      "value": "Europe/London",
      "label": "Oxford - United Kingdom"
    },
    {
      "value": "America/New_York",
      "label": "Oxford - United States"
    },
    {
      "value": "America/Winnipeg",
      "label": "Oxford House - Canada"
    },
    {
      "value": "America/Los_Angeles",
      "label": "Oxnard - United States"
    },
    {
      "value": "Africa/Libreville",
      "label": "Oyem - Gabon"
    },
    {
      "value": "Asia/Manila",
      "label": "Ozamis - Philippines"
    },
    {
      "value": "America/Chicago",
      "label": "Ozona - United States"
    },
    {
      "value": "Europe/Budapest",
      "label": "Pécs-Pogány - Hungary"
    },
    {
      "value": "Pacific/Efate",
      "label": "Paama Island - Vanuatu"
    },
    {
      "value": "America/Godthab",
      "label": "Paamiut - Greenland"
    },
    {
      "value": "Asia/Jakarta",
      "label": "Padang - Indonesia"
    },
    {
      "value": "Asia/Jakarta",
      "label": "Padang Sidempuan - Indonesia"
    },
    {
      "value": "Europe/Berlin",
      "label": "Paderborn - Germany"
    },
    {
      "value": "Europe/Rome",
      "label": "Padova - Italy"
    },
    {
      "value": "America/Chicago",
      "label": "PADUCAH - United States"
    },
    {
      "value": "Asia/Manila",
      "label": "Pagadian - Philippines"
    },
    {
      "value": "America/Phoenix",
      "label": "Page - United States"
    },
    {
      "value": "Pacific/Pago_Pago",
      "label": "Pago Pago - American Samoa"
    },
    {
      "value": "America/New_York",
      "label": "Pahokee - United States"
    },
    {
      "value": "Asia/Bangkok",
      "label": "Pai - Thailand"
    },
    {
      "value": "Europe/London",
      "label": "Pailton - United Kingdom"
    },
    {
      "value": "Europe/Stockholm",
      "label": "Pajala - Sweden"
    },
    {
      "value": "Asia/Rangoon",
      "label": "Pakhokku - Burma"
    },
    {
      "value": "Asia/Vientiane",
      "label": "Pakse - Laos"
    },
    {
      "value": "America/Chicago",
      "label": "Palacios - United States"
    },
    {
      "value": "Europe/Vilnius",
      "label": "Palanga - Lithuania"
    },
    {
      "value": "Asia/Jakarta",
      "label": "Palangkaraya - Indonesia"
    },
    {
      "value": "Asia/Jakarta",
      "label": "Palembang - Indonesia"
    },
    {
      "value": "Europe/Rome",
      "label": "Palermo - Italy"
    },
    {
      "value": "Australia/Brisbane",
      "label": "Palm Island - Australia"
    },
    {
      "value": "America/Los_Angeles",
      "label": "Palm Springs - United States"
    },
    {
      "value": "Europe/Madrid",
      "label": "Palma de Mallorca - Spain"
    },
    {
      "value": "America/Costa_Rica",
      "label": "Palmar Sur - Costa Rica"
    },
    {
      "value": "America/Caracas",
      "label": "Palmarito - Venezuela"
    },
    {
      "value": "America/Fortaleza",
      "label": "Palmas - Brazil"
    },
    {
      "value": "America/Los_Angeles",
      "label": "Palmdale - United States"
    },
    {
      "value": "America/Anchorage",
      "label": "Palmer - United States"
    },
    {
      "value": "Pacific/Auckland",
      "label": "Palmerston North - New Zealand"
    },
    {
      "value": "Asia/Damascus",
      "label": "Palmyra - Syria"
    },
    {
      "value": "America/Los_Angeles",
      "label": "Palo Alto - United States"
    },
    {
      "value": "America/Paramaribo",
      "label": "Paloemeu - Suriname"
    },
    {
      "value": "Asia/Makassar",
      "label": "Palu - Indonesia"
    },
    {
      "value": "Europe/Madrid",
      "label": "Pamplona - Spain"
    },
    {
      "value": "America/Panama",
      "label": "Panama - Panama"
    },
    {
      "value": "America/Panama",
      "label": "Panama City - Panama"
    },
    {
      "value": "America/Chicago",
      "label": "Panama City - United States"
    },
    {
      "value": "Europe/Vilnius",
      "label": "Panevezys - Lithuania"
    },
    {
      "value": "Asia/Jakarta",
      "label": "Pangkal Pinang - Indonesia"
    },
    {
      "value": "Asia/Jakarta",
      "label": "Pangkalan Bun - Indonesia"
    },
    {
      "value": "Asia/Kuala_Lumpur",
      "label": "Pangkor Island - Malaysia"
    },
    {
      "value": "America/Toronto",
      "label": "Pangnirtung - Canada"
    },
    {
      "value": "Asia/Karachi",
      "label": "Panjgur - Pakistan"
    },
    {
      "value": "Europe/Rome",
      "label": "Pantelleria - Italy"
    },
    {
      "value": "Europe/London",
      "label": "Papa Westray - United Kingdom"
    },
    {
      "value": "Pacific/Tahiti",
      "label": "Papeete - French Polynesia"
    },
    {
      "value": "Asia/Nicosia",
      "label": "Paphos - Cyprus"
    },
    {
      "value": "Australia/Perth",
      "label": "Paraburdoo - Australia"
    },
    {
      "value": "Asia/Karachi",
      "label": "Parachinar - Pakistan"
    },
    {
      "value": "America/Caracas",
      "label": "Paraguana - Venezuela"
    },
    {
      "value": "America/Paramaribo",
      "label": "Paramaribo - Suriname"
    },
    {
      "value": "America/Cordoba",
      "label": "Parana - Argentina"
    },
    {
      "value": "Pacific/Auckland",
      "label": "Paraparaumu - New Zealand"
    },
    {
      "value": "America/Belem",
      "label": "Parauapebas - Brazil"
    },
    {
      "value": "Europe/Berlin",
      "label": "Parchim - Germany"
    },
    {
      "value": "Europe/Prague",
      "label": "Pardubice - Czech Republic"
    },
    {
      "value": "America/Boa_Vista",
      "label": "Parintins - Brazil"
    },
    {
      "value": "Europe/Paris",
      "label": "Paris - France"
    },
    {
      "value": "America/New_York",
      "label": "PARKERSBURG - United States"
    },
    {
      "value": "Australia/Sydney",
      "label": "Parkes - Australia"
    },
    {
      "value": "Europe/Rome",
      "label": "Parma - Italy"
    },
    {
      "value": "America/Fortaleza",
      "label": "Parnaiba - Brazil"
    },
    {
      "value": "Europe/Tallinn",
      "label": "Parnu - Estonia"
    },
    {
      "value": "Europe/Athens",
      "label": "Paros - Greece"
    },
    {
      "value": "America/Toronto",
      "label": "Parry Sound - Canada"
    },
    {
      "value": "Asia/Tehran",
      "label": "Parsabad - Iran"
    },
    {
      "value": "America/Los_Angeles",
      "label": "Pasco - United States"
    },
    {
      "value": "Asia/Karachi",
      "label": "Pasni - Pakistan"
    },
    {
      "value": "America/Cordoba",
      "label": "Paso De Los Libres - Argentina"
    },
    {
      "value": "America/Sao_Paulo",
      "label": "Passo Fundo - Brazil"
    },
    {
      "value": "America/Guayaquil",
      "label": "Pastaza - Ecuador"
    },
    {
      "value": "America/Bogota",
      "label": "Pasto - Colombia"
    },
    {
      "value": "Asia/Calcutta",
      "label": "Pathankot - India"
    },
    {
      "value": "Asia/Rangoon",
      "label": "Pathein - Burma"
    },
    {
      "value": "Asia/Calcutta",
      "label": "Patina - India"
    },
    {
      "value": "America/Sao_Paulo",
      "label": "Patos de Minas - Brazil"
    },
    {
      "value": "Europe/Athens",
      "label": "Patras - Greece"
    },
    {
      "value": "Atlantic/Reykjavik",
      "label": "Patreksfjordur - Iceland"
    },
    {
      "value": "Asia/Bangkok",
      "label": "Pattani - Thailand"
    },
    {
      "value": "Asia/Bangkok",
      "label": "Pattaya - Thailand"
    },
    {
      "value": "America/New_York",
      "label": "Patuxent River - United States"
    },
    {
      "value": "Europe/Paris",
      "label": "Pau - France"
    },
    {
      "value": "America/Edmonton",
      "label": "Paulatuk - Canada"
    },
    {
      "value": "America/Fortaleza",
      "label": "Paulo Alfonso - Brazil"
    },
    {
      "value": "Asia/Qyzylorda",
      "label": "Pavlodar - Kazakhstan"
    },
    {
      "value": "Asia/Singapore",
      "label": "Paya Lebar - Singapore"
    },
    {
      "value": "America/Edmonton",
      "label": "Peace River - Canada"
    },
    {
      "value": "America/Toronto",
      "label": "Peawanuck - Canada"
    },
    {
      "value": "Europe/Moscow",
      "label": "Pechora - Russia"
    },
    {
      "value": "America/Chicago",
      "label": "Pecos - United States"
    },
    {
      "value": "America/Asuncion",
      "label": "Pedro Juan Caballero - Paraguay"
    },
    {
      "value": "Europe/Berlin",
      "label": "Peenemunde - Germany"
    },
    {
      "value": "America/Buenos_Aires",
      "label": "Pehuajo - Argentina"
    },
    {
      "value": "Asia/Jakarta",
      "label": "Pekanbaru - Indonesia"
    },
    {
      "value": "America/New_York",
      "label": "Pellston - United States"
    },
    {
      "value": "America/Edmonton",
      "label": "Pelly Bay - Canada"
    },
    {
      "value": "America/Sao_Paulo",
      "label": "Pelotas - Brazil"
    },
    {
      "value": "Africa/Maputo",
      "label": "Pemba - Mozambique"
    },
    {
      "value": "Africa/Dar_es_Salaam",
      "label": "Pemba - Tanzania"
    },
    {
      "value": "America/Chicago",
      "label": "Pembina - United States"
    },
    {
      "value": "America/Toronto",
      "label": "Pembroke - Canada"
    },
    {
      "value": "Asia/Kuala_Lumpur",
      "label": "Penang - Malaysia"
    },
    {
      "value": "Asia/Calcutta",
      "label": "Pendicherry - India"
    },
    {
      "value": "America/Los_Angeles",
      "label": "Pendleton - United States"
    },
    {
      "value": "Australia/Adelaide",
      "label": "Penneshaw - Australia"
    },
    {
      "value": "Pacific/Rarotonga",
      "label": "Penrhyn Island - Cook Islands"
    },
    {
      "value": "America/Chicago",
      "label": "Pensacola - United States"
    },
    {
      "value": "Pacific/Efate",
      "label": "Pentecost Island - Vanuatu"
    },
    {
      "value": "America/Vancouver",
      "label": "Penticton - Canada"
    },
    {
      "value": "Europe/Moscow",
      "label": "Penza - Russia"
    },
    {
      "value": "Europe/London",
      "label": "Penzance - United Kingdom"
    },
    {
      "value": "America/Chicago",
      "label": "Peoria - United States"
    },
    {
      "value": "America/Bogota",
      "label": "Pereira - Colombia"
    },
    {
      "value": "Europe/Paris",
      "label": "Perigueux - France"
    },
    {
      "value": "America/Argentina/Rio_Gallegos",
      "label": "Perito Moreno - Argentina"
    },
    {
      "value": "Asia/Yekaterinburg",
      "label": "Perm - Russia"
    },
    {
      "value": "Europe/Paris",
      "label": "Perpignan - France"
    },
    {
      "value": "America/Anchorage",
      "label": "Perryville - United States"
    },
    {
      "value": "Australia/Perth",
      "label": "Perth - Australia"
    },
    {
      "value": "Europe/London",
      "label": "Perth - United Kingdom"
    },
    {
      "value": "America/New_York",
      "label": "Peru - United States"
    },
    {
      "value": "Europe/Rome",
      "label": "Perugia - Italy"
    },
    {
      "value": "Europe/Rome",
      "label": "Pescara - Italy"
    },
    {
      "value": "Asia/Karachi",
      "label": "Peshawar - Pakistan"
    },
    {
      "value": "America/Toronto",
      "label": "Petawawa - Canada"
    },
    {
      "value": "America/Toronto",
      "label": "Peterborough - Canada"
    },
    {
      "value": "America/Anchorage",
      "label": "Petersburg - United States"
    },
    {
      "value": "America/Fortaleza",
      "label": "Petrolina - Brazil"
    },
    {
      "value": "Asia/Qyzylorda",
      "label": "Petropavlosk - Kazakhstan"
    },
    {
      "value": "Asia/Anadyr",
      "label": "Petropavlovsk - Russia"
    },
    {
      "value": "Europe/Moscow",
      "label": "Petrozavodsk - Russia"
    },
    {
      "value": "Africa/Johannesburg",
      "label": "Phalaborwa - South Africa"
    },
    {
      "value": "Asia/Saigon",
      "label": "Phan Rang - Vietnam"
    },
    {
      "value": "Asia/Katmandu",
      "label": "Phaplu - Nepal"
    },
    {
      "value": "America/New_York",
      "label": "Philadelphia - United States"
    },
    {
      "value": "America/Curacao",
      "label": "Philipsburg - Netherlands Antilles"
    },
    {
      "value": "Asia/Bangkok",
      "label": "Phitsanulok - Thailand"
    },
    {
      "value": "Asia/Phnom_Penh",
      "label": "Phnom-penh - Cambodia"
    },
    {
      "value": "America/Phoenix",
      "label": "Phoenix  - United States"
    },
    {
      "value": "America/Phoenix",
      "label": "Phoenix - United States"
    },
    {
      "value": "Asia/Vientiane",
      "label": "Phon Savan - Laos"
    },
    {
      "value": "Asia/Bangkok",
      "label": "Phrae - Thailand"
    },
    {
      "value": "Asia/Saigon",
      "label": "Phucat - Vietnam"
    },
    {
      "value": "Asia/Bangkok",
      "label": "Phuket - Thailand"
    },
    {
      "value": "Asia/Saigon",
      "label": "Phuquoc - Vietnam"
    },
    {
      "value": "America/Coral_Harbour",
      "label": "Pickle Lake - Canada"
    },
    {
      "value": "Atlantic/Azores",
      "label": "Pico - Portugal"
    },
    {
      "value": "Pacific/Auckland",
      "label": "Picton - New Zealand"
    },
    {
      "value": "America/Mexico_City",
      "label": "Piedras Negras - Mexico"
    },
    {
      "value": "America/Chicago",
      "label": "Pierre - United States"
    },
    {
      "value": "Europe/Bratislava",
      "label": "Piestany - Slovakia"
    },
    {
      "value": "Africa/Johannesburg",
      "label": "Pietermaritzburg - South Africa"
    },
    {
      "value": "America/Winnipeg",
      "label": "Pikangikum - Canada"
    },
    {
      "value": "America/New_York",
      "label": "Pikeville - United States"
    },
    {
      "value": "Africa/Johannesburg",
      "label": "Pilanesberg - South Africa"
    },
    {
      "value": "America/Asuncion",
      "label": "Pilar - Paraguay"
    },
    {
      "value": "America/Anchorage",
      "label": "Pilot Point - United States"
    },
    {
      "value": "America/Havana",
      "label": "Pinar Del Rio Norte - Cuba"
    },
    {
      "value": "America/Chicago",
      "label": "Pine Bluff - United States"
    },
    {
      "value": "America/New_York",
      "label": "Pine Mountain - United States"
    },
    {
      "value": "America/New_York",
      "label": "Pinehurst-Southern Pines - United States"
    },
    {
      "value": "America/Sao_Paulo",
      "label": "Piracununga - Brazil"
    },
    {
      "value": "Europe/Rome",
      "label": "Pisa - Italy"
    },
    {
      "value": "America/Lima",
      "label": "Pisco - Peru"
    },
    {
      "value": "America/Bogota",
      "label": "Pitalito - Colombia"
    },
    {
      "value": "America/New_York",
      "label": "Pittsburgh - United States"
    },
    {
      "value": "America/Lima",
      "label": "Piura - Peru"
    },
    {
      "value": "Indian/Mauritius",
      "label": "Plaisance - Mauritius"
    },
    {
      "value": "America/New_York",
      "label": "Plattsburgh - United States"
    },
    {
      "value": "Asia/Saigon",
      "label": "Pleiku - Vietnam"
    },
    {
      "value": "Africa/Johannesburg",
      "label": "Plettenberg Bay - South Africa"
    },
    {
      "value": "Europe/Sofia",
      "label": "Plovdiv - Bulgaria"
    },
    {
      "value": "Europe/London",
      "label": "Plymouth - United Kingdom"
    },
    {
      "value": "America/New_York",
      "label": "Plymouth - United States"
    },
    {
      "value": "America/Denver",
      "label": "Pocatello - United States"
    },
    {
      "value": "America/Costa_Rica",
      "label": "Pococi - Costa Rica"
    },
    {
      "value": "America/Sao_Paulo",
      "label": "Pocos De Caldas - Brazil"
    },
    {
      "value": "Europe/Podgorica",
      "label": "Podgorica - Montenegro"
    },
    {
      "value": "Asia/Seoul",
      "label": "Pohang - South Korea"
    },
    {
      "value": "Pacific/Ponape",
      "label": "Pohnpei - Micronesia"
    },
    {
      "value": "America/Anchorage",
      "label": "Point Barrow - United States"
    },
    {
      "value": "America/Anchorage",
      "label": "Point Lay - United States"
    },
    {
      "value": "America/Los_Angeles",
      "label": "Point Mugu - United States"
    },
    {
      "value": "America/Grenada",
      "label": "Point Salines - Grenada"
    },
    {
      "value": "America/Guadeloupe",
      "label": "Pointe-a-pitre - Guadeloupe"
    },
    {
      "value": "Africa/Brazzaville",
      "label": "Pointe-noire - Congo (Brazzaville)"
    },
    {
      "value": "America/Regina",
      "label": "Points North Landing - Canada"
    },
    {
      "value": "Europe/Paris",
      "label": "Poitiers - France"
    },
    {
      "value": "Asia/Katmandu",
      "label": "Pokhara - Nepal"
    },
    {
      "value": "Europe/Kiev",
      "label": "Poltava - Ukraine"
    },
    {
      "value": "America/New_York",
      "label": "Pompano Beach - United States"
    },
    {
      "value": "America/Chicago",
      "label": "Ponca City - United States"
    },
    {
      "value": "America/Puerto_Rico",
      "label": "Ponce - Puerto Rico"
    },
    {
      "value": "America/Toronto",
      "label": "Pond Inlet - Canada"
    },
    {
      "value": "Atlantic/Azores",
      "label": "Ponta Delgada - Portugal"
    },
    {
      "value": "America/Campo_Grande",
      "label": "Ponta Pora - Brazil"
    },
    {
      "value": "America/New_York",
      "label": "Pontiac - United States"
    },
    {
      "value": "Asia/Jakarta",
      "label": "Pontianak - Indonesia"
    },
    {
      "value": "Europe/Paris",
      "label": "Pontoise - France"
    },
    {
      "value": "America/Bogota",
      "label": "Popayan - Colombia"
    },
    {
      "value": "America/Chicago",
      "label": "Poplar Bluff - United States"
    },
    {
      "value": "America/Winnipeg",
      "label": "Poplar Hill - Canada"
    },
    {
      "value": "Europe/Bratislava",
      "label": "Poprad - Slovakia"
    },
    {
      "value": "Asia/Calcutta",
      "label": "Porbandar - India"
    },
    {
      "value": "Europe/Helsinki",
      "label": "Pori - Finland"
    },
    {
      "value": "America/Caracas",
      "label": "Porlamar - Venezuela"
    },
    {
      "value": "Australia/Brisbane",
      "label": "Pormpuraaw - Australia"
    },
    {
      "value": "America/Jamaica",
      "label": "Port Antonio - Jamaica"
    },
    {
      "value": "Indian/Antananarivo",
      "label": "Port Bergé - Madagascar"
    },
    {
      "value": "Asia/Calcutta",
      "label": "Port Blair - India"
    },
    {
      "value": "America/Anchorage",
      "label": "Port Clarence - United States"
    },
    {
      "value": "Africa/Johannesburg",
      "label": "Port Elizabeth - South Africa"
    },
    {
      "value": "Africa/Libreville",
      "label": "Port Gentil - Gabon"
    },
    {
      "value": "America/Vancouver",
      "label": "Port Hardy - Canada"
    },
    {
      "value": "Africa/Lagos",
      "label": "Port Hartcourt - Nigeria"
    },
    {
      "value": "Australia/Perth",
      "label": "Port Hedland - Australia"
    },
    {
      "value": "America/Anchorage",
      "label": "Port Heiden - United States"
    },
    {
      "value": "America/St_Johns",
      "label": "Port Hope Simpson - Canada"
    },
    {
      "value": "America/New_York",
      "label": "Port Huron - United States"
    },
    {
      "value": "Australia/Adelaide",
      "label": "Port Lincoln - Australia"
    },
    {
      "value": "Australia/Sydney",
      "label": "Port Macquarie - Australia"
    },
    {
      "value": "America/Toronto",
      "label": "Port Menier - Canada"
    },
    {
      "value": "America/Anchorage",
      "label": "Port Moller - United States"
    },
    {
      "value": "Pacific/Port_Moresby",
      "label": "Port Moresby - Papua New Guinea"
    },
    {
      "value": "America/Nassau",
      "label": "Port Nelson - Bahamas"
    },
    {
      "value": "Africa/Cairo",
      "label": "Port Said - Egypt"
    },
    {
      "value": "Africa/Johannesburg",
      "label": "Port Saint Johns - South Africa"
    },
    {
      "value": "Africa/Khartoum",
      "label": "Port Sudan - Sudan"
    },
    {
      "value": "America/Winnipeg",
      "label": "Portage-la-prairie - Canada"
    },
    {
      "value": "America/Port-au-Prince",
      "label": "Port-au-prince - Haiti"
    },
    {
      "value": "America/Port-au-Prince",
      "label": "Port-de-Paix - Haiti"
    },
    {
      "value": "Europe/Lisbon",
      "label": "Portimao - Portugal"
    },
    {
      "value": "Australia/Hobart",
      "label": "Portland - Australia"
    },
    {
      "value": "America/Los_Angeles",
      "label": "Portland - United States"
    },
    {
      "value": "Europe/Lisbon",
      "label": "Porto - Portugal"
    },
    {
      "value": "Africa/Luanda",
      "label": "Porto Amboim - Angola"
    },
    {
      "value": "Europe/Athens",
      "label": "Porto Heli - Greece"
    },
    {
      "value": "America/Fortaleza",
      "label": "Porto Nacional - Brazil"
    },
    {
      "value": "Europe/Lisbon",
      "label": "Porto Santo - Portugal"
    },
    {
      "value": "America/Fortaleza",
      "label": "Porto Seguro - Brazil"
    },
    {
      "value": "America/Boa_Vista",
      "label": "Porto Velho - Brazil"
    },
    {
      "value": "America/Port_of_Spain",
      "label": "Port-of-spain - Trinidad and Tobago"
    },
    {
      "value": "Europe/Ljubljana",
      "label": "Portoroz - Slovenia"
    },
    {
      "value": "America/Guayaquil",
      "label": "Portoviejo - Ecuador"
    },
    {
      "value": "America/New_York",
      "label": "Portsmouth - United States"
    },
    {
      "value": "Pacific/Efate",
      "label": "Port-vila - Vanuatu"
    },
    {
      "value": "America/Santiago",
      "label": "Porvenir - Chile"
    },
    {
      "value": "America/Cordoba",
      "label": "Posadas - Argentina"
    },
    {
      "value": "Asia/Makassar",
      "label": "Poso - Indonesia"
    },
    {
      "value": "America/Halifax",
      "label": "Postville - Canada"
    },
    {
      "value": "Africa/Johannesburg",
      "label": "Potchefstroom - South Africa"
    },
    {
      "value": "Africa/Johannesburg",
      "label": "Potgietersrus - South Africa"
    },
    {
      "value": "America/La_Paz",
      "label": "Potosi - Bolivia"
    },
    {
      "value": "America/Vancouver",
      "label": "Powell River - Canada"
    },
    {
      "value": "America/Mexico_City",
      "label": "Poza Rico - Mexico"
    },
    {
      "value": "Europe/Warsaw",
      "label": "Poznan - Poland"
    },
    {
      "value": "Asia/Bangkok",
      "label": "Prachuap Khiri Khan - Thailand"
    },
    {
      "value": "Europe/Prague",
      "label": "Prague - Czech Republic"
    },
    {
      "value": "Atlantic/Cape_Verde",
      "label": "Praia, Santiago Island - Cape Verde"
    },
    {
      "value": "Indian/Mahe",
      "label": "Praslin - Seychelles"
    },
    {
      "value": "Asia/Makassar",
      "label": "Praya - Indonesia"
    },
    {
      "value": "Europe/Prague",
      "label": "Prerov - Czech Republic"
    },
    {
      "value": "America/Phoenix",
      "label": "Prescott - United States"
    },
    {
      "value": "America/Sao_Paulo",
      "label": "President Prudente - Brazil"
    },
    {
      "value": "America/New_York",
      "label": "Presque Isle - United States"
    },
    {
      "value": "Europe/London",
      "label": "Prestwick - United Kingdom"
    },
    {
      "value": "Africa/Johannesburg",
      "label": "Pretoria - South Africa"
    },
    {
      "value": "Europe/Athens",
      "label": "Preveza - Greece"
    },
    {
      "value": "America/Denver",
      "label": "Price - United States"
    },
    {
      "value": "America/Regina",
      "label": "Prince Albert - Canada"
    },
    {
      "value": "America/Vancouver",
      "label": "Prince George - Canada"
    },
    {
      "value": "America/Vancouver",
      "label": "Prince Pupert - Canada"
    },
    {
      "value": "America/Vancouver",
      "label": "Prince Rupert - Canada"
    },
    {
      "value": "Africa/Sao_Tome",
      "label": "Principe - Sao Tome and Principe"
    },
    {
      "value": "America/Los_Angeles",
      "label": "Prineville - United States"
    },
    {
      "value": "Europe/Belgrade",
      "label": "Pristina - Serbia"
    },
    {
      "value": "Australia/Adelaide",
      "label": "Prominent Hill - Australia"
    },
    {
      "value": "Europe/Paris",
      "label": "Propriano - France"
    },
    {
      "value": "America/Anchorage",
      "label": "Prospect Creek - United States"
    },
    {
      "value": "Australia/Brisbane",
      "label": "Prosserpine - Australia"
    },
    {
      "value": "America/New_York",
      "label": "Providence - United States"
    },
    {
      "value": "America/Bogota",
      "label": "Providencia - Colombia"
    },
    {
      "value": "America/Grand_Turk",
      "label": "Providenciales - Turks and Caicos Islands"
    },
    {
      "value": "Asia/Anadyr",
      "label": "Provideniya Bay - Russia"
    },
    {
      "value": "America/New_York",
      "label": "Provincetown - United States"
    },
    {
      "value": "America/Denver",
      "label": "Provo - United States"
    },
    {
      "value": "Europe/Moscow",
      "label": "Pskov - Russia"
    },
    {
      "value": "America/Lima",
      "label": "Pucallpa - Peru"
    },
    {
      "value": "America/Santiago",
      "label": "Pucon - Chile"
    },
    {
      "value": "America/Mexico_City",
      "label": "Puebla - Mexico"
    },
    {
      "value": "America/Denver",
      "label": "Pueblo - United States"
    },
    {
      "value": "America/Bogota",
      "label": "Puerto Asis - Colombia"
    },
    {
      "value": "America/Caracas",
      "label": "Puerto Ayacucho - Venezuela"
    },
    {
      "value": "America/Guatemala",
      "label": "Puerto Barrios - Guatemala"
    },
    {
      "value": "America/Caracas",
      "label": "Puerto Cabello - Venezuela"
    },
    {
      "value": "America/Managua",
      "label": "Puerto Cabezas - Nicaragua"
    },
    {
      "value": "America/Bogota",
      "label": "Puerto Carreno - Colombia"
    },
    {
      "value": "America/Argentina/Rio_Gallegos",
      "label": "Puerto Deseado - Argentina"
    },
    {
      "value": "America/Mexico_City",
      "label": "Puerto Escondido - Mexico"
    },
    {
      "value": "America/Bogota",
      "label": "Puerto Inírida - Colombia"
    },
    {
      "value": "America/Costa_Rica",
      "label": "Puerto Jimenez - Costa Rica"
    },
    {
      "value": "America/Bogota",
      "label": "Puerto Leguízamo - Colombia"
    },
    {
      "value": "America/Tegucigalpa",
      "label": "Puerto Lempira - Honduras"
    },
    {
      "value": "America/Catamarca",
      "label": "Puerto Madryn - Argentina"
    },
    {
      "value": "America/Lima",
      "label": "Puerto Maldonado - Peru"
    },
    {
      "value": "America/Santiago",
      "label": "Puerto Montt - Chile"
    },
    {
      "value": "America/Santiago",
      "label": "Puerto Natales - Chile"
    },
    {
      "value": "America/Panama",
      "label": "Puerto Obaldia - Panama"
    },
    {
      "value": "America/Santo_Domingo",
      "label": "Puerto Plata - Dominican Republic"
    },
    {
      "value": "Asia/Manila",
      "label": "Puerto Princesa - Philippines"
    },
    {
      "value": "America/La_Paz",
      "label": "Puerto Rico/Manuripi - Bolivia"
    },
    {
      "value": "America/La_Paz",
      "label": "Puerto Suarez - Bolivia"
    },
    {
      "value": "America/Mexico_City",
      "label": "Puerto Vallarta - Mexico"
    },
    {
      "value": "America/Santiago",
      "label": "Puerto Williams - Chile"
    },
    {
      "value": "Pacific/Tahiti",
      "label": "Puka Puka - French Polynesia"
    },
    {
      "value": "Pacific/Auckland",
      "label": "Pukaki - New Zealand"
    },
    {
      "value": "Europe/Zagreb",
      "label": "Pula - Croatia"
    },
    {
      "value": "America/Los_Angeles",
      "label": "Pullman - United States"
    },
    {
      "value": "Asia/Calcutta",
      "label": "Pune - India"
    },
    {
      "value": "America/Santiago",
      "label": "Punta Arenas - Chile"
    },
    {
      "value": "America/Santo_Domingo",
      "label": "Punta Cana - Dominican Republic"
    },
    {
      "value": "America/Montevideo",
      "label": "Punta del Este - Uruguay"
    },
    {
      "value": "America/New_York",
      "label": "Punta Gorda - United States"
    },
    {
      "value": "America/Hermosillo",
      "label": "Punta Penasco - Mexico"
    },
    {
      "value": "America/Costa_Rica",
      "label": "Puntarenas - Costa Rica"
    },
    {
      "value": "Asia/Rangoon",
      "label": "Putao - Burma"
    },
    {
      "value": "Asia/Jakarta",
      "label": "Putussibau-Borneo Island - Indonesia"
    },
    {
      "value": "America/Toronto",
      "label": "Puvirnituq - Canada"
    },
    {
      "value": "Asia/Rangoon",
      "label": "Pyay - Burma"
    },
    {
      "value": "Asia/Pyongyang",
      "label": "Pyongyang - North Korea"
    },
    {
      "value": "America/Thule",
      "label": "Qaanaaq - Greenland"
    },
    {
      "value": "Asia/Baku",
      "label": "Qabala - Azerbaijan"
    },
    {
      "value": "America/Godthab",
      "label": "Qaqortoq - Greenland"
    },
    {
      "value": "America/Godthab",
      "label": "Qasigiannguit - Greenland"
    },
    {
      "value": "America/Godthab",
      "label": "Qeqertarsuaq Airport - Greenland"
    },
    {
      "value": "Asia/Shanghai",
      "label": "Qiemo - China"
    },
    {
      "value": "Asia/Shanghai",
      "label": "Qingdao - China"
    },
    {
      "value": "Asia/Shanghai",
      "label": "Qingyang - China"
    },
    {
      "value": "Asia/Shanghai",
      "label": "Qinhuangdao - China"
    },
    {
      "value": "Asia/Shanghai",
      "label": "Qiqihar - China"
    },
    {
      "value": "Asia/Shanghai",
      "label": "Quanzhou - China"
    },
    {
      "value": "America/Toronto",
      "label": "Quaqtaq - Canada"
    },
    {
      "value": "America/Toronto",
      "label": "Quebec - Canada"
    },
    {
      "value": "America/New_York",
      "label": "Queensbury - United States"
    },
    {
      "value": "Africa/Johannesburg",
      "label": "Queenstown - South Africa"
    },
    {
      "value": "Pacific/Auckland",
      "label": "Queenstown International - New Zealand"
    },
    {
      "value": "Africa/Maputo",
      "label": "Quelimane - Mozambique"
    },
    {
      "value": "America/Costa_Rica",
      "label": "Quepos - Costa Rica"
    },
    {
      "value": "America/Mexico_City",
      "label": "Queretaro - Mexico"
    },
    {
      "value": "America/Vancouver",
      "label": "Quesnel - Canada"
    },
    {
      "value": "Asia/Karachi",
      "label": "Quetta - Pakistan"
    },
    {
      "value": "America/Guatemala",
      "label": "Quezaltenango - Guatemala"
    },
    {
      "value": "America/Bogota",
      "label": "Quibdo - Colombia"
    },
    {
      "value": "Australia/Brisbane",
      "label": "Quilpie - Australia"
    },
    {
      "value": "Europe/Paris",
      "label": "Quimper - France"
    },
    {
      "value": "America/Chicago",
      "label": "Quincy - United States"
    },
    {
      "value": "America/Anchorage",
      "label": "Quinhagak - United States"
    },
    {
      "value": "America/Guayaquil",
      "label": "Quito - Ecuador"
    },
    {
      "value": "America/Toronto",
      "label": "Quujjuaq - Canada"
    },
    {
      "value": "Asia/Shanghai",
      "label": "Quzhou - China"
    },
    {
      "value": "Europe/Oslo",
      "label": "Rørvik - Norway"
    },
    {
      "value": "Europe/Oslo",
      "label": "Røst - Norway"
    },
    {
      "value": "Africa/Casablanca",
      "label": "Rabat - Morocco"
    },
    {
      "value": "Asia/Saigon",
      "label": "Rach Gia - Vietnam"
    },
    {
      "value": "America/Chicago",
      "label": "Racine - United States"
    },
    {
      "value": "Europe/Warsaw",
      "label": "RADOM - Poland"
    },
    {
      "value": "Asia/Yekaterinburg",
      "label": "Raduzhnyi - Russia"
    },
    {
      "value": "Asia/Riyadh",
      "label": "Rafha - Saudi Arabia"
    },
    {
      "value": "Asia/Tehran",
      "label": "Rafsanjan - Iran"
    },
    {
      "value": "Asia/Karachi",
      "label": "Rahim Yar Khan - Pakistan"
    },
    {
      "value": "Pacific/Tahiti",
      "label": "Raiatea Island - French Polynesia"
    },
    {
      "value": "America/Edmonton",
      "label": "Rainbow Lake - Canada"
    },
    {
      "value": "Asia/Calcutta",
      "label": "Raipur - India"
    },
    {
      "value": "Pacific/Tahiti",
      "label": "Raivavae - French Polynesia"
    },
    {
      "value": "Asia/Calcutta",
      "label": "Rajahmundry - India"
    },
    {
      "value": "Asia/Calcutta",
      "label": "Rajkot - India"
    },
    {
      "value": "Asia/Dhaka",
      "label": "Rajshahi - Bangladesh"
    },
    {
      "value": "America/New_York",
      "label": "Raleigh-durham - United States"
    },
    {
      "value": "Pacific/Guadalcanal",
      "label": "Ramata - Solomon Islands"
    },
    {
      "value": "Asia/Katmandu",
      "label": "Ramechhap - Nepal"
    },
    {
      "value": "Europe/Moscow",
      "label": "Ramenskoe - Russia"
    },
    {
      "value": "Australia/Darwin",
      "label": "Ramingining - Australia"
    },
    {
      "value": "Asia/Jerusalem",
      "label": "Ramon - Israel"
    },
    {
      "value": "Asia/Tehran",
      "label": "Ramsar - Iran"
    },
    {
      "value": "Europe/Berlin",
      "label": "Ramstein - Germany"
    },
    {
      "value": "Asia/Jakarta",
      "label": "Ranai-Natuna Besar Island - Indonesia"
    },
    {
      "value": "America/Santiago",
      "label": "Rancagua - Chile"
    },
    {
      "value": "Asia/Calcutta",
      "label": "Ranchi - India"
    },
    {
      "value": "Pacific/Tahiti",
      "label": "Rangiroa - French Polynesia"
    },
    {
      "value": "America/Winnipeg",
      "label": "Rankin Inlet - Canada"
    },
    {
      "value": "Asia/Bangkok",
      "label": "Ranong - Thailand"
    },
    {
      "value": "America/Denver",
      "label": "Rapid City - United States"
    },
    {
      "value": "Asia/Dubai",
      "label": "Ras Al Khaimah - United Arab Emirates"
    },
    {
      "value": "Asia/Tehran",
      "label": "Rasht - Iran"
    },
    {
      "value": "Asia/Phnom_Penh",
      "label": "Ratanakiri - Cambodia"
    },
    {
      "value": "Australia/Perth",
      "label": "Ravensthorpe - Australia"
    },
    {
      "value": "Asia/Karachi",
      "label": "Rawala Kot - Pakistan"
    },
    {
      "value": "America/Denver",
      "label": "Rawlins - United States"
    },
    {
      "value": "America/New_York",
      "label": "Reading - United States"
    },
    {
      "value": "Europe/Berlin",
      "label": "Rechlin-laerz - Germany"
    },
    {
      "value": "America/Fortaleza",
      "label": "Recife - Brazil"
    },
    {
      "value": "America/Cordoba",
      "label": "Reconquista - Argentina"
    },
    {
      "value": "America/Los_Angeles",
      "label": "Red Bluff - United States"
    },
    {
      "value": "America/Edmonton",
      "label": "Red Deer Industrial - Canada"
    },
    {
      "value": "America/Winnipeg",
      "label": "Red Lake - Canada"
    },
    {
      "value": "America/Chicago",
      "label": "Red River - United States"
    },
    {
      "value": "America/Winnipeg",
      "label": "Red Sucker Lake - Canada"
    },
    {
      "value": "Asia/Kuala_Lumpur",
      "label": "Redang - Malaysia"
    },
    {
      "value": "Pacific/Efate",
      "label": "Redcliffe - Vanuatu"
    },
    {
      "value": "America/Los_Angeles",
      "label": "Redding - United States"
    },
    {
      "value": "America/Belem",
      "label": "Redencao - Brazil"
    },
    {
      "value": "Europe/London",
      "label": "Redhill - United Kingdom"
    },
    {
      "value": "America/Los_Angeles",
      "label": "Redmond-Bend - United States"
    },
    {
      "value": "Europe/Rome",
      "label": "Reggio Calabria - Italy"
    },
    {
      "value": "America/Regina",
      "label": "Regina - Canada"
    },
    {
      "value": "Asia/Jakarta",
      "label": "Rengat - Indonesia"
    },
    {
      "value": "Australia/Adelaide",
      "label": "Renmark - Australia"
    },
    {
      "value": "Pacific/Guadalcanal",
      "label": "Rennell Island - Solomon Islands"
    },
    {
      "value": "Europe/Paris",
      "label": "Rennes - France"
    },
    {
      "value": "America/Los_Angeles",
      "label": "Reno - United States"
    },
    {
      "value": "America/Los_Angeles",
      "label": "Renton - United States"
    },
    {
      "value": "America/Winnipeg",
      "label": "Repulse Bay - Canada"
    },
    {
      "value": "America/Cordoba",
      "label": "Resistencia - Argentina"
    },
    {
      "value": "America/Winnipeg",
      "label": "Resolute - Canada"
    },
    {
      "value": "America/Guatemala",
      "label": "Retalhuleu - Guatemala"
    },
    {
      "value": "Europe/Madrid",
      "label": "Reus - Spain"
    },
    {
      "value": "America/Vancouver",
      "label": "Revelstoke - Canada"
    },
    {
      "value": "America/La_Paz",
      "label": "Reyes - Bolivia"
    },
    {
      "value": "Atlantic/Reykjavik",
      "label": "Reykjavik - Iceland"
    },
    {
      "value": "America/Mexico_City",
      "label": "Reynosa - Mexico"
    },
    {
      "value": "America/Chicago",
      "label": "Rhinelander - United States"
    },
    {
      "value": "America/Sao_Paulo",
      "label": "Ribeirao Preto - Brazil"
    },
    {
      "value": "America/La_Paz",
      "label": "Riberalta - Bolivia"
    },
    {
      "value": "Africa/Johannesburg",
      "label": "Richard's Bay - South Africa"
    },
    {
      "value": "Australia/Sydney",
      "label": "Richmond - Australia"
    },
    {
      "value": "America/New_York",
      "label": "Richmond - United States"
    },
    {
      "value": "America/New_York",
      "label": "Richmond Heights - United States"
    },
    {
      "value": "Europe/Berlin",
      "label": "Riesa - Germany"
    },
    {
      "value": "America/Denver",
      "label": "Rifle - United States"
    },
    {
      "value": "Europe/Riga",
      "label": "Riga - Latvia"
    },
    {
      "value": "America/Halifax",
      "label": "Rigolet - Canada"
    },
    {
      "value": "Europe/Zagreb",
      "label": "Rijeka - Croatia"
    },
    {
      "value": "Europe/Rome",
      "label": "Rimini - Italy"
    },
    {
      "value": "America/Toronto",
      "label": "Rimouski - Canada"
    },
    {
      "value": "America/Argentina/Salta",
      "label": "Rincon de los Sauces - Argentina"
    },
    {
      "value": "Pacific/Guadalcanal",
      "label": "Ringi Cove - Solomon Islands"
    },
    {
      "value": "America/Rio_Branco",
      "label": "Rio Branco - Brazil"
    },
    {
      "value": "America/Cordoba",
      "label": "Rio Cuarto - Argentina"
    },
    {
      "value": "America/Argentina/Rio_Gallegos",
      "label": "Rio Gallegos - Argentina"
    },
    {
      "value": "America/Argentina/Ushuaia",
      "label": "Rio Grande - Argentina"
    },
    {
      "value": "America/Bogota",
      "label": "Rio Hacha - Colombia"
    },
    {
      "value": "America/Cordoba",
      "label": "Rio Hondo - Argentina"
    },
    {
      "value": "America/Bogota",
      "label": "Rio Negro - Colombia"
    },
    {
      "value": "America/Sao_Paulo",
      "label": "Rio Verde - Brazil"
    },
    {
      "value": "Asia/Tokyo",
      "label": "Rishiri Island - Japan"
    },
    {
      "value": "America/Montevideo",
      "label": "Rivera - Uruguay"
    },
    {
      "value": "America/Los_Angeles",
      "label": "Riverside - United States"
    },
    {
      "value": "America/Denver",
      "label": "Riverton WY - United States"
    },
    {
      "value": "America/Toronto",
      "label": "Riviere Du Loup - Canada"
    },
    {
      "value": "Europe/Kiev",
      "label": "Rivne - Ukraine"
    },
    {
      "value": "Asia/Riyadh",
      "label": "Riyadh - Saudi Arabia"
    },
    {
      "value": "Europe/Paris",
      "label": "Roanne - France"
    },
    {
      "value": "America/New_York",
      "label": "Roanoke VA - United States"
    },
    {
      "value": "America/Tegucigalpa",
      "label": "Roatan - Honduras"
    },
    {
      "value": "Africa/Johannesburg",
      "label": "Robertson - South Africa"
    },
    {
      "value": "America/Toronto",
      "label": "Roberval - Canada"
    },
    {
      "value": "Europe/Paris",
      "label": "Rochefort - France"
    },
    {
      "value": "Europe/London",
      "label": "Rochester - United Kingdom"
    },
    {
      "value": "America/New_York",
      "label": "Rochester - United States"
    },
    {
      "value": "America/New_York",
      "label": "Rock Hill - United States"
    },
    {
      "value": "America/Nassau",
      "label": "Rock Sound - Bahamas"
    },
    {
      "value": "America/Denver",
      "label": "Rock Springs - United States"
    },
    {
      "value": "America/Chicago",
      "label": "Rockford - United States"
    },
    {
      "value": "Australia/Brisbane",
      "label": "Rockhampton - Australia"
    },
    {
      "value": "America/New_York",
      "label": "Rockland - United States"
    },
    {
      "value": "America/Chicago",
      "label": "Rockport - United States"
    },
    {
      "value": "America/New_York",
      "label": "Rocky Mount - United States"
    },
    {
      "value": "America/Edmonton",
      "label": "Rocky Mountain House - Canada"
    },
    {
      "value": "Europe/Paris",
      "label": "Rodez - France"
    },
    {
      "value": "Indian/Mauritius",
      "label": "Rodriguez Island - Mauritius"
    },
    {
      "value": "Australia/Brisbane",
      "label": "Roma - Australia"
    },
    {
      "value": "Asia/Manila",
      "label": "Romblon - Philippines"
    },
    {
      "value": "Europe/Rome",
      "label": "Rome - Italy"
    },
    {
      "value": "America/New_York",
      "label": "Rome - United States"
    },
    {
      "value": "Europe/Rome",
      "label": "Ronchi De Legionari - Italy"
    },
    {
      "value": "America/Campo_Grande",
      "label": "Rondonopolis - Brazil"
    },
    {
      "value": "Europe/Copenhagen",
      "label": "Ronne - Denmark"
    },
    {
      "value": "Europe/Stockholm",
      "label": "Ronneby - Sweden"
    },
    {
      "value": "Australia/Darwin",
      "label": "Roper Bar - Australia"
    },
    {
      "value": "Europe/Oslo",
      "label": "Roros - Norway"
    },
    {
      "value": "America/Cordoba",
      "label": "Rosario - Argentina"
    },
    {
      "value": "America/Chicago",
      "label": "Rosecrans - United States"
    },
    {
      "value": "Asia/Jerusalem",
      "label": "Rosh Pina - Israel"
    },
    {
      "value": "America/Managua",
      "label": "Rosita - Nicaragua"
    },
    {
      "value": "Europe/Moscow",
      "label": "Rostov - Russia"
    },
    {
      "value": "America/Denver",
      "label": "Roswell - United States"
    },
    {
      "value": "Pacific/Saipan",
      "label": "Rota - Northern Mariana Islands"
    },
    {
      "value": "Europe/Madrid",
      "label": "Rota - Spain"
    },
    {
      "value": "Pacific/Auckland",
      "label": "Rotorua - New Zealand"
    },
    {
      "value": "Europe/Amsterdam",
      "label": "Rotterdam - Netherlands"
    },
    {
      "value": "Australia/Perth",
      "label": "Rottnest Island - Australia"
    },
    {
      "value": "Pacific/Fiji",
      "label": "Rotuma - Fiji"
    },
    {
      "value": "Europe/Paris",
      "label": "Rouen - France"
    },
    {
      "value": "America/Winnipeg",
      "label": "Round Lake - Canada"
    },
    {
      "value": "Asia/Calcutta",
      "label": "Rourkela - India"
    },
    {
      "value": "America/Toronto",
      "label": "Rouyn - Canada"
    },
    {
      "value": "Europe/Helsinki",
      "label": "Rovaniemi - Finland"
    },
    {
      "value": "America/Costa_Rica",
      "label": "Roxana - Costa Rica"
    },
    {
      "value": "Asia/Manila",
      "label": "Roxas City - Philippines"
    },
    {
      "value": "Europe/Paris",
      "label": "Royan - France"
    },
    {
      "value": "America/Anchorage",
      "label": "Ruby - United States"
    },
    {
      "value": "Europe/Berlin",
      "label": "Ruegen - Germany"
    },
    {
      "value": "America/Denver",
      "label": "Ruidoso - United States"
    },
    {
      "value": "Asia/Katmandu",
      "label": "Rukumkot - Nepal"
    },
    {
      "value": "Africa/Juba",
      "label": "Rumbek - Sudan"
    },
    {
      "value": "Asia/Katmandu",
      "label": "Rumjatar - Nepal"
    },
    {
      "value": "Africa/Windhoek",
      "label": "Rundu - Namibia"
    },
    {
      "value": "America/Anchorage",
      "label": "Russian Mission - United States"
    },
    {
      "value": "Asia/Makassar",
      "label": "Ruteng - Indonesia"
    },
    {
      "value": "America/New_York",
      "label": "Rutland - United States"
    },
    {
      "value": "Europe/Moscow",
      "label": "Rybinsk - Russia"
    },
    {
      "value": "Europe/Oslo",
      "label": "Rygge - Norway"
    },
    {
      "value": "Europe/Warsaw",
      "label": "Rzeszow - Poland"
    },
    {
      "value": "Europe/Budapest",
      "label": "Sármellék - Hungary"
    },
    {
      "value": "Europe/Berlin",
      "label": "Saarbruecken - Germany"
    },
    {
      "value": "America/Curacao",
      "label": "Saba - Netherlands Antilles"
    },
    {
      "value": "Europe/Madrid",
      "label": "Sabadell - Spain"
    },
    {
      "value": "Asia/Jakarta",
      "label": "Sabang - Indonesia"
    },
    {
      "value": "Asia/Tehran",
      "label": "Sabzevar - Iran"
    },
    {
      "value": "Asia/Seoul",
      "label": "Sacheon - South Korea"
    },
    {
      "value": "America/Winnipeg",
      "label": "Sachigo Lake - Canada"
    },
    {
      "value": "America/Edmonton",
      "label": "Sachs Harbour - Canada"
    },
    {
      "value": "America/Los_Angeles",
      "label": "Sacramento - United States"
    },
    {
      "value": "Asia/Tokyo",
      "label": "Sado - Japan"
    },
    {
      "value": "America/Phoenix",
      "label": "Safford - United States"
    },
    {
      "value": "Asia/Tokyo",
      "label": "Saga - Japan"
    },
    {
      "value": "America/New_York",
      "label": "Saginaw - United States"
    },
    {
      "value": "Australia/Brisbane",
      "label": "Saibai Island - Australia"
    },
    {
      "value": "Asia/Dhaka",
      "label": "Saidpur - Bangladesh"
    },
    {
      "value": "Asia/Karachi",
      "label": "Saidu Sharif - Pakistan"
    },
    {
      "value": "America/Toronto",
      "label": "Saint Catherines - Canada"
    },
    {
      "value": "America/Chicago",
      "label": "Saint Cloud - United States"
    },
    {
      "value": "America/Denver",
      "label": "Saint George - United States"
    },
    {
      "value": "Indian/Antananarivo",
      "label": "Sainte Marie - Madagascar"
    },
    {
      "value": "America/Cayenne",
      "label": "Saint-Laurent-du-Maroni - French Guiana"
    },
    {
      "value": "Pacific/Saipan",
      "label": "Saipan - Northern Mariana Islands"
    },
    {
      "value": "Asia/Bangkok",
      "label": "Sakon Nakhon - Thailand"
    },
    {
      "value": "Asia/Muscat",
      "label": "Salalah - Oman"
    },
    {
      "value": "Europe/Madrid",
      "label": "Salamanca - Spain"
    },
    {
      "value": "Asia/Yekaterinburg",
      "label": "Salekhard - Russia"
    },
    {
      "value": "Asia/Calcutta",
      "label": "Salem - India"
    },
    {
      "value": "America/Los_Angeles",
      "label": "Salem - United States"
    },
    {
      "value": "Europe/Rome",
      "label": "Salerno - Italy"
    },
    {
      "value": "America/Chicago",
      "label": "Salina - United States"
    },
    {
      "value": "America/Guayaquil",
      "label": "Salinas - Ecuador"
    },
    {
      "value": "America/New_York",
      "label": "Salisbury - United States"
    },
    {
      "value": "America/Toronto",
      "label": "Salluit - Canada"
    },
    {
      "value": "America/Denver",
      "label": "Salmon - United States"
    },
    {
      "value": "America/Grand_Turk",
      "label": "Salt Cay - Turks and Caicos Islands"
    },
    {
      "value": "America/Denver",
      "label": "Salt Lake City - United States"
    },
    {
      "value": "America/Argentina/Salta",
      "label": "Salta - Argentina"
    },
    {
      "value": "America/Mexico_City",
      "label": "Saltillo - Mexico"
    },
    {
      "value": "America/Montevideo",
      "label": "Salto - Uruguay"
    },
    {
      "value": "Europe/Chisinau",
      "label": "Saltsy - Moldova"
    },
    {
      "value": "America/Fortaleza",
      "label": "Salvador - Brazil"
    },
    {
      "value": "Europe/Vienna",
      "label": "Salzburg - Austria"
    },
    {
      "value": "Asia/Vientiane",
      "label": "Sam Neua - Laos"
    },
    {
      "value": "Europe/Samara",
      "label": "Samara - Russia"
    },
    {
      "value": "Asia/Makassar",
      "label": "Samarinda - Indonesia"
    },
    {
      "value": "Asia/Samarkand",
      "label": "Samarkand - Uzbekistan"
    },
    {
      "value": "Indian/Antananarivo",
      "label": "Sambava - Madagascar"
    },
    {
      "value": "Africa/Nairobi",
      "label": "Samburu South - Kenya"
    },
    {
      "value": "Europe/Zurich",
      "label": "Samedan - Switzerland"
    },
    {
      "value": "Europe/Athens",
      "label": "Samos - Greece"
    },
    {
      "value": "Asia/Jakarta",
      "label": "Sampit-Borneo Island - Indonesia"
    },
    {
      "value": "Europe/Istanbul",
      "label": "Samsun - Turkey"
    },
    {
      "value": "America/Bogota",
      "label": "San Andres Island - Colombia"
    },
    {
      "value": "America/Nassau",
      "label": "San Andros - Bahamas"
    },
    {
      "value": "America/Chicago",
      "label": "San Angelo - United States"
    },
    {
      "value": "America/Chicago",
      "label": "San Antonio - United States"
    },
    {
      "value": "America/Caracas",
      "label": "San Antonio - Venezuela"
    },
    {
      "value": "America/Argentina/Salta",
      "label": "San Antonio Oeste - Argentina"
    },
    {
      "value": "America/Los_Angeles",
      "label": "San Bernardino - United States"
    },
    {
      "value": "America/La_Paz",
      "label": "San Borja - Bolivia"
    },
    {
      "value": "America/Managua",
      "label": "San Carlos - Nicaragua"
    },
    {
      "value": "America/Los_Angeles",
      "label": "San Carlos - United States"
    },
    {
      "value": "America/Argentina/Salta",
      "label": "San Carlos De Bariloche - Argentina"
    },
    {
      "value": "Pacific/Galapagos",
      "label": "San Cristóbal - Ecuador"
    },
    {
      "value": "America/Caracas",
      "label": "San Cristobal - Venezuela"
    },
    {
      "value": "America/Mexico_City",
      "label": "San Cristobal de las Casas - Mexico"
    },
    {
      "value": "America/Los_Angeles",
      "label": "San Diego - United States"
    },
    {
      "value": "America/Caracas",
      "label": "San Felipe - Venezuela"
    },
    {
      "value": "America/Buenos_Aires",
      "label": "San Fernando - Argentina"
    },
    {
      "value": "Asia/Manila",
      "label": "San Fernando - Philippines"
    },
    {
      "value": "America/Caracas",
      "label": "San Fernando De Apure - Venezuela"
    },
    {
      "value": "America/Tijuana",
      "label": "San Filipe - Mexico"
    },
    {
      "value": "America/Los_Angeles",
      "label": "San Francisco - United States"
    },
    {
      "value": "America/Costa_Rica",
      "label": "San Jose - Costa Rica"
    },
    {
      "value": "America/Guatemala",
      "label": "San Jose - Guatemala"
    },
    {
      "value": "Asia/Manila",
      "label": "San Jose - Philippines"
    },
    {
      "value": "America/Los_Angeles",
      "label": "San Jose - United States"
    },
    {
      "value": "America/Mazatlan",
      "label": "San Jose Del Cabo - Mexico"
    },
    {
      "value": "America/Bogota",
      "label": "San Jose Del Guaviare - Colombia"
    },
    {
      "value": "America/Puerto_Rico",
      "label": "San Juan - Puerto Rico"
    },
    {
      "value": "America/Argentina/San_Juan",
      "label": "San Julian - Argentina"
    },
    {
      "value": "America/Argentina/San_Luis",
      "label": "San Luis - Argentina"
    },
    {
      "value": "America/Los_Angeles",
      "label": "San Luis Obispo - United States"
    },
    {
      "value": "America/Mexico_City",
      "label": "San Luis Potosi - Mexico"
    },
    {
      "value": "America/Argentina/Salta",
      "label": "San Martin Des Andes - Argentina"
    },
    {
      "value": "Africa/Abidjan",
      "label": "San Pedro - Cote d'Ivoire"
    },
    {
      "value": "America/Tegucigalpa",
      "label": "San Pedro Sula - Honduras"
    },
    {
      "value": "America/Mendoza",
      "label": "San Rafael - Argentina"
    },
    {
      "value": "America/El_Salvador",
      "label": "San Salvador - El Salvador"
    },
    {
      "value": "Europe/Madrid",
      "label": "San Sebastian - Spain"
    },
    {
      "value": "America/Caracas",
      "label": "San Tome - Venezuela"
    },
    {
      "value": "America/Bogota",
      "label": "San Vincente De Caguan - Colombia"
    },
    {
      "value": "America/Costa_Rica",
      "label": "San Vito De Jaba - Costa Rica"
    },
    {
      "value": "Asia/Aden",
      "label": "Sanaa - Yemen"
    },
    {
      "value": "Asia/Tehran",
      "label": "Sanandaj - Iran"
    },
    {
      "value": "America/Havana",
      "label": "Sancti Spiritus - Cuba"
    },
    {
      "value": "America/Anchorage",
      "label": "Sand Point - United States"
    },
    {
      "value": "Asia/Kuala_Lumpur",
      "label": "Sandakan - Malaysia"
    },
    {
      "value": "Europe/Oslo",
      "label": "Sandane - Norway"
    },
    {
      "value": "Europe/London",
      "label": "Sanday - United Kingdom"
    },
    {
      "value": "Europe/Oslo",
      "label": "Sandefjord - Norway"
    },
    {
      "value": "Europe/Oslo",
      "label": "Sandnessjoen - Norway"
    },
    {
      "value": "America/Vancouver",
      "label": "Sandspit - Canada"
    },
    {
      "value": "America/New_York",
      "label": "Sandusky - United States"
    },
    {
      "value": "America/Winnipeg",
      "label": "Sandy Lake - Canada"
    },
    {
      "value": "America/New_York",
      "label": "Sanford - United States"
    },
    {
      "value": "Asia/Manila",
      "label": "Sanga Sanga - Philippines"
    },
    {
      "value": "Pacific/Efate",
      "label": "Sangafa - Vanuatu"
    },
    {
      "value": "Asia/Manila",
      "label": "Sangley Point - Philippines"
    },
    {
      "value": "Europe/Berlin",
      "label": "Sankt Peter-Ording - Germany"
    },
    {
      "value": "Europe/Istanbul",
      "label": "Sanliurfa - Turkey"
    },
    {
      "value": "America/La_Paz",
      "label": "Santa Ana - Bolivia"
    },
    {
      "value": "Pacific/Guadalcanal",
      "label": "Santa Ana - Solomon Islands"
    },
    {
      "value": "America/Los_Angeles",
      "label": "Santa Ana - United States"
    },
    {
      "value": "America/Caracas",
      "label": "Santa Ana De Uairen - Venezuela"
    },
    {
      "value": "America/Caracas",
      "label": "Santa Barbara - Venezuela"
    },
    {
      "value": "America/Havana",
      "label": "Santa Clara - Cuba"
    },
    {
      "value": "America/Argentina/Rio_Gallegos",
      "label": "Santa Cruz - Argentina"
    },
    {
      "value": "America/La_Paz",
      "label": "Santa Cruz - Bolivia"
    },
    {
      "value": "Atlantic/Canary",
      "label": "Santa Cruz De La Palma - Spain"
    },
    {
      "value": "America/Guatemala",
      "label": "Santa Cruz des Quiche - Guatemala"
    },
    {
      "value": "Pacific/Guadalcanal",
      "label": "Santa Cruz/Graciosa Bay/Luova - Solomon Islands"
    },
    {
      "value": "America/Cordoba",
      "label": "Santa Fe - Argentina"
    },
    {
      "value": "America/Denver",
      "label": "Santa Fe - United States"
    },
    {
      "value": "America/Boa_Vista",
      "label": "Santa Isabel do Rio Negro - Brazil"
    },
    {
      "value": "America/Sao_Paulo",
      "label": "Santa Maria - Brazil"
    },
    {
      "value": "America/Los_Angeles",
      "label": "Santa Maria - United States"
    },
    {
      "value": "Atlantic/Azores",
      "label": "Santa Maria (island) - Portugal"
    },
    {
      "value": "America/Bogota",
      "label": "Santa Marta - Colombia"
    },
    {
      "value": "America/Los_Angeles",
      "label": "Santa Monica - United States"
    },
    {
      "value": "America/Argentina/Salta",
      "label": "Santa Rosa - Argentina"
    },
    {
      "value": "America/Sao_Paulo",
      "label": "Santa Rosa - Brazil"
    },
    {
      "value": "America/Guayaquil",
      "label": "Santa Rosa - Ecuador"
    },
    {
      "value": "America/Los_Angeles",
      "label": "Santa Rosa - United States"
    },
    {
      "value": "America/Buenos_Aires",
      "label": "Santa Teresita - Argentina"
    },
    {
      "value": "America/Campo_Grande",
      "label": "Santa Terezinha - Brazil"
    },
    {
      "value": "America/Belem",
      "label": "Santana do Araguaia - Brazil"
    },
    {
      "value": "Europe/Madrid",
      "label": "Santander - Spain"
    },
    {
      "value": "America/Belem",
      "label": "Santarem - Brazil"
    },
    {
      "value": "America/Santo_Domingo",
      "label": "Santiago - Dominican Republic"
    },
    {
      "value": "America/Panama",
      "label": "Santiago - Panama"
    },
    {
      "value": "Europe/Madrid",
      "label": "Santiago - Spain"
    },
    {
      "value": "America/Havana",
      "label": "Santiago De Cuba - Cuba"
    },
    {
      "value": "America/Cordoba",
      "label": "Santiago Del Estero - Argentina"
    },
    {
      "value": "Pacific/Efate",
      "label": "Santo - Vanuatu"
    },
    {
      "value": "America/Sao_Paulo",
      "label": "Santo Angelo - Brazil"
    },
    {
      "value": "America/Santo_Domingo",
      "label": "Santo Domingo - Dominican Republic"
    },
    {
      "value": "America/Caracas",
      "label": "Santo Domingo - Venezuela"
    },
    {
      "value": "America/Sao_Paulo",
      "label": "Santos - Brazil"
    },
    {
      "value": "Asia/Shanghai",
      "label": "Sanya - China"
    },
    {
      "value": "America/Sao_Paulo",
      "label": "Sao Carlos - Brazil"
    },
    {
      "value": "America/Campo_Grande",
      "label": "Sao Felix do Araguaia - Brazil"
    },
    {
      "value": "America/Belem",
      "label": "Sao Felix do Xingu - Brazil"
    },
    {
      "value": "Atlantic/Cape_Verde",
      "label": "Sao Filipe, Fogo Island - Cape Verde"
    },
    {
      "value": "America/Boa_Vista",
      "label": "Sao Gabriel - Brazil"
    },
    {
      "value": "Atlantic/Azores",
      "label": "Sao Jorge Island - Portugal"
    },
    {
      "value": "America/Sao_Paulo",
      "label": "Sao Jose Do Rio Preto - Brazil"
    },
    {
      "value": "America/Sao_Paulo",
      "label": "Sao Jose Dos Campos - Brazil"
    },
    {
      "value": "America/Fortaleza",
      "label": "Sao Luis - Brazil"
    },
    {
      "value": "Atlantic/Cape_Verde",
      "label": "Sao Nocolau Island - Cape Verde"
    },
    {
      "value": "America/Sao_Paulo",
      "label": "Sao Paulo - Brazil"
    },
    {
      "value": "America/Boa_Vista",
      "label": "Sao Paulo de Olivenca - Brazil"
    },
    {
      "value": "Africa/Sao_Tome",
      "label": "Sao Tome - Sao Tome and Principe"
    },
    {
      "value": "Atlantic/Cape_Verde",
      "label": "Sao Vicente Island - Cape Verde"
    },
    {
      "value": "Asia/Tokyo",
      "label": "Sapporo - Japan"
    },
    {
      "value": "Europe/Sarajevo",
      "label": "Sarajevo - Bosnia and Herzegovina"
    },
    {
      "value": "Asia/Tehran",
      "label": "Sarakhs - Iran"
    },
    {
      "value": "America/New_York",
      "label": "Saranac Lake - United States"
    },
    {
      "value": "Europe/Moscow",
      "label": "Saransk - Russia"
    },
    {
      "value": "America/New_York",
      "label": "Sarasota - United States"
    },
    {
      "value": "America/Denver",
      "label": "SARATOGA - United States"
    },
    {
      "value": "Europe/Moscow",
      "label": "Saratov - Russia"
    },
    {
      "value": "Asia/Vientiane",
      "label": "Saravane - Laos"
    },
    {
      "value": "America/Bogota",
      "label": "Saravena - Colombia"
    },
    {
      "value": "Africa/Ndjamena",
      "label": "Sarh - Chad"
    },
    {
      "value": "America/Toronto",
      "label": "Sarnia - Canada"
    },
    {
      "value": "America/Regina",
      "label": "Saskatoon - Canada"
    },
    {
      "value": "Asia/Yakutsk",
      "label": "Saskylakh - Russia"
    },
    {
      "value": "Asia/Calcutta",
      "label": "Satna - India"
    },
    {
      "value": "Europe/Bucharest",
      "label": "Satu Mare - Romania"
    },
    {
      "value": "Atlantic/Reykjavik",
      "label": "Saudarkrokur - Iceland"
    },
    {
      "value": "America/Cayenne",
      "label": "Saul - French Guiana"
    },
    {
      "value": "America/Toronto",
      "label": "Sault Sainte Marie - Canada"
    },
    {
      "value": "America/New_York",
      "label": "Sault Ste Marie - United States"
    },
    {
      "value": "Africa/Luanda",
      "label": "Saurimo - Angola"
    },
    {
      "value": "Pacific/Apia",
      "label": "Savaii Island - Samoa"
    },
    {
      "value": "America/New_York",
      "label": "Savannah - United States"
    },
    {
      "value": "Asia/Vientiane",
      "label": "Savannakhet - Laos"
    },
    {
      "value": "Europe/Helsinki",
      "label": "Savonlinna - Finland"
    },
    {
      "value": "America/Anchorage",
      "label": "Savoonga - United States"
    },
    {
      "value": "Pacific/Fiji",
      "label": "Savusavu - Fiji"
    },
    {
      "value": "Asia/Aden",
      "label": "Sayun Intl - Yemen"
    },
    {
      "value": "America/Anchorage",
      "label": "Scammon Bay - United States"
    },
    {
      "value": "Europe/London",
      "label": "Scampton - United Kingdom"
    },
    {
      "value": "America/Port_of_Spain",
      "label": "Scarborough - Trinidad and Tobago"
    },
    {
      "value": "Europe/London",
      "label": "Scatsta - United Kingdom"
    },
    {
      "value": "America/Toronto",
      "label": "Schefferville - Canada"
    },
    {
      "value": "Europe/Berlin",
      "label": "Schleswig - Germany"
    },
    {
      "value": "Europe/Berlin",
      "label": "Schoenhagen - Germany"
    },
    {
      "value": "Australia/Sydney",
      "label": "Scone - Australia"
    },
    {
      "value": "America/New_York",
      "label": "Scotia NY - United States"
    },
    {
      "value": "America/Denver",
      "label": "Scottsbluff - United States"
    },
    {
      "value": "America/Phoenix",
      "label": "Scottsdale - United States"
    },
    {
      "value": "America/New_York",
      "label": "Scranton - United States"
    },
    {
      "value": "America/Los_Angeles",
      "label": "Seattle - United States"
    },
    {
      "value": "Africa/Tripoli",
      "label": "Sebha - Libya"
    },
    {
      "value": "America/New_York",
      "label": "Sebring - United States"
    },
    {
      "value": "Africa/Johannesburg",
      "label": "Secunda - South Africa"
    },
    {
      "value": "America/Phoenix",
      "label": "Sedona - United States"
    },
    {
      "value": "Pacific/Guadalcanal",
      "label": "Sege - Solomon Islands"
    },
    {
      "value": "Asia/Karachi",
      "label": "Sehwan Sharif - Pakistan"
    },
    {
      "value": "Europe/Helsinki",
      "label": "Seinäjoki / Ilmajoki - Finland"
    },
    {
      "value": "America/Anchorage",
      "label": "Selawik - United States"
    },
    {
      "value": "Africa/Gaborone",
      "label": "Selebi-phikwe - Botswana"
    },
    {
      "value": "Africa/Nouakchott",
      "label": "Selibabi - Mauritania"
    },
    {
      "value": "America/Chicago",
      "label": "Selma - United States"
    },
    {
      "value": "Asia/Jakarta",
      "label": "Semarang - Indonesia"
    },
    {
      "value": "Asia/Qyzylorda",
      "label": "Semiplatinsk - Kazakhstan"
    },
    {
      "value": "Asia/Tokyo",
      "label": "Sendai - Japan"
    },
    {
      "value": "Asia/Jayapura",
      "label": "Senggeh-Papua Island - Indonesia"
    },
    {
      "value": "Europe/Madrid",
      "label": "Seo De Urgel - Spain"
    },
    {
      "value": "Asia/Seoul",
      "label": "Seogwipo - South Korea"
    },
    {
      "value": "Asia/Seoul",
      "label": "Seoul - South Korea"
    },
    {
      "value": "Asia/Seoul",
      "label": "Seoul East - South Korea"
    },
    {
      "value": "America/Toronto",
      "label": "Sept-iles - Canada"
    },
    {
      "value": "Africa/Dar_es_Salaam",
      "label": "Seronera - Tanzania"
    },
    {
      "value": "Africa/Algiers",
      "label": "Setif - Algeria"
    },
    {
      "value": "Europe/Simferopol",
      "label": "Sevastopol - Ukraine"
    },
    {
      "value": "Europe/Madrid",
      "label": "Sevilla - Spain"
    },
    {
      "value": "America/Chicago",
      "label": "Sewanee - United States"
    },
    {
      "value": "America/Anchorage",
      "label": "Seward - United States"
    },
    {
      "value": "Africa/Tunis",
      "label": "Sfax - Tunisia"
    },
    {
      "value": "America/Anchorage",
      "label": "Shageluk - United States"
    },
    {
      "value": "Asia/Tehran",
      "label": "Shahre Kord - Iran"
    },
    {
      "value": "Africa/Gaborone",
      "label": "Shakawe - Botswana"
    },
    {
      "value": "America/Anchorage",
      "label": "Shaktoolik - United States"
    },
    {
      "value": "America/Winnipeg",
      "label": "Shamattawa - Canada"
    },
    {
      "value": "Asia/Shanghai",
      "label": "Shanghai - China"
    },
    {
      "value": "Asia/Shanghai",
      "label": "Shangri-La - China"
    },
    {
      "value": "Europe/Dublin",
      "label": "Shannon - Ireland"
    },
    {
      "value": "Asia/Shanghai",
      "label": "Shantou - China"
    },
    {
      "value": "Asia/Dubai",
      "label": "Sharjah - United Arab Emirates"
    },
    {
      "value": "Australia/Perth",
      "label": "Shark Bay - Australia"
    },
    {
      "value": "Africa/Cairo",
      "label": "Sharm El Sheikh - Egypt"
    },
    {
      "value": "Asia/Kabul",
      "label": "Sharona - Afghanistan"
    },
    {
      "value": "Africa/Cairo",
      "label": "Sharq Al-Owainat - Egypt"
    },
    {
      "value": "Asia/Riyadh",
      "label": "Sharurah - Saudi Arabia"
    },
    {
      "value": "Europe/Moscow",
      "label": "Shchyolkovo - Russia"
    },
    {
      "value": "America/Chicago",
      "label": "Sheboygan - United States"
    },
    {
      "value": "America/Los_Angeles",
      "label": "Shelton - United States"
    },
    {
      "value": "America/Adak",
      "label": "Shemya - United States"
    },
    {
      "value": "Asia/Shanghai",
      "label": "Shenyang - China"
    },
    {
      "value": "Asia/Shanghai",
      "label": "Shenzhen - China"
    },
    {
      "value": "Australia/Hobart",
      "label": "Shepparton - Australia"
    },
    {
      "value": "America/Toronto",
      "label": "Sherbrooke - Canada"
    },
    {
      "value": "America/Denver",
      "label": "Sheridan - United States"
    },
    {
      "value": "Asia/Shanghai",
      "label": "Shijiazhuang - China"
    },
    {
      "value": "Asia/Calcutta",
      "label": "Shillong - India"
    },
    {
      "value": "Asia/Calcutta",
      "label": "Shimla - India"
    },
    {
      "value": "Asia/Tokyo",
      "label": "Shimojishima - Japan"
    },
    {
      "value": "Asia/Kabul",
      "label": "Shindand - Afghanistan"
    },
    {
      "value": "Africa/Dar_es_Salaam",
      "label": "Shinyanga - Tanzania"
    },
    {
      "value": "Asia/Shanghai",
      "label": "Shiquanhe - China"
    },
    {
      "value": "Asia/Tehran",
      "label": "Shiraz - Iran"
    },
    {
      "value": "America/Anchorage",
      "label": "Shishmaref - United States"
    },
    {
      "value": "Asia/Calcutta",
      "label": "Sholapur - India"
    },
    {
      "value": "Asia/Tokyo",
      "label": "Shonai - Japan"
    },
    {
      "value": "Europe/London",
      "label": "Shoreham By Sea - United Kingdom"
    },
    {
      "value": "America/Phoenix",
      "label": "Show Low - United States"
    },
    {
      "value": "America/Chicago",
      "label": "Shreveport - United States"
    },
    {
      "value": "America/Anchorage",
      "label": "Shungnak - United States"
    },
    {
      "value": "Australia/Brisbane",
      "label": "Shute Harbour - Australia"
    },
    {
      "value": "Asia/Karachi",
      "label": "Sialkot - Pakistan"
    },
    {
      "value": "Asia/Manila",
      "label": "Siargao - Philippines"
    },
    {
      "value": "Europe/Vilnius",
      "label": "Siauliai - Lithuania"
    },
    {
      "value": "Europe/Bucharest",
      "label": "Sibiu - Romania"
    },
    {
      "value": "Asia/Jakarta",
      "label": "Sibolga - Indonesia"
    },
    {
      "value": "Asia/Jakarta",
      "label": "Siborong-Borong - Indonesia"
    },
    {
      "value": "Asia/Kuala_Lumpur",
      "label": "Sibu - Malaysia"
    },
    {
      "value": "Africa/Algiers",
      "label": "Sidi Bel Abbes - Algeria"
    },
    {
      "value": "America/Denver",
      "label": "Sidney - United States"
    },
    {
      "value": "Europe/Berlin",
      "label": "Siegerland - Germany"
    },
    {
      "value": "Asia/Phnom_Penh",
      "label": "Siem-reap - Cambodia"
    },
    {
      "value": "Europe/Rome",
      "label": "Siena - Italy"
    },
    {
      "value": "America/Argentina/Salta",
      "label": "Sierra Grande - Argentina"
    },
    {
      "value": "Asia/Colombo",
      "label": "Sigiriya - Sri Lanka"
    },
    {
      "value": "Atlantic/Reykjavik",
      "label": "Siglufjordur - Iceland"
    },
    {
      "value": "Europe/Rome",
      "label": "Sigonella - Italy"
    },
    {
      "value": "America/Havana",
      "label": "Siguanea - Cuba"
    },
    {
      "value": "Asia/Phnom_Penh",
      "label": "Sihanoukville - Cambodia"
    },
    {
      "value": "Europe/Istanbul",
      "label": "Siirt - Turkey"
    },
    {
      "value": "Africa/Bamako",
      "label": "Sikasso - Mali"
    },
    {
      "value": "America/Chicago",
      "label": "Sikeston - United States"
    },
    {
      "value": "Asia/Calcutta",
      "label": "Silchar - India"
    },
    {
      "value": "Asia/Shanghai",
      "label": "Simao - China"
    },
    {
      "value": "Asia/Katmandu",
      "label": "Simara - Nepal"
    },
    {
      "value": "Europe/Simferopol",
      "label": "Simferopol - Ukraine"
    },
    {
      "value": "Asia/Katmandu",
      "label": "Simikot - Nepal"
    },
    {
      "value": "Europe/Copenhagen",
      "label": "Sindal - Denmark"
    },
    {
      "value": "Asia/Singapore",
      "label": "Singapore - Singapore"
    },
    {
      "value": "Asia/Jakarta",
      "label": "Singkep - Indonesia"
    },
    {
      "value": "America/Campo_Grande",
      "label": "Sinop - Brazil"
    },
    {
      "value": "Europe/Istanbul",
      "label": "Sinop - Turkey"
    },
    {
      "value": "Asia/Jakarta",
      "label": "Sintang-Borneo Island - Indonesia"
    },
    {
      "value": "Europe/Zurich",
      "label": "Sion - Switzerland"
    },
    {
      "value": "America/Chicago",
      "label": "Sioux City - United States"
    },
    {
      "value": "America/Chicago",
      "label": "Sioux Falls - United States"
    },
    {
      "value": "America/Winnipeg",
      "label": "Sioux Lookout - Canada"
    },
    {
      "value": "Asia/Dubai",
      "label": "Sir Bani Yas Island - United Arab Emirates"
    },
    {
      "value": "Asia/Tehran",
      "label": "Siri Island - Iran"
    },
    {
      "value": "Asia/Tehran",
      "label": "Sirjan - Iran"
    },
    {
      "value": "Africa/Tripoli",
      "label": "Sirt - Libya"
    },
    {
      "value": "Africa/Johannesburg",
      "label": "Sishen - South Africa"
    },
    {
      "value": "America/Godthab",
      "label": "Sisimiut - Greenland"
    },
    {
      "value": "Europe/Athens",
      "label": "Sitia - Greece"
    },
    {
      "value": "America/Anchorage",
      "label": "Sitka - United States"
    },
    {
      "value": "Asia/Rangoon",
      "label": "Sittwe - Burma"
    },
    {
      "value": "America/Managua",
      "label": "Siuna - Nicaragua"
    },
    {
      "value": "Europe/Istanbul",
      "label": "Sivas - Turkey"
    },
    {
      "value": "America/Anchorage",
      "label": "Skagway - United States"
    },
    {
      "value": "Asia/Karachi",
      "label": "Skardu - Pakistan"
    },
    {
      "value": "Europe/Stockholm",
      "label": "Skelleftea - Sweden"
    },
    {
      "value": "Europe/Athens",
      "label": "Skiathos - Greece"
    },
    {
      "value": "Europe/Oslo",
      "label": "Skien - Norway"
    },
    {
      "value": "Europe/Athens",
      "label": "Skiros - Greece"
    },
    {
      "value": "Europe/Copenhagen",
      "label": "Skive - Denmark"
    },
    {
      "value": "Europe/Skopje",
      "label": "Skopje - Macedonia"
    },
    {
      "value": "Europe/Stockholm",
      "label": "Skovde - Sweden"
    },
    {
      "value": "Europe/Copenhagen",
      "label": "Skrydstrup - Denmark"
    },
    {
      "value": "Africa/Johannesburg",
      "label": "Skukuza - South Africa"
    },
    {
      "value": "America/Edmonton",
      "label": "Slave Lake - Canada"
    },
    {
      "value": "America/Anchorage",
      "label": "Sleetmute - United States"
    },
    {
      "value": "Europe/Bratislava",
      "label": "Sliac - Slovakia"
    },
    {
      "value": "Europe/Dublin",
      "label": "Sligo - Ireland"
    },
    {
      "value": "Europe/Warsaw",
      "label": "Slupsk - Poland"
    },
    {
      "value": "Africa/El_Aaiun",
      "label": "Smara - Western Sahara"
    },
    {
      "value": "America/Vancouver",
      "label": "Smithers - Canada"
    },
    {
      "value": "America/New_York",
      "label": "Smithfield - United States"
    },
    {
      "value": "America/Chicago",
      "label": "Smyrna - United States"
    },
    {
      "value": "Indian/Antananarivo",
      "label": "Soalala - Madagascar"
    },
    {
      "value": "Europe/Moscow",
      "label": "Sochi - Russia"
    },
    {
      "value": "Asia/Aden",
      "label": "Socotra - Yemen"
    },
    {
      "value": "Europe/Helsinki",
      "label": "Sodankyla - Finland"
    },
    {
      "value": "Europe/Stockholm",
      "label": "Soderhamn - Sweden"
    },
    {
      "value": "Europe/Copenhagen",
      "label": "Soenderborg - Denmark"
    },
    {
      "value": "Europe/Amsterdam",
      "label": "Soesterberg - Netherlands"
    },
    {
      "value": "Europe/Sofia",
      "label": "Sofia - Bulgaria"
    },
    {
      "value": "Europe/Oslo",
      "label": "Sogndal - Norway"
    },
    {
      "value": "Africa/Cairo",
      "label": "Sohag - Egypt"
    },
    {
      "value": "Asia/Seoul",
      "label": "Sokcho / Gangneung - South Korea"
    },
    {
      "value": "Africa/Lagos",
      "label": "Sokoto - Nigeria"
    },
    {
      "value": "Pacific/Efate",
      "label": "Sola - Vanuatu"
    },
    {
      "value": "America/Anchorage",
      "label": "Soldotna - United States"
    },
    {
      "value": "Europe/Paris",
      "label": "Solenzara - France"
    },
    {
      "value": "Asia/Jakarta",
      "label": "Solo City - Indonesia"
    },
    {
      "value": "Europe/Moscow",
      "label": "Solovetsky Islands - Russia"
    },
    {
      "value": "America/New_York",
      "label": "Somerset - United States"
    },
    {
      "value": "America/Godthab",
      "label": "Sondrestrom - Greenland"
    },
    {
      "value": "Africa/Dar_es_Salaam",
      "label": "Songea - Tanzania"
    },
    {
      "value": "Asia/Bangkok",
      "label": "Songkhla - Thailand"
    },
    {
      "value": "Asia/Saigon",
      "label": "Son-La - Vietnam"
    },
    {
      "value": "Europe/Oslo",
      "label": "Sorkjosen - Norway"
    },
    {
      "value": "Asia/Makassar",
      "label": "Soroako - Indonesia"
    },
    {
      "value": "America/Sao_Paulo",
      "label": "Sorocaba - Brazil"
    },
    {
      "value": "Asia/Jayapura",
      "label": "Sorong - Indonesia"
    },
    {
      "value": "Africa/Kampala",
      "label": "Soroti - Uganda"
    },
    {
      "value": "America/Belem",
      "label": "Soure - Brazil"
    },
    {
      "value": "America/New_York",
      "label": "South Bend - United States"
    },
    {
      "value": "America/Grand_Turk",
      "label": "South Caicos - Turks and Caicos Islands"
    },
    {
      "value": "America/Winnipeg",
      "label": "South Indian Lake - Canada"
    },
    {
      "value": "America/Los_Angeles",
      "label": "South Lake Tahoe - United States"
    },
    {
      "value": "America/Anchorage",
      "label": "South Naknek - United States"
    },
    {
      "value": "Europe/London",
      "label": "Southampton - United Kingdom"
    },
    {
      "value": "Africa/Lusaka",
      "label": "Southdowns - Zambia"
    },
    {
      "value": "Europe/London",
      "label": "Southend - United Kingdom"
    },
    {
      "value": "Asia/Yekaterinburg",
      "label": "Sovetskiy - Russia"
    },
    {
      "value": "Africa/Luanda",
      "label": "Soyo - Angola"
    },
    {
      "value": "Europe/Berlin",
      "label": "Spangdahlem - Germany"
    },
    {
      "value": "America/Tortola",
      "label": "Spanish Town - British Virgin Islands"
    },
    {
      "value": "America/Anchorage",
      "label": "Sparrevohn - United States"
    },
    {
      "value": "Europe/Athens",
      "label": "Sparti - Greece"
    },
    {
      "value": "America/Denver",
      "label": "Spearfish-South Dakota - United States"
    },
    {
      "value": "America/Edmonton",
      "label": "Spence Bay - Canada"
    },
    {
      "value": "America/Chicago",
      "label": "Spencer - United States"
    },
    {
      "value": "Europe/Zagreb",
      "label": "Split - Croatia"
    },
    {
      "value": "America/Los_Angeles",
      "label": "Spokane - United States"
    },
    {
      "value": "America/Nassau",
      "label": "Spring Point - Bahamas"
    },
    {
      "value": "Africa/Johannesburg",
      "label": "Springbok - South Africa"
    },
    {
      "value": "America/Chicago",
      "label": "Springfield - United States"
    },
    {
      "value": "Asia/Srednekolymsk",
      "label": "Srednekolymsk - Russia"
    },
    {
      "value": "Asia/Calcutta",
      "label": "Srinagar - India"
    },
    {
      "value": "Australia/Brisbane",
      "label": "St George - Australia"
    },
    {
      "value": "Europe/London",
      "label": "ST MARY\\'S - United Kingdom"
    },
    {
      "value": "America/Anchorage",
      "label": "St Mary's - United States"
    },
    {
      "value": "America/St_Johns",
      "label": "St. Anthony - Canada"
    },
    {
      "value": "America/New_York",
      "label": "St. Augustine Airport - United States"
    },
    {
      "value": "Africa/Cairo",
      "label": "St. Catherine - Egypt"
    },
    {
      "value": "America/St_Thomas",
      "label": "St. Croix Island - Virgin Islands"
    },
    {
      "value": "America/Toronto",
      "label": "St. Jean - Canada"
    },
    {
      "value": "America/Halifax",
      "label": "St. John - Canada"
    },
    {
      "value": "America/St_Johns",
      "label": "St. John's - Canada"
    },
    {
      "value": "America/St_Johns",
      "label": "St. Lewis - Canada"
    },
    {
      "value": "Africa/Dakar",
      "label": "St. Louis - Senegal"
    },
    {
      "value": "America/Chicago",
      "label": "St. Louis - United States"
    },
    {
      "value": "America/Anchorage",
      "label": "St. Michael - United States"
    },
    {
      "value": "America/Chicago",
      "label": "St. Paul - United States"
    },
    {
      "value": "America/Anchorage",
      "label": "St. Paul Island - United States"
    },
    {
      "value": "Europe/Moscow",
      "label": "St. Petersburg - Russia"
    },
    {
      "value": "America/New_York",
      "label": "St. Petersburg - United States"
    },
    {
      "value": "America/Winnipeg",
      "label": "St. Theresa Point - Canada"
    },
    {
      "value": "America/St_Thomas",
      "label": "St. Thomas - Virgin Islands"
    },
    {
      "value": "Europe/Paris",
      "label": "St.-brieuc Armor - France"
    },
    {
      "value": "Indian/Reunion",
      "label": "St.-denis - Reunion"
    },
    {
      "value": "America/Cayenne",
      "label": "St.-georges Oyapock - French Guiana"
    },
    {
      "value": "Europe/Paris",
      "label": "St.-nazaire - France"
    },
    {
      "value": "Indian/Reunion",
      "label": "St.-pierre - Reunion"
    },
    {
      "value": "America/Miquelon",
      "label": "St.-pierre - Saint Pierre and Miquelon"
    },
    {
      "value": "Europe/Paris",
      "label": "St.-yan - France"
    },
    {
      "value": "America/Nassau",
      "label": "Staniel Cay - Bahamas"
    },
    {
      "value": "Atlantic/Stanley",
      "label": "Stanley - Falkland Islands"
    },
    {
      "value": "Europe/Sofia",
      "label": "Stara Zagora - Bulgaria"
    },
    {
      "value": "America/New_York",
      "label": "State College Pennsylvania - United States"
    },
    {
      "value": "America/New_York",
      "label": "Statesville - United States"
    },
    {
      "value": "America/Blanc-Sablon",
      "label": "St-Augustin - Canada"
    },
    {
      "value": "Europe/Copenhagen",
      "label": "Stauning - Denmark"
    },
    {
      "value": "Europe/Oslo",
      "label": "Stavanger - Norway"
    },
    {
      "value": "Europe/Moscow",
      "label": "Stavropol - Russia"
    },
    {
      "value": "America/Denver",
      "label": "Steamboat Springs - United States"
    },
    {
      "value": "America/Nassau",
      "label": "Stella Maris - Bahamas"
    },
    {
      "value": "America/St_Johns",
      "label": "Stephenville - Canada"
    },
    {
      "value": "America/Chicago",
      "label": "Stephenville - United States"
    },
    {
      "value": "America/Denver",
      "label": "Sterling - United States"
    },
    {
      "value": "Europe/Paris",
      "label": "St-Etienne - France"
    },
    {
      "value": "America/Chicago",
      "label": "Stevens Point - United States"
    },
    {
      "value": "Pacific/Auckland",
      "label": "Stewart Island - New Zealand"
    },
    {
      "value": "America/Guadeloupe",
      "label": "St-François - Guadeloupe"
    },
    {
      "value": "America/Chicago",
      "label": "Stillwater - United States"
    },
    {
      "value": "Pacific/Guadalcanal",
      "label": "Stirling Island - Solomon Islands"
    },
    {
      "value": "America/Los_Angeles",
      "label": "Stockton - United States"
    },
    {
      "value": "America/Paramaribo",
      "label": "Stoelmans Eiland - Suriname"
    },
    {
      "value": "Europe/Oslo",
      "label": "Stokmarknes - Norway"
    },
    {
      "value": "America/Regina",
      "label": "Stony Rapids - Canada"
    },
    {
      "value": "Europe/Oslo",
      "label": "Stord - Norway"
    },
    {
      "value": "Europe/London",
      "label": "Stornoway - United Kingdom"
    },
    {
      "value": "Australia/Melbourne",
      "label": "Strahan - Australia"
    },
    {
      "value": "Europe/Paris",
      "label": "Strasbourg - France"
    },
    {
      "value": "America/New_York",
      "label": "Stratford - United States"
    },
    {
      "value": "Europe/Berlin",
      "label": "Straubing - Germany"
    },
    {
      "value": "Asia/Krasnoyarsk",
      "label": "Strezhevoy - Russia"
    },
    {
      "value": "Europe/London",
      "label": "Stronsay - United Kingdom"
    },
    {
      "value": "America/New_York",
      "label": "Stuart - United States"
    },
    {
      "value": "Asia/Phnom_Penh",
      "label": "Stung Treng - Cambodia"
    },
    {
      "value": "Europe/Berlin",
      "label": "Stuttgart - Germany"
    },
    {
      "value": "Asia/Dili",
      "label": "Suai - East Timor"
    },
    {
      "value": "Pacific/Guadalcanal",
      "label": "Suavanao - Solomon Islands"
    },
    {
      "value": "Europe/Bucharest",
      "label": "Suceava - Romania"
    },
    {
      "value": "America/La_Paz",
      "label": "Sucre - Bolivia"
    },
    {
      "value": "America/Toronto",
      "label": "Sudbury - Canada"
    },
    {
      "value": "Australia/Brisbane",
      "label": "Sue Islet - Australia"
    },
    {
      "value": "America/Edmonton",
      "label": "Suffield - Canada"
    },
    {
      "value": "America/Chicago",
      "label": "Sugar Land - United States"
    },
    {
      "value": "Asia/Karachi",
      "label": "Sui - Pakistan"
    },
    {
      "value": "Asia/Bangkok",
      "label": "Sukhothai - Thailand"
    },
    {
      "value": "Asia/Tbilisi",
      "label": "Sukhumi - Georgia"
    },
    {
      "value": "Asia/Karachi",
      "label": "Sukkur - Pakistan"
    },
    {
      "value": "Asia/Makassar",
      "label": "Sulawesi Tenggara - Indonesia"
    },
    {
      "value": "Asia/Riyadh",
      "label": "Sulayel - Saudi Arabia"
    },
    {
      "value": "Asia/Baghdad",
      "label": "Sulaymaniyah - Iraq"
    },
    {
      "value": "Asia/Makassar",
      "label": "Sumbawa Island - Indonesia"
    },
    {
      "value": "Europe/London",
      "label": "Sumburgh - United Kingdom"
    },
    {
      "value": "America/Toronto",
      "label": "Summer Beaver - Canada"
    },
    {
      "value": "America/Halifax",
      "label": "Summerside - Canada"
    },
    {
      "value": "America/New_York",
      "label": "Sumter - United States"
    },
    {
      "value": "Europe/Stockholm",
      "label": "Sundsvall - Sweden"
    },
    {
      "value": "Asia/Yakutsk",
      "label": "Suntar - Russia"
    },
    {
      "value": "Africa/Accra",
      "label": "Sunyani - Ghana"
    },
    {
      "value": "Asia/Jakarta",
      "label": "Surabaya - Indonesia"
    },
    {
      "value": "Asia/Calcutta",
      "label": "Surat - India"
    },
    {
      "value": "Asia/Bangkok",
      "label": "Surat Thani - Thailand"
    },
    {
      "value": "Asia/Yekaterinburg",
      "label": "Surgut - Russia"
    },
    {
      "value": "Asia/Bangkok",
      "label": "Surin - Thailand"
    },
    {
      "value": "Asia/Katmandu",
      "label": "Surkhet - Nepal"
    },
    {
      "value": "Asia/Seoul",
      "label": "Suwon - South Korea"
    },
    {
      "value": "Asia/Shanghai",
      "label": "Suzhou - China"
    },
    {
      "value": "Arctic/Longyearbyen",
      "label": "Svalbard - Norway"
    },
    {
      "value": "Europe/Stockholm",
      "label": "Sveg - Sweden"
    },
    {
      "value": "Europe/Oslo",
      "label": "Svolvær - Norway"
    },
    {
      "value": "Africa/Windhoek",
      "label": "Swakopmund - Namibia"
    },
    {
      "value": "America/Winnipeg",
      "label": "Swan River - Canada"
    },
    {
      "value": "Europe/London",
      "label": "Swansea - United Kingdom"
    },
    {
      "value": "America/Regina",
      "label": "Swift Current - Canada"
    },
    {
      "value": "Asia/Katmandu",
      "label": "Syangboche - Nepal"
    },
    {
      "value": "Australia/Sydney",
      "label": "Sydney - Australia"
    },
    {
      "value": "America/Halifax",
      "label": "Sydney - Canada"
    },
    {
      "value": "Europe/Moscow",
      "label": "Syktyvkar - Russia"
    },
    {
      "value": "Asia/Dhaka",
      "label": "Sylhet Osmani - Bangladesh"
    },
    {
      "value": "America/New_York",
      "label": "Syracuse - United States"
    },
    {
      "value": "Europe/Athens",
      "label": "Syros Island - Greece"
    },
    {
      "value": "Europe/Warsaw",
      "label": "Szczecin - Poland"
    },
    {
      "value": "America/Blanc-Sablon",
      "label": "Tête-à-la-Baleine - Canada"
    },
    {
      "value": "Africa/Cairo",
      "label": "Taba - Egypt"
    },
    {
      "value": "Africa/Tunis",
      "label": "Tabarka - Tunisia"
    },
    {
      "value": "Asia/Tehran",
      "label": "Tabas - Iran"
    },
    {
      "value": "America/Boa_Vista",
      "label": "Tabatinga - Brazil"
    },
    {
      "value": "Pacific/Tarawa",
      "label": "Tabiteuea - Kiribati"
    },
    {
      "value": "Africa/Dar_es_Salaam",
      "label": "Tabora - Tanzania"
    },
    {
      "value": "Asia/Tehran",
      "label": "Tabriz - Iran"
    },
    {
      "value": "Pacific/Port_Moresby",
      "label": "Tabubil - Papua New Guinea"
    },
    {
      "value": "Asia/Riyadh",
      "label": "Tabuk - Saudi Arabia"
    },
    {
      "value": "Asia/Shanghai",
      "label": "Tacheng - China"
    },
    {
      "value": "Asia/Rangoon",
      "label": "Tachilek - Burma"
    },
    {
      "value": "Asia/Manila",
      "label": "Tacloban - Philippines"
    },
    {
      "value": "America/Lima",
      "label": "Tacna - Peru"
    },
    {
      "value": "America/Los_Angeles",
      "label": "Tacoma - United States"
    },
    {
      "value": "Africa/Djibouti",
      "label": "Tadjoura - Djibouti"
    },
    {
      "value": "America/Winnipeg",
      "label": "Tadoule Lake - Canada"
    },
    {
      "value": "Asia/Seoul",
      "label": "Taegu - South Korea"
    },
    {
      "value": "Europe/Moscow",
      "label": "Taganrog - Russia"
    },
    {
      "value": "Asia/Manila",
      "label": "Tagbilaran - Philippines"
    },
    {
      "value": "Africa/Niamey",
      "label": "Tahoua - Niger"
    },
    {
      "value": "Asia/Taipei",
      "label": "Taichung - Taiwan"
    },
    {
      "value": "Asia/Riyadh",
      "label": "Taif - Saudi Arabia"
    },
    {
      "value": "Asia/Taipei",
      "label": "Tainan - Taiwan"
    },
    {
      "value": "Asia/Taipei",
      "label": "Taipei - Taiwan"
    },
    {
      "value": "Asia/Shanghai",
      "label": "Taiyuan - China"
    },
    {
      "value": "Asia/Aden",
      "label": "Taiz - Yemen"
    },
    {
      "value": "Pacific/Auckland",
      "label": "Takaka - New Zealand"
    },
    {
      "value": "Asia/Tokyo",
      "label": "Takamatsu - Japan"
    },
    {
      "value": "Pacific/Tahiti",
      "label": "Takapoto - French Polynesia"
    },
    {
      "value": "Pacific/Tahiti",
      "label": "Takaroa - French Polynesia"
    },
    {
      "value": "Africa/Accra",
      "label": "Takoradi - Ghana"
    },
    {
      "value": "Asia/Jakarta",
      "label": "Talang Gudang-Sumatra Island - Indonesia"
    },
    {
      "value": "America/Lima",
      "label": "Talara - Peru"
    },
    {
      "value": "Asia/Karachi",
      "label": "Talhar - Pakistan"
    },
    {
      "value": "America/Anchorage",
      "label": "Talkeetna - United States"
    },
    {
      "value": "America/New_York",
      "label": "Tallahassee - United States"
    },
    {
      "value": "Europe/Tallinn",
      "label": "Tallinn-ulemiste International - Estonia"
    },
    {
      "value": "Africa/Accra",
      "label": "Tamale - Ghana"
    },
    {
      "value": "Pacific/Tarawa",
      "label": "Tamana - Kiribati"
    },
    {
      "value": "Africa/Algiers",
      "label": "Tamanrasset - Algeria"
    },
    {
      "value": "Africa/Dakar",
      "label": "Tambacounda - Senegal"
    },
    {
      "value": "Indian/Antananarivo",
      "label": "Tambohorano - Madagascar"
    },
    {
      "value": "Europe/Moscow",
      "label": "Tambow - Russia"
    },
    {
      "value": "America/Bogota",
      "label": "Tame - Colombia"
    },
    {
      "value": "America/New_York",
      "label": "Tampa - United States"
    },
    {
      "value": "Europe/Helsinki",
      "label": "Tampere - Finland"
    },
    {
      "value": "America/Mexico_City",
      "label": "Tampico - Mexico"
    },
    {
      "value": "America/Mexico_City",
      "label": "Tamuin - Mexico"
    },
    {
      "value": "Australia/Sydney",
      "label": "Tamworth - Australia"
    },
    {
      "value": "Africa/Casablanca",
      "label": "Tan Tan - Morocco"
    },
    {
      "value": "Asia/Manila",
      "label": "Tandag - Philippines"
    },
    {
      "value": "America/Buenos_Aires",
      "label": "Tandil - Argentina"
    },
    {
      "value": "Asia/Tokyo",
      "label": "Tanegashima - Japan"
    },
    {
      "value": "Africa/Dar_es_Salaam",
      "label": "Tanga - Tanzania"
    },
    {
      "value": "Africa/Casablanca",
      "label": "Tanger - Morocco"
    },
    {
      "value": "Asia/Calcutta",
      "label": "Tanjore - India"
    },
    {
      "value": "Asia/Jakarta",
      "label": "Tanjung Pandan - Indonesia"
    },
    {
      "value": "Asia/Jakarta",
      "label": "Tanjung Pinang - Indonesia"
    },
    {
      "value": "Asia/Makassar",
      "label": "Tanjung Redep-Borneo Island - Indonesia"
    },
    {
      "value": "Asia/Makassar",
      "label": "Tanjung Santan - Indonesia"
    },
    {
      "value": "Asia/Makassar",
      "label": "Tanjung Selor-Borneo Island - Indonesia"
    },
    {
      "value": "Asia/Makassar",
      "label": "Tanjung-Borneo Island - Indonesia"
    },
    {
      "value": "Pacific/Efate",
      "label": "Tanna - Vanuatu"
    },
    {
      "value": "America/Mexico_City",
      "label": "Tapachula - Mexico"
    },
    {
      "value": "Asia/Katmandu",
      "label": "Taplejung - Nepal"
    },
    {
      "value": "Asia/Makassar",
      "label": "Taraken - Indonesia"
    },
    {
      "value": "Asia/Tokyo",
      "label": "Tarama - Japan"
    },
    {
      "value": "America/Guayaquil",
      "label": "Tarapoa - Ecuador"
    },
    {
      "value": "America/Lima",
      "label": "Tarapoto - Peru"
    },
    {
      "value": "America/Rio_Branco",
      "label": "Tarauaca - Brazil"
    },
    {
      "value": "Pacific/Tarawa",
      "label": "Tarawa - Kiribati"
    },
    {
      "value": "Europe/Paris",
      "label": "Tarbes - France"
    },
    {
      "value": "Australia/Sydney",
      "label": "Taree - Australia"
    },
    {
      "value": "Pacific/Port_Moresby",
      "label": "Tari - Papua New Guinea"
    },
    {
      "value": "America/La_Paz",
      "label": "Tarija - Bolivia"
    },
    {
      "value": "Asia/Kabul",
      "label": "Tarin Kowt - Afghanistan"
    },
    {
      "value": "America/Argentina/Salta",
      "label": "Tartagal - Argentina"
    },
    {
      "value": "Europe/Tallinn",
      "label": "Tartu - Estonia"
    },
    {
      "value": "Asia/Samarkand",
      "label": "Tashkent - Uzbekistan"
    },
    {
      "value": "Asia/Jakarta",
      "label": "Tasikmalaya - Indonesia"
    },
    {
      "value": "America/Toronto",
      "label": "Tasiujaq - Canada"
    },
    {
      "value": "America/Anchorage",
      "label": "Tatalina - United States"
    },
    {
      "value": "Pacific/Auckland",
      "label": "Taupo - New Zealand"
    },
    {
      "value": "Pacific/Auckland",
      "label": "Tauranga - New Zealand"
    },
    {
      "value": "Asia/Kuala_Lumpur",
      "label": "Tawau - Malaysia"
    },
    {
      "value": "Asia/Manila",
      "label": "Taytay - Philippines"
    },
    {
      "value": "Asia/Tbilisi",
      "label": "Tbilisi - Georgia"
    },
    {
      "value": "Africa/Libreville",
      "label": "Tchibanga - Gabon"
    },
    {
      "value": "Africa/Algiers",
      "label": "Tebessa - Algeria"
    },
    {
      "value": "Europe/London",
      "label": "Teesside - United Kingdom"
    },
    {
      "value": "America/Boa_Vista",
      "label": "Tefe - Brazil"
    },
    {
      "value": "America/Tegucigalpa",
      "label": "Tegucigalpa - Honduras"
    },
    {
      "value": "Asia/Tehran",
      "label": "Tehran - Iran"
    },
    {
      "value": "America/Mexico_City",
      "label": "Tehuacan - Mexico"
    },
    {
      "value": "America/Tegucigalpa",
      "label": "Tela - Honduras"
    },
    {
      "value": "Asia/Jerusalem",
      "label": "Tel-aviv - Israel"
    },
    {
      "value": "America/Sao_Paulo",
      "label": "Telemaco Borba - Brazil"
    },
    {
      "value": "Australia/Perth",
      "label": "Telfer - Australia"
    },
    {
      "value": "America/Anchorage",
      "label": "Teller - United States"
    },
    {
      "value": "America/Denver",
      "label": "Telluride - United States"
    },
    {
      "value": "Australia/Sydney",
      "label": "Temora - Australia"
    },
    {
      "value": "America/Chicago",
      "label": "Temple - United States"
    },
    {
      "value": "America/Santiago",
      "label": "Temuco - Chile"
    },
    {
      "value": "Atlantic/Canary",
      "label": "Tenerife - Spain"
    },
    {
      "value": "Asia/Singapore",
      "label": "Tengah - Singapore"
    },
    {
      "value": "Asia/Shanghai",
      "label": "Tengchong - China"
    },
    {
      "value": "Australia/Darwin",
      "label": "Tennant Creek - Australia"
    },
    {
      "value": "America/Mazatlan",
      "label": "Tepic - Mexico"
    },
    {
      "value": "America/Fortaleza",
      "label": "Teresina - Brazil"
    },
    {
      "value": "Asia/Samarkand",
      "label": "Termez - Uzbekistan"
    },
    {
      "value": "Asia/Jayapura",
      "label": "Ternate - Indonesia"
    },
    {
      "value": "America/Vancouver",
      "label": "Terrace - Canada"
    },
    {
      "value": "America/New_York",
      "label": "Terre Haute - United States"
    },
    {
      "value": "America/Vancouver",
      "label": "Teslin - Canada"
    },
    {
      "value": "Africa/Maputo",
      "label": "Tete - Mozambique"
    },
    {
      "value": "America/New_York",
      "label": "Teterboro - United States"
    },
    {
      "value": "Pacific/Tahiti",
      "label": "Tetiaroa - French Polynesia"
    },
    {
      "value": "Africa/Casablanca",
      "label": "Tetouan - Morocco"
    },
    {
      "value": "America/Vancouver",
      "label": "Texada - Canada"
    },
    {
      "value": "America/Chicago",
      "label": "Texarkana - United States"
    },
    {
      "value": "Asia/Calcutta",
      "label": "Tezpur - India"
    },
    {
      "value": "Asia/Rangoon",
      "label": "Thandwe - Burma"
    },
    {
      "value": "Australia/Brisbane",
      "label": "Thargomindah - Australia"
    },
    {
      "value": "America/Los_Angeles",
      "label": "The Dalles - United States"
    },
    {
      "value": "America/Winnipeg",
      "label": "The Pas - Canada"
    },
    {
      "value": "America/Anguilla",
      "label": "The Valley - Anguilla"
    },
    {
      "value": "Australia/Brisbane",
      "label": "Theodore - Australia"
    },
    {
      "value": "Europe/Athens",
      "label": "Thessaloniki - Greece"
    },
    {
      "value": "America/Chicago",
      "label": "Thief River Falls - United States"
    },
    {
      "value": "Asia/Thimphu",
      "label": "Thimphu - Bhutan"
    },
    {
      "value": "Europe/Athens",
      "label": "Thira - Greece"
    },
    {
      "value": "Europe/Copenhagen",
      "label": "Thisted - Denmark"
    },
    {
      "value": "America/New_York",
      "label": "Thomasville - United States"
    },
    {
      "value": "America/Winnipeg",
      "label": "Thompson - Canada"
    },
    {
      "value": "Atlantic/Reykjavik",
      "label": "Thorshofn - Iceland"
    },
    {
      "value": "America/Thule",
      "label": "Thule - Greenland"
    },
    {
      "value": "Asia/Muscat",
      "label": "Thumrait - Oman"
    },
    {
      "value": "America/Toronto",
      "label": "Thunder Bay - Canada"
    },
    {
      "value": "Asia/Shanghai",
      "label": "Tianjin - China"
    },
    {
      "value": "Africa/Algiers",
      "label": "Tiaret - Algeria"
    },
    {
      "value": "Africa/Nouakchott",
      "label": "Tidjikja - Mauritania"
    },
    {
      "value": "America/New_York",
      "label": "Tifton - United States"
    },
    {
      "value": "Pacific/Noumea",
      "label": "Tiga - New Caledonia"
    },
    {
      "value": "America/Tijuana",
      "label": "Tijuana - Mexico"
    },
    {
      "value": "Pacific/Tahiti",
      "label": "Tikehau - French Polynesia"
    },
    {
      "value": "Africa/Douala",
      "label": "Tiko - Cameroon"
    },
    {
      "value": "Asia/Yakutsk",
      "label": "Tiksi - Russia"
    },
    {
      "value": "America/Los_Angeles",
      "label": "Tillamook - United States"
    },
    {
      "value": "Africa/Algiers",
      "label": "Tilrempt - Algeria"
    },
    {
      "value": "Pacific/Auckland",
      "label": "Timaru - New Zealand"
    },
    {
      "value": "Asia/Jayapura",
      "label": "Timika - Indonesia"
    },
    {
      "value": "Africa/Algiers",
      "label": "Timimoun - Algeria"
    },
    {
      "value": "Europe/Bucharest",
      "label": "Timisoara - Romania"
    },
    {
      "value": "America/Toronto",
      "label": "Timmins - Canada"
    },
    {
      "value": "America/Anchorage",
      "label": "Tin City - United States"
    },
    {
      "value": "Africa/Algiers",
      "label": "Tindouf - Algeria"
    },
    {
      "value": "America/Lima",
      "label": "Tingo Maria - Peru"
    },
    {
      "value": "Asia/Kuala_Lumpur",
      "label": "Tioman - Malaysia"
    },
    {
      "value": "Africa/Addis_Ababa",
      "label": "Tippi - Ethiopia"
    },
    {
      "value": "America/Guayaquil",
      "label": "Tiputini - Ecuador"
    },
    {
      "value": "Europe/Tirane",
      "label": "Tirana - Albania"
    },
    {
      "value": "Europe/London",
      "label": "Tiree - United Kingdom"
    },
    {
      "value": "Europe/Bucharest",
      "label": "Tirgu Mures - Romania"
    },
    {
      "value": "Asia/Calcutta",
      "label": "Tiruchirappalli - India"
    },
    {
      "value": "Asia/Calcutta",
      "label": "Tirupeti - India"
    },
    {
      "value": "America/New_York",
      "label": "Titusville - United States"
    },
    {
      "value": "Europe/Podgorica",
      "label": "Tivat - Montenegro"
    },
    {
      "value": "Africa/Algiers",
      "label": "Tlemcen - Algeria"
    },
    {
      "value": "Indian/Antananarivo",
      "label": "Toamasina - Madagascar"
    },
    {
      "value": "Africa/Tripoli",
      "label": "Tobruk - Libya"
    },
    {
      "value": "America/New_York",
      "label": "Toccoa - United States"
    },
    {
      "value": "America/Vancouver",
      "label": "Tofino - Canada"
    },
    {
      "value": "America/Anchorage",
      "label": "Togiak Village - United States"
    },
    {
      "value": "America/Anchorage",
      "label": "Tok - United States"
    },
    {
      "value": "Europe/Istanbul",
      "label": "Tokat - Turkey"
    },
    {
      "value": "America/Anchorage",
      "label": "Toksook Bay - United States"
    },
    {
      "value": "Pacific/Port_Moresby",
      "label": "Tokua - Papua New Guinea"
    },
    {
      "value": "Asia/Tokyo",
      "label": "Tokunoshima - Japan"
    },
    {
      "value": "Asia/Tokyo",
      "label": "Tokushima - Japan"
    },
    {
      "value": "Asia/Tokyo",
      "label": "Tokyo - Japan"
    },
    {
      "value": "Indian/Antananarivo",
      "label": "Tolagnaro - Madagascar"
    },
    {
      "value": "America/Sao_Paulo",
      "label": "Toledo - Brazil"
    },
    {
      "value": "America/New_York",
      "label": "Toledo - United States"
    },
    {
      "value": "Indian/Antananarivo",
      "label": "Toliara - Madagascar"
    },
    {
      "value": "America/Bogota",
      "label": "Tolu - Colombia"
    },
    {
      "value": "America/Mexico_City",
      "label": "Toluca - Mexico"
    },
    {
      "value": "Asia/Kuala_Lumpur",
      "label": "Tomanggong - Malaysia"
    },
    {
      "value": "Africa/Bamako",
      "label": "Tombouctou - Mali"
    },
    {
      "value": "Asia/Krasnoyarsk",
      "label": "Tomsk - Russia"
    },
    {
      "value": "Pacific/Tongatapu",
      "label": "Tongatapu - Tonga"
    },
    {
      "value": "Asia/Shanghai",
      "label": "Tonghua - China"
    },
    {
      "value": "Asia/Shanghai",
      "label": "Tongliao - China"
    },
    {
      "value": "Pacific/Efate",
      "label": "Tongoa Island - Vanuatu"
    },
    {
      "value": "Asia/Shanghai",
      "label": "Tongren - China"
    },
    {
      "value": "America/Los_Angeles",
      "label": "Tonopah - United States"
    },
    {
      "value": "Australia/Brisbane",
      "label": "Toowoomba - Australia"
    },
    {
      "value": "America/Chicago",
      "label": "Topeka - United States"
    },
    {
      "value": "Europe/Istanbul",
      "label": "Topel - Turkey"
    },
    {
      "value": "Europe/Rome",
      "label": "Torino - Italy"
    },
    {
      "value": "America/Toronto",
      "label": "Toronto - Canada"
    },
    {
      "value": "America/Los_Angeles",
      "label": "Torrance - United States"
    },
    {
      "value": "America/Mexico_City",
      "label": "Torreon - Mexico"
    },
    {
      "value": "Europe/Stockholm",
      "label": "Torsby - Sweden"
    },
    {
      "value": "America/Tortola",
      "label": "Tortola - British Virgin Islands"
    },
    {
      "value": "Europe/Rome",
      "label": "Tortoli - Italy"
    },
    {
      "value": "Pacific/Gambier",
      "label": "Totegegie - French Polynesia"
    },
    {
      "value": "America/Paramaribo",
      "label": "Totness - Suriname"
    },
    {
      "value": "Asia/Tokyo",
      "label": "Tottori - Japan"
    },
    {
      "value": "Africa/Algiers",
      "label": "Touggourt - Algeria"
    },
    {
      "value": "Pacific/Noumea",
      "label": "Touho - New Caledonia"
    },
    {
      "value": "Europe/Paris",
      "label": "Tours - France"
    },
    {
      "value": "Europe/Paris",
      "label": "Toussous-le-noble - France"
    },
    {
      "value": "Australia/Brisbane",
      "label": "Townsville - Australia"
    },
    {
      "value": "Asia/Tokyo",
      "label": "Toyama - Japan"
    },
    {
      "value": "Asia/Tokyo",
      "label": "Toyooka - Japan"
    },
    {
      "value": "Africa/Tunis",
      "label": "Tozeur - Tunisia"
    },
    {
      "value": "Europe/Istanbul",
      "label": "Trabzon - Turkey"
    },
    {
      "value": "America/Vancouver",
      "label": "Trail - Canada"
    },
    {
      "value": "Asia/Bangkok",
      "label": "Trang - Thailand"
    },
    {
      "value": "Europe/Rome",
      "label": "Trapani - Italy"
    },
    {
      "value": "Asia/Bangkok",
      "label": "Trat - Thailand"
    },
    {
      "value": "America/New_York",
      "label": "Traverse City - United States"
    },
    {
      "value": "America/Nassau",
      "label": "Treasure Cay - Bahamas"
    },
    {
      "value": "America/Catamarca",
      "label": "Trelew - Argentina"
    },
    {
      "value": "America/Toronto",
      "label": "Trenton - Canada"
    },
    {
      "value": "America/New_York",
      "label": "Trenton - United States"
    },
    {
      "value": "America/Buenos_Aires",
      "label": "Tres Arroyos - Argentina"
    },
    {
      "value": "America/Campo_Grande",
      "label": "Tres Lagoas - Brazil"
    },
    {
      "value": "Europe/Rome",
      "label": "Treviso - Italy"
    },
    {
      "value": "Asia/Colombo",
      "label": "Trinciomalee - Sri Lanka"
    },
    {
      "value": "America/La_Paz",
      "label": "Trinidad - Bolivia"
    },
    {
      "value": "America/Havana",
      "label": "Trinidad - Cuba"
    },
    {
      "value": "Africa/Tripoli",
      "label": "Tripoli - Libya"
    },
    {
      "value": "Asia/Calcutta",
      "label": "Trivandrum - India"
    },
    {
      "value": "America/Toronto",
      "label": "Trois Rivieres - Canada"
    },
    {
      "value": "Europe/Stockholm",
      "label": "Trollhattan - Sweden"
    },
    {
      "value": "Europe/Oslo",
      "label": "Tromso - Norway"
    },
    {
      "value": "Europe/Oslo",
      "label": "Trondheim - Norway"
    },
    {
      "value": "America/Los_Angeles",
      "label": "Troutdale - United States"
    },
    {
      "value": "America/Los_Angeles",
      "label": "Truckee - United States"
    },
    {
      "value": "America/Tegucigalpa",
      "label": "Trujillo - Honduras"
    },
    {
      "value": "America/Lima",
      "label": "Trujillo - Peru"
    },
    {
      "value": "America/Denver",
      "label": "Truth Or Consequences - United States"
    },
    {
      "value": "Asia/Qyzylorda",
      "label": "Tselinograd - Kazakhstan"
    },
    {
      "value": "Africa/Lubumbashi",
      "label": "Tshikapa - Congo (Kinshasa)"
    },
    {
      "value": "Indian/Antananarivo",
      "label": "Tsiroanomandidy - Madagascar"
    },
    {
      "value": "Africa/Windhoek",
      "label": "Tsumeb - Namibia"
    },
    {
      "value": "Asia/Tokyo",
      "label": "Tsushima - Japan"
    },
    {
      "value": "Pacific/Tahiti",
      "label": "Tubuai - French Polynesia"
    },
    {
      "value": "America/Phoenix",
      "label": "Tucson - United States"
    },
    {
      "value": "America/Argentina/Tucuman",
      "label": "Tucuman - Argentina"
    },
    {
      "value": "America/Denver",
      "label": "Tucumcari - United States"
    },
    {
      "value": "America/Caracas",
      "label": "Tucupita - Venezuela"
    },
    {
      "value": "America/Belem",
      "label": "Tucurui - Brazil"
    },
    {
      "value": "Asia/Manila",
      "label": "Tuguegarao - Philippines"
    },
    {
      "value": "America/Edmonton",
      "label": "Tuktoyaktuk - Canada"
    },
    {
      "value": "America/Guayaquil",
      "label": "Tulcan - Ecuador"
    },
    {
      "value": "Europe/Bucharest",
      "label": "Tulcea - Romania"
    },
    {
      "value": "Africa/Gaborone",
      "label": "Tuli Lodge - Botswana"
    },
    {
      "value": "America/Edmonton",
      "label": "Tulita - Canada"
    },
    {
      "value": "America/Chicago",
      "label": "Tulsa - United States"
    },
    {
      "value": "America/Bogota",
      "label": "Tulua - Colombia"
    },
    {
      "value": "America/Bogota",
      "label": "Tumaco - Colombia"
    },
    {
      "value": "America/Lima",
      "label": "Tumbes - Peru"
    },
    {
      "value": "America/Caracas",
      "label": "Tumeremo - Venezuela"
    },
    {
      "value": "Asia/Katmandu",
      "label": "Tumling Tar - Nepal"
    },
    {
      "value": "America/Chicago",
      "label": "Tunica - United States"
    },
    {
      "value": "Africa/Tunis",
      "label": "Tunis - Tunisia"
    },
    {
      "value": "America/Chicago",
      "label": "Tupelo - United States"
    },
    {
      "value": "Asia/Riyadh",
      "label": "Turaif - Saudi Arabia"
    },
    {
      "value": "Asia/Karachi",
      "label": "Turbat - Pakistan"
    },
    {
      "value": "Europe/Helsinki",
      "label": "Turku - Finland"
    },
    {
      "value": "Asia/Shanghai",
      "label": "Turpan - China"
    },
    {
      "value": "Asia/Krasnoyarsk",
      "label": "Turukhansk - Russia"
    },
    {
      "value": "America/Chicago",
      "label": "Tuscaloosa AL - United States"
    },
    {
      "value": "Asia/Saigon",
      "label": "Tuy Hoa - Vietnam"
    },
    {
      "value": "Europe/Moscow",
      "label": "Tver - Russia"
    },
    {
      "value": "America/Denver",
      "label": "Twin Falls - United States"
    },
    {
      "value": "America/Chicago",
      "label": "Tyler - United States"
    },
    {
      "value": "Asia/Yekaterinburg",
      "label": "Tyumen - Russia"
    },
    {
      "value": "Africa/Johannesburg",
      "label": "Tzaneen - South Africa"
    },
    {
      "value": "Pacific/Marquesas",
      "label": "Ua Huka - French Polynesia"
    },
    {
      "value": "Pacific/Marquesas",
      "label": "Ua Pou - French Polynesia"
    },
    {
      "value": "America/Sao_Paulo",
      "label": "Uberaba - Brazil"
    },
    {
      "value": "America/Sao_Paulo",
      "label": "Uberlandia - Brazil"
    },
    {
      "value": "Asia/Bangkok",
      "label": "Ubon Ratchathani - Thailand"
    },
    {
      "value": "Asia/Calcutta",
      "label": "Udaipur - India"
    },
    {
      "value": "Asia/Bangkok",
      "label": "Udon Thani - Thailand"
    },
    {
      "value": "Asia/Yekaterinburg",
      "label": "Ufa - Russia"
    },
    {
      "value": "Africa/Luanda",
      "label": "Uige - Angola"
    },
    {
      "value": "Pacific/Majuro",
      "label": "Ujae Atoll - Marshall Islands"
    },
    {
      "value": "Asia/Makassar",
      "label": "Ujung Pandang - Indonesia"
    },
    {
      "value": "Europe/Moscow",
      "label": "Ukhta - Russia"
    },
    {
      "value": "Africa/Nairobi",
      "label": "Ukunda - Kenya"
    },
    {
      "value": "Asia/Hovd",
      "label": "Ulaangom - Mongolia"
    },
    {
      "value": "Asia/Ulaanbaatar",
      "label": "Ulan Bator - Mongolia"
    },
    {
      "value": "Asia/Shanghai",
      "label": "Ulanhot - China"
    },
    {
      "value": "Asia/Irkutsk",
      "label": "Ulan-ude - Russia"
    },
    {
      "value": "Pacific/Guadalcanal",
      "label": "Ulawa - Solomon Islands"
    },
    {
      "value": "Asia/Ulaanbaatar",
      "label": "Uliastai - Mongolia"
    },
    {
      "value": "Asia/Seoul",
      "label": "Ulsan - South Korea"
    },
    {
      "value": "Africa/Johannesburg",
      "label": "Ulundi - South Africa"
    },
    {
      "value": "Australia/Darwin",
      "label": "Uluru - Australia"
    },
    {
      "value": "Europe/Samara",
      "label": "Ulyanovsk - Russia"
    },
    {
      "value": "Europe/Stockholm",
      "label": "Umea - Sweden"
    },
    {
      "value": "America/Toronto",
      "label": "Umiujaq - Canada"
    },
    {
      "value": "Africa/Johannesburg",
      "label": "Umtata - South Africa"
    },
    {
      "value": "America/Sao_Paulo",
      "label": "Umuarama - Brazil"
    },
    {
      "value": "America/Fortaleza",
      "label": "Una - Brazil"
    },
    {
      "value": "America/Anchorage",
      "label": "Unalakleet - United States"
    },
    {
      "value": "America/Anchorage",
      "label": "Unalaska - United States"
    },
    {
      "value": "America/St_Vincent",
      "label": "Union Island - Saint Vincent and the Grenadines"
    },
    {
      "value": "Europe/London",
      "label": "Unst - United Kingdom"
    },
    {
      "value": "America/Godthab",
      "label": "Upernavik - Greenland"
    },
    {
      "value": "Africa/Johannesburg",
      "label": "Upington - South Africa"
    },
    {
      "value": "Asia/Yekaterinburg",
      "label": "Uraj - Russia"
    },
    {
      "value": "Asia/Oral",
      "label": "Uralsk - Kazakhstan"
    },
    {
      "value": "America/Regina",
      "label": "Uranium City - Canada"
    },
    {
      "value": "Asia/Samarkand",
      "label": "Urgench - Uzbekistan"
    },
    {
      "value": "Asia/Tehran",
      "label": "Uromiyeh - Iran"
    },
    {
      "value": "America/Mexico_City",
      "label": "Uruapan - Mexico"
    },
    {
      "value": "America/Sao_Paulo",
      "label": "Uruguaiana - Brazil"
    },
    {
      "value": "Asia/Shanghai",
      "label": "Urumqi - China"
    },
    {
      "value": "Europe/Istanbul",
      "label": "Usak - Turkey"
    },
    {
      "value": "America/Argentina/Ushuaia",
      "label": "Ushuaia - Argentina"
    },
    {
      "value": "Europe/Moscow",
      "label": "Usinsk - Russia"
    },
    {
      "value": "Asia/Irkutsk",
      "label": "Ust Ilimsk - Russia"
    },
    {
      "value": "Asia/Qyzylorda",
      "label": "Ust Kamenogorsk - Kazakhstan"
    },
    {
      "value": "Asia/Irkutsk",
      "label": "Ust-Kut - Russia"
    },
    {
      "value": "Asia/Yakutsk",
      "label": "Ust-Maya - Russia"
    },
    {
      "value": "Asia/Vladivostok",
      "label": "Ust-Nera - Russia"
    },
    {
      "value": "Europe/Moscow",
      "label": "Ust-Tsylma - Russia"
    },
    {
      "value": "America/Tegucigalpa",
      "label": "Utila - Honduras"
    },
    {
      "value": "Pacific/Majuro",
      "label": "Utirik Island - Marshall Islands"
    },
    {
      "value": "Europe/Helsinki",
      "label": "Utti - Finland"
    },
    {
      "value": "America/Godthab",
      "label": "Uummannaq - Greenland"
    },
    {
      "value": "America/Chicago",
      "label": "Uvalde - United States"
    },
    {
      "value": "Africa/Lagos",
      "label": "Uyo - Nigeria"
    },
    {
      "value": "America/La_Paz",
      "label": "Uyuni - Bolivia"
    },
    {
      "value": "Europe/Kiev",
      "label": "Uzhgorod - Ukraine"
    },
    {
      "value": "Europe/Oslo",
      "label": "Værøy - Norway"
    },
    {
      "value": "Europe/Helsinki",
      "label": "Vaasa - Finland"
    },
    {
      "value": "Europe/Oslo",
      "label": "Vadsø - Norway"
    },
    {
      "value": "Atlantic/Faeroe",
      "label": "Vagar - Faroe Islands"
    },
    {
      "value": "America/Denver",
      "label": "Vail - United States"
    },
    {
      "value": "America/Toronto",
      "label": "Val D'or - Canada"
    },
    {
      "value": "America/Anchorage",
      "label": "Valdez - United States"
    },
    {
      "value": "America/Santiago",
      "label": "Valdivia - Chile"
    },
    {
      "value": "America/New_York",
      "label": "Valdosta - United States"
    },
    {
      "value": "America/Fortaleza",
      "label": "Valenca - Brazil"
    },
    {
      "value": "Europe/Paris",
      "label": "Valence - France"
    },
    {
      "value": "Europe/Madrid",
      "label": "Valencia - Spain"
    },
    {
      "value": "America/Caracas",
      "label": "Valencia - Venezuela"
    },
    {
      "value": "Europe/Paris",
      "label": "Valenciennes - France"
    },
    {
      "value": "America/Caracas",
      "label": "Valera - Venezuela"
    },
    {
      "value": "Pacific/Efate",
      "label": "Valesdir - Vanuatu"
    },
    {
      "value": "Europe/Amsterdam",
      "label": "Valkenburg - Netherlands"
    },
    {
      "value": "Europe/Madrid",
      "label": "Valladolid - Spain"
    },
    {
      "value": "America/Caracas",
      "label": "Valle De La Pascua - Venezuela"
    },
    {
      "value": "America/Bogota",
      "label": "Valledupar - Colombia"
    },
    {
      "value": "America/Santiago",
      "label": "Vallenar - Chile"
    },
    {
      "value": "America/Chicago",
      "label": "Valparaiso - United States"
    },
    {
      "value": "Europe/Istanbul",
      "label": "Van - Turkey"
    },
    {
      "value": "America/Los_Angeles",
      "label": "Van Nuys - United States"
    },
    {
      "value": "America/Vancouver",
      "label": "Vancouver - Canada"
    },
    {
      "value": "Pacific/Port_Moresby",
      "label": "Vanimo - Papua New Guinea"
    },
    {
      "value": "Europe/Paris",
      "label": "Vannes - France"
    },
    {
      "value": "Pacific/Fiji",
      "label": "Vanua Balavu - Fiji"
    },
    {
      "value": "America/Havana",
      "label": "Varadero - Cuba"
    },
    {
      "value": "Asia/Calcutta",
      "label": "Varanasi - India"
    },
    {
      "value": "Europe/Oslo",
      "label": "Vardø - Norway"
    },
    {
      "value": "America/Sao_Paulo",
      "label": "Varginha - Brazil"
    },
    {
      "value": "Europe/Helsinki",
      "label": "Varkaus - Finland"
    },
    {
      "value": "Europe/Sofia",
      "label": "Varna - Bulgaria"
    },
    {
      "value": "Europe/Stockholm",
      "label": "Vasteras - Sweden"
    },
    {
      "value": "Pacific/Tongatapu",
      "label": "Vava'u - Tonga"
    },
    {
      "value": "Europe/Stockholm",
      "label": "Vaxjo - Sweden"
    },
    {
      "value": "Europe/Moscow",
      "label": "Velikiye Luki - Russia"
    },
    {
      "value": "America/Anchorage",
      "label": "Venetie - United States"
    },
    {
      "value": "Europe/Rome",
      "label": "Venice - Italy"
    },
    {
      "value": "America/New_York",
      "label": "Venice - United States"
    },
    {
      "value": "Europe/Riga",
      "label": "Ventspils - Latvia"
    },
    {
      "value": "America/Mexico_City",
      "label": "Vera Cruz - Mexico"
    },
    {
      "value": "America/Edmonton",
      "label": "Vermillion - Canada"
    },
    {
      "value": "America/Denver",
      "label": "Vernal - United States"
    },
    {
      "value": "America/New_York",
      "label": "Vero Beach - United States"
    },
    {
      "value": "Europe/Helsinki",
      "label": "Vesivehmaa - Finland"
    },
    {
      "value": "Atlantic/Reykjavik",
      "label": "Vestmannaeyjar - Iceland"
    },
    {
      "value": "Europe/Rome",
      "label": "Vicenza - Italy"
    },
    {
      "value": "Europe/Paris",
      "label": "Vichy - France"
    },
    {
      "value": "America/Vancouver",
      "label": "Victoria - Canada"
    },
    {
      "value": "America/Chicago",
      "label": "Victoria - United States"
    },
    {
      "value": "Africa/Harare",
      "label": "Victoria Falls - Zimbabwe"
    },
    {
      "value": "Australia/Darwin",
      "label": "Victoria River Downs - Australia"
    },
    {
      "value": "America/Los_Angeles",
      "label": "Victorville - United States"
    },
    {
      "value": "America/Argentina/Salta",
      "label": "Viedma - Argentina"
    },
    {
      "value": "Europe/Vienna",
      "label": "Vienna - Austria"
    },
    {
      "value": "Asia/Vientiane",
      "label": "Vientiane - Laos"
    },
    {
      "value": "America/Puerto_Rico",
      "label": "Vieques Island - Puerto Rico"
    },
    {
      "value": "Europe/Madrid",
      "label": "Vigo - Spain"
    },
    {
      "value": "Asia/Calcutta",
      "label": "Vijayawada - India"
    },
    {
      "value": "Europe/Lisbon",
      "label": "Vila Real - Portugal"
    },
    {
      "value": "Africa/Maputo",
      "label": "Vilankulu - Mozambique"
    },
    {
      "value": "Europe/Stockholm",
      "label": "Vilhelmina - Sweden"
    },
    {
      "value": "America/Boa_Vista",
      "label": "Vilhena - Brazil"
    },
    {
      "value": "America/Cordoba",
      "label": "Villa Dolores - Argentina"
    },
    {
      "value": "America/Bogota",
      "label": "Villa Garzon - Colombia"
    },
    {
      "value": "America/Buenos_Aires",
      "label": "Villa Gesell - Argentina"
    },
    {
      "value": "America/La_Paz",
      "label": "Villa Montes - Bolivia"
    },
    {
      "value": "America/Argentina/San_Luis",
      "label": "Villa Reynolds - Argentina"
    },
    {
      "value": "Europe/Paris",
      "label": "Villacoublay - France"
    },
    {
      "value": "Europe/Rome",
      "label": "Villafranca - Italy"
    },
    {
      "value": "America/Mexico_City",
      "label": "Villahermosa - Mexico"
    },
    {
      "value": "America/Bogota",
      "label": "Villavicencio - Colombia"
    },
    {
      "value": "Europe/Vilnius",
      "label": "Vilnius - Lithuania"
    },
    {
      "value": "America/Santiago",
      "label": "Vina del Mar - Chile"
    },
    {
      "value": "America/New_York",
      "label": "Vineyard Haven MA - United States"
    },
    {
      "value": "Asia/Saigon",
      "label": "Vinh - Vietnam"
    },
    {
      "value": "Europe/Kiev",
      "label": "Vinnitsa - Ukraine"
    },
    {
      "value": "Asia/Manila",
      "label": "Virac - Philippines"
    },
    {
      "value": "America/Los_Angeles",
      "label": "Visalia - United States"
    },
    {
      "value": "Europe/Stockholm",
      "label": "Visby - Sweden"
    },
    {
      "value": "Europe/Lisbon",
      "label": "Viseu - Portugal"
    },
    {
      "value": "Asia/Calcutta",
      "label": "Vishakhapatnam - India"
    },
    {
      "value": "America/Fortaleza",
      "label": "Vitória Da Conquista - Brazil"
    },
    {
      "value": "Europe/Minsk",
      "label": "Vitebsk - Belarus"
    },
    {
      "value": "America/Sao_Paulo",
      "label": "Vitoria - Brazil"
    },
    {
      "value": "Europe/Madrid",
      "label": "Vitoria - Spain"
    },
    {
      "value": "Asia/Vladivostok",
      "label": "Vladivostok - Russia"
    },
    {
      "value": "Europe/Prague",
      "label": "Vodochody - Czech Republic"
    },
    {
      "value": "Indian/Antananarivo",
      "label": "Vohemar - Madagascar"
    },
    {
      "value": "Europe/Moscow",
      "label": "Volgograd - Russia"
    },
    {
      "value": "Europe/Moscow",
      "label": "Vologda - Russia"
    },
    {
      "value": "Atlantic/Reykjavik",
      "label": "Vopnafjörður - Iceland"
    },
    {
      "value": "Europe/Moscow",
      "label": "Vorkuta - Russia"
    },
    {
      "value": "Europe/Moscow",
      "label": "Voronezh - Russia"
    },
    {
      "value": "Africa/Johannesburg",
      "label": "Vredendal - South Africa"
    },
    {
      "value": "Africa/Johannesburg",
      "label": "Vryburg - South Africa"
    },
    {
      "value": "Pacific/Fiji",
      "label": "Vunisea - Fiji"
    },
    {
      "value": "Pacific/Noumea",
      "label": "Waala - New Caledonia"
    },
    {
      "value": "America/Halifax",
      "label": "Wabush - Canada"
    },
    {
      "value": "America/Chicago",
      "label": "Waco - United States"
    },
    {
      "value": "Europe/London",
      "label": "Waddington - United Kingdom"
    },
    {
      "value": "Africa/Khartoum",
      "label": "Wadi Halfa - Sudan"
    },
    {
      "value": "Asia/Riyadh",
      "label": "Wadi-al-dawasir - Saudi Arabia"
    },
    {
      "value": "America/Paramaribo",
      "label": "Wageningen - Suriname"
    },
    {
      "value": "Australia/Sydney",
      "label": "Wagga Wagga - Australia"
    },
    {
      "value": "Asia/Makassar",
      "label": "Waikabubak-Sumba Island - Indonesia"
    },
    {
      "value": "Pacific/Honolulu",
      "label": "Waikoloa Village - United States"
    },
    {
      "value": "Asia/Makassar",
      "label": "Waingapu - Indonesia"
    },
    {
      "value": "America/Anchorage",
      "label": "Wainwright - United States"
    },
    {
      "value": "Pacific/Auckland",
      "label": "Wairoa - New Zealand"
    },
    {
      "value": "Asia/Tokyo",
      "label": "Wajima - Japan"
    },
    {
      "value": "Africa/Nairobi",
      "label": "Wajir - Kenya"
    },
    {
      "value": "Pacific/Johnston",
      "label": "Wake island - Wake Island"
    },
    {
      "value": "Asia/Tokyo",
      "label": "Wakkanai - Japan"
    },
    {
      "value": "Pacific/Efate",
      "label": "Walaha - Vanuatu"
    },
    {
      "value": "America/Anchorage",
      "label": "Wales - United States"
    },
    {
      "value": "Australia/Sydney",
      "label": "Walgett - Australia"
    },
    {
      "value": "America/Los_Angeles",
      "label": "Walla Walla - United States"
    },
    {
      "value": "Pacific/Wallis",
      "label": "Wallis - Wallis and Futuna"
    },
    {
      "value": "America/New_York",
      "label": "Wallops Island - United States"
    },
    {
      "value": "Africa/Windhoek",
      "label": "Walvis Bay - Namibia"
    },
    {
      "value": "Asia/Jayapura",
      "label": "Wamena - Indonesia"
    },
    {
      "value": "Asia/Karachi",
      "label": "Wana - Pakistan"
    },
    {
      "value": "Pacific/Auckland",
      "label": "Wanaka - New Zealand"
    },
    {
      "value": "Asia/Taipei",
      "label": "Wang An - Taiwan"
    },
    {
      "value": "Pacific/Auckland",
      "label": "Wanganui - New Zealand"
    },
    {
      "value": "Europe/Berlin",
      "label": "Wangerooge - Germany"
    },
    {
      "value": "Asia/Shanghai",
      "label": "Wanxian - China"
    },
    {
      "value": "Pacific/Port_Moresby",
      "label": "Wapenamanda - Papua New Guinea"
    },
    {
      "value": "Asia/Jayapura",
      "label": "Waris-Papua Island - Indonesia"
    },
    {
      "value": "Europe/London",
      "label": "Warton - United Kingdom"
    },
    {
      "value": "America/New_York",
      "label": "Washington - United States"
    },
    {
      "value": "America/New_York",
      "label": "Washington County - United States"
    },
    {
      "value": "Asia/Jayapura",
      "label": "Wasior - Indonesia"
    },
    {
      "value": "America/Toronto",
      "label": "Waskaganish - Canada"
    },
    {
      "value": "America/Managua",
      "label": "Waspam - Nicaragua"
    },
    {
      "value": "America/Chicago",
      "label": "Wassau - United States"
    },
    {
      "value": "Europe/Dublin",
      "label": "Waterford - Ireland"
    },
    {
      "value": "Africa/Johannesburg",
      "label": "Waterkloof - South Africa"
    },
    {
      "value": "America/Toronto",
      "label": "Waterloo - Canada"
    },
    {
      "value": "America/Chicago",
      "label": "Waterloo - United States"
    },
    {
      "value": "America/New_York",
      "label": "Watertown - United States"
    },
    {
      "value": "America/Vancouver",
      "label": "Watson Lake - Canada"
    },
    {
      "value": "Africa/Juba",
      "label": "Wau - Sudan"
    },
    {
      "value": "America/Chicago",
      "label": "Wausau - United States"
    },
    {
      "value": "America/Toronto",
      "label": "Wawa - Canada"
    },
    {
      "value": "America/Toronto",
      "label": "Webequie - Canada"
    },
    {
      "value": "Europe/Berlin",
      "label": "Weeze - Germany"
    },
    {
      "value": "Asia/Shanghai",
      "label": "Weifang - China"
    },
    {
      "value": "Asia/Shanghai",
      "label": "Weihai - China"
    },
    {
      "value": "Australia/Brisbane",
      "label": "Weipa - Australia"
    },
    {
      "value": "Asia/Riyadh",
      "label": "Wejh - Saudi Arabia"
    },
    {
      "value": "America/Edmonton",
      "label": "Wekweeti - Canada"
    },
    {
      "value": "Pacific/Auckland",
      "label": "Wellington - New Zealand"
    },
    {
      "value": "America/Toronto",
      "label": "Wemindji - Canada"
    },
    {
      "value": "America/Los_Angeles",
      "label": "Wenatchee - United States"
    },
    {
      "value": "America/Denver",
      "label": "Wendover - United States"
    },
    {
      "value": "Asia/Shanghai",
      "label": "Wenzhou - China"
    },
    {
      "value": "America/Chicago",
      "label": "West Chicago - United States"
    },
    {
      "value": "America/New_York",
      "label": "West Hampton Beach - United States"
    },
    {
      "value": "America/New_York",
      "label": "West Palm Beach - United States"
    },
    {
      "value": "Pacific/Saipan",
      "label": "West Tinian - Northern Mariana Islands"
    },
    {
      "value": "America/Denver",
      "label": "West Yellowstone - United States"
    },
    {
      "value": "Europe/Berlin",
      "label": "Westerland - Germany"
    },
    {
      "value": "America/New_York",
      "label": "Westfield - United States"
    },
    {
      "value": "Pacific/Auckland",
      "label": "Westport - New Zealand"
    },
    {
      "value": "Europe/London",
      "label": "Westray - United Kingdom"
    },
    {
      "value": "Pacific/Port_Moresby",
      "label": "Wewak - Papua New Guinea"
    },
    {
      "value": "America/New_York",
      "label": "Weyers Cave - United States"
    },
    {
      "value": "Pacific/Auckland",
      "label": "Whakatane - New Zealand"
    },
    {
      "value": "America/Winnipeg",
      "label": "Whale Cove - Canada"
    },
    {
      "value": "Pacific/Auckland",
      "label": "Whangarei - New Zealand"
    },
    {
      "value": "America/Edmonton",
      "label": "Whatì - Canada"
    },
    {
      "value": "America/New_York",
      "label": "Wheeling - United States"
    },
    {
      "value": "America/Los_Angeles",
      "label": "Whidbey Island - United States"
    },
    {
      "value": "America/Vancouver",
      "label": "Whistler - Canada"
    },
    {
      "value": "America/Anchorage",
      "label": "White Mountain - United States"
    },
    {
      "value": "America/New_York",
      "label": "White Plains - United States"
    },
    {
      "value": "America/Edmonton",
      "label": "Whitecourt - Canada"
    },
    {
      "value": "America/Vancouver",
      "label": "Whitehorse - Canada"
    },
    {
      "value": "Pacific/Auckland",
      "label": "Whitianga - New Zealand"
    },
    {
      "value": "Australia/Adelaide",
      "label": "Whyalla - Australia"
    },
    {
      "value": "America/Toronto",
      "label": "Wiarton - Canada"
    },
    {
      "value": "America/Chicago",
      "label": "Wichita - United States"
    },
    {
      "value": "America/Chicago",
      "label": "Wichita Falls - United States"
    },
    {
      "value": "Europe/London",
      "label": "Wick - United Kingdom"
    },
    {
      "value": "Atlantic/St_Helena",
      "label": "Wide Awake - Saint Helena"
    },
    {
      "value": "Australia/Sydney",
      "label": "Wilcannia - Australia"
    },
    {
      "value": "America/New_York",
      "label": "Wildwood - United States"
    },
    {
      "value": "Europe/Berlin",
      "label": "Wilhelmshaven - Germany"
    },
    {
      "value": "America/New_York",
      "label": "Wilkes-Barre - United States"
    },
    {
      "value": "America/Curacao",
      "label": "Willemstad - Netherlands Antilles"
    },
    {
      "value": "America/St_Johns",
      "label": "Williams Harbour - Canada"
    },
    {
      "value": "America/Vancouver",
      "label": "Williams Lake - Canada"
    },
    {
      "value": "America/New_York",
      "label": "Williamsport - United States"
    },
    {
      "value": "America/Chicago",
      "label": "Williston - United States"
    },
    {
      "value": "America/New_York",
      "label": "Willoughby - United States"
    },
    {
      "value": "America/New_York",
      "label": "Willow Grove - United States"
    },
    {
      "value": "America/New_York",
      "label": "Wilmington - United States"
    },
    {
      "value": "Australia/Perth",
      "label": "Wiluna - Australia"
    },
    {
      "value": "America/New_York",
      "label": "Winder - United States"
    },
    {
      "value": "Africa/Windhoek",
      "label": "Windhoek - Namibia"
    },
    {
      "value": "Australia/Brisbane",
      "label": "Windorah - Australia"
    },
    {
      "value": "America/Toronto",
      "label": "Windsor - Canada"
    },
    {
      "value": "America/New_York",
      "label": "Windsor Locks - United States"
    },
    {
      "value": "America/Chicago",
      "label": "Winfield - United States"
    },
    {
      "value": "America/Chicago",
      "label": "Wink - United States"
    },
    {
      "value": "America/Phoenix",
      "label": "Winslow - United States"
    },
    {
      "value": "America/New_York",
      "label": "Winston-salem - United States"
    },
    {
      "value": "America/New_York",
      "label": "Winter Haven - United States"
    },
    {
      "value": "Australia/Brisbane",
      "label": "Winton - Australia"
    },
    {
      "value": "Asia/Colombo",
      "label": "Wirawila - Sri Lanka"
    },
    {
      "value": "America/Chicago",
      "label": "Wisconsin Rapids - United States"
    },
    {
      "value": "Europe/Amsterdam",
      "label": "Woensdrecht - Netherlands"
    },
    {
      "value": "America/Denver",
      "label": "Wolf Point - United States"
    },
    {
      "value": "America/Regina",
      "label": "Wollaston Lake - Canada"
    },
    {
      "value": "Australia/Sydney",
      "label": "Wollongong - Australia"
    },
    {
      "value": "Asia/Seoul",
      "label": "Wonju - South Korea"
    },
    {
      "value": "Pacific/Auckland",
      "label": "Woodbourne - New Zealand"
    },
    {
      "value": "Australia/Adelaide",
      "label": "Woomera - Australia"
    },
    {
      "value": "America/New_York",
      "label": "Worcester - United States"
    },
    {
      "value": "America/Denver",
      "label": "Worland - United States"
    },
    {
      "value": "America/Anchorage",
      "label": "Wrangell - United States"
    },
    {
      "value": "America/New_York",
      "label": "Wright - United States"
    },
    {
      "value": "America/New_York",
      "label": "Wrightstown - United States"
    },
    {
      "value": "America/Edmonton",
      "label": "Wrigley - Canada"
    },
    {
      "value": "Europe/Warsaw",
      "label": "Wroclaw - Poland"
    },
    {
      "value": "Asia/Shanghai",
      "label": "Wuhai - China"
    },
    {
      "value": "Asia/Shanghai",
      "label": "Wuhan - China"
    },
    {
      "value": "America/Toronto",
      "label": "Wunnumin Lake - Canada"
    },
    {
      "value": "Asia/Shanghai",
      "label": "Wuxi - China"
    },
    {
      "value": "Asia/Shanghai",
      "label": "Wuyishan - China"
    },
    {
      "value": "Asia/Shanghai",
      "label": "Wuzhou - China"
    },
    {
      "value": "Europe/London",
      "label": "Wycombe - United Kingdom"
    },
    {
      "value": "Australia/Perth",
      "label": "Wyndham - Australia"
    },
    {
      "value": "Europe/London",
      "label": "Wyton - United Kingdom"
    },
    {
      "value": "Africa/Luanda",
      "label": "Xangongo - Angola"
    },
    {
      "value": "Asia/Shanghai",
      "label": "Xi\\'AN - China"
    },
    {
      "value": "Asia/Shanghai",
      "label": "Xiahe city - China"
    },
    {
      "value": "Asia/Shanghai",
      "label": "Xiamen - China"
    },
    {
      "value": "Asia/Shanghai",
      "label": "Xi'an - China"
    },
    {
      "value": "Asia/Shanghai",
      "label": "Xiangfan - China"
    },
    {
      "value": "Asia/Shanghai",
      "label": "Xichang - China"
    },
    {
      "value": "Asia/Shanghai",
      "label": "Xilinhot - China"
    },
    {
      "value": "Asia/Shanghai",
      "label": "Xingyi - China"
    },
    {
      "value": "Asia/Shanghai",
      "label": "Xining - China"
    },
    {
      "value": "Asia/Shanghai",
      "label": "Xinyuan - China"
    },
    {
      "value": "Asia/Shanghai",
      "label": "Xuzhou - China"
    },
    {
      "value": "America/La_Paz",
      "label": "Yacuiba - Bolivia"
    },
    {
      "value": "America/Anchorage",
      "label": "Yakataga - United States"
    },
    {
      "value": "America/Los_Angeles",
      "label": "Yakima - United States"
    },
    {
      "value": "Asia/Tokyo",
      "label": "Yakushima - Japan"
    },
    {
      "value": "America/Anchorage",
      "label": "Yakutat - United States"
    },
    {
      "value": "Asia/Yakutsk",
      "label": "Yakutia - Russia"
    },
    {
      "value": "Asia/Yakutsk",
      "label": "Yakutsk - Russia"
    },
    {
      "value": "Australia/Adelaide",
      "label": "Yalata - Australia"
    },
    {
      "value": "Australia/Brisbane",
      "label": "Yam Island - Australia"
    },
    {
      "value": "Asia/Tokyo",
      "label": "Yamagata - Japan"
    },
    {
      "value": "Asia/Tokyo",
      "label": "Yamaguchi - Japan"
    },
    {
      "value": "Africa/Abidjan",
      "label": "Yamoussoukro - Cote d'Ivoire"
    },
    {
      "value": "Asia/Shanghai",
      "label": "Yan'an - China"
    },
    {
      "value": "Asia/Shanghai",
      "label": "Yancheng - China"
    },
    {
      "value": "Pacific/Guadalcanal",
      "label": "Yandina - Solomon Islands"
    },
    {
      "value": "Asia/Rangoon",
      "label": "Yangon - Burma"
    },
    {
      "value": "Asia/Shanghai",
      "label": "Yangzhou - China"
    },
    {
      "value": "Asia/Shanghai",
      "label": "Yanji - China"
    },
    {
      "value": "America/Chicago",
      "label": "Yankton - United States"
    },
    {
      "value": "Asia/Shanghai",
      "label": "Yantai - China"
    },
    {
      "value": "Africa/Douala",
      "label": "Yaounde - Cameroon"
    },
    {
      "value": "Pacific/Truk",
      "label": "Yap - Micronesia"
    },
    {
      "value": "America/Halifax",
      "label": "Yarmouth - Canada"
    },
    {
      "value": "Europe/Moscow",
      "label": "Yaroslavl - Russia"
    },
    {
      "value": "Asia/Tehran",
      "label": "Yasuj - Iran"
    },
    {
      "value": "Asia/Tehran",
      "label": "Yazd - Iran"
    },
    {
      "value": "Asia/Rangoon",
      "label": "Ye - Burma"
    },
    {
      "value": "Asia/Seoul",
      "label": "Yechon - South Korea"
    },
    {
      "value": "Asia/Yekaterinburg",
      "label": "Yekaterinburg - Russia"
    },
    {
      "value": "America/Edmonton",
      "label": "Yellowknife - Canada"
    },
    {
      "value": "Asia/Riyadh",
      "label": "Yenbo - Saudi Arabia"
    },
    {
      "value": "Africa/Freetown",
      "label": "Yengema - Sierra Leone"
    },
    {
      "value": "Europe/Istanbul",
      "label": "Yenisehir - Turkey"
    },
    {
      "value": "Asia/Krasnoyarsk",
      "label": "Yeniseysk - Russia"
    },
    {
      "value": "Asia/Seoul",
      "label": "Yeosu - South Korea"
    },
    {
      "value": "Europe/London",
      "label": "Yeovilton - United Kingdom"
    },
    {
      "value": "Asia/Irkutsk",
      "label": "Yerbogachen - Russia"
    },
    {
      "value": "Asia/Shanghai",
      "label": "Yibin - China"
    },
    {
      "value": "Asia/Shanghai",
      "label": "Yichang - China"
    },
    {
      "value": "Asia/Shanghai",
      "label": "Yichun - China"
    },
    {
      "value": "Asia/Shanghai",
      "label": "Yinchuan - China"
    },
    {
      "value": "Asia/Shanghai",
      "label": "Yinchun - China"
    },
    {
      "value": "Asia/Shanghai",
      "label": "Yining - China"
    },
    {
      "value": "Asia/Shanghai",
      "label": "Yiwu - China"
    },
    {
      "value": "Europe/Helsinki",
      "label": "Ylivieska-raudaskyla - Finland"
    },
    {
      "value": "Asia/Jakarta",
      "label": "Yogyakarta - Indonesia"
    },
    {
      "value": "Asia/Tokyo",
      "label": "Yokota - Japan"
    },
    {
      "value": "Africa/Lagos",
      "label": "Yola - Nigeria"
    },
    {
      "value": "Asia/Tokyo",
      "label": "Yonaguni Jima - Japan"
    },
    {
      "value": "Asia/Shanghai",
      "label": "Yongzhou - China"
    },
    {
      "value": "America/Bogota",
      "label": "Yopal - Colombia"
    },
    {
      "value": "America/Winnipeg",
      "label": "York Landing - Canada"
    },
    {
      "value": "Australia/Brisbane",
      "label": "Yorke Island - Australia"
    },
    {
      "value": "America/Regina",
      "label": "Yorkton - Canada"
    },
    {
      "value": "Asia/Tokyo",
      "label": "Yoron - Japan"
    },
    {
      "value": "Europe/Moscow",
      "label": "Yoshkar-Ola - Russia"
    },
    {
      "value": "America/New_York",
      "label": "Youngstown - United States"
    },
    {
      "value": "America/Los_Angeles",
      "label": "Yuba City - United States"
    },
    {
      "value": "Australia/Darwin",
      "label": "Yuendumu  - Australia"
    },
    {
      "value": "Asia/Shanghai",
      "label": "Yulin - China"
    },
    {
      "value": "America/Phoenix",
      "label": "Yuma - United States"
    },
    {
      "value": "Asia/Shanghai",
      "label": "Yuncheng - China"
    },
    {
      "value": "America/Lima",
      "label": "Yurimaguas - Peru"
    },
    {
      "value": "Asia/Shanghai",
      "label": "Yushu - China"
    },
    {
      "value": "Asia/Srednekolymsk",
      "label": "Yuzhno-sakhalinsk - Russia"
    },
    {
      "value": "Asia/Tehran",
      "label": "Zabol - Iran"
    },
    {
      "value": "America/Mexico_City",
      "label": "Zacatecas - Mexico"
    },
    {
      "value": "Europe/Zagreb",
      "label": "Zadar - Croatia"
    },
    {
      "value": "Africa/Casablanca",
      "label": "Zagora - Morocco"
    },
    {
      "value": "Europe/Zagreb",
      "label": "Zagreb - Croatia"
    },
    {
      "value": "Asia/Tehran",
      "label": "Zahedan - Iran"
    },
    {
      "value": "Europe/Athens",
      "label": "Zakynthos - Greece"
    },
    {
      "value": "Asia/Manila",
      "label": "Zamboanga - Philippines"
    },
    {
      "value": "America/Mexico_City",
      "label": "Zamora - Mexico"
    },
    {
      "value": "America/Paramaribo",
      "label": "Zandery - Suriname"
    },
    {
      "value": "Asia/Tehran",
      "label": "Zanjan - Iran"
    },
    {
      "value": "Africa/Dar_es_Salaam",
      "label": "Zanzibar - Tanzania"
    },
    {
      "value": "America/Argentina/Salta",
      "label": "ZAPALA - Argentina"
    },
    {
      "value": "Europe/Kiev",
      "label": "Zaporozhye - Ukraine"
    },
    {
      "value": "Asia/Baku",
      "label": "Zaqatala - Azerbaijan"
    },
    {
      "value": "Asia/Samarkand",
      "label": "Zarafshan - Uzbekistan"
    },
    {
      "value": "Europe/Madrid",
      "label": "Zaragoza - Spain"
    },
    {
      "value": "Asia/Kabul",
      "label": "Zaranj - Afghanistan"
    },
    {
      "value": "Africa/Lagos",
      "label": "Zaria - Nigeria"
    },
    {
      "value": "Africa/Algiers",
      "label": "Zarzaitine - Algeria"
    },
    {
      "value": "America/New_York",
      "label": "Zephyrhills - United States"
    },
    {
      "value": "Asia/Calcutta",
      "label": "Zero - India"
    },
    {
      "value": "Asia/Shanghai",
      "label": "Zhanjiang - China"
    },
    {
      "value": "Asia/Shanghai",
      "label": "Zhaotong - China"
    },
    {
      "value": "Asia/Shanghai",
      "label": "Zhengzhou - China"
    },
    {
      "value": "Asia/Qyzylorda",
      "label": "Zhezkazgan - Kazakhstan"
    },
    {
      "value": "Asia/Shanghai",
      "label": "Zhijiang - China"
    },
    {
      "value": "Asia/Karachi",
      "label": "Zhob - Pakistan"
    },
    {
      "value": "Asia/Shanghai",
      "label": "Zhongwei - China"
    },
    {
      "value": "Asia/Shanghai",
      "label": "Zhoushan - China"
    },
    {
      "value": "Asia/Shanghai",
      "label": "Zhuhai - China"
    },
    {
      "value": "Europe/Kiev",
      "label": "Zhytomyr - Ukraine"
    },
    {
      "value": "Europe/Warsaw",
      "label": "Zielona Gora - Poland"
    },
    {
      "value": "Africa/Dakar",
      "label": "Ziguinchor - Senegal"
    },
    {
      "value": "America/Mexico_City",
      "label": "Zihuatanejo - Mexico"
    },
    {
      "value": "Africa/Niamey",
      "label": "Zinder - Niger"
    },
    {
      "value": "Europe/Brussels",
      "label": "Zoersel - Belgium"
    },
    {
      "value": "Europe/Istanbul",
      "label": "Zonguldak - Turkey"
    },
    {
      "value": "Africa/Nouakchott",
      "label": "Zouerat - Mauritania"
    },
    {
      "value": "Asia/Shanghai",
      "label": "Zunyi - China"
    },
    {
      "value": "Europe/Zurich",
      "label": "Zurich - Switzerland"
    },
    {
      "value": "Europe/Berlin",
      "label": "Zweibruecken - Germany"
    },
    {
      "value": "Asia/Srednekolymsk",
      "label": "Zyryanka - Russia"
    }
  ]

  export default cityList