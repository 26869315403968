import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import ToCall from "../components/tocall"
import SocialSharing from "../components/socialsharing"
import PopularPosts from "../components/popularposts"
import ToMeet from "../components/tomeet"


class Call extends React.Component {
    render() {
        const { data } = this.props
        const siteTitle = data.site.siteMetadata.title

        return (
        <Layout location={this.props.location} title={siteTitle}>
            <SEO title="Call" />
            <div className="row">
                <div className="col-sm-12 col-lg-8">
                    <div className="col-sm-12 col-lg-10">
                    <p>
                        What time is it in Moscow? What time is it in Colombo? What time is it in London?
                    </p>
                    <p>
                        Are you trying to set up a meeting with someone in another part of the world? Communicating across the world
                        is a challenge; remembering how many hours ahead and behind somewhere is is a challenge.
                    </p>
                    <p>
                      Instead try asking when is it time to call...
                    </p>
                    </div>
                    <ToMeet />
                </div>
            </div>
            <div className="row col-sm-12">
                <SocialSharing location={this.props.location} title={siteTitle} siteUrl={data.site.siteMetadata.siteUrl}/>
                
            </div>
            <div className="row col-sm-12"><PopularPosts /></div>        
            
        </Layout>
        )
    }
}

export default Call

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        siteUrl
      }
    }
  }
`